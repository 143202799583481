/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { HOST } from "utils/config";
import { get } from "utils/helper";
import RewardItem from "./components/rewardItem";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import { Grid, Tabs, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import FormField from "components/FormField";
import SuiSelect from "components/SuiSelect";
// Images
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoAsana from "assets/images/small-logos/logo-asana.svg";
import PropTypes from "prop-types";

import { LootoList20, LootoList18, LootoList18_2, LootoList16 } from "components/Text/TextStyle";

function Betinfo({ formData, stockTabs, setValue, getValues, errors, fields }) {
  const [value, setValueNaja] = useState(0);

  const handleChange = (event, newValue) => {
    setValueNaja(newValue);
  };

  return (
    <Card>
      <TabContext value={value}>
        <SuiBox p={3} lineHeight={1}>
          <SuiBox mb={1}>
            <LootoList18_2>ข้อมูลการแทง</LootoList18_2>
          </SuiBox>
          {/* <Tabs
                orientation={tabsOrientation}
                variant="scrollable"
                scrollButtons="auto"
                value={tabValues}
                onChange={tabHandler}
              > */}
          <Tabs
            style={{ background: "#FFF" }}
            orientation={"horizontal"}
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
          >
            {stockTabs?.map((e, i) => (
              <Tab label={e?.gatcha_name} value={i} key={i} />
            ))}
          </Tabs>
        </SuiBox>
        <SuiBox pt={2} pb={3} px={3}>
          {fields?.map((e, i) => (
            <TabPanel value={i} key={i}>
              <RewardItem
                reward={e}
                errors={errors}
                formData={formData}
                getValues={getValues}
                setValue={setValue}
                keyArr={i}
              />
            </TabPanel>
          ))}
          <Divider />
        </SuiBox>
      </TabContext>
    </Card>
  );
}
Betinfo.propTypes = {
  fields: PropTypes.array,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  getValues: PropTypes.func,
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  stockTabs: PropTypes.array,
};
export default Betinfo;
