// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

import { Collapse, Grid, CardContent, Divider } from "@mui/material";
import Switch from "@mui/material/Switch";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import FormField from "components/FormField";
// Setting pages components
import TableCell from "memberpage/pages/account/settings/components/TableCell";
import selectData from "pages/users/createmember/components/BasicInfo/data/selectData";
import { useSoftUIController } from "context";
import styles from "components/Sidenav/styles/sidenavCard";
import { display } from "@mui/system";

import { LootoList20, LootoList18, LootoList16 } from "components/Text/TextStyle";

function generateOptions(min, max, step) {
  console.log(min, max, step);
  let options = [];
  for (let i = min; i <= max; i += step) {
    options.push({ value: i, label: `${i}%` });
  }

  return options;
}
function KeepMember({ control, errors, getValues, setValue, fields }) {
  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  // const options = generateOptions(10, 50, 0.5);
  // console.log(options);
  const [open, setOpen] = useState(false);
  const [renderedValues, setRenderedValues] = useState({});
  const handleChange = (index, newValue) => {
    // Update the rendered value based on the select input
    const updatedRenderedValues = { ...renderedValues };
    updatedRenderedValues[index] = newValue;
    setRenderedValues(updatedRenderedValues);
  };
  return (
    <Card id="notifications" className="overflow-visible">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <LootoList20>แบ่งหุ้น / เก็บของ</LootoList20>
        </SuiBox>
      </SuiBox>
      <SuiBox pb={3} px={3}>
        <SuiBox minWidth="auto">
          <SuiBox minWidth="auto" pt={2}>
            <Grid container spacing={4}>
              <Grid item sm={3}>
                <LootoList18>ตลาดหวยหลัก</LootoList18>
              </Grid>
              <Grid item sm={2}>
                <LootoList18>ทั้งหมด</LootoList18>
              </Grid>
              <Grid item sm={2.5}>
                <LootoList18>ผลักขึ้นสายบน</LootoList18>
              </Grid>
              <Grid item sm={3}>
                <LootoList18>ถือสู้</LootoList18>
              </Grid>
            </Grid>
          </SuiBox>
          <Divider />
          {fields.map((values, i) => (
            <>
              <SuiBox minWidth="auto" key={i} pt={2}>
                <Grid container spacing={4}>
                  <Grid item sm={3}>
                    <SuiBox display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                      <LootoList18>{values.gatcha_name} </LootoList18>&nbsp;
                    </SuiBox>
                  </Grid>
                  <Grid item sm={2}>
                    <SuiTypography variant="caption">
                      สูงสุด {values.all_stock_display}
                    </SuiTypography>
                  </Grid>
                  <Grid item sm={2.5}>
                    <SuiTypography variant="caption">
                      {values.fighting_mem_max -
                        (renderedValues[i] !== undefined
                          ? renderedValues[i]
                          : values.fighting_mem)}{" "}
                      %
                    </SuiTypography>
                  </Grid>
                  <Grid item sm={3}>
                    <SuiSelect
                      placeholder="แบ่งหุ้น"
                      // options={fightMax(values.fighting_mem_max, 0.5)}
                      options={generateOptions(
                        values.fighting_mem_min,
                        values.fighting_mem_max,
                        0.5
                      )}
                      value={{
                        value:
                          renderedValues[i] !== undefined ? renderedValues[i] : values.fighting_mem,
                        label: `${
                          renderedValues[i] !== undefined ? renderedValues[i] : values.fighting_mem
                        }%`,
                      }}
                      // onChange={(value) => onChangeFight(value, i)}
                      onChange={(value) => {
                        handleChange(i, value.value);
                        setValue(`stock[${i}].fighting_mem`, value.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </SuiBox>
              <Divider />
            </>
          ))}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}
KeepMember.propTypes = {
  fields: PropTypes.array,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  getValues: PropTypes.func,
  control: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
export default KeepMember;
