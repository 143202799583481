/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Swal from "sweetalert2";
// @mui material components
import Grid from "@mui/material/Grid";
import { HOST } from "utils/config";
import { get, post } from "utils/helper";
import { useHistory } from "react-router";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

import SuiButton from "components/SuiButton";
// Settings page components
import { useDispatch, connect } from "react-redux";
import Header from "pages/users/createmember/components/Header";
import BasicInfo from "pages/users/createmember/components/BasicInfo";

import Keep from "pages/users/createmember/components/Keep";
import KeepMember from "pages/users/createmember/components/KeepMember";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Betinfo from "pages/users/createmember/components/Betinfo";
import { useParams } from "react-router-dom";
import Footer from "layouts/Footer";
import Card from "@mui/material/Card";
import validations from "./schemas/validations";
function CopyMember(rest) {
  const history = useHistory();
  const { authentication } = rest;
  const [stock, setstock] = useState({});
  const currentValidation = validations[0];
  const { uid } = useParams();
  const fetchGetRegister = async () => {
    const { data } = await get(`${HOST}api/register/GetDataForEditUser?user_id=${uid}`);
    data.result.profile.role = data.result.profile.role.toUpperCase();
    data.result.profile.credit = data.result.profile.credit_balance;
    data.result.profile.user_name = authentication?.user?.user_name;
    data.result.profile.username = "";
    data.result.profile.password = "";
    data.result.profile.repeatPassword = "";
    data.result.profile.name = "";
    data.result.profile.phone = "";
    setstock(data.result);
  };
  // const setdefault = async () => {
  //   const { data } = await get(`${HOST}api/register/GetDataForRegister`);

  //   setstock(data.result);
  // };

  useEffect(() => {
    fetchGetRegister();
  }, []);
  const handleSubmit = async (event) => {
    try {
      event.profile.user_name = event.profile.user_name + event.profile.username;
      event.profile.credit = parseFloat(event.profile.credit);

      const { data } = await post(`${HOST}api/register/Register`, event);

      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      history.push("/manage/member/all");
    } catch (error) {
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่อีกครั้ง", "error");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={4}>
        {stock.profile && (
          <Formik initialValues={stock} validationSchema={validations} onSubmit={handleSubmit}>
            {(formProps) => (
              <Form autoComplete="off">
                <SuiBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Header />
                    </Grid>
                    <Grid item xs={12}>
                      <BasicInfo formData={formProps} />
                    </Grid>
                    <Grid item xs={12}>
                      {formProps.values.profile.role === "MEMBER" ? (
                        <KeepMember formData={formProps} />
                      ) : (
                        <Keep formData={formProps} />
                      )}
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Betinfo formData={formProps} stockTabs={stock.stock} />
                    </Grid> */}

                    <Grid item xs={12}>
                      <Card>
                        <SuiButton
                          disabled={false}
                          type="submit"
                          variant="gradient"
                          buttonColor="success"
                        >
                          สร้างสมาชิก
                        </SuiButton>
                      </Card>
                    </Grid>
                  </Grid>
                </SuiBox>
              </Form>
            )}
          </Formik>
        )}
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}
const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(CopyMember);
