import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import SuiBox from "components/SuiBox";
const ManageDiscount = (props) => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>555</SuiBox>
      <Footer />
    </DashboardLayout>
  );
};

ManageDiscount.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageDiscount);
