import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";

import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";

import Style from "../style.js";
import "../index.scss";
import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Swal from "sweetalert2";
import { TextField, TextareaAutosize } from "@mui/material";

const useStyle = makeStyles(Style);
function PlaceCard({ formData, onsetTotaldata }) {
  const classes = useStyle();

  const [lottoNumber, setLottoNumber] = useState([]);
  const [lottoNumberUp, setLottoNumberUp] = useState("");
  const [lottoNumberDown, setLottoNumberDown] = useState("");
  const [lottoNumberMulti, setLottonumberMulti] = useState("");
  const [textValue, setTextValue] = useState();
  const [inputList, setInputList] = useState([{ gatchaNumber: "", numberUp: "", numberDown: "" }]);

  const handleInputChange = (e) => {
    const inputLenght = e.target.value.length;
    const { name, value } = e.target;
    // formData.setFieldValue(`gatchaFlashNumber[0].${name}`, value);
    if (name == "numberUp") {
      setLottoNumberUp(value);
    }
    if (name == "numberDown") {
      setLottoNumberDown(value);
    }
    if (name == "numberMulti") {
      setLottonumberMulti(value);
    }
    // document.getElementById("gatchaNumber").value = "";
  };

  const onChangeReverseNumber = (event) => {
    const rename = event.replace(/[\/ \-\n\+\*\@\.]/g, "/");
    const splitNumbers = rename.match(/\d{1,3}/g)?.join("/")

    if (splitNumbers){
      const splitValues = splitNumbers?.split("/")
      const filteredValues = splitValues?.filter((item) => item !== "");
      setLottoNumber((lottoNumber) => [...filteredValues]);
    }else {
      setLottoNumber([])
    }
  };

  const deleteLottoItem = (index, values) => {
    setLottoNumber(lottoNumber.filter((e) => e !== values));
  };
  const deleteLottoAllItem = () => {
    setTextValue("");
    setLottoNumber([]);
  };

  const reverstNumber = () => {
    const numberForReverse = lottoNumber.pop();
    if (!numberForReverse) {
      Swal.fire("ผิดพลาด!", "กรุณาใส่ตัวเลขก่อนกลับเลข", "error");
    } else {
      const numberReverse = numberForReverse
        ? numberForReverse.toString().split("").reverse().join("")
        : numberForReverse;
      const exists = lottoNumber.find((p) => p == numberReverse);
      if (!exists) {
        setLottoNumber((lottoNumber) => [...lottoNumber, numberForReverse, numberReverse]);
      }
    }
  };
  const reverstAllNumber = () => {
    if (lottoNumber.length === 0) {
      Swal.fire("ผิดพลาด!", "กรุณาใส่ตัวเลขก่อนกลับเลข", "error");
    } else {
      let datafor = [];
      lottoNumber.map((e, i) => {
        if (!lottoNumber.find((p) => p == e.toString().split("").reverse().join(""))) {
          datafor.push(e.toString().split("").reverse().join(""));
        }
      });
      setLottoNumber((lottoNumber) => [...lottoNumber, ...datafor]);
    }
  };

  const handleAddBill = async () => {
    if (lottoNumber < 1) {
      Swal.fire("ผิดพลาด!", "กรุณาใส่ตัวเลข", "error");
    } else if (lottoNumberDown < 1 && lottoNumberUp < 1 && lottoNumberMulti < 1) {
      Swal.fire("ผิดพลาด!", "จำนวนเงินไม่ถูกต้อง", "error");
    } else {
      const addBillArray = {};
      const addBillArrayRun = {};
      const addBillArrayMulti = {};
      let totalBill = [];
      const filteredValuesMulti = lottoNumber.filter((item) => item.length === 3);
      const filteredValuesRun = lottoNumber.filter((item) => item.length === 1);
      const filteredValuesTwo = lottoNumber.filter((item) => item.length === 2);
      if (filteredValuesTwo.length > 0) {
        addBillArray.type = "2";
        addBillArray.gatchaNumber = filteredValuesTwo;
        addBillArray.numberUp = lottoNumberUp;
        addBillArray.numberDown = lottoNumberDown;
        if (lottoNumberUp > 0 || lottoNumberDown > 0) {
          totalBill.push(addBillArray);
        }
      }
      if (filteredValuesRun.length > 0) {
        addBillArrayRun.type = "1";
        addBillArrayRun.gatchaNumber = filteredValuesRun;
        addBillArrayRun.numberUp = lottoNumberUp;
        addBillArrayRun.numberDown = lottoNumberDown;
        if (lottoNumberUp > 0 || lottoNumberDown > 0) {
          totalBill.push(addBillArrayRun);
        }
      }
      if (filteredValuesMulti.length > 0) {
        addBillArrayMulti.type = "3";
        addBillArrayMulti.gatchaNumber = filteredValuesMulti;
        addBillArrayMulti.numberUp = lottoNumberUp;
        addBillArrayMulti.numberMulti = lottoNumberMulti;
        if (lottoNumberUp > 0 || lottoNumberMulti > 0) {
          totalBill.push(addBillArrayMulti);
        }
      }

      formData.setFieldValue(`gatchaFlashNumber`, [
        ...formData.values.gatchaFlashNumber,
        ...totalBill,
      ]);
      //   addBillArray.numberMulti = lottoNumberMulti;

      //   formData.setFieldValue(`gatchaFlashNumber`, [
      //     ...formData.values.gatchaFlashNumber,
      //     addBillArray,
      //   ]);
      setLottoNumberUp("");
      setLottoNumberDown("");
      setLottonumberMulti("");
      setTextValue("");
      // document.getElementById("numberUp").value = "";
      // document.getElementById("numberDown").value = "";
      await Promise.resolve(formData.values.gatchaFlashNumber);
      setLottoNumber([]);
    }
  };

  useEffect(() => {
    // console.log("เข้าาา", formData);
    if (formData.values.gatchaFlashNumber.length > 0) {
      var num = 0;
      formData.values.gatchaFlashNumber.map((e, i) => {
        for (var x = 0; x < e.gatchaNumber.length; x++) {
          num =
            num +
            parseInt(e.numberUp || 0) +
            parseInt(e.numberDown || 0) +
            parseInt(e.numberMulti || 0);
        }
      });
      onsetTotaldata(num, "fast");
    }
  }, [formData.values.gatchaFlashNumber]);

  return (
    <div className="flash-card">
      <div className="lotto-number-item">
        {lottoNumber?.map((values, index) => (
          <div
            key={index}
            className={
              values.length > 3 ? `lotto-number-item-value` : `lotto-number-item-blue-value`
            }
            onClick={() => deleteLottoItem(index, values)}
          >
            <p>{values}</p>
          </div>
        ))}
      </div>
      {lottoNumber.length > 0 && (
        <div className="lotto-number-delete d-flex " onClick={() => deleteLottoAllItem()}>
          <DeleteIcon />
          ยกเลิก
        </div>
      )}
      <div style={{ marginBottom: 8 }}>
        <SuiInput
          style={{ width: "100%", borderRadius: 8, padding: 16 }}
          fullWidth
          multiline
          rows={10}
          type="text"
          name={"gatchaNumber"}
          value={textValue}
          placeholder="กรอกตัวเลข 1-3 หลัก จากนั้นใช้เครื่องหมาย / เพื่อแบ่งตัวเลข ตัวอย่างเช่น 7/88/168"
          onChange={(e) => {
            onChangeReverseNumber(e.target.value);
            setTextValue(e.target.value);
            // formData.setFieldValue("gatchaNumber", e.target.value)
          }}
          onKeyPress={(event) => {
            if (!/[0-9/ -/n+*@.]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </div>
      <div className="two-digits ">
        <div className="block-flex">
          <p>ใส่ราคา</p>
          <p>บน</p>
          <div className="input-box">
            <SuiInput
              id="numberUp"
              type="text"
              placeholder="0"
              name={"numberUp"}
              onChange={(e) => {
                handleInputChange(e);
                // formData.setFieldValue("numberUp", e.target.value)
              }}
              value={lottoNumberUp}
              // error={lottoNumberDown < 1 && lottoNumberUp < 1}
              // value={lottoNumberUp}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <p>ล่าง</p>
          <div className="input-box">
            <SuiInput
              id="numberDown"
              type="text"
              placeholder="0"
              name={"numberDown"}
              onChange={(e) => {
                handleInputChange(e);
                // formData.setFieldValue("numberDown", e.target.value)
              }}
              value={lottoNumberDown}
              // error={lottoNumberDown < 1 && lottoNumberUp < 1}
              // value={lottoNumberDown}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>{" "}
          <p>โต๊ด</p>
          <div className="input-box">
            <SuiInput
              id="numberMulti"
              type="text"
              placeholder="0"
              name={"numberMulti"}
              onChange={(e) => {
                handleInputChange(e);
              }}
              value={lottoNumberMulti}
              // error={lottoNumberDown < 1 && lottoNumberUp < 1}
              // value={lottoNumberDown}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="two-digits-bill">
        <div className="button-contain">
          <SuiButton
            variant="gradient"
            buttonColor="info"
            // type="submit"
            fullWidth
            customClass={classes.submitButton}
            onClick={() => handleAddBill()}
          >
            เพิ่มบิล
          </SuiButton>
        </div>
      </div>
    </div>
  );
}
PlaceCard.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onsetTotaldata: PropTypes.func,
};
export default PlaceCard;
