import * as React from 'react';
import { createStyles } from '@mui/styles';

const Style = createStyles({
    root: {
      background: '#fff',
      boxShadow: '0px 0px 5px 2px #dadada',
      textAlign: 'center',
    },
    type: {
        background: '#CEE9F6',
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '1rem',
        padding: '0.5rem 0',
      },
      button: {
        padding: '0rem',
        width: '5rem',
        margin: '0 0.5rem'
      },
      bath: {
        marginTop: '0.8rem',
        color: '#000',
        fontSize: '12px'
      },
      inputCenter: {
        textAlign: 'center'
      },
      inputRight: {
        textAlign: 'right'
      },

      submitButton: {
        width: '7rem',
        fontSize: '1rem',
        lineHeight: '1rem',
        padding: '0',
        margin: '1rem 1rem',
        background: '#0890D0'
      },
      cancelButton: {
        width: '7rem',
        fontSize: '1rem',
        fontWeight: 'bold',
        lineHeight: '1rem',
        padding: '0',
        margin: '1rem 1rem',
        background: '#fff',
        color: '#000'
      }
  });

  export default Style