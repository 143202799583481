/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import PropTypes from "prop-types";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";

// Settings page components
// import HookForm from "components/HookForm";
import HookForm from "components/HookForm";
// Data
import selectData from "pages/users/createmember/components/BasicInfo/data/selectData";
import SuiButton from "components/SuiButton";
import { useDispatch, connect, useSelector } from "react-redux";
import SuiInput from "components/SuiInput";
function BasicInfo({
  setValue,
  getValues,
  errors,
  formData,
  isEdit,
  handleOpenChangepass,
  handleOpenChangeBalance,
  credituser,
}) {
  const authentication = useSelector((state) => state.authentication);
  const [isBlur, setIsBlur] = useState({});
  const handleOnBlur = (e) => {
    console.log(e.target.name, "eieie");
    const updatedRenderedValues = { ...isBlur };
    updatedRenderedValues[e.target.name] = true;
    setIsBlur(updatedRenderedValues);
  };

  //
  return (
    <Card className="overflow-visible">
      <SuiBox p={3}>
        <SuiTypography variant="h5">ข้อมูลสมาชิก</SuiTypography>
      </SuiBox>
      <SuiBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SuiBox display="flex" flexDirection="column" height="100%">
              <SuiBox mb={1} lineHeight={0} display="inline-block">
                <SuiTypography
                  component="label"
                  variant={"caption"}
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  ระดับ
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                // control={formData}
                // errors={errors?.profile?.role}
                // {...formData("profile.role")}
                placeholder="โปรดเลือกระดับ"
                isDisabled={true}
                options={
                  authentication?.user?.roleselectData?.role_id === "PROGRAMER"
                    ? selectData.roleAdmin
                    : selectData.roleAgent
                }
                // onChange={(value) => formData.setFieldValue("profile.role", value.value)}
                // onBlur={formData.handleBlur}
                name="profile.role"
                value={{
                  value: getValues()?.profile?.role,
                  label: getValues()?.profile?.role,
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            {isEdit ? (
              <SuiBox display="flex">
                <HookForm
                  label="จำนวนเงิน"
                  placeholder="โปรดกรอกจำนวนเครดิต"
                  name={"profile.credit"}
                  control={formData}
                  errors={errors?.profile?.credit}
                  rule={{
                    required: "กรุณากรอกจำนวนเครดิต",
                  }}
                  // {...formData("profile.credit")}
                  // onChange={formData.handleChange}
                  // value={formData.values.profile.credit}
                  isDisabled={true}
                  startAdornment={
                    <InputAdornment position="start">
                      {credituser?.user?.credit_balance_display}/
                    </InputAdornment>
                  }
                  error={errors?.profile?.credit}
                  // success={!errors?.profile?.credit}
                  // error={formData.errors?.profile?.credit && formData.touched?.profile?.credit}
                  // success={
                  //   formData.values?.profile?.credit.length > 0 && !formData.errors?.profile?.credit
                  // }
                />
                <SuiBox mt={3} ml={2}>
                  <SuiButton buttonColor="error" onClick={() => handleOpenChangeBalance()}>
                    แก้ไขวงเงิน
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            ) : (
              <HookForm
                label="จำนวนเงิน"
                placeholder="โปรดกรอกจำนวนเครดิต"
                control={formData}
                errors={errors?.profile?.credit}
                name={"profile.credit"}
                rule={{
                  required: "กรุณากรอกจำนวนเครดิต",
                }}
                // {...formData("profile.credit")}
                // onChange={formData.handleChange}
                // value={formData.values.profile.credit}
                startAdornment={
                  <InputAdornment position="start">
                    {credituser?.user?.credit_balance_display}/
                  </InputAdornment>
                }
                // onBlur={(e) => handleOnBlur(e)}
                error={errors?.profile?.credit}
                // success={!errors?.profile?.credit && isBlur["profile.credit"]}
                // error={formData.errors?.profile?.credit && formData.touched?.profile?.credit}
                // success={
                //   formData.values?.profile?.credit.length > 0 && !formData.errors?.profile?.credit
                // }
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                {isEdit ? (
                  <HookForm
                    label="ชื่อผู้ใช้"
                    placeholder="โปรดกรอกชื่อผู้ใช้"
                    isDisabled
                    name={"profile.user_name"}
                    control={formData}
                    errors={errors?.profile?.user_name}
                    rule={{
                      required: "กรุณากรอกชื่อผู้ใช้",
                    }}
                    // {...formData("profile.user_name")}
                    // onChange={formData.handleChange}
                    // value={formData.values.profile.user_name}
                    // error={
                    //   formData.errors?.profile?.user_name && formData.touched?.profile?.username
                    // }
                    // success={
                    //   formData.values?.profile?.user_name.length > 0 &&
                    //   !formData.errors?.profile?.user_name
                    // }
                    error={errors?.profile?.user_name}
                    success={!errors?.profile?.user_name}
                  />
                ) : (
                  <HookForm
                    label="ชื่อผู้ใช้"
                    placeholder="โปรดกรอกชื่อผู้ใช้"
                    name={"profile.username"}
                    control={formData}
                    errors={errors?.profile?.username}
                    rule={{
                      required: "กรุณากรอกชื่อผู้ใช้",
                    }}
                    // onChange={formData.handleChange}
                    // value={formData.values.profile.username}
                    // startAdornment={
                    //   <InputAdornment position="start">
                    //     {formData.values.profile.user_name}
                    //   </InputAdornment>
                    // }
                    // error={
                    //   formData.errors?.profile?.username && formData.touched?.profile?.username
                    // }
                    // success={
                    //   formData.values?.profile?.username.length > 0 &&
                    //   !formData.errors?.profile?.username
                    // }
                    // onBlur={(e) => handleOnBlur(e)}
                    error={errors?.profile?.username}
                    // success={!errors?.profile?.username && isBlur["profile.username"]}
                  />
                )}
              </Grid>
              {!isEdit && (
                <>
                  <Grid item xs={12} sm={4}>
                    <HookForm
                      label="รหัสผ่าน"
                      placeholder="ยืนยันรหัสผ่าน"
                      inputProps={{ type: "password", autoComplete: "" }}
                      name={"profile.password"}
                      control={formData}
                      errors={errors?.profile?.password}
                      rule={{
                        required: "กรุณากรอกรหัสผ่าน",
                        min: { value: 6, message: "รหัสผ่านขั้นต่ำ 6 ตัว" },
                      }}
                      // onChange={formData.handleChange}
                      // value={formData.values.profile.password}
                      // error={
                      //   formData.errors?.profile?.password && formData.touched?.profile?.password
                      // }
                      // success={
                      //   formData.values?.profile?.password.length > 0 &&
                      //   !formData.errors?.profile?.password
                      // }
                      // onBlur={(e) => handleOnBlur(e)}
                      error={errors?.profile?.password}
                      // success={!errors?.profile?.password && isBlur["profile.password"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <HookForm
                      label="รหัสผ่าน"
                      placeholder="ยืนยันรหัสผ่าน"
                      inputProps={{ type: "password", autoComplete: "" }}
                      name={"profile.repeatPassword"}
                      control={formData}
                      errors={errors?.profile?.repeatPassword}
                      rule={{
                        required: "กรุณากรอกยืนยันรหัสผ่าน",
                        min: { value: 6, message: "รหัสผ่านขั้นต่ำ 6 ตัว" },
                      }}
                      // onChange={formData.handleChange}
                      // value={formData.values.profile.repeatPassword}
                      // error={
                      //   formData.errors?.profile?.repeatPassword &&
                      //   formData.touched?.profile?.repeatPassword
                      // }
                      // success={
                      //   formData.values?.profile?.repeatPassword.length > 0 &&
                      //   !formData.errors?.profile?.repeatPassword
                      // }
                      error={errors?.profile?.repeatPassword}
                      // success={!errors?.profile?.repeatPassword}
                    />
                  </Grid>
                </>
              )}
              {isEdit && (
                <Grid item xs={12} sm={4}>
                  <SuiBox mt={3}>
                    <SuiButton buttonColor="warning" onClick={() => handleOpenChangepass()}>
                      แก้ไขรหัสผ่าน
                    </SuiButton>
                  </SuiBox>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <HookForm
              label="ชื่อสกุล"
              placeholder="โปรดกรอกชื่อ"
              inputProps={{ type: "text" }}
              name="profile.name"
              control={formData}
              errors={errors?.profile?.name}
              rule={{
                required: "กรุณากรอกชื่อสกุล",
              }}
              // onChange={formData.handleChange}
              // value={formData.values.profile.name}
              // error={formData.errors?.profile?.name && formData.touched?.profile?.name}
              // success={formData.values?.profile?.name.length > 0 && !formData.errors?.profile?.name}
              error={errors?.profile?.name}
              // success={!errors?.profile?.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HookForm
              label="phone number"
              placeholder="0880001111"
              inputProps={{ type: "text" }}
              name="profile.phone"
              control={formData}
              errors={errors?.profile?.phone}
              rule={{
                required: "กรุณากรอกเบอร์โทร",
              }}
              // onChange={formData.handleChange}
              // value={formData.values.profile.phone}
              // error={formData.errors?.profile?.phone && formData.touched?.profile?.phone}
              // success={
              //   formData.values?.profile?.phone.length > 0 && !formData.errors?.profile?.phone
              // }
              error={errors?.profile?.phone}
              // success={!errors?.profile?.phone}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}
BasicInfo.defaultProps = {
  isEdit: false,
};
BasicInfo.propTypes = {
  setValue: PropTypes.func,
  errors: PropTypes.object,
  getValues: PropTypes.func,
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  isEdit: PropTypes.bool,
  handleOpenChangepass: PropTypes.func,
  handleOpenChangeBalance: PropTypes.func,
  credituser: PropTypes.object,
};
export default BasicInfo;
