import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SuiBox from "components/SuiBox";
import Table from "components/Tables/Table";
import DataTable from "components/Tables/DataTable";
import { Switch, Grid } from "@mui/material";
import { TextBlack24, Text16_2 } from "components/Text/TextStyle";
import { get, post } from "utils/helper";
import { HOST } from "utils/config";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Select, MenuItem, FormControl } from "@mui/material";
import colors from "assets/theme/base/colors";
import Swal from "sweetalert2";

function fightMax(max, min) {
  let arrStock = [];
  let numberSelectedStock = min;
  arrStock.push({ value: numberSelectedStock, label: `${numberSelectedStock}%` });
  let maxNumber = max * 2;
  for (let i = min * 2; i < maxNumber; i++) {
    numberSelectedStock = numberSelectedStock + 0.5;
    arrStock.push({ value: numberSelectedStock, label: `${numberSelectedStock}%` });
  }
  return arrStock.reverse();
}
const ManageUser = ({ dataLoto, dataheader }) => {
  const { success, grey } = colors;
  const [dataAgent, setDataAgent] = useState(dataLoto);

  useEffect(() => {
    setDataAgent(dataLoto);
  }, [dataLoto]);
  const makeTittle = dataheader.map((e, i) => {
    return { name: i + "name", align: "center", label: e.name };
  });
  const columns = [
    { name: "role", align: "center", label: "ระดับชั้น" },
    { name: "user_name", align: "center", label: "ชื่อผู้ใช้" },
    { name: "name", align: "center", label: "ชื่อ-สกุล" },
    { name: "phone_nuumber", align: "center", label: "ติดต่อ	" },
    ...makeTittle,
    // {
    //   name: "market_status",
    //   align: "center",
    //   label: (
    //     <SuiBox display="flex" justifyContent="space-between">
    //       {dataheader?.length > 0 &&
    //         dataheader?.map((e, i) => (
    //           <SuiBox key={i} borderRight={"0.0625rem solid #e9ecef"} px={1} width={80}>
    //             {e.name}
    //           </SuiBox>
    //         ))}
    //     </SuiBox>
    //   ),
    // },
  ];
  const dataRow = dataAgent?.map((elem, index) => {
    const marketStatusProperties = {};

    elem.market_status.forEach((e, i) => {
      marketStatusProperties[i + "name"] = (
        <SuiBox borderRight={"0.0625rem solid #e9ecef"} px={1} key={i} width={80}>
          <SuiBox
            mr={1}
            width="100%"
            backgroundColor={e.enable ? success.main : grey[500]}
            borderRadius={8}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Text16_2>{e.enable ? "เปิด" : "ปิด"} </Text16_2>
          </SuiBox>
        </SuiBox>
      );
    });

    return {
      role: elem.role,
      user_name: elem.user_name,
      name: elem.name,
      phone_number: elem.phone_number, // Fixed the typo in phone_number
      ...marketStatusProperties,
    };
  });

  return (
    <SuiBox p={2} backgroundColor={"#FFF"}>
      <TextBlack24>ข้อมูลสถานะตลาดของสมาชิก</TextBlack24>

      <Table
        columns={columns}
        rows={dataRow}
        // rows={dataAgent?.map((elem, index) => ({
        //   role: elem.role,
        //   user_name: elem.user_name,
        //   name: elem.name,
        //   phone_nuumber: elem.phone_nuumber,
        //   ...elem.market_status.map((e, i) => {
        //     return {
        //       [e.name]: (
        //         <SuiBox borderRight={"0.0625rem solid #e9ecef"} px={1} key={i} width={80}>
        //           <SuiBox
        //             mr={1}
        //             width="100%"
        //             backgroundColor={e.enable ? success.main : grey[500]}
        //             borderRadius={8}
        //             display="flex"
        //             justifyContent="center"
        //             alignItems="center"
        //           >
        //             <Text16_2>{e.enable ? "เปิด" : "ปิด"} </Text16_2>
        //           </SuiBox>
        //         </SuiBox>
        //       ),
        //     };
        //   }),
        //   // market_status: (
        //   //   <SuiBox display="flex" width="100%" px={"8px"}>
        //   //     {elem.market_status.map((e, i) => (
        //   // <SuiBox borderRight={"0.0625rem solid #e9ecef"} px={1} key={i} width={80}>
        //   //   <SuiBox
        //   //     mr={1}
        //   //     width="100%"
        //   //     backgroundColor={e.enable ? success.main : grey[500]}
        //   //     borderRadius={8}
        //   //     display="flex"
        //   //     justifyContent="center"
        //   //     alignItems="center"
        //   //   >
        //   //     <Text16_2>{e.enable ? "เปิด" : "ปิด"} </Text16_2>
        //   //   </SuiBox>
        //   // </SuiBox>
        //   //     ))}
        //   //   </SuiBox>
        //   // ),
        // }))}
      />
    </SuiBox>
  );
};

ManageUser.propTypes = {
  dataLoto: PropTypes.array,
  dataheader: PropTypes.array,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);
