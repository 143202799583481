/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { ErrorMessage, Field, FastField } from "formik";
import { useForm, Controller } from "react-hook-form";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

function HookForm({
  name,
  label,
  flexDi,
  variants,
  justify,
  isDisabled,
  errors,
  control,
  rule,
  ...rest
}) {
  return (
    <SuiBox display="flex" flexDirection={flexDi} justifyContent={justify}>
      <SuiBox
        mb={1}
        ml={flexDi === "flex-start" ? 2 : 0.5}
        mr={flexDi === "flex-start" ? 2 : 0}
        lineHeight={0}
        display="inline-block"
      >
        <SuiTypography
          component="label"
          variant={variants}
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <Controller
        name={name}
        control={control}
        rules={{ ...rule }}
        render={({ field }) => <SuiInput {...field} disabled={isDisabled} {...rest} />}
      />
      {/* <SuiInput name={name} disabled={isDisabled} {...rest} /> */}

      <SuiBox mt={0.75}>
        <SuiTypography component="div" variant="caption" textColor="error">
          {errors && <p>{errors?.message}</p>}

          {/* <ErrorMessage name={name} /> */}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

// Setting default values for the props of HookForm
HookForm.defaultProps = {
  label: " ",
  flexDi: "column",
  variants: "caption",
  justify: "flex-end",
};

// Typechecking props for HookForm
HookForm.propTypes = {
  rule: PropTypes.any,
  control: PropTypes.any,
  errors: PropTypes.object,
  label: PropTypes.string,
  flexDi: PropTypes.string,
  variants: PropTypes.string,
  justify: PropTypes.string,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default HookForm;
