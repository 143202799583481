import React from "react";
import styled from "styled-components";
import colors from "assets/theme/base/colors";
const { secondary, error, white, info, grey } = colors;
export const FirstRiskReward = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  color: #007aff;
`;
export const TextDanger = styled.span`
  color: ${error.main};
`;

export const SecondRiskReward = styled.span`
  font-size: 0.8rem;
  color: #8e8e93;
`;
export const TextGreyBolder = styled.span`
  font-weight: bolder;
  color: #949fb7;
`;
export const TextGray = styled.span`
  color: ${grey[700]};
`;
export const TextGray8 = styled.span`
  color: ${grey[700]};
  font-size: 8px;
`;
export const TextGray10 = styled.span`
  color: ${grey[700]};
  font-size: 10px;
`;
export const TextGray24 = styled.span`
  color: ${grey[700]};
  font-size: 24px;
`;

export const TextTitle24 = styled.span`
  font-size: 24px;
  font-weight: bold;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;
export const TextSubTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
`;
export const TextSubTitleGray = styled.span`
  font-size: 16px;
  font-weight: bold;
`;
export const TextDanger24 = styled.div`
  font-size: 24px;
  color: ${error.main};
  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }
`;
export const TextDanger16 = styled.div`
  font-size: 16px;
  color: ${error.main};
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;
export const TextBlack30 = styled.div`
  font-size: 30px;
  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (max-width: 748px) {
    font-size: 16px;
  }
`;
export const TextBlack24 = styled.div`
  font-size: 24px;
  font-weight: 200;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;
export const TextBlack18SmiBold = styled.div`
  font-size: 18px;
  font-weight: 400;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;
export const TextDefault = styled.div`
  font-size: 20px;
  font-weight: 200;
  @media screen and (max-width: 1024px) {
    font-size: 20px;
    font-weight: 200;
  }
`;
export const TextBlack48 = styled.div`
  font-size: 48px;
  @media screen and (max-width: 1024px) {
    font-size: 36px;
  }
  @media screen and (max-width: 748px) {
    font-size: 30px;
  }
`;
export const TextBlack32 = styled.div`
  font-size: 32px;
  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
`;
export const TextBlack20 = styled.div`
  font-size: 20px;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
`;
export const TextCenter = styled.div`
  text-align: center;
`;
export const TextHeadTable = styled.div`
  font-size: 14px;
  color: ${grey[700]};
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;
export const TextHeadBlack = styled.div`
  font-size: 14px;
  color: #000000 !important;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;
export const TextCellTable = styled.div`
  font-size: 14px;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;
export const TextBold16 = styled.div`
  font-size: 16px;
  font-weight: bold;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;
export const TextMedium16 = styled.div`
  font-size: 16px;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;
export const TextWhite64 = styled.div`
  font-size: 64px;
  color: ${white.main};
  @media screen and (max-width: 1024px) {
    font-size: 36px;
  }
  @media screen and (max-width: 748px) {
    font-size: 30px;
  }
`;
export const TextWhite32 = styled.div`
  font-size: 32px;
  color: ${white.main};
  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
`;
export const TextWhite24 = styled.div`
  font-size: 24px;
  font-weight: 200;
  color: ${white.main};
  @media screen and (max-width: 748px) {
    font-size: 16px;
  }
`;
export const TextWhite14 = styled.div`
  font-size: 14px;
  font-weight: 200;
  color: ${white.main};
  @media screen and (max-width: 748px) {
    font-size: 12px;
  }
`;
export const TextWhite16 = styled.div`
  font-size: 16px;
  font-weight: 200;
  color: ${white.main};
  @media screen and (max-width: 748px) {
    font-size: 14px;
  }
`;
export const TextBlue16 = styled.span`
  font-size: 16px;
  font-weight: 200;
  color: ${info.main};
  @media screen and (max-width: 748px) {
    font-size: 14px;
  }
`;

export const Text16 = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0.5rem;

  @media screen and (max-width: 1199px) {
    font-size: 14px;
  }
`;

export const Text16_2 = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #fff;

  @media screen and (max-width: 1199px) {
    font-size: 14px;
  }
`;

export const Text18 = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0.5rem;

  @media screen and (max-width: 1199px) {
    font-size: 16px;
  }
`;

export const Table18 = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #000;
  // text-align: center;

  @media screen and (max-width: 1199px) {
    font-size: 12px;
  }
`;

export const Table16 = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #000;
  text-align: center;

  @media screen and (max-width: 1199px) {
    font-size: 10px;
  }
`;

export const Nav = styled.div`
  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

export const ButtonWidth100 = styled.div`
  width: 100%;
`;

export const FlexContain = styled.div`
  width: 100%;
  display: flex;
  margin: 0.5rem 0;
`;

export const Flex1 = styled.div`
  width: 100%;
  text-align: center;
  margin-left: -1.2rem;
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  border-right: 1px solid #f6f6f6;
  padding: 0.5rem;
`;

export const Flex2 = styled.div`
  width: 100%;
  text-align: center;
  margin-right: -1.2rem;
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  padding: 0.5rem;
`;

export const FlexText1 = styled.p`
  color: #000;
  font-size: 14px;
  text-align: left;
  margin-left: 0.5rem;
`;

export const FlexText2 = styled.p`
  color: #0890d0;
  font-size: 32px;
`;

export const FlexText3 = styled.p`
  color: #ea0606;
  font-size: 32px;
`;

export const DivTextAlignRight = styled.div`
  width: 100%;
  text-align: right;
`;

export const TextP14 = styled.p`
  color: #000;
  font-size: 14px;
`;

export const TextP16b = styled.p`
  color: #000;
  font-size: 16px;
  font-weight: bold;
`;

export const LootoList20 = styled.p`
  color: #000;
  font-size: 20px;
  font-weight: bold;

  @media screen and (max-width: 1199px) {
    font-size: 18px;
  }
`;

export const LootoList20_2 = styled.p`
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin-top: 0.5rem;

  @media screen and (max-width: 1199px) {
    font-size: 18px;
  }
`;

export const LootoList20_3 = styled.p`
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin-top: 2rem;

  @media screen and (max-width: 1199px) {
    font-size: 18px;
  }
`;

export const LootoList18 = styled.p`
  font-size: 18px;

  @media screen and (max-width: 1199px) {
    font-size: 16px;
  }
`;

export const LootoList18_2 = styled.p`
  margin-bottom: 2rem;

  color: #000;
  font-size: 20px;
  font-weight: bold;

  @media screen and (max-width: 1199px) {
    font-size: 18px;
  }
`;

export const LootoList16 = styled.p`
  font-size: 16px;

  @media screen and (max-width: 1199px) {
    font-size: 12px;
  }
`;

export const LootoList16_2 = styled.p`
  font-size: 16px;
  margin-top: 0.5rem;

  @media screen and (max-width: 1199px) {
    font-size: 12px;
  }
`;

export const LootoList16span = styled.span`
  font-size: 16px;
  margin-right: 0.5rem;

  @media screen and (max-width: 1199px) {
    font-size: 12px;
  }
`;

export const LootoList36 = styled.p`
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin: 3rem 0;

  @media screen and (max-width: 1199px) {
    font-size: 24px;
  }
`;

export const TabsMargin = styled.div`
  margin-top: 1rem;
`;

export const LootoList12 = styled.p`
  font-size: 12px;
  margin-top: 0.8rem;
`;

export const LootoList14 = styled.span`
  font-size: 14px;

  @media screen and (max-width: 1199px) {
    font-size: 12px;
  }
`;

export const LootoList16red = styled.p`
  font-size: 16px;
  color: red;

  @media screen and (max-width: 1199px) {
    font-size: 12px;
  }
`;

export const Nopadding = styled.div`
  padding-top: 0;
  padding-left: 0;
`;