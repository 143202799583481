import react, { useState, useEffect } from "react";
import { post } from "utils/helper/request";
import { HOST } from "utils/config";
// @mui material components
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import { Grid, Divider } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { LootoList20, LootoList18, LootoList16 } from "components/Text/TextStyle";
// const copyItem = [
//   { name: "3 ตัวบน", value: "0", lotto_type_id: "1" },
//   { name: "3 ตัวโต้ด", value: "0", lotto_type_id: "3" },
//   { name: "2 ตัวบน", value: "0", lotto_type_id: "4" },
//   { name: "2 ตัวล่าง", value: "0", lotto_type_id: "5" },
//   { name: "1 วิ่งบน", value: "0", lotto_type_id: "6" },
//   { name: "1 วิ่งล่าง", value: "0", lotto_type_id: "7" },
// ];
// Data
function LottoList({ dataLotto }) {
  const [lotto, setLotto] = useState([]);
  const [copyItem, setCopyItem] = useState([
    { name: "3 ตัวบน", value: "0", lotto_type_id: "1" },
    { name: "3 ตัวโต้ด", value: "0", lotto_type_id: "3" },
    { name: "2 ตัวบน", value: "0", lotto_type_id: "4" },
    { name: "2 ตัวล่าง", value: "0", lotto_type_id: "5" },
    { name: "1 วิ่งบน", value: "0", lotto_type_id: "6" },
    { name: "1 วิ่งล่าง", value: "0", lotto_type_id: "7" },
  ]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const saveSigleLimit = async (typeId, amount) => {
    try {
      setLoading(true);
      const payload = new FormData();

      payload.append("lotto_config_type_id", typeId);
      payload.append("total_limit", amount);
      const { data } = await post(`${HOST}api/Lotto/SetLimitLotto`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const saveAllLimit = async (item) => {
    try {
      setLoading(true);
      const payload = [];
      item.map((e) => {
        let my_object = {};
        my_object.lotto_config_type_id = e.config_types_id;
        my_object.total_limit = e.total_limit;
        payload.push(my_object);
      });

      const { data } = await post(`${HOST}api/Lotto/SetLimitLottoArray`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const saveAllLimitOther = async (item) => {
    try {
      setLoading(true);
      console.log(item);
      const payload = [];
      item.map((e) => {
        console.log(e.lotto_type_list_item, "e");
        e.lotto_type_list_item.map((el, i) => {
          let my_object = {};
          my_object.lotto_config_type_id = el.config_types_id;
          my_object.total_limit = el.total_limit;
          payload.push(my_object);
        });
      });
      console.log(payload, "payload");
      const { data } = await post(`${HOST}api/Lotto/SetLimitLottoArray`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChageAmount = (i, index, event) => {
    // let lottoChange = lotto;
    // lottoChange.lotto_list_item[i].lotto_type_list_item[index].total_limit = parseInt(
    //   event.target.value
    // );
    setLotto((oldState) => {
      oldState[i].lotto_type_list_item[index].total_limit = event.target.value;
      return oldState;
    });
    // setLotto(lottoChange);
  };
  useEffect(() => {
    setLotto(dataLotto?.lotto_list_item);
  }, [dataLotto]);
  const onChangeValueCopy = (value, index) => {
    let data = copyItem;
    data[index].value = value;
    setCopyItem([...data]);
  };
  const onCopyRisk = (id, value) => {
    let data = lotto;
    data.map((e, i) =>
      e.lotto_type_list_item.map((el, index) => {
        if (el.lotto_type_id === parseInt(id)) {
          el.total_limit = value;
        }
      })
    );

    setLotto([...data]);
  };
  return (
    <SuiBox my={3}>
      <Card>
        {id === "1" ? (
          <SuiBox>
            {lotto?.map((e, i) => (
              <SuiBox key={i} p={3}>
                <SuiBox display={"flex"} justifyContent={"space-between"}>
                  <SuiTypography variant={"h5"} fontWeight="bold">
                    <LootoList20>{e.lotto_name}</LootoList20>
                  </SuiTypography>
                  <SuiButton
                    buttonColor="info"
                    onClick={() => saveAllLimit(e.lotto_type_list_item)}
                  >
                    <LootoList16>บันทึกทั้งหมด</LootoList16>
                  </SuiButton>
                </SuiBox>

                <SuiBox>
                  <Grid container mt={2}>
                    <Grid xs={1}></Grid>
                    <Grid xs={7}>
                      <LootoList18>ประเภท</LootoList18>
                    </Grid>
                    <Grid xs={4}>
                      <LootoList18>กำหนดจำนวน</LootoList18>
                    </Grid>
                  </Grid>
                  <Divider />
                  {e.lotto_type_list_item.map((values, index) => {
                    return (
                      <Grid container key={index} style={{ padding: 8 }} spacing={4}>
                        <Grid xs={0} sm={1} item></Grid>
                        <Grid xs={4} sm={7} item>
                          <LootoList16>{values.lotto_type_name}</LootoList16>
                        </Grid>
                        <Grid xs={4} sm={2} item>
                          <SuiInput
                            type="number"
                            placeholder="โปรดกรอกจำนวนจำกัด"
                            name={"limit"}
                            onChange={(e) => {
                              lotto[i].lotto_type_list_item[index].total_limit = e.target.value;
                              setLotto([...lotto]);
                            }}
                            value={values.total_limit}
                          />
                        </Grid>
                        <Grid xs={2} item>
                          <SuiButton
                            buttonColor="info"
                            onClick={() =>
                              saveSigleLimit(values.config_types_id, values.total_limit)
                            }
                          >
                            บันทึก
                          </SuiButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                </SuiBox>
                <Divider />
              </SuiBox>
            ))}
          </SuiBox>
        ) : (
          <SuiBox>
            <SuiBox p={3}>
              <Grid container spacing={2}>
                <Grid xs={1} sm={2.8} item>
                  <SuiBox>
                    <LootoList20>ตลาด</LootoList20>
                  </SuiBox>
                  <SuiButton buttonColor="info" onClick={() => saveAllLimitOther(lotto)}>
                    บันทึกทั้งหมด
                  </SuiButton>
                </Grid>
                {copyItem.map((e, i) => (
                  <Grid xs={1} sm={1.5} key={i} item>
                    <LootoList16>{e.name}</LootoList16>
                    <SuiButton
                      buttonColor={"info"}
                      onClick={() => onCopyRisk(e.lotto_type_id, e.value)}
                    >
                      Copy
                    </SuiButton>
                    <SuiBox mt={2} mr={2}>
                      <SuiInput
                        name={e.lotto_type_id}
                        value={e.value || ""}
                        onChange={(e) => {
                          onChangeValueCopy(e.target.value, i);
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </SuiBox>
                  </Grid>
                ))}
              </Grid>
            </SuiBox>
            {lotto?.map((e, i) => (
              <SuiBox key={i} p={3}>
                <SuiBox>
                  <Divider />{" "}
                  <Grid container style={{ padding: 8 }} spacing={2}>
                    <Grid xs={1} sm={2.8} item>
                      <SuiTypography variant={"h5"} fontWeight="bold">
                        <LootoList18>{e.lotto_name}</LootoList18>
                      </SuiTypography>
                    </Grid>
                    {e.lotto_type_list_item.map((values, index) => {
                      return (
                        <>
                          <Grid xs={1} sm={1.5} item>
                            <SuiBox>
                              <LootoList16>{values.lotto_type_name}</LootoList16>
                              <SuiBox mr={2}>
                                <SuiInput
                                  type="number"
                                  placeholder="โปรดกรอกจำนวนจำกัด"
                                  name={"limit"}
                                  onChange={(e) => {
                                    lotto[i].lotto_type_list_item[index].total_limit =
                                      e.target.value;
                                    setLotto([...lotto]);
                                  }}
                                  value={values.total_limit}
                                />
                              </SuiBox>
                            </SuiBox>
                          </Grid>
                        </>
                      );
                    })}{" "}
                  </Grid>
                </SuiBox>
                <Divider />
              </SuiBox>
            ))}
          </SuiBox>
        )}
      </Card>
    </SuiBox>
  );
}
LottoList.propTypes = {
  dataLotto: PropTypes.array,
};
export default LottoList;
