import { useState, useEffect } from "react";
import { useHistory } from "react-router";

import SuiBadgeDot from "components/SuiBadgeDot";

// @mui material components

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";

import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";
// Reports page components

import SuiButton from "components/SuiButton";
// Data
import DialogAddGroup from "./Dialogaddgroup";
import DialogEditgroup from "./DialogEditgroup";

import { LootoList20 } from "components/Text/TextStyle";

const columns = [
  { name: "number", align: "center", label: "ลำดับ" },
  { name: "name", align: "center", label: "ชื่อกลุ่มหวย" },
  { name: "status", align: "center", label: "สถานะ" },
  { name: "createdate", align: "center", label: "วันที่สร้าง" },
  { name: "action", align: "center", label: "" },
];
function ListGroupLotto() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [datalistlotto, setdataListLotto] = useState([]);
  const [dataUpdate, setDataUpdate] = useState({});
  const [pages, setpages] = useState(1);

  const [perpages, setperpages] = useState(10);
  const [rows, setRows] = useState([]);
  const fetchLottoList = async () => {
    try {
      setLoading(true);

      const { data } = await get(`${HOST}api/MasterLotto/GetLottoGroupList`);
      setdataListLotto(data.results);

      const rownaja = data.results.map((elem) => ({
        number: elem.row_no,
        name: elem.name,
        status: (
          <SuiBox ml={-1.325}>
            <SuiBadgeDot size="small" badgeContent={elem.status ? "เปิด" : "ปิด"} />
          </SuiBox>
        ),
        createdate: elem.updated_date_display,
        action: (
          <SuiBox w={"100%"} display="flex">
            <SuiButton
              buttonColor={"warning"}
              onClick={() => {
                setOpenDialogUpdate(true);
                setDataUpdate(elem);
              }}
            >
              แก้ไข
            </SuiButton>
            <div style={{ margin: "0px 2px" }} />
            <SuiButton
              buttonColor={"info"}
              onClick={() => history.push(`/managelotto/lottolist/${elem.id}`)}
            >
              จัดการหวย
            </SuiButton>
          </SuiBox>
        ),
      }));

      setRows(rownaja);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const onSuccess = async () => {
    fetchLottoList();
  };
  useEffect(() => {
    fetchLottoList();
  }, []);
  const onCloseDialog = () => {
    setOpenDialog(false);
  };
  const onOpenDialog = () => {
    setOpenDialog(true);
  };
  const onCloseDialogUpdate = () => {
    setOpenDialogUpdate(false);
  };
  const onOpenDialogUpdate = () => {
    setOpenDialogUpdate(true);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox py={3} display="flex" justifyContent="space-between">
          <LootoList20>จัดการกลุ่มหวย</LootoList20>
          <SuiButton onClick={() => onOpenDialog()} buttonColor="info">
            เพิ่มกลุ่มหวย
          </SuiButton>
        </SuiBox>
        {loading ? (
          <Box>
            <Skeleton variant="rectangular" width={"auto"} height={500} />
          </Box>
        ) : (
          <Table columns={columns} rows={rows} />
        )}
        {/* <Table columns={columns} rows={rows} /> */}
      </SuiBox>
      <DialogAddGroup open={openDialog} onClose={onCloseDialog} onSuccess={onSuccess} />
      <DialogEditgroup
        dataUpdate={dataUpdate}
        open={openDialogUpdate}
        onClose={onCloseDialogUpdate}
        onSuccess={onSuccess}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default ListGroupLotto;
