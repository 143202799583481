/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import SuiBadgeDot from "components/SuiBadgeDot";
import Swal from "sweetalert2";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";
// Reports page components
import Reviews from "memberpage/pages/users/reports/components/Reviews";
import PropTypes, { func } from "prop-types";
import { Divider } from "@mui/material";
import FormField from "components/FormField";
// Data
import { Formik, Form, FieldArray, FastField } from "formik";
function DialogAllTranfer({ open, onClose, dataUser, onSucces, type }) {
  const [remark, setRemark] = useState("");
  const [amount, setAmount] = useState(0);
  const [isloading, setIsloading] = useState(false);
  const onhandleSubmit = async (event) => {
    try {
      setIsloading(true);
      let trandata = event.dataTranfer.map((e, i) => {
        return {
          to_user_id: e.to_user_id,
          amount: parseFloat(e.amount),
          remark: e.remark,
        };
      });
      const payload = { transfer_data: trandata };
      console.log(payload);
      const { data } = await post(`${HOST}api/Transfer/SetTransfer`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success").then((result) => {
        setRemark("");
        onSucces();
        onClose();
      });
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
      console.log(error);
    }
  };
  function validateAmount(value, check) {
    let error;

    if (!value) {
      error = "โปรดกรอกจำนวนที่โอน";
    }

    return error;
  }
  function validateRemark(value, check) {
    let error;

    if (!value) {
      error = "โปรดกรอกหมายเหตุ";
    }

    return error;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
      <DialogTitle>รายการโอนยอดทั้งหมด</DialogTitle>

      {dataUser.length > 0 ? (
        <Formik
          initialValues={{
            dataTranfer: dataUser.map((e, i) => {
              return {
                to_user_id: e.user_id,
                display_name: e.display_name,
                loss_profit: e.loss_profit,
                loss_profit_display: e.loss_profit_display,
                amount: 0,
                remark: "",
              };
            }),
          }}
          // validationSchema={}
          onSubmit={onhandleSubmit}
        >
          {(formProps) => (
            <Form autoComplete="off">
              {type === "all" && (
                <SuiBox pl={4}>
                  <SuiButton
                    buttonColor="primary"
                    onClick={() => {
                      formProps.values.dataTranfer.map((e, i) => {
                        formProps.setFieldValue(`dataTranfer[${i}].amount`, e?.loss_profit);
                        formProps.setFieldValue(`dataTranfer[${i}].remark`, "โอนเต็มยอด");
                      });
                    }}
                  >
                    โอนยอดสมาชิกทั้งหมด
                  </SuiButton>
                </SuiBox>
              )}
              <DialogContent>
                <SuiBox>
                  {formProps.values.dataTranfer.map((dataDialog, i) => (
                    <SuiBox key={i}>
                      <SuiBox display="flex" justifyContent="space-between">
                        <SuiTypography>ชื่อผู้ใช้ : {dataDialog?.display_name}</SuiTypography>
                        <SuiTypography textColor="error">
                          ยอดทั้งหมด : {dataDialog?.loss_profit_display}
                        </SuiTypography>{" "}
                      </SuiBox>
                      <SuiBox display="flex" justifyContent="end">
                        <SuiButton
                          onClick={() => {
                            formProps.setFieldValue(
                              `dataTranfer[${i}].amount`,
                              dataDialog?.loss_profit
                            );
                            formProps.setFieldValue(`dataTranfer[${i}].remark`, "โอนเต็มยอด");
                          }}
                          buttonColor="info"
                        >
                          โอนเต็มยอด
                        </SuiButton>
                      </SuiBox>
                      <SuiTypography variant="caption">จำนวนที่โอน</SuiTypography>

                      <FormField
                        type="text"
                        placeholder="โปรดกรอกจำนวนที่โอน"
                        name={`dataTranfer[${i}].amount`}
                        onChange={formProps.handleChange}
                        onKeyPress={(event) => {
                          if (!/[0-9,.,-]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(event) => {
                          formProps.setFieldValue(
                            `dataTranfer[${i}].amount`,
                            event.target.value.replace(/,/g, "")
                          );
                        }}
                        value={formProps.values.dataTranfer[i]?.amount}
                        validate={(e) => validateAmount(e, dataDialog.loss_profit)}
                      />

                      <SuiTypography variant="caption">หมายเหตุ</SuiTypography>
                      <FormField
                        // label="จำนวนเงิน"
                        type="text"
                        placeholder="หมายเหตุ"
                        multiline
                        rows={4}
                        name={`dataTranfer[${i}].remark`}
                        onChange={formProps.handleChange}
                        value={formProps.values.dataTranfer[i]?.remark}
                        validate={(e) => validateRemark(e)}
                      />

                      <Divider />
                    </SuiBox>
                  ))}
                </SuiBox>
              </DialogContent>
              <DialogActions>
                <SuiButton buttonColor="error" onClick={onClose}>
                  ยกเลิก
                </SuiButton>
                <SuiButton type="submit" buttonColor="success" disabled={isloading}>
                  โอนยอด
                </SuiButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <SuiBox display="flex" justifyContent="center">
            ไม่มียอดที่ต้องโอน
          </SuiBox>
          <DialogActions>
            <SuiButton buttonColor="error" onClick={onClose}>
              ปิด
            </SuiButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
DialogAllTranfer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  dataUser: PropTypes.any,
  onSucces: PropTypes.func,
  type: PropTypes.string,
};
export default DialogAllTranfer;
