import React, { useEffect, useState } from "react";
//mui
import { FormControlLabel, Grid, Radio, RadioGroup, Switch } from "@mui/material";
import Swal from "sweetalert2";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { post } from "utils/helper";
import { HOST } from "utils/config";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiButton from "components/SuiButton";
import InputBase from "@mui/material/InputBase";
import { LootoList20, LootoList18, LootoList16 } from "components/Text/TextStyle";

function FormLimit({ lotoType, refetch }) {
  const [itemLimit, setItemLimit] = useState(null);
  const [priceLimit, setPriceLimit] = useState(0);
  const [lottoType, setLottoType] = useState(null);
  const [numberlength, setNumberlength] = useState(null);
  const [checkOpen, setCheckOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const setLimitLottoNumber = async () => {
    if (!checkOpen && parseInt(priceLimit) === 0) {
      Swal.fire("เกิดข้อผิดพลาด", "กรุณากรอกจำนวนที่จะรับ", "error");
    } else {
      try {
        setLoading(true);

        const payload = new FormData();
        payload.append("gatcha_type_id", lottoType);
        payload.append("gatcha_drawing_configs_id", lotoType.gatcha_drawing_id);
        payload.append("p_gatcha_number", itemLimit);
        {
          priceLimit && payload.append("total_limit", priceLimit);
        }
        payload.append("status_receive", checkOpen);
        const { data } = await post(`${HOST}api/Lotto/SetLimitLottoNumber`, payload);

        Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success").then((result) => {
          refetch();
          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  return (
    <Grid container style={{ padding: 8 }} spacing={4}>
      <Grid xs={3} item>
        <SuiSelect
          // isDisabled={!checkOpen}
          // disabled
          // inputProps={{ disabled: lottoType === null }}
          placeholder="โปรดเลือกประเภทหวย"
          options={lotoType.lotto_type_list_item.map((e) => ({
            value: e,
            label: e.lotto_type_name,
          }))}
          onChange={(value) => {
            setNumberlength(value.value.lotto_type_length);
            setLottoType(value.value.lotto_type_id);
          }}
        />
      </Grid>
      <Grid xs={3} item>
        {/* <InputBase
          disabled
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Google Maps"
          inputProps={{ "aria-label": "search google maps" }}
        /> */}
        <SuiInput
          type="text"
          inputProps={{ disabled: lottoType === null }}
          disabled={lottoType === null}
          placeholder="โปรดกรอกหมายเลขอั้น"
          name={"itemLimit"}
          value={itemLimit}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
            if (event.target.value.length >= numberlength) {
              event.preventDefault();
            }
          }}
          onChange={(e) => setItemLimit(e.target.value)}
        />
      </Grid>{" "}
      <Grid xs={2} item>
        <SuiBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={{ xs: "100%", sm: "auto" }}
          mt={{ xs: 1, sm: 0 }}
        >
          {/* <SuiBox lineHeight={0} mx={2}>
            <SuiTypography variant="button" textColor="text" fontWeight="regular">
              {checkOpen ? "เปิดรับ" : "ปิดรับ"}
            </SuiTypography>
          </SuiBox> */}
          <SuiBox>
            <RadioGroup
              value={checkOpen}
              onChange={(event) => {
                if (event.target.value === "true") {
                  setCheckOpen(true);
                } else {
                  setCheckOpen(false);
                }
              }}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              {" "}
              <FormControlLabel value={true} control={<Radio />} label="ปิดรับ(อั้น)" />
              <FormControlLabel value={false} control={<Radio />} label="เปิดรับ ตามจำนวน" />
            </RadioGroup>
            {/* <Switch checked={checkOpen} onChange={() => setCheckOpen(!checkOpen)} /> */}
          </SuiBox>
        </SuiBox>
      </Grid>
      <Grid xs={3} item>
        <SuiInput
          type="text"
          inputProps={{ disabled: checkOpen || lottoType === null }}
          disabled={checkOpen || lottoType === null}
          placeholder="โปรดกรอกจำนวนจำกัด"
          name={"priceLimit"}
          value={priceLimit}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => setPriceLimit(e.target.value)}
        />
      </Grid>
      <Grid item xs={1}>
        <SuiButton
          buttonColor="info"
          onClick={() => setLimitLottoNumber()}
          disabled={lottoType === null || itemLimit === null || itemLimit.length === 0}
        >
          เพิ่ม
        </SuiButton>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of FormField
FormLimit.defaultProps = {};

// Typechecking props for FormField
FormLimit.propTypes = {
  lotoType: PropTypes.array,
  refetch: PropTypes.func,
};

export default FormLimit;
