import moment from "moment";
import colors from "assets/theme/base/colors";
const { info, white, black, text, tableBuy } = colors;

export const COLUMNS = [
  {
    Header: "ลำดับ",
    accessor: "row_no",
    Cell: ({ row }) => {
      return row.original?.row_no;
    },
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "ตลาด",
    accessor: "gatcha_name",
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "งวดประจำวันที่",
    accessor: "drawing_date_display",
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "3 ตัวบน",
    accessor: "three_On",
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "2 ตัวบน",
    accessor: "two_On",
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "2 ตัวล่าง",
    accessor: "two_Under",
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "",
    accessor: "action",
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  // {
  //   Header: "+",
  //   accessor: "dataCollape",
  //   Cell: ({ row }) => {
  //     return "bet_number_list";
  //   },
  // },
];
