import { useState, useEffect } from "react";

import Select from "@mui/material/Select";

import Swal from "sweetalert2";
import validations from "./schemas/validations";
// @mui material components
import Grid from "@mui/material/Grid";

import MenuItem from "@mui/material/MenuItem";

import { Switch } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
// Soft UI Dashboard PRO React example components

import { post, get } from "utils/helper";
import { HOST } from "utils/config";

// Reports page components

import PropTypes from "prop-types";
import { Divider, InputAdornment } from "@mui/material";
import FormField from "components/FormField";
// Data
import { Formik, Form, FieldArray, FastField } from "formik";

import {
  LootoList20,
  LootoList18,
  LootoList18_2,
  LootoList16,
  LootoList16span,
  LootoList12,
  LootoList14,
} from "components/Text/TextStyle";

function DialogaddGatchatype({ open, onClose, groupId, onSuccess }) {
  const [dataGatchatype, setdataGatchatype] = useState([]);
  const [gatchatype, setGatchatype] = useState("");

  const handleChange = (event) => {
    setGatchatype(event.target.value);
  };
  useEffect(() => {
    fetchGatchaType();
  }, []);
  const fetchGatchaType = async () => {
    const { data } = await get(`${HOST}api/MasterLotto/GetLottoTypeList`);
    setdataGatchatype(data.results);
  };

  const onhandleSubmit = async (event) => {
    try {
      const payload = new FormData();
      payload.append("gatcha_id", groupId);
      payload.append("gatcha_type_id", event.gatcha_type_id);
      payload.append("order_no", event.order_no);
      payload.append("reward_rate", event.reward_rate);
      payload.append("discount", event.discount);
      payload.append("spin", event.spin);
      payload.append("status", event.status);
      payload.append("max_reward_rate", event.max_reward_rate);
      payload.append("max_discount", event.max_discount);
      payload.append("max_spin", event.max_spin);
      const { data } = await post(`${HOST}api/MasterLotto/AddLottoConfig`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success").then((result) => {
        onSuccess();
        onClose();
      });
    } catch (error) {
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
      console.log(error);
    }
  };
  function validateAmount(value, title) {
    let error;

    if (!value) {
      error = "โปรดกรอก";
    }

    return error;
  }
  const validateSelect = (value) => {
    let errors = {};

    if (!value.gatcha_type_id) {
      errors = "โปรดกรอก";
    }

    return errors;
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"lg"}>
      <DialogTitle>เพิ่มกลุ่มหวย</DialogTitle>

      <Formik
        initialValues={{
          discount: null,
          reward_rate: null,
          gatcha_type_id: null,
          status: true,
          order_no: 1,
          spin: null,
          max_reward_rate: null,
          max_discount: null,
          max_spin: null,
        }}
        validation={validations}
        onSubmit={onhandleSubmit}
      >
        {(formProps) => (
          <Form autoComplete="off">
            <DialogContent>
              <SuiBox>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <SuiTypography variant="caption">ประเภท</SuiTypography>
                    <Select
                      input={
                        <SuiInput
                          error={formProps.values.gatcha_type_id == null}
                          name={`gatcha_type_id`}
                        />
                      }
                      value={formProps.values.gatcha_type_id}
                      onChange={(event) =>
                        formProps.setFieldValue(`gatcha_type_id`, event.target.value)
                      }
                    >
                      {dataGatchatype?.map((e, i) => (
                        <MenuItem value={e.gatcha_type_id} key={i}>
                          {e.gatcha_type_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <LootoList12>สถานะ</LootoList12>
                    <SuiBox>
                      <SuiTypography variant="BUTTON">
                        <LootoList14>{formProps.values.status ? "เปิด" : "ปิด"} </LootoList14>
                      </SuiTypography>
                      <Switch
                        checked={formProps.values.status}
                        onChange={() => formProps.setFieldValue(`status`, !formProps.values.status)}
                      />
                    </SuiBox>
                  </Grid>
                  <Grid item xs={4}>
                    <SuiTypography variant="caption">หมายเลข Order</SuiTypography>
                    <FormField
                      type="text"
                      placeholder="โปรดกรอกหมายเลข Order"
                      name={`order_no`}
                      onChange={formProps.handleChange}
                      value={formProps.values.order_no}
                      validate={(e) => validateAmount(e, "โปรดกรอกหมายเลข Order")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SuiTypography variant="caption">อัตราจ่าย</SuiTypography>
                    <FormField
                      type="text"
                      placeholder="โปรดกรอกรอบอัตราจ่าย"
                      name={`reward_rate`}
                      onChange={formProps.handleChange}
                      value={formProps.values.reward_rate}
                      validate={(e) => validateAmount(e)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SuiTypography variant="caption">ส่วนลด</SuiTypography>
                    <FormField
                      type="text"
                      placeholder="โปรดกรอกรอกส่วนลด"
                      name={`discount`}
                      onChange={formProps.handleChange}
                      value={formProps.values.discount}
                      validate={(e) => validateAmount(e)}
                      endAdornment={
                        <InputAdornment position="end">
                          <LootoList16>%</LootoList16>
                        </InputAdornment>
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SuiTypography variant="caption">แทงสูงสุด</SuiTypography>
                    <FormField
                      type="text"
                      placeholder="โปรดกรอกรอบแทงสูงสุด"
                      name={`spin`}
                      onChange={formProps.handleChange}
                      value={formProps.values.spin}
                      validate={(e) => validateAmount(e)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SuiTypography variant="caption">อัตราจ่าย (สูงสุด)</SuiTypography>
                    <FormField
                      type="text"
                      placeholder="โปรดกรอกรอบอัตราจ่าย (สูงสุด)"
                      name={`max_reward_rate`}
                      onChange={formProps.handleChange}
                      value={formProps.values.max_reward_rate}
                      validate={(e) => validateAmount(e)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SuiTypography variant="caption">ส่วนลด (สูงสุด)</SuiTypography>
                    <FormField
                      type="text"
                      placeholder="โปรดกรอกรอบส่วนลด (สูงสุด)"
                      name={`max_discount`}
                      onChange={formProps.handleChange}
                      value={formProps.values.max_discount}
                      validate={(e) => validateAmount(e)}
                      endAdornment={
                        <InputAdornment position="end">
                          <LootoList16>%</LootoList16>
                        </InputAdornment>
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SuiTypography variant="caption">แทงสูงสุด (สูงสุด)</SuiTypography>
                    <FormField
                      type="text"
                      placeholder="โปรดกรอกรอบแทงสูงสุด (สูงสุด)"
                      name={`max_spin`}
                      onChange={formProps.handleChange}
                      value={formProps.values.max_spin}
                      validate={(e) => validateAmount(e)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Divider />
                </Grid>
              </SuiBox>
            </DialogContent>
            <DialogActions>
              <SuiButton buttonColor="error" onClick={onClose}>
                ยกเลิก
              </SuiButton>
              <SuiButton type="submit" buttonColor="success">
                เพิ่มกลุ่มหวย
              </SuiButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
DialogaddGatchatype.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
};
export default DialogaddGatchatype;
