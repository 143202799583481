export default {
  gatchaTypeId: "",
  gatchaConfigTypeId: "",
  gatchaDrawingConfigTypeId: "",
  gatchaNumber: [
    { gatchaNumber: "", numberUp: "", numberDown: "", numberMulti: "", checktext: false },
  ],
  gatchaFlashNumber: [],
  gatchaTextNumber: [],
  gatchaRunNumber: [{ gatchaNumber: "", numberUp: "", numberDown: "", checktext: false }],
  totalBuy: 0,
  gatchaReason: "",
  runOption: 0,
  billGatcha: [],
  payloadGatcha: [],
  showBill: false,

  // twoNumber: "",
  // twoNumberUp: "",
  // twoNumberDown: "",

  // threeNumber: "",
  // threeNumberUp: "",
  // threeNumberDown: "",
  // threeNumberMulti: "",
};
