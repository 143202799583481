/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Soft UI Dashboard PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { size, fontWeightRegular } = typography;
const { borderRadius } = borders;
const { dark, blue } = colors;

export default {
  styleOverrides: {
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      // flex: "1 1 auto",
      textAlign: "center",
      // maxWidth: "unset !important",
      // minWidth: "unset !important",
      // minHeight: "unset !important",
      fontSize: size.regular,
      fontWeight: fontWeightRegular,
      textTransform: "none",
      lineHeight: "inherit",
      padding: "10px 18px",
      borderRadius: `${borderRadius.md} ${borderRadius.md} 0px 0px`,
      color: `${blue.focus}`,
      opacity: "1 !important",
      // background: blue.main,
      border: "1px solid #0890D0",
      fontWeight: 700,
      // marginRight: pxToRem(4),
      "& .material-icons, .material-icons-round": {
        marginBottom: "0 !important",
        marginRight: pxToRem(4),
      },

      "& svg": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },
      "  &.Mui-selected": {
        color: "#FFF",
        background: blue.focus,
      },
    },

    labelIcon: {
      paddingTop: pxToRem(4),
    },
  },
};
