import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Box, Alert } from "@mui/material";
import SuiButton from "components/SuiButton";
import { lottoCompiler } from "lotto-parser";
import Swal from "sweetalert2";
import Lottothai from "./component/lottothai";
import LottoOther from "./component/lottoother";
export const TextCard = ({ formData, onsetTotaldata, dataLotto }) => {
  const [result, setResult] = useState(null);

  const [lottoBill, setLottoBill] = useState(`1 30*30
123 100*100
12 20*20
21 20*20
3^ 20*20
^4 20*20
%7 50*50
8% 50*50
567 100*100
^89 100*100
1^2 100*100
3^4 40*40
56^ 40*40`);
  const handleAddBill = async (bill) => {
    let checkType = dataLotto.gatcha_configs.some((e) => e.gatcha_types_code === "3_Under")
      ? false
      : bill.some((e) => e.type === 3 && e.back_price > 0);
    if (bill === null) {
      Swal.fire("ผิดพลาด!", "กรุณาใส่ตัวเลข", "error");
    }
    // else if (checkType) {
    //   Swal.fire("ผิดพลาด!", "เนื่องจากประเภทหวยไม่รองรับ 3 ตัวล่าง กรุณาลองใหม่", "error");
    // }
    else {
      console.log(bill, "bill", dataLotto.gatcha_configs);
      let arrBill = [];
      let listbill = bill.map((e, i) => {
        const addBillArray = {};
        addBillArray.type = e.type;
        addBillArray.gatchaNumber = [e.lotto];
        addBillArray.numberUp = e.front_price;
        addBillArray.numberDown = !checkType && e.back_price;
        addBillArray.numberMulti = (checkType && e.type === 3 && e.back_price) || e.tod_price;
        arrBill.push(addBillArray);
      });
      formData.setFieldValue(`gatchaTextNumber`, [...formData.values.gatchaTextNumber, ...arrBill]);

      setLottoBill("");

      await Promise.resolve(formData.values.gatchaTextNumber);

      if (formData.values.gatchaTextNumber.length > 0) {
        var num = 0;
        formData.values.gatchaTextNumber.map((e, i) => {
          for (var x = 0; x < e.gatchaNumber.length; x++) {
            num = num + parseInt(e.numberUp || 0) + parseInt(e.numberDown || 0);
          }
        });
        onsetTotaldata(num, "text");
      }
    }
  };
  useEffect(() => {
    if (formData.values.gatchaTextNumber.length > 0) {
      var num = 0;
      formData.values.gatchaTextNumber.map((e, i) => {
        for (var x = 0; x < e.gatchaNumber.length; x++) {
          num = num + parseInt(e.numberUp || 0) + parseInt(e.numberDown || 0);
        }
      });
      onsetTotaldata(num, "text");
    }
  }, [formData.values.gatchaTextNumber]);
  return (
    <div>
      <Box display={"flex"}>
        <Box width={"100%"} px={2}>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={30}
            placeholder="โปรดกรอกตามแบบตัวอย่าง"
            style={{ width: "100%", borderRadius: 8, padding: 16 }}
            value={lottoBill}
            onChange={(event) => setLottoBill(event.target.value)}
          />
        </Box>
        {dataLotto.gatcha_configs.some((e) => e.gatcha_types_code === "3_Under") ? (
          <Lottothai />
        ) : (
          <LottoOther />
        )}
      </Box>

      <Box ml={2}>
        <SuiButton
          buttonColor="primary"
          variant="gradient"
          onClick={() => {
            if (lottoBill) {
              try {
                let bill = lottoCompiler(lottoBill);
                setResult(bill);
                Swal.fire("สำเร็จ", "ทำการเพิ่มบิลสำเร็จ", "success");
                handleAddBill(bill);
              } catch (err) {
                Swal.fire(
                  "เกิดข้อผิดพลาด",
                  `เนื่องจากรูปแบบบิลไม่ถูกต้อง โปรดตรวจสอบรูปแบบบิลอีกครั้ง`,
                  "error"
                );
              }
            }
          }}
        >
          เพิ่มบิล
        </SuiButton>
      </Box>
    </div>
  );
};

TextCard.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onsetTotaldata: PropTypes.func,
  gatchaconfig: PropTypes.array,
  dataLotto: PropTypes.object,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TextCard);
