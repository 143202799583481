/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
// Custom styles for ComplexProjectCard
import style from "layouts/Cards/ProjectCards/ComplexProjectCard/styles";

import {
  FlexContain,
  Flex1,
  Flex2,
  FlexText1,
  FlexText2,
  FlexText3,
  DivTextAlignRight,
  TextP14,
  TextP16b,
} from "components/Text/TextStyle";

function LottoProjectCard({
  color,
  image,
  title,
  lottoout,
  lottoin,
  description,
  subtitle,
  gatchatype,
  gatchatypenumber,
  gatchatypeuserlist,
  gatchadrawingconfigsid,
}) {
  const classes = style();

  const handleOpen = () => {};

  return (
    <Card>
      <SuiBox p={2}>
        <SuiBox display="flex" alignItems="center">
          <SuiAvatar
            src={image}
            alt={title}
            size="xl"
            variant="rounded"
            backgroundColor={color}
            customClass={classes.complexProjectCard_image}
          />
          {/* <SuiBox ml={2} lineHeight={0}>
            <SuiBox mb={1} lineHeight={0}>
              <SuiTypography variant="h6" textTransform="capitalize" fontWeight="medium">
                {title}
              </SuiTypography>
            </SuiBox>
            <SuiBox display="flex">
              <SuiTypography variant="h6" textTransform="capitalize" fontWeight="medium">
                {subtitle}
              </SuiTypography>
            </SuiBox>
          </SuiBox> */}
          <DivTextAlignRight>
            <TextP16b>{title}</TextP16b>
            <TextP14>{subtitle}</TextP14>
            <TextP14>{description}</TextP14>
          </DivTextAlignRight>
        </SuiBox>
        {/* <SuiBox my={2} lineHeight={1}>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            {description}
          </SuiTypography>
        </SuiBox> */}

        {/* <Divider /> */}
        {/* <SuiBox display="flex" justifyContent="space-between" alignItems="center">
          <SuiBox display="flex" flexDirection="column" lineHeight={0}>
            <SuiTypography variant="h4" fontWeight="medium" textColor="success">
              {lottoin || 0}
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="medium" textColor="secondary">
              ยอดเข้า
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" flexDirection="column" lineHeight={0}>
            <SuiTypography variant="h4" fontWeight="medium" textColor="error">
              {lottoout || 0}
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="medium" textColor="secondary">
              ส่งออก
            </SuiTypography>
          </SuiBox>
        </SuiBox> */}

        <FlexContain>
          <Flex1>
            <FlexText1>ยอดเข้า</FlexText1>
            <FlexText2>{lottoin || 0}</FlexText2>
          </Flex1>
          <Flex2>
            <FlexText1>ส่งออก</FlexText1>
            <FlexText3>{lottoout || 0}</FlexText3>
          </Flex2>
        </FlexContain>

        <SuiBox display="flex" justifyContent="space-between">
          <Grid item xs={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SuiButton
                buttonColor="primary"
                onClick={() => gatchatype(gatchadrawingconfigsid)}
                customClass={classes.button1}
              >
                ยอดตามประเภท
              </SuiButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SuiButton
                buttonColor="info"
                onClick={() => gatchatypenumber(gatchadrawingconfigsid)}
                customClass={classes.button2}
              >
                ยอดตามหมายเลข
              </SuiButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SuiButton
                buttonColor="success"
                onClick={() => gatchatypeuserlist(gatchadrawingconfigsid)}
                customClass={classes.button3}
              >
                ยอดตามสมาชิก
              </SuiButton>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of ComplexProjectCard
LottoProjectCard.defaultProps = {
  color: "dark",
  dateTime: "",
  members: [],
  dropdown: false,
};

// Typechecking props for the ProfileInfoCard
LottoProjectCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  lottoout: PropTypes.string,
  lottoin: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.node.isRequired,
  gatchatype: PropTypes.func,
  gatchatypenumber: PropTypes.func,
  gatchatypeuserlist: PropTypes.func,
  gatchadrawingconfigsid: PropTypes.number.isRequired,
};

export default LottoProjectCard;
