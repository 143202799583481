import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";

import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";

import Style from "../style.js";
import "../index.scss";
import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Swal from "sweetalert2";

const useStyle = makeStyles(Style);

function TwoAndThreeCard({ formData, onsetTotaldata, gatchaconfig }) {
  String.prototype.swapLetters = function (i) {
    var a = this.split("");
    var temp = this[i];
    a[i] = a[i + 1];
    a[i + 1] = temp;
    return a.join("");
  };

  function combs(n) {
    if (n === 0) {
      return 1;
    } else {
      return n * combs(n - 1);
    }
  }

  function permAlone(str) {
    var reg = new RegExp(/(\w)\1+/);
    var c = 0;
    var arrtest = [];

    function permutations(str, p, n, b, perms) {
      var index = p - n;
      var len = str.length - 2;
      if (arrtest.findIndex((e) => e === str) < 0) {
        arrtest.push(str);
      }

      if (perms === 0) {
        return arrtest;
      }
      //if p is less then or equal and b is zStart then increase p by 1
      //if p is equal to len do not increment p just call function with b===zBreak (call function to swich first two letters)
      if (p <= len && b === "zStart") {
        if (p === len) {
          b = "zBreak";
        } else {
          p = p + 1;
        }
        return permutations(str.swapLetters(index), p, n, b, perms - 1);
      }
      //if n is less then or equal and b is lStart then increase n by 1
      //if n is equal to len do not increment n just call function with b===lBreak (call function to swich first last two letters)
      if (n <= len && b === "lStart") {
        if (n === len) {
          b = "lBreak";
        } else {
          n = n + 1;
        }
        return permutations(str.swapLetters(index), p, n, b, perms - 1);
      }
      //if b is zBreak swap first two letters and set b to be lStart because
      //next break should swap last two letters
      if (b === "zBreak") {
        return permutations(str.swapLetters(0), p, n, "lStart", perms - 1);
      } else if (b === "lBreak") {
        return permutations(str.swapLetters(len), 0, 0, "zStart", perms - 1);
      }
      return permutations(str.swapLetters(index), p, n, b, perms - 1);
    }

    permutations(str, 0, 0, "zStart", combs(str.length) - 1);
    return arrtest;
  }

  const [inputList, setInputList] = useState([
    { gatchaNumber: "", numberUp: "", numberDown: "", numberMulti: "" },
  ]);
  const [lottoNumber, setLottoNumber] = useState([]);
  const [testArr, setTestArr] = useState([]);
  const [setInput, setsetInput] = useState(false);
  const [totalBuy, setTotalBuy] = useState([]);
  const [numForReverst, setNumForReverst] = useState();

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value, key } = e.target;

    formData.setFieldValue(`gatchaNumber[${index}].${name}`, value);
    if (
      name === "gatchaNumber" &&
      value > 2 &&
      gatchaconfig.findIndex((e, i) => e.gatcha_types_code === "3_Under") < 0
    ) {
      formData.setFieldValue(`gatchaNumber[${index}].numberDown`, "");
    }
    if (
      name == "gatchaNumber" &&
      value.length > 0 &&
      !formData.values.gatchaNumber[index].checktext
    ) {
      formData.setFieldValue(`gatchaNumber`, [
        ...formData.values.gatchaNumber,
        { gatchaNumber: "", numberUp: "", numberDown: "", numberMulti: "", checktext: false },
      ]);

      setNumForReverst(value);
      formData.setFieldValue(`gatchaNumber[${index}].${name}`, value);
      formData.setFieldValue(`gatchaNumber[${index}].checktext`, true);
    }
  };

  const handleBlurChange = (e, index) => {
    const { name, value } = e.target;

    // if (name != "gatchaNumber") {
    //   console.log("555 = ", parseInt(value));
    //   setTotalBuy((totalBuy) => [...totalBuy, parseInt(value) ? parseInt(value) : 0]);
    //   // console.log("totalBuy = ", (priceForBuy.reduce((a,v) =>  a = a + v , 0 )))
    // }

    if (value.length === 0 && formData.values.gatchaNumber[index].checktext) {
      let list = [...formData.values.gatchaNumber];
      list.pop();
      formData.setFieldValue(`gatchaNumber`, list);
      formData.setFieldValue(`gatchaNumber[${index}].checktext`, false);
    }
  };
  const handleblurTotalchange = () => {
    var num = 0;
    formData.values.gatchaNumber.map((e, i) => {
      num =
        num +
        parseInt(e.numberUp || 0) +
        parseInt(e.numberDown || 0) +
        parseInt(e.numberMulti || 0);
    });
    onsetTotaldata(num, "two");
  };

  const reverstNumber = async () => {
    formData?.values?.gatchaNumber.length > 1 ? formData?.values?.gatchaNumber.pop() : null;
    // const checklengt = formData?.values?.gatchaNumber.length;
    // console.log(checklengt, "checklengt");
    // const { gatchaNumber } = formData?.values?.gatchaNumber[checklengt];
    const { gatchaNumber } = formData?.values?.gatchaNumber.pop();
    // console.log("gatchaNumber = ", gatchaNumber);

    if (gatchaNumber < 1) {
      Swal.fire("ผิดพลาด!", "กรุณาใส่ตัวเลขก่อนกลับเลข", "error");
      formData.setFieldValue(`gatchaNumber`, [
        { gatchaNumber: "", numberUp: "", numberDown: "", numberMulti: "", checktext: false },
      ]);
    } else {
      if (gatchaNumber.length == 3) {
        const numValue = [];
        const numChange = permAlone(gatchaNumber);
        numValue.push(lottoNumber.concat(numChange));
        // formData?.values?.gatchaNumber.pop();
        let arr = [];
        numValue[0].map((e, i) =>
          arr.push({
            gatchaNumber: e,
            numberUp: "",
            numberDown: "",
            numberMulti: "",
            checktext: true,
          })
        );
        // console.log(arr);
        formData.setFieldValue(`gatchaNumber`, [
          ...formData.values.gatchaNumber,
          ...arr,
          {
            gatchaNumber: "",
            numberUp: "",
            numberDown: "",
            numberMulti: "",
            checktext: false,
          },
        ]);
      } else {
        const numberReverse = gatchaNumber
          ? gatchaNumber.toString().split("").reverse().join("")
          : gatchaNumber;
        // const exists = formData.values.gatchaNumber.find((p) => p == numberReverse);
        // console.log("exists = ",exists)
        //   if (!exists) {
        //     formData.setFieldValue(`gatchaNumber`, [
        //       ...formData.values.gatchaNumber,
        //       { gatchaNumber: numberReverse, numberUp: "", numberDown: "", numberMulti: "", checktext: false },
        //       { gatchaNumber: "", numberUp: "", numberDown: "", numberMulti: "", checktext: false },
        //     ]);
        //   }
        formData.setFieldValue(`gatchaNumber`, [
          ...formData.values.gatchaNumber,
          {
            gatchaNumber: gatchaNumber,
            numberUp: "",
            numberDown: "",
            numberMulti: "",
            checktext: false,
          },
          {
            gatchaNumber: numberReverse,
            numberUp: "",
            numberDown: "",
            numberMulti: "",
            checktext: false,
          },
          { gatchaNumber: "", numberUp: "", numberDown: "", numberMulti: "", checktext: false },
        ]);
      }
    }
  };
  const copyNumber = async (valuesCopy) => {
    // formData?.values?.gatchaNumber.pop();

    const { numberUp, numberDown, numberMulti } = formData?.values?.gatchaNumber[0];

    let copyarr = formData.values.gatchaNumber;
    if (valuesCopy === "numberUp") {
      if (numberUp < 1) {
        Swal.fire("ผิดพลาด!", "กรุณาใส่ราคา", "error");
      } else {
        formData?.values?.gatchaNumber.length > 1 ? formData?.values?.gatchaNumber.pop() : null;
        await copyarr.map((e, i) => {
          copyarr[i].numberUp = numberUp;

          formData.setFieldValue(`gatchaNumber`, [
            ...copyarr,
            {
              gatchaNumber: "",
              numberUp: "",
              numberDown: "",
              numberMulti: "",
              checktext: false,
            },
          ]);
          handleblurTotalchange();
        });
      }
    } else if (valuesCopy === "numberDown") {
      if (numberDown < 1) {
        Swal.fire("ผิดพลาด!", "กรุณาใส่ราคา", "error");
      } else {
        formData?.values?.gatchaNumber.length > 1 ? formData?.values?.gatchaNumber.pop() : null;
        await copyarr.map((e, i) => {
          if (
            copyarr[i].gatchaNumber.length > 2 &&
            gatchaconfig.findIndex((e, i) => e.gatcha_types_code === "3_Under") < 0
          ) {
          } else {
            copyarr[i].numberDown = numberDown;
          }

          formData.setFieldValue(`gatchaNumber`, [
            ...copyarr,
            {
              gatchaNumber: "",
              numberUp: "",
              numberDown: "",
              numberMulti: "",
              checktext: false,
            },
          ]);
          handleblurTotalchange();
        });
      }
    } else if (valuesCopy === "numberMulti") {
      if (numberMulti < 1) {
        Swal.fire("ผิดพลาด!", "กรุณาใส่ราคา", "error");
      } else {
        formData?.values?.gatchaNumber.length > 1 ? formData?.values?.gatchaNumber.pop() : null;
        await copyarr.map((e, i) => {
          if (copyarr[i].gatchaNumber.length > 2) {
            copyarr[i].numberMulti = numberMulti;
          }

          formData.setFieldValue(`gatchaNumber`, [
            ...copyarr,
            {
              gatchaNumber: "",
              numberUp: "",
              numberDown: "",
              numberMulti: "",
              checktext: false,
            },
          ]);

          handleblurTotalchange();
        });
      }
    }
  };

  return (
    <>
      <table className="card-table">
        <thead className="head1">
          <td>หมายเลข</td>
          <td>ตัวบน</td>
          <td>ตัวล่าง</td>
          <td>ตัวโต๊ด</td>
        </thead>
        <thead className="head2">
          <td>
            <div className="type-button" onClick={() => reverstNumber()}>
              กลับเลข
            </div>
          </td>
          <td>
            <div className="type-button" onClick={() => copyNumber("numberUp")}>
              คัดลอก
            </div>
          </td>
          <td>
            <div className="type-button" onClick={() => copyNumber("numberDown")}>
              คัดลอก
            </div>
          </td>
          <td>
            <div className="type-button" onClick={() => copyNumber("numberMulti")}>
              คัดลอก
            </div>
          </td>
        </thead>
        <tbody className="body1">
          {formData?.values?.gatchaNumber.map((x, i) => {
            return (
              <tr key={i}>
                <td>
                  <div className="table-flex text-center">
                    <SuiInput
                      type="text"
                      placeholder="หวย"
                      name={"gatchaNumber"}
                      onChange={(e) => {
                        handleInputChange(e, i);
                      }}
                      onBlur={(e) => handleBlurChange(e, i)}
                      value={x.gatchaNumber} //formData.values.gatchaNumber
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                        if (event.target.value.length >= 3) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className="table-flex text-right">
                    <SuiInput
                      type="text"
                      placeholder="0"
                      name={"numberUp"}
                      onK
                      onChange={(e) => {
                        handleInputChange(e, i);
                        // formData.setFieldValue("numberUp", e.target.value);
                      }}
                      value={x.numberUp} //formData.values.twoThreeNumberUp
                      onBlur={(e) => handleblurTotalchange(e, i)}
                      //   error={x.numberUp < 1 && x.numberDown < 1 && x.gatchaNumber !== ""}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <p className="td-text">บาท</p>
                  </div>
                </td>
                <td>
                  <div className="table-flex text-right">
                    <SuiInput
                      type="text"
                      placeholder="0"
                      name={"numberDown"}
                      onChange={(e) => {
                        handleInputChange(e, i);
                        // formData.setFieldValue("numberDown", e.target.value);
                      }}
                      value={x.numberDown} //formData.values.twoThreeNumberDown
                      onBlur={(e) => handleblurTotalchange(e, i)}
                      //   error={x.numberUp < 1 && x.numberDown < 1 && x.gatchaNumber !== ""}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }

                        if (
                          x.gatchaNumber.length > 2 &&
                          gatchaconfig.findIndex((e, i) => e.gatcha_types_code === "3_Under") < 0
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <p className="td-text">บาท</p>
                  </div>
                </td>
                <td>
                  <div className="table-flex text-right">
                    <SuiInput
                      type="text"
                      placeholder="0"
                      name={"numberMulti"}
                      onChange={(e) => {
                        handleInputChange(e, i);
                        // formData.setFieldValue("numberMulti", e.target.value);
                      }}
                      value={x.gatchaNumber.length > 2 ? x.numberMulti : ""} //formData.values.twoThreeNumberMulti
                      onBlur={(e) => handleblurTotalchange(e, i)}
                      //   error={
                      //     x.gatchaNumber.length > 2
                      //       ? x.numberUp < 1 && x.numberDown < 1
                      //       : x.numberUp < 1 && x.numberDown < 1 && x.numberMulti
                      //   }
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                        if (x.gatchaNumber.length < 3) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <p className="td-text">บาท</p>
                  </div>
                </td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

TwoAndThreeCard.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onsetTotaldata: PropTypes.func,
  gatchaconfig: PropTypes.array,
};

export default TwoAndThreeCard;
