import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React components
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Custom styles for the SidenavCard
import styles from "components/Sidenav/styles/SidenavCreditCard";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";

import { HOST } from "utils/config";
import { get } from "utils/helper";

import { Text16_2 } from "components/Text/TextStyle";

function SidenavCreditCard({ credit_balance, credit_on_bet, total_credit, loss_profit }) {
  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  const classes = styles({ miniSidenav, sidenavColor });
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [dataLotto, setDataLotto] = useState([]);

  const fetchLotto = async () => {
    try {
      setIsLoading(true);
      // const { data } = await get(
      //   `${HOST}api/Gatcha/GetRewardRateByGatchaId?gatcha_id=${id ? id : 1}`
      // );
      // setDataLotto(data.result.reward_rate);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLotto();
  }, []);

  const creditLotto = [
    { id: 1, name: "3 ตัวบน", pay: 800, dis: 15, bet: "1 - 1000" },
    { id: 2, name: "3 ตัวล่าง", pay: 145, dis: 15, bet: "1 - 10000" },
    { id: 3, name: "3 ตัวโต๊ด", pay: 125, dis: 15, bet: "1 - 10000" },
    { id: 4, name: "2 ตัวบน", pay: 90, dis: 8, bet: "1 - 20000" },
    { id: 5, name: "2 ตัวล่าง", pay: 90, dis: 8, bet: "1 - 20000" },
    { id: 6, name: "1 วิ่งบน", pay: 3, dis: 12, bet: "1 - 50000" },
    { id: 7, name: "1 วิ่งล่าง", pay: 4, dis: 12, bet: "1 - 50000" },
  ];

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.card_content}>
          {/* {miniSidenav && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <SuiBox customClass={classes.card_iconBox}>
                <Icon className={classes.card_icon} fontSize="medium">
                  star
                </Icon>
              </SuiBox>
            </div>
          )} */}
          {/* {!miniSidenav && ( 
            // <SuiBox mb={1} lineHeight={1}>*/}
          <Grid>
            <Grid container>
              <Grid xs={6} textAlign="left" fontSize="1rem">
                <Text16_2>เครดิต</Text16_2>
              </Grid>
              <Grid xs={6} textAlign="right" fontSize="1rem">
                <Text16_2>{total_credit}</Text16_2>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={6} textAlign="left" fontSize="1rem">
                <Text16_2>พนันคงค้าง</Text16_2>
              </Grid>
              <Grid xs={6} textAlign="right" fontSize="1rem">
                <Text16_2>{credit_on_bet}</Text16_2>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={6} textAlign="left" fontSize="1rem">
                <Text16_2>งบดุล</Text16_2>
              </Grid>
              <Grid xs={6} textAlign="right" fontSize="1rem">
                <Text16_2>{loss_profit}</Text16_2>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={6} textAlign="left" fontSize="1rem">
                <Text16_2>เครดิตพนัน</Text16_2>
              </Grid>
              <Grid xs={6} textAlign="right" fontSize="1rem">
                <Text16_2>{credit_balance}</Text16_2>
              </Grid>
            </Grid>
          </Grid>
          {/* // </SuiBox>
          )} */}
        </CardContent>
      </Card>
      {/* <Card className={classes.card}>
        <CardContent className={classes.card_content2}>
          <Grid>
            <Grid container>
              <Grid
                xs={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontSize="12px"
                ml={0.1}
                mr={0.1}
                borderRight="1px solid #dadada"
                borderBottom="1px solid #dadada"
              >
                <p>หวยไทย</p>
              </Grid>
              <Grid
                xs={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontSize="12px"
                ml={0.1}
                mr={0.1}
                borderRight="1px solid #dadada"
                borderBottom="1px solid #dadada"
              >
                <div style={{ textAlign: "center" }}>
                  <p>จ่าย</p>
                  <p>[บาท]</p>
                </div>
              </Grid>
              <Grid
                xs={1.5}
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontSize="12px"
                ml={0.1}
                mr={0.1}
                borderRight="1px solid #dadada"
                borderBottom="1px solid #dadada"
              >
                <div style={{ textAlign: "center" }}>
                  <p>ลด</p>
                  <p>[%]</p>
                </div>
              </Grid>
              <Grid
                xs={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontSize="12px"
                ml={0.1}
                mr={0.1}
                borderBottom="1px solid #dadada"
              >
                <div style={{ textAlign: "center" }}>
                  <p>แทงขั้นต่ำ</p>
                  <p>[บาท]</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            {dataLotto.map((e, i) => (
              <Grid container index={i} key={i}>
                <Grid
                  xs={4}
                  textAlign="center"
                  fontSize="12px"
                  ml={0.1}
                  mr={0.1}
                  borderRight="1px solid #dadada"
                >
                  {e.gatcha_type_name}
                </Grid>
                <Grid
                  xs={2}
                  textAlign="center"
                  fontSize="12px"
                  ml={0.1}
                  mr={0.1}
                  borderRight="1px solid #dadada"
                >
                  {e.reward_rate}
                </Grid>
                <Grid
                  xs={1.5}
                  textAlign="center"
                  fontSize="12px"
                  ml={0.1}
                  mr={0.1}
                  borderRight="1px solid #dadada"
                >
                  {e.discount}
                </Grid>
                <Grid xs={4} textAlign="center" fontSize="12px" ml={0.1} mr={0.1}>
                  {e.max_spin}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card> */}
    </>
  );
}
SidenavCreditCard.propTypes = {
  credit_balance: PropTypes.number,
  credit_on_bet: PropTypes.number,
  total_credit: PropTypes.number,
  loss_profit: PropTypes.number,
};
export default SidenavCreditCard;
