import "./index.scss";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import Navbar from "memberpage/pages/navbar";

import howtoImg1 from "assets/images/rule.png";

function HowToPlay() {
  return (
    <>
      <DashboardLayout>
        <Navbar />
        <div className="howto">
          <a href="https://bcel.live/" target="_blank" rel="noreferrer">
            <img src={howtoImg1} width={600} />
          </a>
        </div>
      </DashboardLayout>
    </>
  );
}

export default HowToPlay;
