import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SuiBox from "components/SuiBox";
import Table from "components/Tables/Table";
import DataTable from "components/Tables/DataTable";
import { Switch, Grid } from "@mui/material";
import { TextBlack24, Table16, TextBlack18SmiBold } from "components/Text/TextStyle";
import { get, post } from "utils/helper";
import { HOST } from "utils/config";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Select, MenuItem, FormControl } from "@mui/material";
import colors from "assets/theme/base/colors";
import Swal from "sweetalert2";
import SuiTypography from "components/SuiTypography";

const ManageAction = ({ dataLoto, dataheader, markets }) => {
  const { success, grey } = colors;
  const [isLoading, setIsLoading] = useState(false);
  const [dataAgent, setDataAgent] = useState(dataLoto);
  const [dataMarkets, setDataMarkets] = useState(markets);
  const chageValues = (index, value) => {
    let data = dataAgent;
    data[index].check = value;
    setDataAgent([...data]);
  };
  const chageValuesLotto = (i, index, value) => {
    let data = dataMarkets;
    data[i].gatcha_list[index].check = value;
    setDataMarkets([...data]);
  };
  const chageValuesAll = (value, index) => {
    let data = dataMarkets;
    data.map((e, i) => e.gatcha_list.map((el, index) => (el.check = value)));
    setDataMarkets([...data]);
  };
  const chageValuesGroupAll = (value, index) => {
    let data = dataMarkets;
    data[index].gatcha_list.map((el, index) => (el.check = value));
    setDataMarkets([...data]);
  };
  const chageValuesUserAll = (value) => {
    let data = dataAgent;
    data.map((el, index) => (el.check = value));
    setDataAgent([...data]);
  };
  useEffect(() => {
    setDataAgent(dataLoto);
    setDataMarkets(markets);
  }, [dataLoto]);

  const columns = [
    { name: "role", align: "center", label: "ระดับชั้น" },
    { name: "user_name", align: "center", label: "ชื่อผู้ใช้" },
    { name: "name", align: "center", label: "ชื่อ-สกุล" },
    { name: "phone", align: "center", label: "ติดต่อ	" },
    {
      name: "choose",
      align: "center",
      label: "เลือก",
    },
  ];
  const onSubmitLotto = async (openLotto) => {
    setIsLoading(true);
    try {
      let findUserArr = [];
      const findUser = dataAgent.map((e) => {
        if (e.check) {
          findUserArr.push({ user_Id: e.user_id });
        }
      });
      let findArr = [];
      const findLotto = dataMarkets.map((e, i) =>
        e.gatcha_list.map((el) => {
          if (el.check) {
            findArr.push({ gatcha_id: el.gatcha_id });
          }
        })
      );
      let payload = { enable: openLotto, market_list: findArr, member_list: findUserArr };
      const { data } = await post(`${HOST}api/UserConfig/SetStatusMarket`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
      setIsLoading(false);
    }
  };
  return (
    <SuiBox p={2}>
      <SuiBox backgroundColor={"#FFF"} p={3} display="flex" justifyContent="space-between">
        <TextBlack24>ข้อมูลสถานะตลาดของสมาชิก</TextBlack24>
        <SuiBox>
          <SuiButton
            buttonColor="info"
            onClick={() => chageValuesAll(true)}
            sx={{ marginRight: 1 }}
          >
            เลือกทั้งหมด
          </SuiButton>
          <SuiButton onClick={() => chageValuesAll(false)}>ไม่เลือกทั้งหมด</SuiButton>
        </SuiBox>
      </SuiBox>
      <SuiBox backgroundColor={"#FFF"} p={3}>
        {dataMarkets?.map((e, i) => (
          <SuiBox key={i} display="flex" p={2}>
            <Grid container spacing={4}>
              <Grid item xs={4} border="1px solid #000" p={2}>
                <TextBlack18SmiBold>{e.group_name}</TextBlack18SmiBold>
                <SuiButton
                  buttonColor="info"
                  onClick={() => chageValuesGroupAll(true, i)}
                  sx={{ marginRight: 1 }}
                >
                  เลือกทั้งหมด
                </SuiButton>
                <SuiButton onClick={() => chageValuesGroupAll(false, i)}>ไม่เลือกทั้งหมด</SuiButton>
              </Grid>
              <Grid item xs={8} border="1px solid #000" p={2}>
                <Grid container>
                  {e.gatcha_list.map((elem, index) => (
                    <Grid item xs={4} key={index}>
                      <SuiBox display="flex">
                        <Switch
                          checked={elem.check}
                          onChange={() => chageValuesLotto(i, index, !elem.check)}
                          sx={{ marginRight: 2 }}
                        />
                        <Table16>{elem.gatcha_name} </Table16>
                      </SuiBox>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </SuiBox>
        ))}
      </SuiBox>
      <SuiBox backgroundColor={"#FFF"} mt={2}>
        <SuiBox display="flex" justifyContent="space-between">
          <TextBlack24>เลือกสมาชิกที่ต้องการเปิด/ปิด</TextBlack24>
          <SuiBox>
            <SuiButton
              buttonColor="info"
              onClick={() => chageValuesUserAll(true)}
              sx={{ marginRight: 1 }}
            >
              เลือกทั้งหมด
            </SuiButton>
            <SuiButton onClick={() => chageValuesUserAll(false)}>ไม่เลือกทั้งหมด</SuiButton>
          </SuiBox>
        </SuiBox>

        <Table
          columns={columns}
          rows={dataAgent?.map((elem, index) => ({
            role: elem.role,
            user_name: elem.user_name,
            name: elem.name,
            phone: elem.phone,
            choose: (
              <SuiBox display="flex" width="100%" justifyContent="center">
                <Switch
                  checked={elem.check}
                  onChange={() => chageValues(index, !elem.check)}
                  sx={{ marginRight: 2 }}
                />
                <Table16>{elem.check ? "เลือก" : "ไม่เลือก"} </Table16>
              </SuiBox>
            ),
          }))}
        />
      </SuiBox>
      <SuiBox textAlign="center" mt={2}>
        <SuiTypography textColor="error">
          คลิกเปิดถ้าต้องการเปิดตลาดเลือก หรือ คลิกปิดถ้าต้องการปิดตลาดที่เลือก
        </SuiTypography>
        <SuiBox mt={2} display="flex" justifyContent="center">
          <SuiButton
            buttonColor="success"
            onClick={() => onSubmitLotto(true)}
            size="larger"
            sx={{ width: "100%", marginRight: 4 }}
            disabled={isLoading}
          >
            เปิด
          </SuiButton>
          <SuiButton
            buttonColor="error"
            onClick={() => onSubmitLotto(false)}
            size="larger"
            sx={{ width: "100%", marginLeft: 4 }}
            disabled={isLoading}
          >
            ปิด
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
};

ManageAction.propTypes = {
  dataLoto: PropTypes.array,
  dataheader: PropTypes.array,
  markets: PropTypes.array,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAction);
