/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { HOST } from "utils/config";
import { get } from "utils/helper";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import { Grid, Collapse, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import FormField from "components/FormField";
import SuiSelect from "components/SuiSelect";
// Images
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoAsana from "assets/images/small-logos/logo-asana.svg";
import PropTypes from "prop-types";

import {
  LootoList20,
  LootoList18,
  LootoList18_2,
  LootoList16,
  LootoList16_2,
} from "components/Text/TextStyle";
import HookForm from "components/HookForm";

function fightMax(max) {
  let arrStock = [];
  let numberSelectedStock = 0;
  arrStock.push({ value: numberSelectedStock, label: `${numberSelectedStock}%` });
  let maxNumber = max * 2;
  for (let i = 0; i < maxNumber; i++) {
    numberSelectedStock = numberSelectedStock + 0.5;
    arrStock.push({ value: numberSelectedStock, label: `${numberSelectedStock}%` });
  }
  return arrStock;
}
function RewardItem({ reward, formData, keyArr, errors, getValues, setValue }) {
  // let arr = [];
  // let numberSelected = 0;
  // for (let i = 0; i < 70; i++) {
  //   numberSelected = numberSelected + 0.5;
  //   arr.push({ value: numberSelected, label: `${numberSelected}%` });
  // }
  const [open, setOpen] = useState(false);
  // console.log(reward, "reward");
  // const validateValue = (value, max) => {
  //   return value < max || "Value must be less than 10";
  // };

  // console.log(errors);
  return (
    <SuiBox>
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1} onClick={() => setOpen(!open)}>
          <LootoList18>{reward?.gatcha_name}</LootoList18>
        </SuiBox>
      </SuiBox>

      <SuiBox pt={2} pb={3} px={3}>
        <Grid container spacing={4}>
          <Grid item xs={2}>
            <LootoList18>ประเภท</LootoList18>
          </Grid>
          <Grid item xs={3}>
            <LootoList18>อัตราจ่าย(บาทละ)</LootoList18>
          </Grid>
          <Grid item xs={3}>
            <LootoList18>ส่วนลด</LootoList18>
          </Grid>
          <Grid item xs={4}>
            <LootoList18>แทงสูงสุด</LootoList18>
          </Grid>
        </Grid>

        {reward &&
          reward?.config_reward_rate?.map((e, i) => (
            <Grid container spacing={4} style={{ padding: 8 }} key={i}>
              <Grid item xs={2}>
                <LootoList16_2>{e?.gatcha_type_name}</LootoList16_2>
              </Grid>
              <Grid item xs={3}>
                <HookForm
                  placeholder="โปรดกรอกจำนวนเงิน"
                  // flexDi="row"
                  type="number"
                  variants={"body1"}
                  justify="flex-start"
                  control={formData}
                  rule={{
                    required: "กรุณากรอกจำนวนเงิน",
                    max: {
                      value: e?.reward_rate_max,
                      message: `ยอดแทงสูงสุด ${e?.reward_rate_max}`,
                    },
                  }}
                  // rule={{
                  //   required: "This is required",
                  //   validate: (value) => {
                  //     validateValue(value, e.reward_rate_max);
                  //   },
                  // }}
                  // rule={{ required: true }}
                  // rule={{ validate: validateValue(e.reward_rate_max) }}
                  // rule={validateValue(e.reward_rate_max)}
                  errors={
                    errors?.reward?.length > 0 &&
                    errors?.reward[keyArr]?.config_reward_rate[i]?.reward_rate
                  }
                  // onChange={(value) => {
                  //   formData.handleChange(value);
                  // }}
                  // onChange={formData.handleChange}
                  name={`reward[${keyArr}].config_reward_rate[${i}].reward_rate`}
                  // value={e.reward_rate}
                />
              </Grid>
              <Grid item xs={3}>
                <SuiSelect
                  placeholder="ส่วนลด"
                  options={fightMax(e.discount_max)}
                  name="discount"
                  value={{ value: e.discount, label: `${e.discount}%` }}
                  onChange={(value) =>
                    setValue(`reward[${keyArr}].config_reward_rate[${i}].discount`, value.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <HookForm
                  placeholder="999"
                  // flexDi="row"
                  variants={"body1"}
                  justify="flex-start"
                  rule={{
                    required: "กรุณากรอกจำนวนเงิน",
                    max: { value: e?.max_spin_max, message: `ยอดแทงสูงสุด ${e?.max_spin_max}` },
                  }}
                  // rule={{ validate: validateValue(e.max_spin_max) }}
                  // value={e.max_spin}
                  control={formData}
                  errors={
                    errors?.reward?.length > 0 &&
                    errors?.reward[keyArr]?.config_reward_rate[i]?.max_spin
                  }
                  // onChange={(e) => onValueChange(e, keyArr, i)}
                  // onChange={formData.handleChange}
                  // errors={}
                  name={`reward[${keyArr}].config_reward_rate[${i}].max_spin`}
                  type="number"
                />
              </Grid>
            </Grid>
          ))}

        <Divider />
      </SuiBox>
    </SuiBox>
  );
}
RewardItem.propTypes = {
  setValue: PropTypes.func,
  errors: PropTypes.object,
  getValues: PropTypes.func,
  reward: PropTypes.array,
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,

  keyArr: PropTypes.any,
};
export default RewardItem;
