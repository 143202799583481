import moment from "moment";
import colors from "assets/theme/base/colors";
const { info, white, black, text, tableBuy } = colors;
export const COLUMNS = [
  {
    Header: "ลำดับ",
    accessor: "row_no",
    Cell: ({ row }) => {
      return row.original?.row_no;
    },
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "เวลาแทง",
    accessor: "bill_date",
    Cell: ({ row }) => {
      return moment(row.original?.bill_date).format("DD/MM HH:mm");
    },
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "เลขที่บิล",
    accessor: "bill_number",
    Cell: ({ row }) => {
      return "#" + row.original?.bill_number;
    },
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "ตลาด",
    accessor: "market",
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "อ้างอิง",
    accessor: "reference",
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "รายการ",
    accessor: "number_of_list",
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "ยอดแทง",
    accessor: "total_buy",
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "ส่วนลด",
    accessor: "total_discount",
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
  {
    Header: "สถานะ",
    accessor: "status",
    bgcolor: tableBuy.background,
    fontcolor: black.main,
  },
];
