import react, { useState, useEffect } from "react";
import { post } from "utils/helper/request";
import { HOST } from "utils/config";
import { Formik, Form } from "formik";
import { useParams } from "react-router";
// @mui material components
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import { Grid, Divider, Typography } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import FormField from "components/FormField";
import validations from "./components/validations";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
// Data

import "./index.scss";

import {
  LootoList20,
  LootoList18,
  LootoList18_2,
  LootoList16,
  LootoList16red,
  LootoList12,
  LootoList14,
  Nopadding,
} from "components/Text/TextStyle";

function LottoList({ dataLotto, onSuccess }) {
  const { id } = useParams();
  const history = useHistory();
  String.prototype.swapLetters = function (i) {
    var a = this.split("");
    var temp = this[i];
    a[i] = a[i + 1];
    a[i + 1] = temp;
    return a.join("");
  };

  function combs(n) {
    if (n === 0) {
      return 1;
    } else {
      return n * combs(n - 1);
    }
  }

  function permAlone(str, twotodd) {
    var reg = new RegExp(/(\w)\1+/);
    var c = 0;
    var arrtest = [];
    var arrtodd = [];

    function permutations(str, p, n, b, perms) {
      var index = p - n;
      var len = str.length - 2;
      if (arrtest.findIndex((e) => e === str) < 0) {
        if (twotodd) {
          arrtest.push(str);
          arrtodd.push(str.substring(1, 3));
        } else {
          arrtest.push(str);
        }
      }

      if (perms === 0) {
        return arrtest;
      }
      //if p is less then or equal and b is zStart then increase p by 1
      //if p is equal to len do not increment p just call function with b===zBreak (call function to swich first two letters)
      if (p <= len && b === "zStart") {
        if (p === len) {
          b = "zBreak";
        } else {
          p = p + 1;
        }
        return permutations(str.swapLetters(index), p, n, b, perms - 1);
      }
      //if n is less then or equal and b is lStart then increase n by 1
      //if n is equal to len do not increment n just call function with b===lBreak (call function to swich first last two letters)
      if (n <= len && b === "lStart") {
        if (n === len) {
          b = "lBreak";
        } else {
          n = n + 1;
        }
        return permutations(str.swapLetters(index), p, n, b, perms - 1);
      }
      //if b is zBreak swap first two letters and set b to be lStart because
      //next break should swap last two letters
      if (b === "zBreak") {
        return permutations(str.swapLetters(0), p, n, "lStart", perms - 1);
      } else if (b === "lBreak") {
        return permutations(str.swapLetters(len), 0, 0, "zStart", perms - 1);
      }
      return permutations(str.swapLetters(index), p, n, b, perms - 1);
    }

    permutations(str, 0, 0, "zStart", combs(str.length) - 1);
    if (twotodd) {
      return arrtodd;
    } else {
      return arrtest;
    }
  }

  const [lotto, setLotto] = useState([]);
  const [loading, setLoading] = useState(false);

  const saveAllAward = async (event) => {
    console.log(event, "eoeoeo");
    let tarr = [];
    let arr = event.award.map((e, i) => {
      if (
        e.gatcha_types_code === "3_Switch" ||
        e.gatcha_types_code === "1_Run_On" ||
        e.gatcha_types_code === "1_Run_Under"
      ) {
        const chars = e.gatcha_result_number.split(",");
        chars.map((elem, index) =>
          tarr.push({
            gatcha_config_types_id: e.gatcha_config_types_id,
            gatcha_result_number: elem,
          })
        );
      } else {
        tarr.push({
          gatcha_config_types_id: e.gatcha_config_types_id,
          gatcha_result_number: e.gatcha_result_number,
        });
      }
    });

    try {
      setLoading(true);
      const payload = {
        gatcha_drawing_config_id: dataLotto.gatcha_drawing_config_id,
        gatcha_result_number: tarr,
      };

      const { data } = await post(`${HOST}api/GatchaResults/SetGatchaResult`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success").then(() => {
        onSuccess();
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
    }
  };
  function getDistinctArray(arr) {
    return [...new Set(arr)];
  }
  const onChangeLoto = async (event, formProps, gatcha_types_code) => {
    formProps.setFieldValue(event.target.name, event.target.value);

    if (gatcha_types_code === "3_On" && event.target.value.length === 3) {
      console.log("เข้า");
      let findex = formProps.values.award.findIndex((e) => e.gatcha_types_code === "3_Switch");
      let findextwo = formProps.values.award.findIndex((e) => e.gatcha_types_code === "2_On");
      let findexone = formProps.values.award.findIndex((e) => e.gatcha_types_code === "1_Run_On");
      const chars = event.target.value.split("");
      let chartuniq = getDistinctArray(chars);
      formProps.setFieldValue(
        `award[${findex}].gatcha_result_number`,
        permAlone(event.target.value).toString()
      );
      formProps.setFieldValue(
        `award[${findextwo}].gatcha_result_number`,
        event.target.value.substring(1, 3)
      );
      formProps.setFieldValue(`award[${findexone}].gatcha_result_number`, chartuniq.toString());
      // formProps.setFieldValue(
      //   `award[${findexone}].gatcha_result_number`,
      //   permAlone(event.target.value, true).toString()
      // );
      // console.log(formProps.values.award);
      //  formProps.setFieldValue(, event.target.value);
    } else if (gatcha_types_code === "2_Under" && event.target.value.length === 2) {
      let findexone = formProps.values.award.findIndex(
        (e) => e.gatcha_types_code === "1_Run_Under"
      );
      const chars = event.target.value.split("");
      let chartuniq = getDistinctArray(chars);
      formProps.setFieldValue(`award[${findexone}].gatcha_result_number`, chartuniq.toString());
    }
  };
  const insertAt = (array, index) => {
    var arrayToInsert = Array.prototype.splice.apply(arguments, [2]);
    return insertArrayAt(array, index, arrayToInsert);
  };
  const insertArrayAt = (array, index, arrayToInsert) => {
    array.splice(index, 0, ...arrayToInsert);
    return array;
  };
  useEffect(() => {
    if (dataLotto?.gatcha_name == "หวยไทย") {
      var arrToInsert = [
        {
          gatcha_config_types_id: "2",
          gatcha_types_code: "3_Under",
          gatcha_types_id: 2,
          gatcha_types_length: 3,
          gatcha_types_name: "3 ตัวล่าง ลำดับที่2",
          reward_rate: 145,
        },
        {
          gatcha_config_types_id: "2",
          gatcha_types_code: "3_Under",
          gatcha_types_id: 2,
          gatcha_types_length: 3,
          gatcha_types_name: "3 ตัวล่าง ลำดับที่3",
          reward_rate: 145,
        },
        {
          gatcha_config_types_id: "2",
          gatcha_types_code: "3_Under",
          gatcha_types_id: 2,
          gatcha_types_length: 3,
          gatcha_types_name: "3 ตัวล่าง ลำดับที่4",
          reward_rate: 145,
        },
      ];
      dataLotto?.gatcha_configs.length > 0 &&
        dataLotto?.gatcha_configs.length < 8 &&
        insertArrayAt(dataLotto?.gatcha_configs, 2, arrToInsert);
    }
  }, [id]);
  return (
    <SuiBox my={3}>
      <Card>
        {dataLotto?.gatcha_configs.length > 0 && (
          <Formik
            initialValues={{ award: dataLotto?.gatcha_configs }}
            validationSchema={validations}
            onSubmit={saveAllAward}
          >
            {(formProps) => (
              <Form autoComplete="off">
                <SuiBox p={3}>
                  <SuiBox display={"flex"} justifyContent={"space-between"}>
                    <SuiTypography variant={"h5"} fontWeight="bold">
                      <LootoList20>{dataLotto?.gatcha_name}</LootoList20>
                    </SuiTypography>
                    <LootoList16red>
                      ประจำงวดที่ {dataLotto?.gatcha_drawing_date_display} <br />
                    </LootoList16red>
                    <SuiBox>
                      <SuiButton
                        type="submit"
                        buttonColor="primary"
                        sx={{ marginRight: 2 }}
                        onClick={() =>
                          history.push(`/supposedly/${dataLotto?.gatcha_drawing_config_id}`)
                        }
                      >
                        ดูตามงวด
                      </SuiButton>
                      <SuiButton
                        type="submit"
                        buttonColor="success"
                        sx={{ marginRight: 2 }}
                        onClick={() => history.push(`/supposedly`)}
                      >
                        คาดคะเนออกหวย
                      </SuiButton>
                      <SuiButton type="submit" buttonColor="info">
                        บันทึกทั้งหมด
                      </SuiButton>
                    </SuiBox>
                  </SuiBox>
                  <Grid container>
                    <Grid xs={1}></Grid>
                    <Grid xs={7}>
                      <LootoList16>ประเภท</LootoList16>
                    </Grid>
                    <Grid xs={4}>
                      <LootoList16>หวยที่ออก</LootoList16>
                    </Grid>
                  </Grid>
                  <Divider />

                  {formProps.values?.award.length > 0 &&
                    formProps.values?.award.map((values, index) => {
                      return (
                        <>
                          <Grid container key={index} style={{ padding: 8 }} spacing={2}>
                            <Grid xs={1} item></Grid>
                            <Grid xs={7} item>
                              <LootoList16>{values?.gatcha_types_name}</LootoList16>
                            </Grid>
                            <Grid xs={2} item>
                              {values.gatcha_types_code === "3_Switch" ||
                              values.gatcha_types_code === "2_On" ||
                              values.gatcha_types_code === "1_Run_On" ||
                              values.gatcha_types_code === "1_Run_Under" ? (
                                <Typography>{values.gatcha_result_number || ""}</Typography>
                              ) : (
                                <FormField
                                  // label="จำนวนเงิน"
                                  placeholder="โปรดกรอกหวยที่ออก"
                                  name={`award[${index}].gatcha_result_number`}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                    if (event.target.value.length >= values.gatcha_types_length) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(event) => {
                                    onChangeLoto(event, formProps, values.gatcha_types_code, index);
                                  }}
                                  value={values?.award?.gatcha_result_number}
                                  // error={
                                  //   formProps.errors?.award[index]?.numberaward &&
                                  //   formProps.touched?.award[index]?.numberaward
                                  // }
                                  // success={
                                  //   formData.values?.profile?.credit.length > 0 &&
                                  //   !formData.errors?.profile?.credit
                                  // }
                                />
                              )}
                            </Grid>
                            <Grid xs={2} item></Grid>
                          </Grid>
                          <Divider />
                        </>
                      );
                    })}
                  {/* <Divider /> */}
                </SuiBox>
              </Form>
            )}
          </Formik>
        )}
      </Card>
    </SuiBox>
  );
}
LottoList.propTypes = {
  dataLotto: PropTypes.array,
  onSuccess: PropTypes.func,
};
export default LottoList;
