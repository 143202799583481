import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DataTable from "components/Tables/DataTable";
import SuiButton from "components/SuiButton";
import colors from "assets/theme/base/colors";
import SuiBox from "components/SuiBox";
const { info, white, primary, success } = colors;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogDetail({ open, onClose, dataTypeDetail, report }) {
  const rednderTotal = () => {
    let total_buy_all = 0;
    let downline_receive_all = 0;
    let downline_discount_all = 0;
    let downline_receive_discount_all = 0;
    let downline_total_reward_all = 0;
    let agent_total_receive_all = 0;
    let agent_total_discount_all = 0;
    let agent_total_receive_discount_all = 0;
    let agent_total_reward_all = 0;
    let upline_receive_all = 0;
    let upline_discount_all = 0;
    let upline_receive_total_all = 0;
    let upline_total_reward_all = 0;
    let downline_summary_all = 0;
    let agent_summary_all = 0;
    let upline_summary_all = 0;
    const datatotal =
      dataTypeDetail &&
      dataTypeDetail.map((e, i) => {
        {
          total_buy_all = total_buy_all + e.total_buy;
          downline_receive_all = downline_receive_all + e.downline_receive;
          downline_discount_all = downline_discount_all + e.downline_discount;
          downline_receive_discount_all =
            downline_receive_discount_all + e.downline_receive_discount;
          agent_total_receive_all = agent_total_receive_all + e.agent_total_receive;
          agent_total_discount_all = agent_total_discount_all + e.agent_total_discount;
          agent_total_receive_discount_all =
            agent_total_receive_discount_all + e.agent_total_receive_discount;
          upline_receive_all = upline_receive_all + e.upline_receive;
          upline_discount_all = upline_discount_all + e.upline_discount;
          upline_receive_total_all = upline_receive_total_all + e.upline_receive_total;
          agent_total_reward_all = agent_total_reward_all + e.agent_total_reward;
          upline_total_reward_all = upline_total_reward_all + e.upline_total_reward;
          downline_total_reward_all = downline_total_reward_all + e.downline_total_reward;
          downline_summary_all = downline_summary_all + e.downline_summary;
          agent_summary_all = agent_summary_all + e.agent_summary;
          upline_summary_all = upline_summary_all + e.upline_summary;
        }
      });
    let datatofix = dataTypeDetail.map((e, i) => ({
      gatcha_types_name: e.gatcha_types_name,
      total_buy: e.total_buy?.toFixed(2),
      downline_receive: e.downline_receive?.toFixed(2),
      downline_discount: e.downline_discount?.toFixed(2),
      downline_receive_discount: e.downline_receive_discount?.toFixed(2),
      downline_total_reward: e.downline_total_reward?.toFixed(2),
      agent_total_receive: e.agent_total_receive?.toFixed(2),
      agent_total_discount: e.agent_total_discount?.toFixed(2),
      agent_total_receive_discount: e.agent_total_receive_discount?.toFixed(2),
      agent_total_reward: e.agent_total_reward?.toFixed(2),
      upline_receive: e.upline_receive?.toFixed(2),
      upline_discount: e.upline_discount?.toFixed(2),
      upline_receive_total: e.upline_receive_total?.toFixed(2),
      upline_total_reward: e.upline_total_reward?.toFixed(2),
      downline_summary: e.downline_summary?.toFixed(2),
      agent_summary: e.agent_summary?.toFixed(2),
      upline_summary: e.upline_summary?.toFixed(2),
    }));

    // dataTypeDetail.push({ gatcha_types_name: "รวม", total_buy: total_buy_all });
    return [
      ...datatofix,
      {
        // gatcha_types_name: (
        //   <SuiBox textAlign="end" width={"100%"}>
        //     รวม
        //   </SuiBox>
        // ),
        total_buy: total_buy_all.toFixed(2),
        downline_receive: downline_receive_all.toFixed(2),
        downline_discount: downline_discount_all?.toFixed(2),
        downline_receive_discount: downline_receive_discount_all?.toFixed(2),
        downline_total_reward: downline_total_reward_all?.toFixed(2),
        agent_total_receive: agent_total_receive_all?.toFixed(2),
        agent_total_discount: agent_total_discount_all?.toFixed(2),
        agent_total_receive_discount: agent_total_receive_discount_all?.toFixed(2),
        agent_total_reward: agent_total_reward_all?.toFixed(2),
        upline_receive: upline_receive_all?.toFixed(2),
        upline_discount: upline_discount_all?.toFixed(2),
        upline_receive_total: upline_receive_total_all?.toFixed(2),
        upline_total_reward: upline_total_reward_all?.toFixed(2),
        downline_summary: downline_summary_all?.toFixed(2),
        agent_summary: agent_summary_all?.toFixed(2),
        upline_summary: upline_summary_all?.toFixed(2),
      },
    ];
  };

  const dataDetail = {
    columns: [
      { Header: "ประเภท", accessor: "gatcha_types_name" },
      { Header: "ยอดพนัน", accessor: "total_buy" },
      {
        Header: "ยอดส่งออก",
        accessor: "downline_receive",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "ส่วนลด",
        accessor: "downline_discount",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "รวม",
        accessor: "downline_receive_discount",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "ยอดถือสู้",
        accessor: "agent_total_receive",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "ส่วนลด",
        accessor: "agent_total_discount",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "รวม",
        accessor: "agent_total_receive_discount",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "ยอดถือสู้",
        accessor: "upline_receive",
        bgcolor: success.main,
        fontcolor: white.main,
      },
      {
        Header: "ส่วนลด",
        accessor: "upline_discount",
        bgcolor: success.main,
        fontcolor: white.main,
      },
      {
        Header: "รวม",
        accessor: "upline_receive_total",
        bgcolor: success.main,
        fontcolor: white.main,
      },
    ],
    rows: rednderTotal(dataTypeDetail) || [],
  };
  const mainTable = [
    { Header: "" },
    { Header: "" },
    { Header: "", bgcolor: info.main, fontcolor: white.main },
    { Header: "ลูกทีม", bgcolor: info.main, fontcolor: white.main },
    { Header: "", bgcolor: info.main, fontcolor: white.main },
    { Header: "", bgcolor: primary.main, fontcolor: white.main },
    { Header: "ตัวคุณ", bgcolor: primary.main, fontcolor: white.main },
    { Header: "", bgcolor: primary.main, fontcolor: white.main },
    { Header: "", bgcolor: success.main, fontcolor: white.main },
    { Header: "แม่ทีม", bgcolor: success.main, fontcolor: white.main },
    { Header: "", bgcolor: success.main, fontcolor: white.main },
  ];
  const dataDetailReport = {
    columns: [
      { Header: "ประเภท", accessor: "gatcha_types_name" },
      { Header: "ยอดพนัน", accessor: "total_buy" },
      {
        Header: "ยอดส่งออก",
        accessor: "downline_receive",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "ส่วนลด",
        accessor: "downline_discount",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "ถูกรางวัล",
        accessor: "downline_total_reward",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "รวม",
        accessor: "downline_summary",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "ยอดถือสู้",
        accessor: "agent_total_receive",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "ส่วนลด",
        accessor: "agent_total_discount",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "ถูกรางวัล",
        accessor: "agent_total_reward",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "รวม",
        accessor: "agent_summary",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "ยอดถือสู้",
        accessor: "upline_receive",
        bgcolor: success.main,
        fontcolor: white.main,
      },
      {
        Header: "ส่วนลด",
        accessor: "upline_discount",
        bgcolor: success.main,
        fontcolor: white.main,
      },
      {
        Header: "ถูกรางวัล",
        accessor: "upline_total_reward",
        bgcolor: success.main,
        fontcolor: white.main,
      },
      {
        Header: "รวม",
        accessor: "upline_summary",
        bgcolor: success.main,
        fontcolor: white.main,
      },
    ],
    rows: rednderTotal(dataTypeDetail) || [],
  };
  const mainTableReport = [
    { Header: "" },
    { Header: "" },
    { Header: "", bgcolor: info.main, fontcolor: white.main },
    { Header: "", bgcolor: info.main, fontcolor: white.main },
    { Header: "ลูกทีม", bgcolor: info.main, fontcolor: white.main },
    { Header: "", bgcolor: info.main, fontcolor: white.main },
    { Header: "", bgcolor: primary.main, fontcolor: white.main },
    { Header: "", bgcolor: primary.main, fontcolor: white.main },
    { Header: "ตัวคุณ", bgcolor: primary.main, fontcolor: white.main },
    { Header: "", bgcolor: primary.main, fontcolor: white.main },
    { Header: "", bgcolor: success.main, fontcolor: white.main },
    { Header: "", bgcolor: success.main, fontcolor: white.main },
    { Header: "แม่ทีม", bgcolor: success.main, fontcolor: white.main },
    { Header: "", bgcolor: success.main, fontcolor: white.main },
  ];

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={false}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        ยอดตามประเภท
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <DataTable
          table={report ? dataDetailReport : dataDetail}
          mainHeadTable={report ? mainTableReport : mainTable}
        />
      </DialogContent>
      <DialogActions>
        <SuiButton
          onClick={onClose}
          variant="gradient"
          buttonColor={"error"}
          size="small"
          circular
          autoFocus
        >
          ปิด
        </SuiButton>
        {/* <Button autoFocus onClick={onClose}>
          ปิด
        </Button> */}
      </DialogActions>
    </BootstrapDialog>
  );
}

DialogDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dataTypeDetail: PropTypes.array,
  dataColumn: PropTypes.array,
  report: PropTypes.bool,
};
