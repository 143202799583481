import Style from "./style.js";
import "./index.scss";
import { makeStyles } from "@mui/styles";

import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";

import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";

const useStyle = makeStyles(Style);

function BetDetail() {
  const classes = useStyle();

  const listTypeLotto = [
    { id: 1, name: "กลุ่มหวยไทย", amont: "จำนวนหวยในกลุ่ม", tottal: 1, list: "รายการ" },
    { id: 2, name: "กลุ่มหวยต่างประเทศ", amont: "จำนวนหวยในกลุ่ม", tottal: 2, list: "รายการ" },
    {
      id: 3,
      name: "กลุ่มหวยหุ้นไทยและต่างประเทศ",
      amont: "จำนวนหวยในกลุ่ม",
      tottal: 3,
      list: "รายการ",
    },
    { id: 4, name: "กลุ่มหวย 4D", amont: "จำนวนหวยในกลุ่ม", tottal: 4, list: "รายการ" },
    { id: 5, name: "กลุ่มหวยยี่กี", amont: "จำนวนหวยในกลุ่ม", tottal: 5, list: "รายการ" },
  ];

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <div className="bet-detail">
          <Grid container>
            <Grid xs={3}></Grid>
          </Grid>
        </div>
      </DashboardLayout>
    </>
  );
}

export default BetDetail;
