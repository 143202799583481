/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useMemo, useCallback } from "react";
import { Formik, Form, useFormik, FastField, Field } from "formik";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
// @mui material components
import Grid from "@mui/material/Grid";
import { HOST } from "utils/config";
import { get, post } from "utils/helper";
import { useHistory } from "react-router";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import initialValues from "./schemas/initialValues";
import SuiButton from "components/SuiButton";
// Settings page components
import { useDispatch, connect } from "react-redux";
import Header from "pages/users/createmember/components/Header";
import BasicInfo from "pages/users/createmember/components/BasicInfo";
import Keep from "./components/Keep";
import KeepMember from "./components/KeepMember";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Betinfo from "./components/Betinfo";

import Footer from "layouts/Footer";
import Card from "@mui/material/Card";
import validations from "./schemas/validations";

function CreateMember(rest) {
  const history = useHistory();
  const { authentication } = rest;
  const [stock, setStock] = useState({});
  const [profile, setProfile] = useState({});
  const currentValidation = validations[0];

  const useYupValidationResolver = (validationSchema) =>
    useCallback(
      async (data) => {
        console.log(data, "data");
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors) {
          console.log(errors, "errors");
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );
  const fetchGetRegister = async () => {
    try {
      const { data } = await get(
        `${HOST}api/register/GetDataForRegister?isMember=${
          authentication?.user?.role_id === "COMPANY"
            ? false
            : authentication?.user?.role_id === "MASTER"
            ? false
            : true
        }`
      );

      data.result.profile.role =
        authentication?.user?.role_id === "COMPANY"
          ? "MASTER"
          : authentication?.user?.role_id === "MASTER"
          ? "AGENT"
          : "MEMBER";
      data.result.profile.credit = "";
      data.result.profile.username = "";
      data.result.profile.password = "";
      data.result.profile.repeatPassword = "";
      data.result.profile.name = "";
      data.result.profile.phone = "";

      data?.result?.stock.map((e, i) => (e.give_difference = false));
      setProfile(data.result.profile);
      setStock(data.result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeProfile = (name, value) => {
    let isProfile = profile;
    isProfile[name] = value;

    setProfile(isProfile);
  };
  // const {
  //   register,
  //   handleSubmit,
  //   watch,
  //   setValue,
  //   formState: { errors },
  // } = useForm({
  //   defaultValues: stock,
  // });
  const resolver = useYupValidationResolver(validations);
  const {
    watch,
    setValue,
    register,
    getValues,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validations),
    defaultValues: useMemo(() => {
      return stock;
    }, [stock]),
  });
  // const setdefault = async () => {
  //   const { data } = await get(`${HOST}api/register/GetDataForRegister`);

  //   setstock(data.result);
  // };
  useEffect(() => {
    reset(stock);
  }, [stock]);
  useEffect(() => {
    if (authentication?.user?.role_id) {
      fetchGetRegister();
    }
  }, [authentication]);
  const handleSubmitForm = async (event) => {
    try {
      event.profile.user_name = event.profile.username;
      event.profile.credit = parseFloat(event.profile.credit);
      const { data } = await post(`${HOST}api/register/Register`, event);
      console.log(event, "event");
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      history.push("/manage/member/all");
    } catch (error) {
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่อีกครั้ง", "error");
    }
  };
  // const watchStock = watch();
  const { fields: stockFields } = useFieldArray({
    control,
    name: "stock",
  });
  const { fields: rewardFields } = useFieldArray({ control, name: "reward" });
  // console.log(watch());
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={4}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Header />
            </Grid>

            <Grid item xs={12}>
              <BasicInfo
                formData={control}
                errors={errors}
                credituser={authentication}
                getValues={getValues}
                setValue={setValue}
              />
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {getValues()?.profile?.role === "MEMBER" ? (
                  <KeepMember
                    formData={register}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    register={register}
                    fields={stockFields}
                  />
                ) : (
                  <Keep
                    formData={register}
                    errors={errors}
                    getValues={watch}
                    setValue={setValue}
                    register={register}
                    fields={stockFields}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {rewardFields.length > 0 && (
                  <Betinfo
                    fields={rewardFields}
                    formData={control}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    stockTabs={stock?.stock}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <SuiButton
                    disabled={false}
                    type="submit"
                    variant="gradient"
                    buttonColor="success"
                  >
                    สร้างสมาชิก
                  </SuiButton>
                </Card>
              </Grid>
            </Grid>
          </form>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}
const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(CreateMember);
