import { get, post, posts } from "utils/helper/request";
import Swal from "sweetalert2";
import { HOST } from "utils/config";
import { useHistory } from "react-router";

export const getLotto = (token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "Lotto/Loading", isLoading: true });

      const { data } = await post(`${HOST}api/Lotto/GetLotto`);
      const lotto = {
        lotto: data.results,
        isFetchLoto: true,
        type: "Lotto/Success",
      };
      return dispatch(lotto);
    } catch (e) {
      dispatch({ type: "Lotto/Loading", isLoading: false });
      console.log(e);
      return dispatch({
        type: "Lotto/Failure",
        message: "",
      });
    }
  };
};
