/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React components
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { logout } from "utils/auth";
// Custom styles for the SidenavCard
import styles from "components/Sidenav/styles/sidenavCard";
import { Text16, Text18 } from "components/Text/TextStyle"

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";

function SidenavCard({ name, total_credit, total_member, role, status, display_name, role_id }) {
  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  const classes = styles({ miniSidenav, sidenavColor });
  const handleLogout = () => {
    logout();
  };
  return (
    <Card className={classes.card}>
      <CardContent className={classes.card_content}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <SuiBox customClass={classes.card_iconBox}>
            <Icon className={classes.card_icon} fontSize="medium">
              star
            </Icon>
          </SuiBox> */}
          {!miniSidenav && (
            <SuiBox mb={1} lineHeight={1}>
              <Text18>
                ชื่อ : {display_name}
              </Text18>
            </SuiBox>
          )}
        </div>

        <SuiBox mb={1} lineHeight={1}>
            <Text16>
              ยูสเซอร์เนม : {name}
            </Text16>
          <Text16>
            ระดับ : {role}
          </Text16>
          <Text16>
            สถานะ : {status}
          </Text16>
          {role != 'Member' && <SuiBox mb={1} mt={-1}>
            <Text16>
              สมาชิก : {total_member}
            </Text16>
          </SuiBox>}
          {/* <SuiBox mb={1} mt={-1}>
            <SuiTypography variant="caption" textColor="white" fontWeight="medium">
              จำนวนเงิน : {total_credit} ฿
            </SuiTypography>
          </SuiBox> */}
          <SuiButton size="small" buttonColor="error" fullWidth onClick={() => handleLogout()}>
            ออกจากระบบ
          </SuiButton>
        </SuiBox>
      </CardContent>
    </Card>
  );
}
SidenavCard.propTypes = {
  name: PropTypes.string,
  total_credit: PropTypes.number,
  total_member: PropTypes.number,
  role: PropTypes.string,
  status: PropTypes.string,
  display_name: PropTypes.string,
  role_id: PropTypes.string,
};
export default SidenavCard;
