import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import PropTypes from "prop-types";
import { HOST } from "utils/config";
import { get } from "utils/helper";
import { post } from "utils/helper/request";

import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import initialValues from "./schemas/initialValues";

import SuiBox from "components/SuiBox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CircularProgress from "@mui/material/CircularProgress";

import Style from "./style.js";
import "./index.scss";
import { makeStyles } from "@mui/styles";

import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Swal from "sweetalert2";

import TwoAndThreeCard from "memberpage/pages/play-game/two-three-card/index";
import FlashCard from "memberpage/pages/play-game/flash-card/index";
import RunCard from "memberpage/pages/play-game/run-card/index";
import ShowBill from "memberpage/pages/play-game/showbill";
import Addbill from "memberpage/pages/play-game/add-bill";
import Navbar from "memberpage/pages/navbar";
import TextCard from "./text-card";
import AddBillText from "./add-bill-parse";
import { Dialog } from "@mui/material";
import PlaceCard from "./place-card";
import WinCard from "./win-card";
const useStyle = makeStyles(Style);

function playGame() {
  const classes = useStyle();
  const history = useHistory();
  const [gatchaDrawingConfigs, setGatchaDrawingConfigs] = useState(null);
  const [typeButton, setTypeButton] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBill, setIsLoadingBill] = useState(false);
  const { id } = useParams();
  const [error, setIsError] = useState(false);
  const [gatCha, setGatCha] = useState([]);
  const [dataBuyLotto, setDataBuyLotto] = useState([]);
  const [showBill, setShowBill] = useState(false);
  const [billData, setBillData] = useState([]);
  const [payloadData, setPayloadData] = useState([]);
  const [dataLotto, setDataLotto] = useState([]);
  const [dataBillLotto, setDataBillLotto] = useState([]);
  const [dataCheck, setDataCheck] = useState([]);
  const [totalBuy, setTotalBuy] = useState(0);
  const [totaldataBuy, setTotalDataBuy] = useState(0);
  const [totaldatatwo, setTotalDataTwo] = useState(0);
  const [totaldatafast, setTotalDataFast] = useState(0);
  const [totaldatarun, setTotalDataRun] = useState(0);
  const [totaldatatext, setTotalDataText] = useState(0);
  const [dataLimit, setDataLimit] = useState([]);
  const [lastHistory, setLastHistory] = useState([]);
  const [lottoDys, setLottoDys] = useState();
  let interval = useRef();

  const fetchLotto = async () => {
    try {
      setIsLoading(true);
      const { data } = await get(`${HOST}api/Gatcha/GetGatchaForSpinByGatchaId?gatcha_id=${id}`);
      setDataLotto(data.result);
      // console.log("data.result = ", data.result);
      const { market_status: marketStatus } = data.result;
      if (parseInt(marketStatus) !== 1 || marketStatus == "ปิดงวดชั่วคราว") {
        history.push(`/`);
      }
      const countDownDate = new Date(data.result.gatcha_close_market).getTime();
      const now1 = new Date().getTime();
      const distance1 = countDownDate - now1;
      setLottoDys(
        Math.floor(distance1 / (1000 * 60 * 60 * 24))
          .toString()
          .padStart(1, "0")
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchGatchaLast = async () => {
    const dataLast = await get(`${HOST}api/Gatcha/GetLastThreeDrawHistory?gatcha_id=${id}`);
    setLastHistory(dataLast.data.results);
  };

  const fetchGatchaForSpin = async () => {
    const { data } = await get(`${HOST}api/Gatcha/GetGatchaForSpinByGatchaId?gatcha_id=${id}`);
    const { gatcha_drawing_configs_id, gatcha_id } = data.result;
    const dataLimit = await get(
      `${HOST}api/Gatcha/GetGatchaLimitForShow?gatcha_id=${gatcha_id}&gatcha_drawing_id=${gatcha_drawing_configs_id}`
    );
    setDataLimit(dataLimit.data.results);
  };

  const fetchBillLotto = async () => {
    try {
      const url = HOST + "api/Gatcha/GetBetBill";
      const { data } = await get(url);
      setDataBillLotto(data.results);
    } catch (error) {}
  };

  const lottoCountDown = (value, status, desc) => {
    const countDownDate = new Date(value).getTime();
    const now1 = new Date().getTime();
    const distance1 = countDownDate - now1;

    const days1 = Math.floor(distance1 / (1000 * 60 * 60 * 24))
      .toString()
      .padStart(1, "0");
    const hours1 = Math.floor((distance1 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      .toString()
      .padStart(2, "0");
    const minutes1 = Math.floor((distance1 % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, "0");
    const seconds1 = Math.floor((distance1 % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, "0");

    const [timerDays, setTimerDays] = useState(days1 < 0 ? "0" : days1);
    const [timerHours, setTimerHours] = useState(hours1 < 0 ? "00" : hours1);
    const [timerMinutes, setTimerMinutes] = useState(minutes1 < 0 ? "00" : minutes1);
    const [timerSecond, setTimerSecond] = useState(seconds1 < 0 ? "00" : seconds1);

    let interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
        .toString()
        .padStart(1, "0");
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        .toString()
        .padStart(2, "0");
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        .toString()
        .padStart(2, "0");
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)
        .toString()
        .padStart(2, "0");

      if (distance < 0) {
        console.log("เข้ามากกว่า 0");
        clearInterval(interval.current);
        clearInterval(interval);
      } else {
        if (!isNaN(seconds)) {
          if (
            parseInt(days) === 0 &&
            parseInt(hours) === 0 &&
            parseInt(minutes) === 0 &&
            parseInt(seconds) === 0
          ) {
            Swal.fire("เวลาซื้อขายหมดแล้ว", "กรุณารอรอบถัดไป", "error").then(() => {
              history.push("/select-lotto");
            });
            clearInterval(interval.current);
            clearInterval(interval);
          } else {
            setTimerDays(days);
            setTimerHours(hours);
            setTimerMinutes(minutes);
            setTimerSecond(seconds);
          }
        }
      }
    }, 1000);

    if (parseInt(status) !== 1 || status == "ปิดงวดชั่วคราว") {
      return (
        <>
          <p className="text-red">{desc}</p>
        </>
      );
    } else {
      return (
        <>
          <p className="text-red">
            {timerDays < 1 ? `เหลือเวลา` : ""} {timerDays > 0 ? `เหลือเวลา ${timerDays} วัน` : ""}{" "}
            {timerHours} : {timerMinutes} : {timerSecond}
          </p>
        </>
      );
    }
  };

  useEffect(() => {
    fetchLotto();
    fetchGatchaLast();
    fetchGatchaForSpin();
    fetchBillLotto();
  }, []);

  function setButtonType(type) {
    setTypeButton(type);
  }
  useEffect(() => {
    fetchGetGatchaForSpinByGatchaId();
  }, [id]);
  const fetchGetGatchaForSpinByGatchaId = async () => {
    try {
      const { data } = await get(`${HOST}api/Gatcha/GetGatchaForSpinByGatchaId?gatcha_id=${id}`);
      setGatchaDrawingConfigs(data.result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (item) => {
    try {
      setIsLoading(true);
      setIsError(true);
      //   console.log("item = ",item)

      const drawingConfig = gatchaDrawingConfigs?.gatcha_drawing_configs_id;
      const payload = [];
      const billLotto = [];
      const priceForBuy = [];
      item.gatchaNumber.pop();
      item.gatchaRunNumber.pop();
      const checkError = item.gatchaNumber.some(
        (e) => e.numberUp === "" && e.numberDown === "" && e.numberMulti === ""
      );

      if (
        item.gatchaNumber.length < 1 &&
        item.gatchaRunNumber.length < 1 &&
        item.gatchaFlashNumber.length < 1 &&
        item.gatchaTextNumber.length < 1
      ) {
        item.gatchaNumber.push({
          gatchaNumber: "",
          numberUp: "",
          numberDown: "",
          numberMulti: "",
          checktext: false,
        });

        Swal.fire("ผิดพลาด", "กรุณาลองใหม่", "error");
        setIsLoading(false);
      } else {
        item.gatchaNumber?.map((e) => {
          let lottoThreeUp = {};
          let lottoThreeDown = {};
          let lottoThreeMulti = {};
          let lottoTwoUp = {};
          let lottoTwoDown = {};

          let lottoBillThreeUp = {};
          let lottoBillThreeDown = {};
          let lottoBillTwoUp = {};
          let lottoBillTwoDown = {};
          let lottoBillMulti = {};

          if (e.gatchaNumber.length == 2) {
            if (e.numberUp && parseInt(e.numberUp) != 0) {
              let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                (e) => e.gatcha_types_id === 4
              );
              lottoTwoUp.gatcha_types_id = 4;
              lottoTwoUp.gatcha_config_types_id = datafind.gatcha_config_types_id || 0;
              lottoTwoUp.gatcha_drawing_configs_id = drawingConfig;
              lottoTwoUp.gatcha_number = e.gatchaNumber;
              lottoTwoUp.total_buy = parseInt(e.numberUp);
              lottoTwoUp.gatcha_reason = item.gatchaReason;
              payload.push(lottoTwoUp);

              lottoBillTwoUp.getcha_type_name = "2 ตัวบน";
              lottoBillTwoUp.gatcha_number = e.gatchaNumber;
              lottoBillTwoUp.total_buy = e.numberUp.toString() + ".00";
              lottoBillTwoUp.pay_rate = datafind?.reward_rate + ".00";
              lottoBillTwoUp.discount = datafind?.discount + ".00";
              billLotto.push(lottoBillTwoUp);
            }
            if (e.numberDown && parseInt(e.numberDown) != 0) {
              let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                (e) => e.gatcha_types_id === 5
              );
              lottoTwoDown.gatcha_types_id = 5;
              lottoTwoDown.gatcha_config_types_id = datafind.gatcha_config_types_id || 0;
              lottoTwoDown.gatcha_drawing_configs_id = drawingConfig;
              lottoTwoDown.gatcha_number = e.gatchaNumber;
              lottoTwoDown.total_buy = parseInt(e.numberDown);
              lottoTwoDown.gatcha_reason = item.gatchaReason;
              payload.push(lottoTwoDown);

              lottoBillTwoDown.getcha_type_name = "2 ตัวล่าง";
              lottoBillTwoDown.gatcha_number = e.gatchaNumber;
              lottoBillTwoDown.total_buy = e.numberDown.toString() + ".00";
              lottoBillTwoDown.pay_rate = datafind?.reward_rate + ".00";
              lottoBillTwoDown.discount = datafind?.discount + ".00";
              billLotto.push(lottoBillTwoDown);
            }
          }

          if (e.gatchaNumber.length == 3) {
            if (e.numberUp && parseInt(e.numberUp) != 0) {
              let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                (e) => e.gatcha_types_id === 1
              );
              lottoThreeUp.gatcha_types_id = 1;
              lottoThreeUp.gatcha_config_types_id = datafind?.gatcha_config_types_id || 0;
              lottoThreeUp.gatcha_drawing_configs_id = drawingConfig;
              lottoThreeUp.gatcha_number = e.gatchaNumber;
              lottoThreeUp.total_buy = parseInt(e.numberUp);
              lottoThreeUp.gatcha_reason = item.gatchaReason;
              payload.push(lottoThreeUp);

              lottoBillThreeUp.getcha_type_name = "3 ตัวบน";
              lottoBillThreeUp.gatcha_number = e.gatchaNumber;
              lottoBillThreeUp.total_buy = e.numberUp.toString() + ".00";
              lottoBillThreeUp.pay_rate = datafind.reward_rate + ".00";
              lottoBillThreeUp.discount = datafind?.discount + ".00";
              billLotto.push(lottoBillThreeUp);
            }
            if (e.numberDown && parseInt(e.numberDown) != 0) {
              let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                (e) => e.gatcha_types_id === 2
              );
              lottoThreeDown.gatcha_types_id = 2;
              lottoThreeDown.gatcha_config_types_id = datafind.gatcha_config_types_id || 0;
              lottoThreeDown.gatcha_drawing_configs_id = drawingConfig;
              lottoThreeDown.gatcha_number = e.gatchaNumber;
              lottoThreeDown.total_buy = parseInt(e.numberDown);
              lottoThreeDown.gatcha_reason = item.gatchaReason;
              payload.push(lottoThreeDown);

              lottoBillThreeDown.getcha_type_name = "3 ตัวล่าง";
              lottoBillThreeDown.gatcha_number = e.gatchaNumber;
              lottoBillThreeDown.total_buy = e.numberDown.toString() + ".00";
              lottoBillThreeDown.pay_rate = datafind?.reward_rate + ".00";
              lottoBillThreeDown.discount = datafind?.discount + ".00";
              billLotto.push(lottoBillThreeDown);
            }
            if (e.numberMulti && parseInt(e.numberMulti) != 0) {
              console.log("เข้า");
              let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                (e) => e.gatcha_types_id === 3
              );
              lottoThreeMulti.gatcha_types_id = 3;
              lottoThreeMulti.gatcha_config_types_id = datafind.gatcha_config_types_id || 0;
              lottoThreeMulti.gatcha_drawing_configs_id = drawingConfig;
              lottoThreeMulti.gatcha_number = e.gatchaNumber;
              lottoThreeMulti.total_buy = parseInt(e.numberMulti);
              lottoThreeMulti.gatcha_reason = item.gatchaReason;
              payload.push(lottoThreeMulti);

              lottoBillMulti.getcha_type_name = "3 ตัวโต๊ด";
              lottoBillMulti.gatcha_number = e.gatchaNumber;
              lottoBillMulti.total_buy = e.numberMulti.toString() + ".00";
              lottoBillMulti.pay_rate = datafind?.reward_rate + ".00";
              lottoBillMulti.discount = datafind?.discount + ".00";
              billLotto.push(lottoBillMulti);
            }
          }
        });

        item.gatchaRunNumber?.map((e) => {
          let lottoRunTwoUp = {};
          let lottoRunTwoDown = {};

          let billRunTwoUp = {};
          let billRunTwoDown = {};

          if (e.gatchaNumber.length == 1) {
            if (e.numberUp && parseInt(e.numberUp) != 0) {
              let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                (e) => e.gatcha_types_id === 6
              );
              lottoRunTwoUp.gatcha_types_id = 6;
              lottoRunTwoUp.gatcha_config_types_id = datafind.gatcha_config_types_id;
              lottoRunTwoUp.gatcha_drawing_configs_id = drawingConfig;
              lottoRunTwoUp.gatcha_number = e.gatchaNumber;
              lottoRunTwoUp.total_buy = parseInt(e.numberUp);
              lottoRunTwoUp.gatcha_reason = item.gatchaReason;
              payload.push(lottoRunTwoUp);

              billRunTwoUp.getcha_type_name = "1 วิ่งบน";
              billRunTwoUp.gatcha_number = e.gatchaNumber;
              billRunTwoUp.total_buy = e.numberUp.toString() + ".00";
              billRunTwoUp.pay_rate = datafind?.reward_rate + ".00";
              billRunTwoUp.discount = datafind?.discount + ".00";
              billLotto.push(billRunTwoUp);
            }
            if (e.numberDown && parseInt(e.numberDown) != 0) {
              let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                (e) => e.gatcha_types_id === 7
              );
              lottoRunTwoDown.gatcha_types_id = 7;
              lottoRunTwoDown.gatcha_config_types_id = datafind.gatcha_config_types_id;
              lottoRunTwoDown.gatcha_drawing_configs_id = drawingConfig;
              lottoRunTwoDown.gatcha_number = e.gatchaNumber;
              lottoRunTwoDown.total_buy = parseInt(e.numberDown);
              lottoRunTwoDown.gatcha_reason = item.gatchaReason;
              payload.push(lottoRunTwoDown);

              billRunTwoDown.getcha_type_name = "1 วิ่งล่าง";
              billRunTwoDown.gatcha_number = e.gatchaNumber;
              billRunTwoDown.total_buy = e.numberDown.toString() + ".00";
              billRunTwoDown.pay_rate = datafind?.reward_rate + ".00";
              billRunTwoDown.discount = datafind?.discount + ".00";
              billLotto.push(billRunTwoDown);
            }
          }
        });

        item.gatchaFlashNumber?.map((e) => {
          let lottoTwoUp = {};
          let lottTwoDown = {};

          if (e.type == "1") {
            e.type == "1"
              ? e.gatchaNumber.map((x) => {
                  let arrayNumUp = {};
                  let arrayNumDown = {};

                  let arrayNumUpBill = {};
                  let arrayNumDownBill = {};

                  if (e.numberUp && parseInt(e.numberUp) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 6
                    );
                    arrayNumUp.gatcha_types_id = 6;
                    arrayNumUp.gatcha_config_types_id = datafind?.gatcha_config_types_id;
                    arrayNumUp.gatcha_drawing_configs_id = drawingConfig || 0;
                    arrayNumUp.gatcha_number = x;
                    arrayNumUp.total_buy = parseInt(e.numberUp);
                    arrayNumUp.gatcha_reason = item.gatchaReason;
                    payload.push(arrayNumUp);

                    arrayNumUpBill.getcha_type_name = "1 วิ่งบน";
                    arrayNumUpBill.gatcha_number = x;
                    arrayNumUpBill.total_buy = e.numberUp.toString() + ".00";
                    arrayNumUpBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayNumUpBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayNumUpBill);
                  }

                  if (e.numberDown && parseInt(e.numberDown) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 7
                    );
                    arrayNumDown.gatcha_types_id = 7;
                    arrayNumDown.gatcha_config_types_id = datafind?.gatcha_config_types_id;
                    arrayNumDown.gatcha_drawing_configs_id = drawingConfig || 0;
                    arrayNumDown.gatcha_number = x;
                    arrayNumDown.total_buy = parseInt(e.numberDown);
                    arrayNumDown.gatcha_reason = item.gatchaReason;
                    payload.push(arrayNumDown);

                    arrayNumDownBill.getcha_type_name = "1 วิ่งล่าง";
                    arrayNumDownBill.gatcha_number = x;
                    arrayNumDownBill.total_buy = e.numberDown.toString() + ".00";
                    arrayNumDownBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayNumDownBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayNumDownBill);
                  }
                })
              : "";
          }

          if (e.type == "2") {
            e.type == "2"
              ? e.gatchaNumber.map((x) => {
                  let arrayNumUp = {};
                  let arrayNumDown = {};

                  let arrayNumUpBill = {};
                  let arrayNumDownBill = {};

                  if (e.numberUp && parseInt(e.numberUp) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 4
                    );
                    arrayNumUp.gatcha_types_id = 4;
                    arrayNumUp.gatcha_config_types_id = datafind?.gatcha_config_types_id;
                    arrayNumUp.gatcha_drawing_configs_id = drawingConfig || 0;
                    arrayNumUp.gatcha_number = x;
                    arrayNumUp.total_buy = parseInt(e.numberUp);
                    arrayNumUp.gatcha_reason = item.gatchaReason;
                    payload.push(arrayNumUp);

                    arrayNumUpBill.getcha_type_name = "2 ตัวบน";
                    arrayNumUpBill.gatcha_number = x;
                    arrayNumUpBill.total_buy = e.numberUp.toString() + ".00";
                    arrayNumUpBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayNumUpBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayNumUpBill);
                  }

                  if (e.numberDown && parseInt(e.numberDown) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 5
                    );
                    arrayNumDown.gatcha_types_id = 5;
                    arrayNumDown.gatcha_config_types_id = datafind?.gatcha_config_types_id;
                    arrayNumDown.gatcha_drawing_configs_id = drawingConfig || 0;
                    arrayNumDown.gatcha_number = x;
                    arrayNumDown.total_buy = parseInt(e.numberDown);
                    arrayNumDown.gatcha_reason = item.gatchaReason;
                    payload.push(arrayNumDown);

                    arrayNumDownBill.getcha_type_name = "2 ตัวล่าง";
                    arrayNumDownBill.gatcha_number = x;
                    arrayNumDownBill.total_buy = e.numberDown.toString() + ".00";
                    arrayNumDownBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayNumDownBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayNumDownBill);
                  }
                })
              : "";
          }

          if (e.type == "3") {
            // console.log("เลข 3 ตัว = ",e)

            e.type == "3"
              ? e.gatchaNumber.map((x) => {
                  let arrayThreeUp = {};
                  let arrayThreeMulti = {};
                  let arrayThreeDown = {};

                  let arrayThreeUpBill = {};
                  let arrayThreeMultiBill = {};
                  let arrayThreeDownBill = {};

                  if (e.numberUp && parseInt(e.numberUp) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 1
                    );
                    arrayThreeUp.gatcha_types_id = 1;
                    arrayThreeUp.gatcha_config_types_id = datafind?.gatcha_config_types_id;
                    arrayThreeUp.gatcha_drawing_configs_id = drawingConfig || 0;
                    arrayThreeUp.gatcha_number = x;
                    arrayThreeUp.total_buy = parseInt(e.numberUp);
                    arrayThreeUp.gatcha_reason = item.gatchaReason;
                    payload.push(arrayThreeUp);

                    arrayThreeUpBill.getcha_type_name = "3 ตัวบน";
                    arrayThreeUpBill.gatcha_number = x;
                    arrayThreeUpBill.total_buy = e.numberUp.toString() + ".00";
                    arrayThreeUpBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayThreeUpBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayThreeUpBill);
                  }
                  if (e.numberDown && parseInt(e.numberDown) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 2
                    );
                    arrayThreeDown.gatcha_types_id = 2;
                    arrayThreeDown.gatcha_config_types_id = datafind.gatcha_config_types_id || 0;
                    arrayThreeDown.gatcha_drawing_configs_id = drawingConfig;
                    arrayThreeDown.gatcha_number = x;
                    arrayThreeDown.total_buy = parseInt(e.numberDown);
                    arrayThreeDown.gatcha_reason = item.gatchaReason;
                    payload.push(arrayThreeDown);

                    arrayThreeDownBill.getcha_type_name = "3 ตัวล่าง";
                    arrayThreeDownBill.gatcha_number = x;
                    arrayThreeDownBill.total_buy = e.numberDown.toString() + ".00";
                    arrayThreeDownBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayThreeDownBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayThreeDownBill);
                  }
                  if (e.numberMulti && parseInt(e.numberMulti) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 3
                    );
                    arrayThreeMulti.gatcha_types_id = 3;
                    arrayThreeMulti.gatcha_config_types_id = datafind?.gatcha_config_types_id;
                    arrayThreeMulti.gatcha_drawing_configs_id = drawingConfig || 0;
                    arrayThreeMulti.gatcha_number = x;
                    arrayThreeMulti.total_buy = parseInt(e.numberMulti);
                    arrayThreeMulti.gatcha_reason = item.gatchaReason;
                    payload.push(arrayThreeMulti);

                    arrayThreeMultiBill.getcha_type_name = "3 โต๊ด";
                    arrayThreeMultiBill.gatcha_number = x;
                    arrayThreeMultiBill.total_buy = e.numberMulti.toString() + ".00";
                    arrayThreeMultiBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayThreeMultiBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayThreeMultiBill);
                  }
                })
              : "";
          }
        });
        item.gatchaTextNumber?.map((e) => {
          let lottoTwoUp = {};
          let lottTwoDown = {};

          if (e.type == "1") {
            e.type == "1"
              ? e.gatchaNumber.map((x) => {
                  let arrayNumUp = {};
                  let arrayNumDown = {};

                  let arrayNumUpBill = {};
                  let arrayNumDownBill = {};

                  if (e.numberUp && parseInt(e.numberUp) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 6
                    );
                    arrayNumUp.gatcha_types_id = 6;
                    arrayNumUp.gatcha_config_types_id = datafind?.gatcha_config_types_id;
                    arrayNumUp.gatcha_drawing_configs_id = drawingConfig || 0;
                    arrayNumUp.gatcha_number = x;
                    arrayNumUp.total_buy = parseInt(e.numberUp);
                    arrayNumUp.gatcha_reason = item.gatchaReason;
                    payload.push(arrayNumUp);

                    arrayNumUpBill.getcha_type_name = "1 วิ่งบน";
                    arrayNumUpBill.gatcha_number = x;
                    arrayNumUpBill.total_buy = e.numberUp.toString() + ".00";
                    arrayNumUpBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayNumUpBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayNumUpBill);
                  }

                  if (e.numberDown && parseInt(e.numberDown) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 7
                    );
                    arrayNumDown.gatcha_types_id = 7;
                    arrayNumDown.gatcha_config_types_id = datafind?.gatcha_config_types_id;
                    arrayNumDown.gatcha_drawing_configs_id = drawingConfig || 0;
                    arrayNumDown.gatcha_number = x;
                    arrayNumDown.total_buy = parseInt(e.numberDown);
                    arrayNumDown.gatcha_reason = item.gatchaReason;
                    payload.push(arrayNumDown);

                    arrayNumDownBill.getcha_type_name = "1 วิ่งล่าง";
                    arrayNumDownBill.gatcha_number = x;
                    arrayNumDownBill.total_buy = e.numberDown.toString() + ".00";
                    arrayNumDownBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayNumDownBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayNumDownBill);
                  }
                })
              : "";
          }

          if (e.type == "2") {
            e.type == "2"
              ? e.gatchaNumber.map((x) => {
                  let arrayNumUp = {};
                  let arrayNumDown = {};

                  let arrayNumUpBill = {};
                  let arrayNumDownBill = {};

                  if (e.numberUp && parseInt(e.numberUp) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 4
                    );
                    arrayNumUp.gatcha_types_id = 4;
                    arrayNumUp.gatcha_config_types_id = datafind?.gatcha_config_types_id;
                    arrayNumUp.gatcha_drawing_configs_id = drawingConfig || 0;
                    arrayNumUp.gatcha_number = x;
                    arrayNumUp.total_buy = parseInt(e.numberUp);
                    arrayNumUp.gatcha_reason = item.gatchaReason;
                    payload.push(arrayNumUp);

                    arrayNumUpBill.getcha_type_name = "2 ตัวบน";
                    arrayNumUpBill.gatcha_number = x;
                    arrayNumUpBill.total_buy = e.numberUp.toString() + ".00";
                    arrayNumUpBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayNumUpBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayNumUpBill);
                  }

                  if (e.numberDown && parseInt(e.numberDown) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 5
                    );
                    arrayNumDown.gatcha_types_id = 5;
                    arrayNumDown.gatcha_config_types_id = datafind?.gatcha_config_types_id;
                    arrayNumDown.gatcha_drawing_configs_id = drawingConfig || 0;
                    arrayNumDown.gatcha_number = x;
                    arrayNumDown.total_buy = parseInt(e.numberDown);
                    arrayNumDown.gatcha_reason = item.gatchaReason;
                    payload.push(arrayNumDown);

                    arrayNumDownBill.getcha_type_name = "2 ตัวล่าง";
                    arrayNumDownBill.gatcha_number = x;
                    arrayNumDownBill.total_buy = e.numberDown.toString() + ".00";
                    arrayNumDownBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayNumDownBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayNumDownBill);
                  }
                })
              : "";
          }

          if (e.type == "3") {
            // console.log("เลข 3 ตัว = ",e)

            e.type == "3"
              ? e.gatchaNumber.map((x) => {
                  let arrayThreeUp = {};
                  let arrayThreeMulti = {};
                  let arrayThreeDown = {};

                  let arrayThreeUpBill = {};
                  let arrayThreeMultiBill = {};
                  let arrayThreeDownBill = {};
                  if (e.numberUp && parseInt(e.numberUp) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 1
                    );
                    arrayThreeUp.gatcha_types_id = 1;
                    arrayThreeUp.gatcha_config_types_id = datafind?.gatcha_config_types_id;
                    arrayThreeUp.gatcha_drawing_configs_id = drawingConfig || 0;
                    arrayThreeUp.gatcha_number = x;
                    arrayThreeUp.total_buy = parseInt(e.numberUp);
                    arrayThreeUp.gatcha_reason = item.gatchaReason;
                    payload.push(arrayThreeUp);

                    arrayThreeUpBill.getcha_type_name = "3 ตัวบน";
                    arrayThreeUpBill.gatcha_number = x;
                    arrayThreeUpBill.total_buy = e.numberUp.toString() + ".00";
                    arrayThreeUpBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayThreeUpBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayThreeUpBill);
                  }

                  if (e.numberDown && parseInt(e.numberDown) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 2
                    );
                    arrayThreeDown.gatcha_types_id = 2;
                    arrayThreeDown.gatcha_config_types_id = datafind.gatcha_config_types_id || 0;
                    arrayThreeDown.gatcha_drawing_configs_id = drawingConfig;
                    arrayThreeDown.gatcha_number = x;
                    arrayThreeDown.total_buy = parseInt(e.numberDown);
                    arrayThreeDown.gatcha_reason = item.gatchaReason;
                    payload.push(arrayThreeDown);

                    arrayThreeDownBill.getcha_type_name = "3 ตัวล่าง";
                    arrayThreeDownBill.gatcha_number = x;
                    arrayThreeDownBill.total_buy = e.numberDown.toString() + ".00";
                    arrayThreeDownBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayThreeDownBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayThreeDownBill);
                  }
                  if (e.numberMulti && parseInt(e.numberMulti) != 0) {
                    let datafind = gatchaDrawingConfigs?.gatcha_configs.find(
                      (e) => e.gatcha_types_id === 3
                    );
                    arrayThreeMulti.gatcha_types_id = 3;
                    arrayThreeMulti.gatcha_config_types_id = datafind?.gatcha_config_types_id;
                    arrayThreeMulti.gatcha_drawing_configs_id = drawingConfig || 0;
                    arrayThreeMulti.gatcha_number = x;
                    arrayThreeMulti.total_buy = parseInt(e.numberMulti);
                    arrayThreeMulti.gatcha_reason = item.gatchaReason;
                    payload.push(arrayThreeMulti);

                    arrayThreeMultiBill.getcha_type_name = "3 โต๊ด";
                    arrayThreeMultiBill.gatcha_number = x;
                    arrayThreeMultiBill.total_buy = e.numberMulti.toString() + ".00";
                    arrayThreeMultiBill.pay_rate = datafind?.reward_rate + ".00";
                    arrayThreeMultiBill.discount = datafind?.discount + ".00";
                    billLotto.push(arrayThreeMultiBill);
                  }
                })
              : "";
          }
        });

        setTotalBuy(priceForBuy);
        setPayloadData(payload);
        setBillData(billLotto);
        if (!checkError) {
          // setIsLoading(true)
          try {
            const dataCheck = await post(`${HOST}api/Buy/CheckBuyLotto`, payload);
            setDataCheck(dataCheck.data.results);
            setShowBill(true);
            setIsLoading(false);
          } catch (error) {
            Swal.fire("เกิดข้อผิดพลาด", error.response.data.errorMessage, "error").then(() => {
              setShowBill(false);
              fetchBillLotto();
              onsetTotaldata(0, "reset");
              setIsLoading(false);
            });
          }
        } else {
          item.gatchaNumber.push({
            gatchaNumber: "",
            numberUp: "",
            numberDown: "",
            numberMulti: "",
            checktext: false,
          });

          Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
          setIsLoading(false);
        }
      }

      //   console.log(payload, billLotto, "billLotto");
      //   console.log("payloadData = ",payloadData)
      //   console.log("billData = ",billData)
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  {
  }
  const submitBuyLotto = async () => {
    try {
      setIsLoading(true);
      const dataPost = await post(`${HOST}api/Buy/BuyLotto`, payloadData);
      // console.log("dataPost.data.result = ", dataPost.data.result);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success").then(() => {
        setShowBill(false);
        fetchBillLotto();
        setIsLoading(false);
        history.go(0);
      });
    } catch (error) {
      Swal.fire("เกิดข้อผิดพลาด", error.response.data.errorMessage, "error").then(() => {
        setShowBill(false);
        fetchBillLotto();
        setIsLoading(false);
      });
    }
  };

  const onsetTotaldata = (value, type) => {
    // console.log("เข้าาา", type, value);
    if (type === "fast") {
      setTotalDataFast(value);
    } else if (type === "text") {
      setTotalDataText(value);
    } else if (type === "run") {
      setTotalDataRun(value);
    } else if (type === "two") {
      setTotalDataTwo(value);
    } else if (type === "reset") {
      setTotalDataFast(value);
      setTotalDataRun(value);
      setTotalDataTwo(value);
    }
  };

  const handleReset = (formProps) => {
    formProps.handleReset();
    onsetTotaldata(0, "reset");
  };

  return (
    <>
      <DashboardLayout>
        <Navbar />
        <div className="play-game">
          <div className="d-flex " style={{ flexWrap: "wrap" }}>
            <div className="card-play">
              {isLoading && (
                <div className="isLoading">
                  <CircularProgress />
                </div>
              )}
              {!isLoading && (
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                  {(formProps) => (
                    <Form>
                      {" "}
                      <div className="card-header">
                        <>
                          <p className="card-header-text">
                            {dataLotto.gatcha_name} {dataLotto.gatcha_drawing_date_display}
                          </p>{" "}
                          <p className="card-header-text2">
                            {lottoCountDown(
                              dataLotto?.gatcha_close_market,
                              dataLotto?.market_status,
                              dataLotto?.description
                            )}
                          </p>
                        </>
                      </div>
                      {!showBill && (
                        <div className="card-left">
                          <div className="button-group">
                            <div
                              className={typeButton == 1 ? "type-button active" : "type-button"}
                              onClick={() => setButtonType(1)}
                            >
                              2ตัว/3ตัว
                            </div>
                            <div
                              className={typeButton == 2 ? "type-button active" : "type-button"}
                              onClick={() => setButtonType(2)}
                            >
                              แทงเร็ว
                            </div>
                            <div
                              className={typeButton == 3 ? "type-button active" : "type-button"}
                              onClick={() => setButtonType(3)}
                            >
                              วิ่ง
                            </div>
                            <div
                              className={typeButton == 4 ? "type-button active" : "type-button"}
                              onClick={() => setButtonType(4)}
                            >
                              วางบิล
                            </div>
                            <div
                              className={typeButton == 5 ? "type-button active" : "type-button"}
                              onClick={() => setButtonType(5)}
                            >
                              วางโพย
                            </div>
                            <div
                              className={typeButton == 6 ? "type-button active" : "type-button"}
                              onClick={() => setButtonType(6)}
                            >
                              จับวิน
                            </div>
                          </div>

                          {typeButton == 1 && (
                            <TwoAndThreeCard
                              formData={formProps}
                              onsetTotaldata={onsetTotaldata}
                              gatchaconfig={dataLotto.gatcha_configs}
                            />
                          )}
                          {typeButton == 2 && (
                            <FlashCard
                              formData={formProps}
                              onsetTotaldata={onsetTotaldata}
                              gatchaconfig={dataLotto.gatcha_configs}
                            />
                          )}
                          {typeButton == 3 && (
                            <RunCard formData={formProps} onsetTotaldata={onsetTotaldata} />
                          )}
                          {typeButton == 4 && (
                            <TextCard
                              formData={formProps}
                              onsetTotaldata={onsetTotaldata}
                              dataLotto={dataLotto}
                            />
                          )}
                          {typeButton == 5 && (
                            <PlaceCard
                              formData={formProps}
                              onsetTotaldata={onsetTotaldata}
                              dataLotto={dataLotto}
                            />
                          )}
                          {typeButton == 6 && (
                            <WinCard
                              formData={formProps}
                              onsetTotaldata={onsetTotaldata}
                              dataLotto={dataLotto}
                            />
                          )}
                          <AddBillText formData={formProps} onsetTotaldata={onsetTotaldata} />
                          <Addbill formData={formProps} onsetTotaldata={onsetTotaldata} />
                          <div className="card-bottom">
                            <p>บันทึกช่วยจำ</p>
                            <div className="text-memmory">
                              <SuiInput
                                type="text"
                                // placeholder="หวย"
                                name={"gatchaReason"}
                                onChange={(e) => {
                                  formProps.setFieldValue("gatchaReason", e.target.value);
                                }}
                                value={formProps.values.gatchaReason} //formData.values.gatchaNumber
                              />
                            </div>
                            <p>ยอดรวม</p>
                            <p className="total-price">
                              {(
                                totaldatatwo +
                                totaldatafast +
                                totaldatarun +
                                totaldatatext
                              ).toLocaleString() || 0}
                            </p>
                          </div>
                          <div className="bottom-button-group">
                            <SuiButton
                              disabled={isLoading}
                              variant="gradient"
                              buttonColor="info"
                              fullWidth
                              onClick={() => handleReset(formProps)}
                              customClass={classes.cancelButton}
                            >
                              ล้างตาราง
                            </SuiButton>
                            <SuiButton
                              disabled={isLoading}
                              variant="gradient"
                              buttonColor="info"
                              fullWidth
                              type="submit"
                              //   onClick={()=>setShowBill(true)}
                              customClass={classes.submitButton}
                            >
                              บันทึก
                            </SuiButton>
                          </div>
                        </div>
                      )}{" "}
                      {showBill && (
                        <div className="card-left">
                          <table className="card-table">
                            <thead className="head1">
                              <tr>
                                <td>ประเภท @ หมายเลข</td>
                                <td>ยอดเดิมพัน</td>
                                <td>เรทจ่าย</td>
                                <td>ส่วนลด</td>
                              </tr>
                            </thead>
                            <tbody className="body2">
                              {dataCheck.length > 0 &&
                                dataCheck.map((e, i) => (
                                  <tr key={i} className={e.status == true ? "" : "close-limit"}>
                                    <td>
                                      {e.gatcha_type_name} @ {e.gatcha_number}
                                    </td>
                                    <td>{e.total_buy}</td>
                                    <td>
                                      {e.status == true ? (
                                        e.reward_total
                                      ) : (
                                        <p className="text-red">ปิดรับ</p>
                                      )}
                                    </td>
                                    <td>{e.total_discount}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          <div className="bottom-button-group">
                            <SuiButton
                              disabled={isLoading}
                              variant="gradient"
                              buttonColor="info"
                              fullWidth
                              onClick={() => {
                                // setShowBill(false);
                                history.go(0);
                                // formProps.values.gatchaNumber.length < 1
                                //   ? formProps.setFieldValue(`gatchaNumber`, [
                                //       ...formProps.values.gatchaNumber,
                                //       {
                                //         gatchaNumber: "",
                                //         numberUp: "",
                                //         numberDown: "",
                                //         numberMulti: "",
                                //         checktext: false,
                                //       },
                                //     ])
                                //   : null;
                                // formProps.values.gatchaRunNumber.length < 1
                                //   ? formProps.setFieldValue(`gatchaRunNumber`, [
                                //       ...formProps.values.gatchaRunNumber,
                                //       {
                                //         gatchaNumber: "",
                                //         numberUp: "",
                                //         numberDown: "",
                                //         checktext: false,
                                //       },
                                //     ])
                                //   : null;
                              }}
                              customClass={classes.cancelButton}
                            >
                              ยกเลิก
                            </SuiButton>
                            <SuiButton
                              disabled={isLoading}
                              variant="gradient"
                              buttonColor="info"
                              fullWidth
                              // type="submit"
                              onClick={() => submitBuyLotto()}
                              customClass={classes.submitButton}
                            >
                              บันทึก
                            </SuiButton>
                          </div>
                        </div>
                      )}
                    </Form>
                  )}
                </Formik>
              )}
            </div>
            {/* <div item xs={12} sm={12} md={12} lg={3}> */}
            <div className="card-showbill">
              <ShowBill
                lastHistory={lastHistory}
                dataLimit={dataLimit}
                dataBillLotto={dataBillLotto}
              />
            </div>
          </div>
        </div>
        {/* <Dialog open={open} onClose={onClose} fullWidth maxWidth={"lg"}>
          หมดเวลาการซื้อขายแล้ว
        </Dialog> */}
      </DashboardLayout>
    </>
  );
}

export default playGame;
