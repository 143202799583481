import react, { useState, useEffect } from "react";
import { post } from "utils/helper/request";
import { HOST } from "utils/config";
// @mui material components
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import { Grid, Divider } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
// Soft UI Dashboard PRO React example components
import Swal from "sweetalert2";
import FormLimit from "./formLimit";

import { LootoList20, LootoList18, LootoList16 } from "components/Text/TextStyle";

// Data
function NumberLitmit({ numberLimit, lotoType }) {
  const [dataLimit, setDataLimit] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchLottoLimitNumber = async () => {
    try {
      setLoading(true);
      const payload = new FormData();

      payload.append("gatcha_drawing_configs_id", numberLimit);
      const { data } = await post(`${HOST}api/Lotto/GetLimitLottoNumber`, payload);
      setDataLimit(data.results);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteLimitNumber = async (id) => {
    setLoading(true);
    const payload = new FormData();
    payload.append("limit_id", id);
    const { data } = await post(`${HOST}api/Lotto/DeleteLimitLottoNumber`, payload);

    Swal.fire("สำเร็จ", "คุณได้ทำการลบสำเร็จ", "success");
    fetchLottoLimitNumber();
    setLoading(false);
  };
  const onSuccess = async () => {
    fetchLottoLimitNumber();
  };
  useEffect(() => {
    fetchLottoLimitNumber();
  }, []);
  return (
    <>
      <FormLimit lotoType={lotoType} refetch={onSuccess} />
      <SuiBox my={3}>
        <Card>
          <SuiBox p={3}>
            <SuiTypography variant={"h5"} fontWeight="bold">
              {/* {e.lotto_name} */}
            </SuiTypography>
            <Grid container pacing={2}>
              <Grid xs={3}>
                <LootoList16>ประเภท</LootoList16>
              </Grid>
              <Grid xs={3}>
                <LootoList16>หมายเลข</LootoList16>
              </Grid>
              <Grid xs={3}>
                <LootoList16>จำนวนที่จะรับ</LootoList16>
              </Grid>
              <Grid xs={3}>
                <LootoList16>ดำเนินการ</LootoList16>
              </Grid>
            </Grid>
            <Divider />
            {dataLimit?.map((values, index) => (
              <Grid container key={index} style={{ padding: 8 }} spacing={2}>
                <Grid xs={3} item>
                  {values.gatcha_type_name}
                </Grid>
                <Grid xs={3} item>
                  {values.gatcha_number}
                </Grid>
                <Grid xs={3} item>
                  {values.total_limit}
                </Grid>
                <Grid xs={3} item>
                  <SuiButton buttonColor="error" onClick={() => deleteLimitNumber(values.id)}>
                    ลบ
                  </SuiButton>
                </Grid>
              </Grid>
            ))}
            {/* <Divider /> */}
          </SuiBox>
        </Card>
      </SuiBox>
    </>
  );
}
NumberLitmit.propTypes = {
  numberLimit: PropTypes.number,
  lotoType: PropTypes.array,
};
export default NumberLitmit;
