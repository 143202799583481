import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";

import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";

import Style from "../style.js";
import "../index.scss";
import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Swal from "sweetalert2";

const useStyle = makeStyles(Style);

function TwoDigits({ formData, onsetTotaldata }) {
  const classes = useStyle();

  const [lottoNumber, setLottoNumber] = useState([]);
  const [lottoNumberUp, setLottoNumberUp] = useState("");
  const [lottoNumberDown, setLottoNumberDown] = useState("");
  const [inputList, setInputList] = useState([{ gatchaNumber: "", numberUp: "", numberDown: "" }]);

  const handleInputChange = (e) => {
    const inputLenght = e.target.value.length;
    const { name, value } = e.target;
    // formData.setFieldValue(`gatchaFlashNumber[0].${name}`, value);
    if (name == "numberUp") {
      setLottoNumberUp(value);
    }
    if (name == "numberDown") {
      setLottoNumberDown(value);
    }
    document.getElementById("gatchaNumber").value = "";
  };

  const onChangeReverseNumber = (event) => {
    const inputLenght = event.length;
    if (inputLenght == 2) {
      // const exists = lottoNumber.find((p) => p == event);
      // if (!exists) {
      //   setLottoNumber((lottoNumber) => [...lottoNumber, event]);
      // }
      setLottoNumber((lottoNumber) => [...lottoNumber, event]);
      document.getElementById("gatchaNumber").value = "";
    }
  };

  const deleteLottoItem = (index, values) => {
    setLottoNumber(lottoNumber.filter((e) => e !== values));
  };
  const deleteLottoAllItem = () => {
    setLottoNumber([]);
  };

  const reverstNumber = () => {
    const numberForReverse = lottoNumber.pop();
    if (!numberForReverse) {
      Swal.fire("ผิดพลาด!", "กรุณาใส่ตัวเลขก่อนกลับเลข", "error");
    } else {
      const numberReverse = numberForReverse
        ? numberForReverse.toString().split("").reverse().join("")
        : numberForReverse;
      const exists = lottoNumber.find((p) => p == numberReverse);
      if (!exists) {
        setLottoNumber((lottoNumber) => [...lottoNumber, numberForReverse, numberReverse]);
      }
    }
  };
  const reverstAllNumber = () => {
    if (lottoNumber.length === 0) {
      Swal.fire("ผิดพลาด!", "กรุณาใส่ตัวเลขก่อนกลับเลข", "error");
    } else {
      let datafor = [];
      lottoNumber.map((e, i) => {
        if (!lottoNumber.find((p) => p == e.toString().split("").reverse().join(""))) {
          datafor.push(e.toString().split("").reverse().join(""));
        }
      });
      setLottoNumber((lottoNumber) => [...lottoNumber, ...datafor]);
    }
  };

  const handleAddBill = async () => {
    if (lottoNumber < 1) {
      Swal.fire("ผิดพลาด!", "กรุณาใส่ตัวเลข", "error");
    } else if (!lottoNumberDown && !lottoNumberUp) {
      Swal.fire("ผิดพลาด!", "จำนวนเงินไม่ถูกต้อง", "error");
    } else if (!lottoNumberDown && lottoNumberUp < 1) {
      Swal.fire("ผิดพลาด!", "จำนวนเงินไม่ถูกต้อง", "error");
    } else if (lottoNumberDown < 1 && !lottoNumberUp) {
      Swal.fire("ผิดพลาด!", "จำนวนเงินไม่ถูกต้อง", "error");
    } else if (lottoNumberDown < 1 && lottoNumberUp < 1) {
      Swal.fire("ผิดพลาด!", "จำนวนเงินไม่ถูกต้อง", "error");
    } else {
      const addBillArray = {};
      addBillArray.type = "2";
      addBillArray.gatchaNumber = lottoNumber;
      addBillArray.numberUp = lottoNumberUp;
      addBillArray.numberDown = lottoNumberDown;

      formData.setFieldValue(`gatchaFlashNumber`, [
        ...formData.values.gatchaFlashNumber,
        addBillArray,
      ]);
      setLottoNumberUp("");
      setLottoNumberDown("");
      // document.getElementById("numberUp").value = "";
      // document.getElementById("numberDown").value = "";
      await Promise.resolve(formData.values.gatchaFlashNumber);
      setLottoNumber([]);
      handleChangetotal();
    }
  };
  const handleChangetotal = () => {
    onsetTotaldata;
    // console.log(formData.values.gatchaFlashNumber, "formData.values.gatchaFlashNumber");
  };

  useEffect(() => {}, [formData.values.gatchaFlashNumber]);

  return (
    <>
      <div className="lotto-number-item">
        {lottoNumber?.map((values, index) => (
          <div
            key={index}
            className="lotto-number-item-value"
            onClick={() => deleteLottoItem(index, values)}
          >
            <p>{values}</p>
          </div>
        ))}
      </div>
      {lottoNumber.length > 0 && (
        <div className="lotto-number-delete d-flex " onClick={() => deleteLottoAllItem()}>
          <DeleteIcon />
          ยกเลิก
        </div>
      )}
      <div className="two-digits">
        <div className="block-flex">
          <p>ใส่เลข</p>
          <div className="input-box">
            <SuiInput
              id="gatchaNumber"
              type="text"
              placeholder="หวย"
              name={"gatchaNumber"}
              onChange={(e) => {
                onChangeReverseNumber(e.target.value);
                // formData.setFieldValue("gatchaNumber", e.target.value)
              }}
              // value={formData.values.gatchaNumber} //formData.values.gatchaNumber
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
                if (event.target.value.length >= 2) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className="button-contain">
            <div className="type-button" onClick={() => reverstAllNumber()}>
              กลับเลข
            </div>
          </div>
        </div>
        <div className="block-flex">
          <p>ใส่ราคา</p>
          <p>บน</p>
          <div className="input-box">
            <SuiInput
              id="numberUp"
              type="text"
              placeholder="0"
              name={"numberUp"}
              onChange={(e) => {
                handleInputChange(e);
                // formData.setFieldValue("numberUp", e.target.value)
              }}
              value={lottoNumberUp}
              // error={lottoNumberDown < 1 && lottoNumberUp < 1}
              // value={lottoNumberUp}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <p>ล่าง</p>
          <div className="input-box">
            <SuiInput
              id="numberDown"
              type="text"
              placeholder="0"
              name={"numberDown"}
              onChange={(e) => {
                handleInputChange(e);
                // formData.setFieldValue("numberDown", e.target.value)
              }}
              value={lottoNumberDown}
              // error={lottoNumberDown < 1 && lottoNumberUp < 1}
              // value={lottoNumberDown}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="two-digits-bill">
        <div className="button-contain">
          <SuiButton
            variant="gradient"
            buttonColor="info"
            // type="submit"
            fullWidth
            customClass={classes.submitButton}
            onClick={() => handleAddBill()}
          >
            เพิ่มบิล
          </SuiButton>
        </div>
      </div>
    </>
  );
}

TwoDigits.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onsetTotaldata: PropTypes.func,
};

export default TwoDigits;
