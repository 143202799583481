/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import SuiBadgeDot from "components/SuiBadgeDot";
import Swal from "sweetalert2";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import { Switch } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogTitle from "@mui/material/DialogTitle";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
// Soft UI Dashboard PRO React example components
import SuiDropzone from "components/SuiDropzone";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";

// Reports page components
import Reviews from "memberpage/pages/users/reports/components/Reviews";
import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import FormField from "components/FormField";
// Data
import { Formik, Form, FieldArray, FastField } from "formik";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: "auto",
  height: 150,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
function DialogEditLotto({ open, onClose, groupId, dataUpdate, onSuccess }) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview || dataUpdate.cover} style={img} />
        <DeleteIcon
          onClick={() => handleDelete()}
          fontSize="inherit"
          sx={{
            position: "absolute",
            right: 0,
            background: "#FFF",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        />
      </div>
      {/* <IconButton aria-label="delete" size="small"> */}

      {/* </IconButton> */}
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const onhandleSubmit = async (event) => {
    // console.log(files[0], "files");
    const payload = new FormData();
    payload.append("name", event.name);
    payload.append("status", event.status);
    payload.append("gatcha_drawing_date_text", event.gatcha_drawing_date_text);

    files.length > 0 && payload.append("fileUpload", files[0]);
    payload.append("gatcha_group_id", groupId);
    payload.append("id", dataUpdate.id);
    try {
      const { data } = await post(`${HOST}api/MasterLotto/UpdateLotto`, payload);
      if (data.status) {
        Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success").then((result) => {
          onSuccess();
          onClose();
        });
      } else {
        Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
      }
    } catch (error) {
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
      console.log(error);
    }
  };
  function validateAmount(value) {
    let error;

    if (!value) {
      error = "โปรดกรอกชื่อกลุ่มหวย";
    }

    return error;
  }
  const handleDelete = () => {
    setFiles([]);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
      <DialogTitle>แก้ไขหวย</DialogTitle>
      <SuiBox
        {...getRootProps({ className: "dropzone" })}
        component="div"
        sx={{ cursor: "pointer" }}
      >
        <SuiBox component="input" name="file" type="file" {...getInputProps()} />
        {/* <input {...getInputProps()} /> */}
        {files.length < 1 && <p>select files</p>}
        <SuiBox display="flex" justifyContent="center" style={thumbsContainer}>
          {files.length > 0 ? (
            thumbs
          ) : (
            <div style={thumb}>
              <div style={thumbInner}>
                <img src={dataUpdate.cover} style={img} />
              </div>
            </div>
          )}
        </SuiBox>
      </SuiBox>
      <Formik
        initialValues={{
          name: dataUpdate.name,
          status: true,
          fileUpload: null,
          gatcha_drawing_date_text: dataUpdate.gatcha_drawing_date_text,
        }}
        // validationSchema={}
        onSubmit={onhandleSubmit}
      >
        {(formProps) => (
          <Form autoComplete="off">
            {console.log(formProps.values)}
            <DialogContent>
              <SuiBox>
                <SuiBox>
                  {/* {images && (
                    <SuiBox display={"flex"} justifyContent="center" id="preview">
                      <img src={images.dataURL} width={200} />
                    </SuiBox>
                  )} */}
                  <SuiTypography variant="caption">ชื่อหวย</SuiTypography>
                  <FormField
                    type="text"
                    placeholder="โปรดกรอกชื่อหวย"
                    name={`name`}
                    onChange={formProps.handleChange}
                    value={formProps.values.name}
                    validate={(e) => validateAmount(e)}
                  />
                  <SuiTypography variant="caption">รอบที่ออก</SuiTypography>
                  <FormField
                    type="text"
                    placeholder="โปรดกรอกรอบหวย"
                    name={`gatcha_drawing_date_text`}
                    onChange={formProps.handleChange}
                    value={formProps.values.gatcha_drawing_date_text}
                    validate={(e) => validateAmount(e)}
                  />

                  <SuiBox>สถานะ</SuiBox>
                  <SuiBox>
                    <SuiTypography variant="BUTTON">
                      {formProps.values.status ? "เปิด" : "ปิด"}{" "}
                    </SuiTypography>
                    <Switch
                      checked={formProps.values.status}
                      onChange={() => formProps.setFieldValue(`status`, !formProps.values.status)}
                    />
                  </SuiBox>

                  <Divider />
                </SuiBox>
              </SuiBox>
            </DialogContent>
            <DialogActions>
              <SuiButton buttonColor="error" onClick={onClose}>
                ยกเลิก
              </SuiButton>
              <SuiButton type="submit" buttonColor="success">
                แก้ไขหวย
              </SuiButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
DialogEditLotto.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  dataUpdate: PropTypes.object,
  onSuccess: PropTypes.func,
};
export default DialogEditLotto;
