/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { ErrorMessage, FastField, Field } from "formik";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

function FormField({ name, label, flexDi, variants, justify, isDisabled, ...rest }) {
  return (
    <SuiBox display="flex" flexDirection={flexDi} justifyContent={justify}>
      <SuiBox
        mb={1}
        ml={flexDi === "flex-start" ? 2 : 0.5}
        mr={flexDi === "flex-start" ? 2 : 0}
        lineHeight={0}
        display="inline-block"
      >
        <SuiTypography
          component="label"
          variant={variants}
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <FastField
        {...rest}
        name={name}
        // render={({ field }) => <SuiInput disabled={isDisabled} {...rest} {...field} />}
        as={SuiInput}
      >
        {({ field, form, meta }) => <SuiInput name={name} disabled={isDisabled} {...rest} />}
      </FastField>
      {/* <Field
        {...rest}
        name={name}
        // render={({ field }) => <SuiInput disabled={isDisabled} {...rest} {...field} />}
        as={SuiInput}
      /> */}
      <SuiBox mt={0.75}>
        <SuiTypography component="div" variant="caption" textColor="error">
          <ErrorMessage name={name} />
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

// Setting default values for the props of FormField
FormField.defaultProps = {
  label: " ",
  flexDi: "column",
  variants: "caption",
  justify: "flex-end",
};

// Typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string,
  flexDi: PropTypes.string,
  variants: PropTypes.string,
  justify: PropTypes.string,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default FormField;
