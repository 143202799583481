/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { useDispatch, connect } from "react-redux";
import Swal from "sweetalert2";
import validations from "./schemas/validations";
// Settings page components
import FormField from "components/FormField";
import { HOST } from "utils/config";
import { get, post } from "utils/helper";
import { authenticateAction, checkAuthAction } from "redux/authentication/action";
function ChangeBalance({ dataChangepass, onClose, userId, onSuccess, ...rest }) {
  const history = useHistory();
  const { authentication } = rest;
  const [dataCredit, setdataCredit] = useState({});
  const handleSubmit = async (event) => {
    try {
      const payload = { user_id: userId, credit: event.newcredit };
      const { data } = await post(`${HOST}api/register/EditCredit`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      onSuccess(
        authentication.user.credit_balance + dataChangepass?.profile?.total_credit - event.newcredit
      );
      onClose();

      // history.push("/");
    } catch (error) {
      Swal.fire("เกิดข้อผิดพลาด", error?.response?.data?.errorMessage, "error");
    }
  };
  const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};
    console.log(values.newcredit, "values.newcredit", values.newcredit.length);
    if (values.newcredit.length === 0) {
      errors.newcredit = "กรุณากรอกจำนวนเครดิต";
    } else if (values.newcredit < dataChangepass.profile.credit_used) {
      errors.newcredit = "เครดิตต้องมากกว่าที่ใช้ไป";
    }

    //...

    return errors;
  };
  return (
    <Card id="change-password">
      <Formik
        initialValues={{
          newcredit: dataChangepass?.profile?.total_credit,
        }}
        validate={(values) => validate(values)}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <Form autoComplete="off">
            <SuiBox pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <SuiTypography variant="h5">เครดิตเอเย่นต์ปัจจุบัน (ใช้ไป/วงเงิน) </SuiTypography>
                  <SuiTypography
                    variant="h5"
                    component="span"
                    style={{
                      color:
                        dataChangepass.profile.credit_used > formProps.values.newcredit
                          ? "red"
                          : "#000",
                    }}
                  >
                    {dataChangepass.profile.credit_used}
                  </SuiTypography>
                  <SuiTypography variant="h5" component="span">
                    /{dataChangepass.profile.total_credit}
                  </SuiTypography>
                </Grid>
                <Grid item xs={4}>
                  <FormField
                    label="เครดิตเอเย่นต์ใหม่	"
                    placeholder="เครดิตเอเย่นต์ใหม่"
                    inputProps={{ type: "number", autoComplete: "" }}
                    name="newcredit"
                    onChange={formProps.handleChange}
                    value={formProps.values?.newcredit}
                    error={formProps.errors?.newcredit && formProps.touched?.newcredit}
                    success={formProps.values?.newcredit > 0 && !formProps.errors?.newcredit}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SuiTypography variant="h5">เครดิตสายบนเหลือ</SuiTypography>
                  <SuiTypography variant="h5">
                    {authentication.user.credit_balance +
                      dataChangepass?.profile?.total_credit -
                      formProps.values.newcredit}
                  </SuiTypography>
                </Grid>
              </Grid>
              <SuiBox mt={6} mb={1}>
                <SuiTypography variant="h5">กรุณาเช็คความถูกต้อง</SuiTypography>
              </SuiBox>

              <SuiBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                flexWrap="wrap"
              >
                <SuiBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                  <SuiTypography
                    variant="button"
                    textColor="text"
                    fontWeight="regular"
                    verticalAlign="middle"
                  >
                    กรุณาเช็คความถูกต้องก่อนเพิ่มเครดิต หากมีข้อสงสัยกรุณาติดต่อสายบน
                  </SuiTypography>
                </SuiBox>
                <SuiBox ml="auto">
                  <SuiButton
                    variant="gradient"
                    buttonColor="dark"
                    size="small"
                    type="submit"
                    sx={{ marginRight: 4 }}
                  >
                    บันทึก
                  </SuiButton>

                  <SuiButton variant="gradient" buttonColor="error" size="small" onClick={onClose}>
                    ยกเลิก
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return state;
};
ChangeBalance.propTypes = {
  dataChangepass: PropTypes.object,
  onClose: PropTypes.func,
  userId: PropTypes.string,
  onSuccess: PropTypes.func,
};
// CONNECT REDUX
export default connect(mapStateToProps)(ChangeBalance);
