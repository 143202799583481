/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";

export default Yup.object().shape({
  password: Yup.string().required("กรุณากรอกรหัสผ่าน").min(6, "รหัสผ่านขั้นต่ำ 6 ตัว"),
  repeatPassword: Yup.string().required("กรุณากรอกยืนยันรหัสผ่าน").min(6, "รหัสผ่านขั้นต่ำ 6 ตัว"),
  repeatPassword: Yup.string()
    .required("กรุณากรอกยืนยันรหัสผ่าน")
    .oneOf([Yup.ref("password"), null], "รหัสผ่านไม่ตรงกัน"),
});
