/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";
import { useHistory } from "react-router";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { useDispatch, connect } from "react-redux";
import Swal from "sweetalert2";
import validations from "./schemas/validations";
// Settings page components
import FormField from "components/FormField";
import { HOST } from "utils/config";
import { get, post } from "utils/helper";
function ChangePassword({ ...rest }) {
  const history = useHistory();
  const { authentication } = rest;
  const passwordRequirements = [
    "อักขระพิเศษหนึ่งตัว",
    "ตั้งขั้นต่ำ 6 ตัว",
    "หนึ่งหมายเลข (แนะนำ 2 รายการ)",
    "เปลี่ยนรหัสผ่านสม่ำเสมอ",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <SuiBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <SuiTypography
          variant="button"
          textColor="text"
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </SuiTypography>
      </SuiBox>
    );
  });
  const handleSubmit = async (event) => {
    try {
      const { data } = await post(`${HOST}api/register/ChangePassword`, event);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      history.push("/");
    } catch (error) {
      console.log(error);
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่อีกครั้ง", "error");
    }
  };
  return (
    <Card id="change-password">
      <SuiBox p={3}>
        <SuiTypography variant="h5">เปลี่ยนรหัสผ่าน</SuiTypography>
      </SuiBox>
      <Formik
        initialValues={{
          user_name: authentication?.user?.user_name,
          password: "",
          repeatPassword: "",
        }}
        validationSchema={validations}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <Form autoComplete="off">
            <SuiBox pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormField
                    label="รหัสผ่านใหม่"
                    placeholder="รหัสผ่านใหม่"
                    inputProps={{ type: "password", autoComplete: "" }}
                    onChange={formProps.handleChange}
                    name="password"
                    value={formProps.values.password}
                    error={formProps.errors?.password && formProps.touched?.password}
                    success={formProps.values?.password.length > 0 && !formProps.errors?.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    label="ยืนยันรหัสผ่าน"
                    placeholder="ยืนยันรหัสผ่าน"
                    inputProps={{ type: "password", autoComplete: "" }}
                    name="repeatPassword"
                    onChange={formProps.handleChange}
                    value={formProps.values.repeatPassword}
                    error={formProps.errors?.repeatPassword && formProps.touched?.repeatPassword}
                    success={
                      formProps.values?.repeatPassword > 0 && !formProps.errors?.repeatPassword
                    }
                  />
                </Grid>
              </Grid>
              <SuiBox mt={6} mb={1}>
                <SuiTypography variant="h5">ข้อกำหนดรหัสผ่าน</SuiTypography>
              </SuiBox>
              <SuiBox mb={1}>
                <SuiTypography variant="body2" textColor="text">
                  โปรดปฏิบัติตามคำแนะนำนี้สำหรับรหัสผ่านที่คาดเดายาก
                </SuiTypography>
              </SuiBox>
              <SuiBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                flexWrap="wrap"
              >
                <SuiBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                  {renderPasswordRequirements}
                </SuiBox>
                <SuiBox ml="auto">
                  <SuiButton variant="gradient" buttonColor="dark" size="small" type="submit">
                    update password
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return state;
};

// CONNECT REDUX
export default connect(mapStateToProps)(ChangePassword);
