import Style from "./style.js";
import "./index.scss";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";
import SuiButton from "components/SuiButton";
import { HOST } from "utils/config";
import { get } from "utils/helper";
import { post } from "utils/helper/request";
import { Grid, Box } from "@mui/material";

const useStyle = makeStyles(Style);

function AddBillText({ formData, onsetTotaldata }) {
  const gatchaTextNumber = formData.values.gatchaTextNumber;

  //   console.log("gatchaTextNumber = ", formData.values.gatchaTextNumber, "55555");

  const deleteLottoItem = async (index) => {
    var arr = formData?.values?.gatchaTextNumber;
    await arr.splice(index, 1);
    formData.setFieldValue("gatchaTextNumber", arr);
    var num = 0;

    await arr.map((e, i) => {
      for (var x = 0; x < e.gatchaNumber.length; x++) {
        // num = num + parseInt(e.numberUp || 0) + parseInt(e.numberDown || 0);
        num =
          num +
          parseInt(e.numberUp || 0) +
          parseInt(e.numberDown || 0) +
          parseInt(e.numberMulti || 0);
      }
    });

    handledsetTotal(num);
  };
  const deleteLottoItemAll = async () => {
    formData.setFieldValue("gatchaTextNumber", []);
    var num = 0;

    await arr.map((e, i) => {
      for (var x = 0; x < e.gatchaNumber.length; x++) {
        // num = num + parseInt(e.numberUp || 0) + parseInt(e.numberDown || 0);
        num =
          num +
          parseInt(e.numberUp || 0) +
          parseInt(e.numberDown || 0) +
          parseInt(e.numberMulti || 0);
      }
    });

    handledsetTotal(num);
  };
  const handledsetTotal = (num) => {
    onsetTotaldata(num, "text");
  };

  return (
    <>
      <div>
        <Grid container>
          {formData.values.gatchaTextNumber?.map((e, i) => (
            <Grid item xs={6} md={6} lg={6} xl={4} key={i}>
              <div className="add-bill">
                <div className="lotto-detail-text">
                  {e.type == "1" && <p>1 วิ่ง</p>}
                  {e.type == "2" && <p>2 ตัว</p>}
                  {e.type == "3" && <p>3 ตัว</p>}
                  {e.type == "4" && <p>3 ตัว</p>}
                  {e.type == "1" && (
                    <p>
                      {e.numberUp ? "บน" : ""} {e.numberUp && e.numberDown ? "x" : ""}{" "}
                      {e.numberDown ? "ล่าง" : ""}
                      {e.numberMulti ? "โต๊ด" : ""}
                    </p>
                  )}
                  {e.type == "2" && (
                    <p>
                      {e.numberUp ? "บน" : ""} {e.numberUp && e.numberDown ? "x" : ""}{" "}
                      {e.numberDown ? "ล่าง" : ""}
                    </p>
                  )}
                  {e.type == "3" && (
                    <p>
                      {e.numberUp ? "บน" : ""} {e.numberUp && e.numberDown ? "x" : ""}{" "}
                      {e.numberDown ? "ล่าง" : ""}
                      {e.numberMulti ? "โต๊ด" : ""}
                    </p>
                  )}
                  {e.type == "4" && <p>บน</p>}
                  <p>
                    {e.numberUp ? e.numberUp : ""} {e.numberUp && e.numberDown ? "x" : ""}{" "}
                    {e.numberDown ? e.numberDown : ""}
                  </p>
                </div>
                <div className="lotto-number-text">
                  {e.gatchaNumber.map((el, ind) => (
                    <span key={ind}>{el} </span>
                  ))}
                </div>
                <div className="delete-button-group-text">
                  <div className="button" onClick={() => deleteLottoItem(i)}>
                    <p>ลบ</p>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        <Box ml={2}>
          {formData.values.gatchaTextNumber.length > 0 && (
            <SuiButton buttonColor="error" onClick={() => deleteLottoItemAll()}>
              ลบทั้งหมด
            </SuiButton>
          )}
        </Box>
      </div>
    </>
  );
}

AddBillText.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onsetTotaldata: PropTypes.func,
};

export default AddBillText;
