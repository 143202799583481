import "./index.scss";

import { useEffect, useState } from "react";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import { HOST } from "utils/config";
import { get } from "utils/helper";
import { post } from "utils/helper/request";

// or
import { Collapse } from "@mui/material";
import Paginated from "memberpage/pages/list-select/Paginated";
import { COLUMNS } from "memberpage/pages/list-select/column";
import DataTable from "components/Tables/DataTable";
import CircularProgress from "@mui/material/CircularProgress";
import SuiBox from "components/SuiBox";
import Navbar from "memberpage/pages/navbar";

function ListSelect() {
  const options = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 50, label: 50 },
  ];

  const [isLoading, setIsloading] = useState(false);
  const [dataLotto, setDataLotto] = useState([]);

  const fetchLotto = async () => {
    try {
      setIsloading(true);
      //   console.log("localStorage = ",localStorage)
      const url = HOST + `api/Gatcha/GetBetBill?page=1&per_page=50`;
      const { data } = await get(url);
      // const { data } = await get(`${HOST}api/Gatcha/GetBetBill?page=1&per_page=50`);
      setDataLotto(data.results);
      //   console.log("dataLotto = ",data.results);
      setIsloading(false);
    } catch (error) {}
  };
  useEffect(() => {
    fetchLotto();
  }, []);

  return (
    <>
      <DashboardLayout>
        <Navbar />
        <div className="list-select">
          {/* <Paginated data={dataLotto} columns={COLUMNS} /> */}
          {isLoading && (
            <div className="isLoading">
              <CircularProgress />
            </div>
          )}
          {!isLoading && (
            <DataTable
              entriesPerPage={{ defaultValue: 25, entries: [5, 10, 15, 20, 25] }}
              table={{
                columns: COLUMNS,
                rows: dataLotto,
              }}
              canSearch
              isCollape
              collapseColumns={[
                { accessorKey: "type", align: "center", header: "ประเภท" },
                { accessorKey: "number", align: "center", header: "หมายเลข" },
                { accessorKey: "total_buy_display", align: "center", header: "ราคาที่ซื้อ" },
                { accessorKey: "reward_rate_display", align: "center", header: "เรทที่จ่าย" },
                { accessorKey: "discount_display", align: "center", header: "ส่วนลด" },
              ]}
              collapseRowsName="bet_number_list"
              collapseBgColor="#FFD700"
            />
          )}
        </div>
      </DashboardLayout>
    </>
  );
}

export default ListSelect;
