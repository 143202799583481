import { makeStyles } from "@mui/styles";
const manageStyle = makeStyles({
  selectmenu: {
    width: "100%",
  },
  customButton: {
    padding: "0 1rem",
    backgroundColor: "#9BC6DB",
    color: "#0890D0",
    border: "1px solid #0890D0",
  },
  customBox: {
    height: "2.5rem",
  },
  customSubmit: {
    backgroundColor: "#0890D0",
    color: "#fff",
    width: "100%",
    fontSize: "18px",
    "&:hover": {
      color: "#0890D0",
    },
  },
});

export default manageStyle;
