import "./index.scss";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import Navbar from "memberpage/pages/navbar";

function RuleLotto() {
  return (
    <>
      <DashboardLayout>
        <Navbar />
        <div className="rule">
          <p>กฎ และ กติกา</p>
          <br />
          <p>
            AG NanaLotto ยึดเอาข้อมูลที่ เอเย่นต์และ สมาชิกทุกคน บนหน้าเว๊บไซต์เป็นหลัก
            กรุณาตรวจสอบรายการเล่นและ พิมพ์เก็บไว้เป็นหลักฐานเสมอ หากมีข้อสงสัยในการตั้งค่าต่างๆ
            และต้องการความช่วยเหลือ โปรดติดต่อต้นสายของท่าน หากมีข้อผิดพลาดที่เกิดขึ้นกับระบบ
            โปรดแจ้งทางต้นสายของท่านก่อนออกผลรางวัล มิเช่นนั้นบริษัท
            จะถือเอารายการแทงและข้อมูลที่เกิดขึ้นในเว็บไซต์เป็นหลักเสมอ
          </p>
          <br />
          <p>
            1. กติกา การเข้าเล่น แต่ละตลาดหวย สมาชิกสามารถเข้าเล่นที่ เมนู แทงหวย
            **สำคัญ**โปรดตรวจทานทุกครั้งก่อนกดยืนยันการแทงเพราะแทงแล้วยกเลิกไม่ได้
            และทางบริษัทแนะนำให้แทงแล้ว เก็บของมูลการแทงไว้(save/print) เพื่อตรวจสอบหลังผลรางวัลออก
            <br />
            2. สามารถตรวจสอบรายการที่แทงแล้ว ที่ส่วนขวาของหน้าแทงหวย และ ที่เมนู รายการแทง
            ด้านบนของหน้าเล่น
            <br />
            3. สามารถตรวจสอบรายการที่ประกาศผลแล้ว ที่เมนู รายการการเงิน ด้านบนของหน้าเล่น
            <br />
            4. สามารถเช็คผลรางวัลแต่ละตลาดได้ที่ เมนู ตรวจผลรางวัล
            ด้านบนของหน้าเล่น+หากไม่มีหลักฐานมายืนยัน(ที่บันทึก/ปริ้นไว้) มายืนยัน
            บริษัทยึดตามข้อมูลที่บริษัทมีอยู่
            <br />
            5. หากเอเย่นต์หรือสมาชิก ท่านใดพบปัญหาการแทง หรือระบบบัญชี ในหน้าส่วนตัว
            โปรดติดต่อเอเย่นต์ ของท่าน ภายใน1ชม ก่อนหวยออก
            มิเช่นนั้นบริษัทจะยึดข้อมูลตามเว๊บไซต์เป็นหลัก
            <br />
            6. หากมีการเปลี่ยนแปลงในการออกผลรางวัลตลาดต่างๆทางบริษัท จะแจ้งลูกค้าผ่านทางข้าวสาร
            ‘ตัวอักษรวิ่ง’ ด้านบน, หรือ แจ้งผ่านทางเอเย่นต์ของท่าน
            <br />
            7. บริษัทแนะนำให้ เอเย่นต์และสมาชิกทุกท่าน ดูแลรับผิดชอบข้อมูลส่วนตัว
            และรหัสการเข้าสู่หน้าแทง ของสมาชิกภายใต้การดูแลของสายงานเป็นอย่างดี
            เพื่อผลประโยชน์ของทุกท่าน
            <br />
            8. บริษัทปฎิเสธการจ่ายเงินรางวัล ทั้งสายงาน และยกเลิกความเป็นสมาชิก
            หากตรวจพบการโกงหรือทุจรจิตร ทุกรูปแบบที่เกิดขึ้น
            <br />
            9. เอเยนต์ทุกสายงาน ต้องรับผิดชอบกับยอดที่เกิดขึ้นภายใต้สายงานตัวเองทุกกรณี และ
            บริษัทมีสิทธิในการยกเลิก ทุกยอดที่มีการเล่นเข้ามาหลังหวยออกแล้วของทุกตลาดหวย
            <br />
            10.ระบบทำการคืนโพยเเละเครดิตให้ในกรณีที่ไม่มาการออกผลหวยลาว ภายใน 1 ชั่วโมง
            ถือว่าไม่มีการออกผลรางวัล
          </p>
          <br />
          <ul>
            <b>หวยลาวการค้า (bcel)</b>
            <br />
            ออกผลรางวัล เป็นเลข 6 หลัก
            <li>รางวัล 3 ตัวบน จะใช้3ตัวสุดท้ายของรางวัลที่ออก</li>
            <li>รางวัล 3 ตัวโต้ด จะใช้3ตัวสุดท้ายของรางวัลที่ออกสลับตำแหน่งได้</li>
            <li>รางวัล 2 ตัวบน จะใช้2ตัวสุดท้ายของรางวัลที่ออก</li>
            <li>รางวัล วิ่งบน จะใช้3ตัวสุดท้ายของรางวัลที่ออกตัวสลับตำแหน่งได้</li>
            <li>รางวัล 2 ตัวล่าง จะใช้ตัวที่ 3 และ 4 ของรางวัลที่ออก</li>
            <li>รางวัล วิ่งล่าง จะใช้รางวัล2 ตัวล่างของรางวัลที่ออก ถูก1ตัวสลับตำแหน่งได้</li>
            <li>รางวัล วิ่งล่าง จะใช้รางวัลเลขท้าย 2 ตัว ถูก1ตัวสลับตำแหน่งได้</li>
          </ul>
          <br />
          <ul>
            <b>หวยลาวพัฒนา (VIP)</b>
            <br />
            ออกผลรางวัล เป็นเลข 6 หลัก
            <li>รางวัล 3 ตัวบน จะใช้3ตัวสุดท้ายของรางวัลที่ออก</li>
            <li>รางวัล 3 ตัวโต้ด จะใช้3ตัวสุดท้ายของรางวัลที่ออกสลับตำแหน่งได้</li>
            <li>รางวัล 2 ตัวบน จะใช้2ตัวสุดท้ายของรางวัลที่ออก</li>
            <li>รางวัล วิ่งบน จะใช้3ตัวสุดท้ายของรางวัลที่ออกตัวสลับตำแหน่งได้</li>
            <li>รางวัล 2 ตัวล่าง จะใช้ตัวที่ 3 และ 4 ของรางวัลที่ออก</li>
            <li>รางวัล วิ่งล่าง จะใช้รางวัล2 ตัวล่างของรางวัลที่ออก ถูก1ตัวสลับตำแหน่งได้</li>
            <li>รางวัล วิ่งล่าง จะใช้รางวัลเลขท้าย 2 ตัว ถูก1ตัวสลับตำแหน่งได้</li>
          </ul>
          <br />
          {/* <hr />
          <br />
          <p>ข้อมูลการคั้งค่าหวย</p>
          <p>ออกทุกวัน</p>
          <p>เปิดตลาด 8.00 น.- 20.15 น.</p>
          <p>เวลาหวยออก 20.30 น.</p>
          <br />
          <ul>
            <p>อัตราจ่าย - แทงสูงสุด</p>
            <li>3 ตัวบน บาทละ 850</li>
            <li>3 ตัวโต็ส บาทละ 100</li>
            <li>2 ตัวบน บาทละ 90</li>
            <li>2 ตัวล่าง บาทละ 90</li>
            <li>วิ่งบน 3 เท่า</li>
            <li>วิ่งล่าง 4 เท่า</li>
          </ul> */}
        </div>
      </DashboardLayout>
    </>
  );
}

export default RuleLotto;
