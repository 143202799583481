import moment from 'moment'

export const COLUMNS = [
  {
    Header: "#",
    accessor: "row_no",
  },
  {
    Header: "เวลาแทง",
    accessor: "bill_date",
    Cell: ({row}) =>{
      return moment(row.original?.bill_date).format('DD/MM HH:mm')
    }
  },
  {
    Header: "ตลาด",
    accessor: "market"
  },
  {
    Header: "อ้างอิง",
    accessor: "reference"
  },
  {
    Header: "รายการ",
    accessor: "number_of_list"
  },
  {
    Header: "บาท",
    accessor: "total_buy"
  },
  {
    Header: "ดูบิล",
    Cell:()=>{
      return(
        <>
          <p className="click-button">คลิกที่นี่</p>
        </>
      )
    }
  },
];