import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";

import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";

import Style from "../style.js";
import "../index.scss";
import { makeStyles } from "@mui/styles";

import Paper from "@mui/material/Paper";

import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Swal from "sweetalert2";

const useStyle = makeStyles(Style);

function ThreeDigits({ formData, gatchaconfig }) {
  const classes = useStyle();

  String.prototype.swapLetters = function (i) {
    var a = this.split("");
    var temp = this[i];
    a[i] = a[i + 1];
    a[i + 1] = temp;
    return a.join("");
  };

  function combs(n) {
    if (n === 0) {
      return 1;
    } else {
      return n * combs(n - 1);
    }
  }

  function permAlone(str) {
    var reg = new RegExp(/(\w)\1+/);
    var c = 0;
    var arrtest = [];

    function permutations(str, p, n, b, perms) {
      var index = p - n;
      var len = str.length - 2;
      if (arrtest.findIndex((e) => e === str) < 0) {
        arrtest.push(str);
      }

      if (perms === 0) {
        return arrtest;
      }
      //if p is less then or equal and b is zStart then increase p by 1
      //if p is equal to len do not increment p just call function with b===zBreak (call function to swich first two letters)
      if (p <= len && b === "zStart") {
        if (p === len) {
          b = "zBreak";
        } else {
          p = p + 1;
        }
        return permutations(str.swapLetters(index), p, n, b, perms - 1);
      }
      //if n is less then or equal and b is lStart then increase n by 1
      //if n is equal to len do not increment n just call function with b===lBreak (call function to swich first last two letters)
      if (n <= len && b === "lStart") {
        if (n === len) {
          b = "lBreak";
        } else {
          n = n + 1;
        }
        return permutations(str.swapLetters(index), p, n, b, perms - 1);
      }
      //if b is zBreak swap first two letters and set b to be lStart because
      //next break should swap last two letters
      if (b === "zBreak") {
        return permutations(str.swapLetters(0), p, n, "lStart", perms - 1);
      } else if (b === "lBreak") {
        return permutations(str.swapLetters(len), 0, 0, "zStart", perms - 1);
      }
      return permutations(str.swapLetters(index), p, n, b, perms - 1);
    }

    permutations(str, 0, 0, "zStart", combs(str.length) - 1);
    return arrtest;
  }

  const [numberForReverse, setNumberForReverse] = useState([]);
  const [lottoNumber, setLottoNumber] = useState([]);
  const [lottoNumberUp, setLottoNumberUp] = useState("");
  const [lottonumberMulti, setLottonumberMulti] = useState("");
  const [inputList, setInputList] = useState([{ gatchaNumber: "", numberUp: "", numberMulti: "" }]);

  const handleInputChange = (e) => {
    const inputLenght = e.target.value.length;
    const { name, value } = e.target;
    if (name == "numberUp") {
      setLottoNumberUp(value);
    }
    if (name == "numberMulti") {
      setLottonumberMulti(value);
    }
    document.getElementById("gatchaNumber").value = "";
  };

  const onChangeReverseNumber = (event) => {
    const inputLenght = event.length;
    setNumberForReverse((numberForReverse) => [...numberForReverse, event]);
    if (inputLenght == 3) {
      // const exists = lottoNumber.find((p) => p == event);
      // if (!exists) {
      //   setLottoNumber((lottoNumber) => [...lottoNumber, event]);
      // }
      setLottoNumber((lottoNumber) => [...lottoNumber, event]);
      document.getElementById("gatchaNumber").value = "";
    }
  };

  const deleteLottoItem = (index, values) => {
    setLottoNumber(lottoNumber.filter((e) => e !== values));
  };
  const deleteLottoAllItem = () => {
    setLottoNumber([]);
  };

  const reverstNumber = () => {
    const numberForReverse = lottoNumber.pop();
    if (!numberForReverse) {
      Swal.fire("ผิดพลาด!", "กรุณาใส่ตัวเลขก่อนกลับเลข", "error");
    } else {
      const numChange = permAlone(numberForReverse);
      setLottoNumber(lottoNumber.concat(numChange));
    }
  };
  const reverstNumberAll = () => {
    const numberForReverse = lottoNumber;
    if (!numberForReverse) {
      Swal.fire("ผิดพลาด!", "กรุณาใส่ตัวเลขก่อนกลับเลข", "error");
    } else {
      const numChange = [];
      lottoNumber.map((e, i) => {
        if (!lottoNumber.find((p) => p == e.toString().split("").reverse().join(""))) {
          numChange.push(...permAlone(e));
        }
      });

      setLottoNumber(lottoNumber.concat(numChange));
    }
  };

  const handleAddBill = () => {
    if (lottoNumber < 1) {
      Swal.fire("ผิดพลาด!", "กรุณาใส่ตัวเลข", "error");
    } else if (!lottonumberMulti && !lottoNumberUp) {
      Swal.fire("ผิดพลาด!", "จำนวนเงินไม่ถูกต้อง", "error");
    } else if (!lottonumberMulti && lottoNumberUp < 1) {
      Swal.fire("ผิดพลาด!", "จำนวนเงินไม่ถูกต้อง", "error");
    } else if (lottonumberMulti < 1 && !lottoNumberUp) {
      Swal.fire("ผิดพลาด!", "จำนวนเงินไม่ถูกต้อง", "error");
    } else if (lottonumberMulti < 1 && lottoNumberUp < 1) {
      Swal.fire("ผิดพลาด!", "จำนวนเงินไม่ถูกต้อง", "error");
    } else {
      const addBillArray = {};
      addBillArray.type = "3";
      addBillArray.gatchaNumber = lottoNumber;
      addBillArray.numberUp = lottoNumberUp;
      addBillArray.numberMulti = lottonumberMulti;
      formData.setFieldValue(`gatchaFlashNumber`, [
        ...formData.values.gatchaFlashNumber,
        addBillArray,
      ]);
      setLottoNumberUp("");
      setLottonumberMulti("");
      setLottoNumber([]);
      // document.getElementById("numberUp").value = "";
      // document.getElementById("numberMulti").value = "";
    }
  };

  return (
    <>
      <div className="lotto-number-item">
        {lottoNumber?.map((values, index) => (
          <div
            key={index}
            className="lotto-number-item-value"
            onClick={() => deleteLottoItem(index, values)}
          >
            <p>{values}</p>
          </div>
        ))}
      </div>
      {lottoNumber.length > 0 && (
        <div className="lotto-number-delete d-flex " onClick={() => deleteLottoAllItem()}>
          <DeleteIcon />
          ยกเลิก
        </div>
      )}
      <div className="two-digits">
        <div className="block-flex">
          <p>ใส่เลข</p>
          <div className="input-box">
            <SuiInput
              id="gatchaNumber"
              type="text"
              placeholder="หวย"
              name={"gatchaNumber"}
              onChange={(e) => {
                onChangeReverseNumber(e.target.value);
                // formData.setFieldValue("gatchaNumber", e.target.value)
              }}
              // value={formData.values.gatchaNumber} //formData.values.gatchaNumber
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
                if (event.target.value.length >= 3) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className="button-contain">
            <div className="type-button" onClick={() => reverstNumberAll()}>
              กลับเลข
            </div>
          </div>
        </div>
        <div className="block-flex">
          <p>ใส่ราคา</p>
          <p>บน</p>
          <div className="input-box">
            <SuiInput
              id="numberUp"
              type="text"
              placeholder="0"
              name={"numberUp"}
              onChange={(e) => {
                handleInputChange(e);
                // formData.setFieldValue("numberUp", e.target.value)
              }}
              value={lottoNumberUp}
              // value={formData.values.numberUp}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <p>โต๊ด</p>
          <div className="input-box">
            <SuiInput
              id="numberMulti"
              type="text"
              placeholder="0"
              name={"numberMulti"}
              onChange={(e) => {
                handleInputChange(e);
                // formData.setFieldValue("numberMulti", e.target.value)
              }}
              value={lottonumberMulti}
              // value={formData.values.numberMulti}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
                // if (gatchaconfig.findIndex((e, i) => e.gatcha_types_code === "3_Under") < 0) {

                //   event.preventDefault();
                // }
              }}
            />
          </div>
        </div>
      </div>
      <div className="two-digits-bill">
        <div className="button-contain">
          <SuiButton
            variant="gradient"
            buttonColor="info"
            // type="submit"
            fullWidth
            customClass={classes.submitButton}
            onClick={() => handleAddBill()}
          >
            เพิ่มบิล
          </SuiButton>
        </div>
      </div>
    </>
  );
}

ThreeDigits.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  gatchaconfig: PropTypes.array,
};

export default ThreeDigits;
