import colors from "assets/theme/base/colors";
const { info, white, primary, success } = colors;
export const columnsDetail = [
  { Header: "ตลาด", accessor: "gatcha_name" },
  { Header: "ยอดพนัน", accessor: "total_buy" },
  {
    Header: "ยอดส่งออก",
    accessor: "downline_receive",
    bgcolor: info.main,
    fontcolor: white.main,
  },
  {
    Header: "ส่วนลด",
    accessor: "downline_discount",
    bgcolor: info.main,
    fontcolor: white.main,
  },
  {
    Header: "ถูกรางวัล",
    accessor: "downline_total_reward",
    bgcolor: info.main,
    fontcolor: white.main,
  },
  {
    Header: "รวม",
    accessor: "downline_summary",
    bgcolor: info.main,
    fontcolor: white.main,
  },
  {
    Header: "ยอดถือสู้",
    accessor: "agent_total_receive",
    bgcolor: primary.main,
    fontcolor: white.main,
  },
  {
    Header: "ส่วนลด",
    accessor: "agent_total_discount",
    bgcolor: primary.main,
    fontcolor: white.main,
  },

  {
    Header: "ถูกรางวัล",
    accessor: "agent_total_reward",
    bgcolor: primary.main,
    fontcolor: white.main,
  },
  {
    Header: "รวม",
    accessor: "agent_summary",
    bgcolor: primary.main,
    fontcolor: white.main,
  },
  {
    Header: "ยอดถือสู้",
    accessor: "upline_receive",
    bgcolor: success.main,
    fontcolor: white.main,
  },
  {
    Header: "ส่วนลด",
    accessor: "upline_discount",
    bgcolor: success.main,
    fontcolor: white.main,
  },
  {
    Header: "ถูกรางวัล",
    accessor: "upline_total_reward",
    bgcolor: success.main,
    fontcolor: white.main,
  },
  {
    Header: "รวม",
    accessor: "upline_summary",
    bgcolor: success.main,
    fontcolor: white.main,
  },
];

export const columnsDetailUser = [
  { Header: "สมาชิก", accessor: "downline_user_id" },
  { Header: "ติดต่อ", accessor: "downline_name" },
  { Header: "ยอดพนัน", accessor: "total_buy" },
  {
    Header: "ยอดส่งออก",
    accessor: "downline_receive",
    bgcolor: info.main,
    fontcolor: white.main,
  },
  {
    Header: "ส่วนลด",
    accessor: "downline_discount",
    bgcolor: info.main,
    fontcolor: white.main,
  },
  {
    Header: "ถูกรางวัล",
    accessor: "downline_total_reward",
    bgcolor: info.main,
    fontcolor: white.main,
  },
  {
    Header: "รวม",
    accessor: "downline_summary",
    bgcolor: info.main,
    fontcolor: white.main,
  },
  {
    Header: "ยอดถือสู้",
    accessor: "agent_total_receive",
    bgcolor: primary.main,
    fontcolor: white.main,
  },
  {
    Header: "ส่วนลด",
    accessor: "agent_total_discount",
    bgcolor: primary.main,
    fontcolor: white.main,
  },
  {
    Header: "ถูกรางวัล",
    accessor: "agent_total_reward",
    bgcolor: primary.main,
    fontcolor: white.main,
  },
  {
    Header: "รวม",
    accessor: "agent_summary",
    bgcolor: primary.main,
    fontcolor: white.main,
  },
  {
    Header: "ยอดถือสู้	",
    accessor: "upline_receive",
    bgcolor: success.main,
    fontcolor: white.main,
  },
  {
    Header: "ส่วนลด",
    accessor: "upline_discount",
    bgcolor: success.main,
    fontcolor: white.main,
  },
  {
    Header: "ถูกรางวัล",
    accessor: "upline_total_reward",
    bgcolor: success.main,
    fontcolor: white.main,
  },
  {
    Header: "รวม",
    accessor: "upline_summary",
    bgcolor: success.main,
    fontcolor: white.main,
  },
];
