// import BaseLayout from "layouts/pages/account/components/BaseLayout";

import PropTypes from "prop-types";
// @mui material components
import { useHistory, useParams } from "react-router";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BaseLayout from "./components/BaseLayout";
import React, { useState, useEffect } from "react";
import { get, post } from "utils/helper";
import { HOST } from "utils/config";
import LottoList from "./lottolist";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import SuiBox from "components/SuiBox";
function Award() {
  const [dataLotto, setDataLotto] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [value, setValue] = React.useState(0);
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchLotto = async () => {
    try {
      setLoading(true);
      const { data } = await get(`${HOST}api/GatchaResults/GetGatchaForSetResult`);
      setDataLotto(data.results);

      setLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    fetchLotto();
  }, [id]);
  const onSuccess = () => {
    fetchLotto();
  };

  return (
    <BaseLayout tabValues={id} tabHandler={handleSetTabValue} tabData={dataLotto}>
      
      <TabContext value={id}>
        {dataLotto.length > 0 ? (
          dataLotto.map((e, i) => (
            <TabPanel value={`${e.row_no}`} key={i}>
              <LottoList dataLotto={e} onSuccess={onSuccess} />
            </TabPanel>
          ))
        ) : (
          <SuiBox textAlign="center">ยังไม่มีรายการหวยที่จะต้องออกรางวัล</SuiBox>
        )}
      </TabContext>
      {/* <AwardRoute dataLotto={dataLotto.find((value) => value.group_id === parseInt(id))} /> */}
    </BaseLayout>
  );
}

export default Award;
