/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import { useHistory } from "react-router";
import SuiBadgeDot from "components/SuiBadgeDot";
import SuiTypography from "components/SuiTypography";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Skeleton } from "@mui/material";
import { useParams } from "react-router";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import moment from "moment";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";
// Reports page components
import Reviews from "memberpage/pages/users/reports/components/Reviews";
import SuiButton from "components/SuiButton";
// Data
import DialogaddAnnouce from "./DialogaddAnnouce";
import DialogEditAnnouce from "./DialogEditAnnouce";
const columns = [
  { name: "number", align: "center", label: "ลำดับ" },
  { name: "images", align: "center", label: "รูปภาพ" },
  { name: "title", align: "center", label: "หัวข้อ" },
  { name: "description", align: "center", label: "รายละเอียดประกาศ" },
  { name: "status", align: "center", label: "สถานะ" },
  { name: "effective_date", align: "center", label: "เริ่มประกาศ - สิ้นสุด" },
  { name: "action", align: "center", label: "" },
];
function ListAnnouce() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [dataListAnnounce, setdataListAnnounce] = useState([]);
  const [dataUpdate, setdataUpdate] = useState({});
  const [pages, setpages] = useState(1);
  const [perpages, setperpages] = useState(10);
  const { id } = useParams();
  const [rows, setRows] = useState([]);
  const fetchAnnouce = async () => {
    try {
      setLoading(true);

      const { data } = await get(
        `${HOST}api/Announcement/GetAnnouncement?endDate=${moment()
          .add(1, "M")
          .format("MM/DD/YYYY")}&startDate=${moment().format("MM/DD/YYYY")}`
      );
      setdataListAnnounce(data.results);

      const rownaja = data.results.map((elem) => ({
        number: elem.row_no,
        images: <img src={`${elem.cover}`} width={50} style={{ borderRadius: 8 }} />,
        title: elem.title,
        description: elem.description,
        status: (
          <SuiBox ml={-1.325}>
            <SuiBadgeDot size="small" badgeContent={elem.status ? "เปิด" : "ปิด"} />
          </SuiBox>
        ),
        effective_date: elem.effective_date_display,
        action: (
          <SuiBox w={"100%"} display="flex">
            <SuiButton
              buttonColor={"warning"}
              onClick={() => {
                setdataUpdate(elem);
                onOpenEditDialog();
              }}
            >
              แก้ไข
            </SuiButton>
          </SuiBox>
        ),
      }));

      setRows(rownaja);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAnnouce();
  }, []);
  const onCloseDialog = () => {
    setOpenDialog(false);
  };
  const onOpenDialog = () => {
    setOpenDialog(true);
  };
  const onCloseEditDialog = () => {
    setOpenEditDialog(false);
  };
  const onOpenEditDialog = () => {
    setOpenEditDialog(true);
  };
  const onSuccess = () => {
    fetchAnnouce();
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox py={3} display="flex" justifyContent="space-between">
          <SuiTypography>จัดการประกาศ</SuiTypography>
          <SuiButton onClick={() => onOpenDialog()} buttonColor="info">
            เพิ่มประกาศ
          </SuiButton>
        </SuiBox>
        {loading ? (
          <SuiBox>
            <Skeleton variant="rectangular" width={"auto"} height={500} />
          </SuiBox>
        ) : rows.length > 0 ? (
          <Table columns={columns} rows={rows} />
        ) : (
          <SuiBox textAlign="center">ไม่พบข้อมูล</SuiBox>
        )}
      </SuiBox>
      <DialogaddAnnouce open={openDialog} onClose={onCloseDialog} onSuccess={onSuccess} />
      <DialogEditAnnouce
        open={openEditDialog}
        onClose={onCloseEditDialog}
        dataUpdate={dataUpdate}
        onSuccess={onSuccess}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default ListAnnouce;
