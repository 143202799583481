import Basket from "layouts/Icons/Basket";
import CreditCard from "layouts/Icons/CreditCard";
import Cube from "layouts/Icons/Cube";
import CustomerSupport from "layouts/Icons/CustomerSupport";
import Document from "layouts/Icons/Document";
import Office from "layouts/Icons/Office";
import Settings from "layouts/Icons/Settings";
import Shop from "layouts/Icons/Shop";
import SpaceShip from "layouts/Icons/SpaceShip";

const pageRoutesMember = [
  {
    name: "แทงหวย",
    key: "select-lotto",
    type: "collapse",
    noCollapse: true,
    icon: <SpaceShip size="12px" color="white" />,
    route: "/select-lotto",
  },
  {
    name: "รายการแทง",
    key: "list-select",
    type: "collapse",
    noCollapse: true,
    icon: <Basket size="12px" color="white" />,
    route: "/list-select",
  },
  {
    name: "บัญชีการเงิน",
    key: "financial",
    type: "collapse",
    noCollapse: true,
    icon: <Document size="12px" color="white" />,
    route: "/financial",
  },
  {
    name: "ผลรางวัล",
    key: "reward",
    type: "collapse",
    noCollapse: true,
    icon: <CreditCard size="12px" color="white" />,
    route: "/report/reward",
  },
  {
    name: "กฎกติกา",
    key: "rule",
    type: "collapse",
    noCollapse: true,
    icon: <CustomerSupport size="12px" color="white" />,
    route: "/rule",
  },
  {
    name: "วิธีเล่นหวย",
    key: "howto",
    type: "collapse",
    noCollapse: true,
    icon: <Settings size="12px" color="white" />,
    route: "/howto",
  },
];

export default pageRoutesMember;
