// Soft UI Dashboard PRO React icons
import Shop from "layouts/Icons/Shop";
import Office from "layouts/Icons/Office";
import SettingsIcon from "layouts/Icons/Settings";
import Basket from "layouts/Icons/Basket";
import Document from "layouts/Icons/Document";
import CreditCard from "layouts/Icons/CreditCard";
const pageRoutesAdmin = [
  {
    type: "collapse",
    name: "ประกาศ",
    key: "",
    icon: <Shop size="12px" />,
    noCollapse: true,
    route: "/",
    isAuth: "private",
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "ออกรางวัล",
    key: "award",
    icon: <Office size="12px" />,
    collapse: [
      {
        name: "ออกรางวัล",
        key: "setaward",
        route: "/award/setaward/1",
        isAuth: "private",
      },
      {
        name: "คาดคะเนหวย",
        key: "supposedly",
        route: "/supposedly",
        isAuth: "private",
      },
    ],
  },
  {
    type: "collapse",
    name: "รายการแทงหวย",
    key: "risk",
    icon: <Office size="12px" />,
    collapse: [
      {
        name: "ตั้งค่ารับแยกตามหวย",
        key: "limit",
        route: "/risk/limit/3",
        isAuth: "private",
      },
      {
        name: "ยอดแทงรวม",
        key: "purchasedetails",
        route: "/risk/purchasedetails",
        isAuth: "private",
      },
    ],
  },
  {
    type: "collapse",
    name: "จัดการสมาชิก",
    key: "manage",
    icon: <SettingsIcon size="12px" />,
    collapse: [
      {
        name: "สมาชิก",
        key: "member",
        collapse: [
          {
            name: "รายชื่อสมาชิก",
            key: "reports",
            route: "/manage/member/all",
            isAuth: "private",
          },
          {
            name: "เพิ่มสมาชิก",
            key: "createmember",
            route: "/manage/member/create",
            isAuth: "private",
          },
        ],
      },
      {
        name: "แบ่งหุ้น",
        key: "share",
        route: "/manage/share/",
        noCollapse: true,
        isAuth: "private",
      },
      {
        name: "อัตราจ่าย",
        key: "rate",
        route: "/manage/rate/",
        noCollapse: true,
        isAuth: "private",
      },
      {
        name: "เปิดปิดหวย",
        key: "openmarket",
        route: "/manage/openmarket/",
        noCollapse: true,
        isAuth: "private",
      },
    ],
  },
  {
    type: "collapse",
    name: "จัดการหวย",
    key: "managelotto",
    icon: <Office size="12px" />,
    collapse: [
      {
        name: "จัดการกลุ่มหวย",
        key: "lottogrouplist",
        route: "/managelotto/lottogrouplist",
        isAuth: "private",
      },
    ],
  },
  {
    type: "collapse",
    name: "จัดการประกาศ",
    key: "manageannounce",
    icon: <Office size="12px" />,
    collapse: [
      {
        name: "รายการประกาศ",
        key: "announcelist",
        route: "/manageannounce/announcelist",
        isAuth: "private",
      },
    ],
  },
  {
    type: "collapse",
    name: "รายงานแพ้ชนะ",
    key: "report",
    icon: <Basket size="12px" />,
    collapse: [
      {
        name: "รายงานผลแพ้ชนะ",
        key: "winlosereport",
        route: "/report/winlosereport",
        isAuth: "private",
      },
      {
        name: "ผลรางวัล",
        key: "reward",
        type: "collapse",
        noCollapse: true,
        icon: <CreditCard size="12px" color="white" />,
        route: "/report/reward",
      },
    ],
  },
  {
    type: "collapse",
    name: "การเงิน",
    key: "balance",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "โอนยอด",
        key: "transfer",
        route: "/balance/transfer/",
        isAuth: "private",
      },
      {
        name: "รายการโอนยอด",
        key: "transferlist",
        route: "/balance/transferlist/",
        isAuth: "private",
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "ตั้งค่าบัญชี",
    key: "profile",
    icon: <Office size="12px" />,
    route: "/profile/setting",
    isAuth: "private",
    noCollapse: true,
  },
];

export default pageRoutesAdmin;
