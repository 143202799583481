// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

import { Collapse, Grid, CardContent, Divider } from "@mui/material";
import Switch from "@mui/material/Switch";
import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import FormField from "components/FormField";
// Setting pages components
import TableCell from "memberpage/pages/account/settings/components/TableCell";
import selectData from "pages/users/createmember/components/BasicInfo/data/selectData";
import { useSoftUIController } from "context";
import styles from "components/Sidenav/styles/sidenavCard";
import { display } from "@mui/system";

import { LootoList20, LootoList18, LootoList16, LootoList16span } from "components/Text/TextStyle";

function fightMax(max) {
  let arrStock = [];
  let numberSelectedStock = 0;
  arrStock.push({ value: numberSelectedStock, label: `${numberSelectedStock}%` });
  let maxNumber = max * 2;
  for (let i = 0; i < maxNumber; i++) {
    numberSelectedStock = numberSelectedStock + 0.5;
    arrStock.push({ value: numberSelectedStock, label: `${numberSelectedStock}%` });
  }
  return arrStock;
}

function Keep({ formData, errors, getValues, setValue, fields, register }) {
  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;

  const [open, setOpen] = useState(false);
  const [isStock, setIsStock] = useState([]);

  const rederInput = useMemo(
    () => (
      <div>
        {fields.map((values, i) => (
          <div key={i}>
            <SuiBox minWidth="auto" pt={2}>
              <Grid container spacing={4}>
                <Grid item lg={2}>
                  <SuiBox display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <LootoList18>{values.gatcha_name} </LootoList18>&nbsp;
                  </SuiBox>
                </Grid>
                <Grid item lg={1}>
                  <LootoList16span>{values.access ? "เปิด" : "ปิด"}</LootoList16span>
                  <Switch
                    checked={values.access}
                    onChange={() => setValue(`stock[${i}].access`, !values.access)}
                  />
                </Grid>
                <Grid item lg={2}>
                  <SuiTypography variant="caption">สูงสุด {values.all_stock_display}</SuiTypography>
                </Grid>
                <Grid item lg={2}>
                  <SuiTypography variant="caption">
                    ตัวคุณ {values.all_stock - values.stock} %
                  </SuiTypography>
                </Grid>
                <Grid item lg={2}>
                  <SuiSelect
                    placeholder="แบ่งหุ้น"
                    options={fightMax(values.fighting_max)}
                    value={{ value: values.stock, label: `${values.stock}%` }}
                    // {...formData(`stock[${i}].stock`)}
                    // {...register(`stock.${i}.stock`)}
                    // onChange={(value) => onChangeFight(value, i)}
                    onChange={(value) => {
                      setValue(`stock[${i}].stock`, value.value);
                      if (value.value < values.fighting_control) {
                        setValue(`stock[${i}].fighting_control`, value.value);
                      }
                    }}
                  />
                </Grid>
                <Grid item lg={2}>
                  <SuiSelect
                    placeholder="เก็บของ"
                    options={fightMax(values.stock)}
                    value={{
                      value: values.fighting_control,
                      label: `${values.fighting_control}%`,
                    }}
                    // onChange={(value) => onChangeKeep(value, i)}
                    onChange={(value) => setValue(`stock[${i}].fighting_control`, value.value)}
                  />
                </Grid>
                {/* <Grid item lg={2}>
<LootoList16span>{values.give_difference ? "เก็บ" : "ไม่เก็บ"}</LootoList16span>
<Switch
  checked={values.give_difference}
  onChange={() =>
    setValue(
      `stock[${i}].give_difference`,
      !values.give_difference
    )
  }
/>
</Grid> */}
              </Grid>
            </SuiBox>
            <Divider />
          </div>
        ))}
      </div>
    ),
    [fields]
  );
  const [renderChecked, setRenderChecked] = useState({});
  const [renderedValues, setRenderedValues] = useState({});
  const [renderedValuesFight, setRenderedValuesFight] = useState({});
  const [renderCloseOpen, setRenderCloseOpen] = useState({});
  const handleChange = (index, newValue) => {
    // Update the rendered value based on the select input
    const updatedRenderedValues = { ...renderedValues };
    updatedRenderedValues[index] = newValue;
    setRenderedValues(updatedRenderedValues);
  };
  const handleChangeFight = (index, newValue) => {
    console.log(newValue);
    // Update the rendered value based on the select input
    const updatedRenderedValuesFight = { ...renderedValuesFight };
    updatedRenderedValuesFight[index] = newValue;
    setRenderedValuesFight(updatedRenderedValuesFight);
  };
  const handleCheckedValue = (index, newValue) => {
    const updatedRenderedCheck = { ...renderChecked };
    updatedRenderedCheck[index] = newValue;
    setRenderChecked(updatedRenderedCheck);
  };
  const handleCheckedCloseOpen = (index, newValue) => {
    const updatedRenderedCheckCloseOpen = { ...renderCloseOpen };
    updatedRenderedCheckCloseOpen[index] = newValue;
    setRenderCloseOpen(updatedRenderedCheckCloseOpen);
  };

  return (
    <Card id="notifications" className="overflow-visible">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <LootoList20>แบ่งหุ้น / เก็บของ</LootoList20>
        </SuiBox>
      </SuiBox>
      <SuiBox pb={3} px={3}>
        <SuiBox minWidth="auto">
          <SuiBox minWidth="auto" pt={2}>
            <Grid container spacing={4}>
              <Grid item sm={3}>
                <LootoList18>ตลาดหวยหลัก</LootoList18>
              </Grid>
              <Grid item sm={3} sx={{}}>
                <SuiTypography sx={{ textAlign: "end" }}>
                  <LootoList18>ถือสู้(ตัวคุณ)</LootoList18>
                </SuiTypography>
              </Grid>
              <Grid item sm={3}>
                <LootoList18>ถือสู้(สมาชิก)</LootoList18>
              </Grid>
              <Grid item sm={2}>
                <LootoList18>บังคับสู้</LootoList18>
              </Grid>
              <Grid item sm={1}>
                <LootoList18>เก็บของที่เกิน</LootoList18>
              </Grid>
            </Grid>
            {/* {rederInput} */}
            {fields?.map((values, i) => (
              <div key={i}>
                <SuiBox minWidth="auto" key={i} pt={2}>
                  <Grid container spacing={4}>
                    <Grid item lg={2}>
                      <SuiBox
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <LootoList18>{values.gatcha_name} </LootoList18>&nbsp;
                      </SuiBox>
                    </Grid>
                    <Grid item lg={1}>
                      <LootoList16span>
                        {(renderCloseOpen[i] !== undefined ? renderCloseOpen[i] : values.access)
                          ? "เปิด"
                          : "ปิด"}
                      </LootoList16span>

                      <Switch
                        // defaultChecked={values.access}
                        // checked={false}
                        checked={
                          renderCloseOpen[i] !== undefined ? renderCloseOpen[i] : values.access
                        }
                        onChange={(e) => {
                          // console.log(e.target.checked);
                          handleCheckedCloseOpen(i, e.target.checked);
                          setValue(`stock[${i}].access`, e.target.checked);
                        }}
                        // handleCheckedCloseOpen
                        //   checked={values.access}
                        //   onChange={() => setValue(`stock[${i}].access`, !values.access)}
                      />
                    </Grid>
                    <Grid item lg={2}>
                      <SuiTypography variant="caption">
                        สูงสุด {values.all_stock_display}
                      </SuiTypography>
                    </Grid>
                    <Grid item lg={1}>
                      <SuiTypography variant="caption">
                        ตัวคุณ {values.all_stock - (renderedValues[i] || values.stock)} %
                      </SuiTypography>
                    </Grid>
                    <Grid item lg={2}>
                      <SuiSelect
                        placeholder="แบ่งหุ้น"
                        options={fightMax(values.fighting_max)}
                        defaultValue={{ value: values.stock, label: `${values.stock}%` }}
                        name={`stock[${i}].stock`}
                        // defaultValue={`${item.name}`}
                        ref={register()}
                        // {...formData(`stock[${i}].stock`)}
                        // {...register(`stock.${i}.stock`)}
                        // onChange={(value) => onChangeFight(value, i)}
                        onChange={(value) => {
                          handleChange(i, value.value);
                          setValue(`stock[${i}].stock`, value.value);
                          if (value.value < (renderedValuesFight[i] || values.fighting_control)) {
                            handleChangeFight(i, value.value);
                            setValue(`stock[${i}].fighting_control`, value.value);
                          }
                        }}
                      />
                    </Grid>

                    <Grid item lg={2}>
                      <SuiSelect
                        placeholder="เก็บของ"
                        options={fightMax(
                          renderedValues[i] !== undefined ? renderedValues[i] : values.stock
                        )}
                        defaultValue={{
                          value: renderedValues[i] || values.fighting_control,
                          label: `${renderedValues[i] || values.fighting_control}%`,
                        }}
                        value={{
                          value:
                            renderedValuesFight[i] !== undefined
                              ? renderedValuesFight[i]
                              : renderedValues[i] || values.fighting_control,
                          label: `${
                            renderedValuesFight[i] !== undefined
                              ? renderedValuesFight[i]
                              : renderedValues[i] || values.fighting_control
                          }%`,
                        }}
                        // onChange={(value) => onChangeKeep(value, i)}
                        onChange={(value) => {
                          handleChangeFight(i, value.value);
                          setValue(`stock[${i}].fighting_control`, value.value);
                        }}
                      />
                    </Grid>
                    <Grid item lg={1}>
                      <LootoList16span>{renderChecked[i] ? "เก็บ" : "ไม่เก็บ"}</LootoList16span>
                      <Switch
                        checked={renderChecked[i] || values.give_difference}
                        onChange={(e) => {
                          handleCheckedValue(i, e.target.checked);
                          setValue(`stock[${i}].give_difference`, e.target.checked);
                        }}
                      />
                    </Grid>
                  </Grid>
                </SuiBox>
                <Divider />
              </div>
            ))}
          </SuiBox>
          <Divider />
        </SuiBox>
      </SuiBox>
    </Card>
  );
}
Keep.propTypes = {
  register: PropTypes.func,
  fields: PropTypes.array,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  getValues: PropTypes.array,
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
export default Keep;
