/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";

export default Yup.object().shape({
  gatcha_type_id: Yup.string().required("กรุณากรอก"),
  discount: Yup.string().required("กรุณากรอก"),
  discount: Yup.string().required("กรุณากรอก"),
  reward_rate: Yup.string().required("กรุณากรอก"),
  order_no: Yup.string().required("กรุณากรอก"),
  spin: Yup.string().required("กรุณากรอก"),
  max_reward_rate: Yup.string().required("กรุณากรอก"),
  max_discount: Yup.string().required("กรุณากรอก"),
  max_spin: Yup.string().required("กรุณากรอก"),
  // discount: null,
  // reward_rate: null,
  // gatcha_type_id: null,
  // status: true,
  // order_no: 1,
  // spin: null,
  // max_reward_rate: null,
  // max_discount: null,
  // max_spin: null,
});
