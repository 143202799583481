// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";
import { Divider, InputAdornment } from "@mui/material";
// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";
import EventNoteIcon from "@mui/icons-material/EventNote";
// Soft UI Dashboard PRO React components
import SuiInput from "components/SuiInput";
import moment from "moment";
function SuiDatePicker({ input, dateDefault, ...rest }) {
  return (
    <Flatpickr
      options={{
        enableTime: true,
        time_24hr: true,
        dateFormat: "m/d/Y H:i",
        defaultDate: moment(dateDefault).format("MM/DD/YYYY HH:mm"),
      }}
      {...rest}
      render={({ defaultValue }, ref) => (
        <SuiInput
          {...input}
          defaultValue={defaultValue}
          inputRef={ref}
          startAdornment={
            <InputAdornment
              position="start"
              variant="outlined"
              sx={{ borderRight: "1px solid #d2d6da", height: "auto" }}
            >
              <EventNoteIcon fontSize="large" />
            </InputAdornment>
          }
        />
      )}
    />
  );
}

// Setting default values for the props of SuiDatePicker
SuiDatePicker.defaultProps = {
  input: {},
};

// Typechecking props for the SuiDatePicker
SuiDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  dateDefault: PropTypes.string,
};

export default SuiDatePicker;
