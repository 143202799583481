/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette, functions, typography }) => {
  const { white, text, dark, transparent, gradients, grey } = palette;
  const { pxToRem, linearGradient } = functions;
  const { size } = typography;

  return {
    borderGrid: {
      border: `1px solid ${grey[300]}`,
      padding: 4,
    },
  };
});
