// Soft UI Dashboard PRO React icons
import Shop from "layouts/Icons/Shop";
import Office from "layouts/Icons/Office";
import SettingsIcon from "layouts/Icons/Settings";
import Basket from "layouts/Icons/Basket";
import Document from "layouts/Icons/Document";
import CreditCard from "layouts/Icons/CreditCard";
const pageRoutesSubAdmin = [
  {
    type: "collapse",
    name: "ประกาศ",
    key: "",
    icon: <Shop size="12px" />,
    noCollapse: true,
    route: "/",
    isAuth: "private",
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "ออกรางวัล",
    key: "award",
    icon: <Office size="12px" />,
    collapse: [
      {
        name: "ออกรางวัล",
        key: "setaward",
        route: "/award/setaward/1",
        isAuth: "private",
      },
    ],
  },

  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "ตั้งค่าบัญชี",
    key: "profile",
    icon: <Office size="12px" />,
    route: "/profile/setting",
    isAuth: "private",
    noCollapse: true,
  },
];

export default pageRoutesSubAdmin;
