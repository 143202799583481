import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
import { Grid } from "@mui/material";
import SuiInput from "components/SuiInput";
import ManageRateAgent from "./components/ManageRateAgent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "./components/Tabpanel";
import managefightPageStyle from "./styles";
import { get } from "utils/helper/";
import { HOST } from "utils/config";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  TextBlack30,
  TextCenter,
  LootoList20,
  LootoList18,
  LootoList16,
  LootoList16span,
  LootoList20_2,
  LootoList20_3,
  LootoList36,
  TabsMargin,
} from "components/Text/TextStyle";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageRate = (props) => {
  const [groupSelect, setgroupSelect] = useState(1);
  const [groupSelectLotto, setgroupSelectLotto] = useState(1);
  const [dataLoto, setDataLoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabvalue, setTapvalue] = useState("max");
  const { lotto } = props;

  const handleChange = (event, newValue) => {
    setTapvalue(newValue);
  };
  const matches = useMediaQuery("(min-width:600px)");
  const setSelectValue = ({ value }) => {
    setgroupSelect(value);
  };
  const setSelectLotto = ({ value }) => {
    setgroupSelectLotto(value?.lotto_id);
    fetchLotto(value?.lotto_id);
  };
  const fetchLotto = async (value) => {
    setLoading(true);

    try {
      const { data } = await get(
        `${HOST}api/UserConfig/GetUserConfigRateForManage?gatcha_id=${groupSelectLotto}&tab=${tabvalue}`
      );
      setDataLoto(data.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const classes = managefightPageStyle();
  useEffect(() => {
    fetchLotto();
  }, [tabvalue, groupSelectLotto]);
  useEffect(() => {
    if (lotto?.isFetchLoto) {
      setgroupSelect(lotto?.lotto[0]?.group_id);

      setSelectLotto({ value: lotto?.lotto[0]?.lotto_list_item[0] });
    }
  }, [lotto]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox p={3} display="flex" justifyContent={"space-between"} backgroundColor={"#FFF"}>
        <Grid container>
          <Grid xs={6}>
            <LootoList20_2> สูงสุด/อัตราจ่าย/ส่วนลด</LootoList20_2>

            {/* <SuiTypography variant="h4" textColor="secondary" fontSize={matches ? 10 : 20}>
              ถือหุ้น/แบ่งสู้
            </SuiTypography> */}
          </Grid>
          <Grid xs={6}>
            {lotto?.isFetchLoto && (
              <SuiBox display="flex">
                <SuiSelect
                  defaultValue={{
                    value: lotto?.lotto[0]?.group_id,
                    label: lotto?.lotto[0]?.group_name,
                  }}
                  options={lotto?.lotto.map((entry) => ({
                    value: entry.group_id,
                    label: entry.group_name,
                  }))}
                  onChange={setSelectValue}
                  size="large"
                  className={classes.selectmenu}
                />

                <SuiBox mr={2} />
                {groupSelect !== null && (
                  <SuiSelect
                    defaultValue={{
                      value: lotto?.lotto[0]?.lotto_list_item[0],
                      label: lotto?.lotto[0]?.lotto_list_item[0]?.lotto_name,
                    }}
                    options={lotto?.lotto
                      .find((e) => e.group_id === groupSelect)
                      ?.lotto_list_item.map((entry) => ({
                        value: entry,
                        label: entry.lotto_name,
                      }))}
                    onChange={setSelectLotto}
                    size="large"
                    className={classes.selectmenu}
                  />
                )}
              </SuiBox>
            )}
          </Grid>
        </Grid>
      </SuiBox>
      <TabsMargin>
        <Tabs value={tabvalue} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="สูงสุด" value={"max"} />
          <Tab label="อัตราจ่าย" value={"reward"} />
          <Tab label="ส่วนลด" value={"discount"} />
        </Tabs>
      </TabsMargin>
      <TabPanel value={tabvalue} index={"max"}>
        {dataLoto?.agent_rate.length > 0 && (
          <ManageRateAgent
            maxrate={dataLoto?.max_rate}
            agentrate={dataLoto?.agent_rate}
            gatchaId={groupSelectLotto}
            gatchaGroups={dataLoto?.gatcha_groups}
            title="เอเย่นต์"
            tabname={tabvalue}
          />
        )}
        {dataLoto?.member_rate.length > 0 && (
          <ManageRateAgent
            maxrate={dataLoto?.max_rate}
            agentrate={dataLoto?.member_rate}
            gatchaId={groupSelectLotto}
            gatchaGroups={dataLoto?.gatcha_groups}
            title="เมมเบอร์"
            tabname={tabvalue}
          />
        )}
      </TabPanel>
      <TabPanel value={tabvalue} index={"reward"}>
        {dataLoto?.agent_rate.length > 0 && (
          <ManageRateAgent
            maxrate={dataLoto?.max_rate}
            agentrate={dataLoto?.agent_rate}
            gatchaId={groupSelectLotto}
            gatchaGroups={dataLoto?.gatcha_groups}
            title="เอเย่นต์"
            tabname={tabvalue}
          />
        )}
        {dataLoto?.member_rate.length > 0 && (
          <ManageRateAgent
            maxrate={dataLoto?.max_rate}
            agentrate={dataLoto?.member_rate}
            gatchaId={groupSelectLotto}
            gatchaGroups={dataLoto?.gatcha_groups}
            title="เมมเบอร์"
            tabname={tabvalue}
          />
        )}
      </TabPanel>
      <TabPanel value={tabvalue} index={"discount"}>
        {dataLoto?.agent_rate.length > 0 && (
          <ManageRateAgent
            maxrate={dataLoto?.max_rate}
            agentrate={dataLoto?.agent_rate}
            gatchaId={groupSelectLotto}
            gatchaGroups={dataLoto?.gatcha_groups}
            title="เอเย่นต์"
            tabname={tabvalue}
          />
        )}
        {dataLoto?.member_rate.length > 0 && (
          <ManageRateAgent
            maxrate={dataLoto?.max_rate}
            agentrate={dataLoto?.member_rate}
            gatchaId={groupSelectLotto}
            gatchaGroups={dataLoto?.gatcha_groups}
            title="เมมเบอร์"
            tabname={tabvalue}
          />
        )}
      </TabPanel>
      {/* {dataLoto?.agent_stock ? (
        <SuiBox>
          <SuiBox display="flex" justifyContent="center" mt={2} backgroundColor="#FFF">
            {groupSelectLotto?.lotto_name}
          </SuiBox>
          {dataLoto.agent_stock.length > 0 && (
            <ManageAgent dataLoto={dataLoto} gatchaId={groupSelect} />
          )}

          {dataLoto.member_fighting.length > 0 && (
            <ManageMember dataLoto={dataLoto} gatchaId={groupSelect} />
          )}
        </SuiBox>
      ) : (
        <SuiBox
          display="flex"
          justifyContent={"center"}
          alignItems="center"
          height="50vh"
          fontSize={40}
        >
          โปรดเลือกประเภทหวย
        </SuiBox>
      )} */}
      <Footer />
    </DashboardLayout>
  );
};

ManageRate.propTypes = {
  lotto: PropTypes.object,
};

const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageRate);
