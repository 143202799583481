import { logout } from "utils/auth";

// import SocketIOManager from '@utils/SocketIOManager'

export const initialState = {
  lotto: null,
  isFetchLoto: false,
  isLoadding: true,
  errorMessage: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "Lotto/Loading":
      return { ...state, isLoadding: action.isLoading };
    case "Lotto/Success":
      return {
        ...state,
        lotto: action.lotto,
        isLoadding: false,
        isFetchLoto: true,
        errorMessage: "",
      };
    case "Lotto/Failure":
      return {
        lotto: null,
        isFetchLoto: false,
        isLoadding: false,
        errorMessage: action.message,
      };

    default:
      return state;
  }
};

export default reducer;
