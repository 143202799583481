import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { HOST } from "./config";
import { get } from "./helper";

export const logout = () => {
  localStorage.setItem("access_token", "");
  localStorage.setItem("expires_in", "");
  localStorage.setItem("refresh_token", "");
  localStorage.setItem("is_auth_token", "");
  localStorage.removeItem("recent_search");
  window.location.assign("/sign-in");
};
