import Axios from "axios";

export const get = (url, withAuth = true) => {
  const headers = withAuth
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        timeout: 30000,
      }
    : {
        headers: { language: localStorage.getItem("local") || 1 },
        timeout: 30000,
      };

  return Axios.get(url, headers);
};

export const post = (url, data = new FormData(), withAuth = true) => {
  if (typeof data === typeof FormData) {
    data.append("_method", "post");
  }

  const headers = withAuth
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        timeout: 120000,
      }
    : {
        headers: {},
        timeout: 120000,
      };
  return Axios.post(url, data, headers);
};

export const posts = (url, data, withAuth = false) => {
  const headers = withAuth
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        timeout: 120000,
      }
    : {
        headers: {},
        timeout: 120000,
      };
  return Axios.post(url, data, headers);
};

export const put = (url, data = new FormData(), withAuth = true, languageID = 1) => {
  data.append("_method", "put");
  const headers = withAuth
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        timeout: 30000,
      }
    : {
        // headers: { language: localStorage.getItem('local') || 1 },
        timeout: 30000,
      };
  return Axios.post(url, data, headers);
};

export const del = (url, data = new FormData(), withAuth = true, languageID = 1) => {
  data.append("_method", "delete");
  const headers = withAuth
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          language: localStorage.getItem("local") || 1,
        },
        timeout: 30000,
      }
    : {
        // headers: { language: localStorage.getItem('local') || 1 },
        timeout: 30000,
      };
  return Axios.post(url, data, headers);
};

export const delV4 = (url, withAuth = true, languageID = 1) => {
  const headers = withAuth
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        timeout: 30000,
      }
    : {
        // headers: { language: localStorage.getItem('local') || 1 },
        timeout: 30000,
      };
  return Axios.delete(url, headers);
};

export const patch = (url, payload, withAuth = true, languageID = 1) => {
  const headers = withAuth
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        timeout: 30000,
      }
    : {
        // headers: { language: localStorage.getItem('local') || 1 },
        timeout: 30000,
      };
  return Axios.patch(url, payload, headers);
};
