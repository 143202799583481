import { authenticateAction } from "redux/authentication/action";
import { useDispatch, connect } from "react-redux";
import react, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import { useHistory } from "react-router";
import { Button } from "@mui/material";
// react-router-dom components
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// @mui material components
import Switch from "@mui/material/Switch";
import Swal from "sweetalert2";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { getLotto } from "redux/lotto/action";
// Authentication layout components
import CoverLayout from "memberpage/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";

function Cover({ authenticateAction, ...rest }) {
  const { authentication } = rest;

  const history = useHistory();
  const [rememberMe, setRememberMe] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useDispatch();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const validate = (values) => {
    const errors = {};

    if (values.username === "") {
      errors.username = "Username is required";
    }
    if (values.password === "") {
      errors.password = "password is required";
    }

    return errors;
  };
  const handleSignin = async (event) => {
    setIsloading(true);
    try {
      const { username, password, rememberMe } = event;

      await authenticateAction({ username, password });
      await dispatch(getLotto());
      history.push("/");
      setIsloading(false);
    } catch (error) {
      console.log(error);
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: "Something went wrong! signIn",
      // });
      setIsloading(false);
    }
  };
  useEffect(() => {
    if (authentication.isAuthenticated) {
      history.push("/");
    }
  }, [authentication.isAuthenticated]);
  return (
    <CoverLayout title="AG NanaLotto" description="ยินดีต้อนรับสู่ AG NanaLotto" image={curved9}>
      <Formik
        initialValues={{
          username: "",
          password: "",
          // rememberMe: false,
        }}
        validate={(values) => validate(values)}
        onSubmit={handleSignin}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <SuiBox>
              <SuiBox mb={2} lineHeight={1.25}>
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Username
                  </SuiTypography>
                </SuiBox>
                <SuiInput
                  type="text"
                  placeholder="Username"
                  name={"username"}
                  value={formProps.values.username}
                  error={formProps?.errors?.username}
                  success={formProps?.values.username?.length > 0 && !formProps?.errors?.username}
                  onChange={formProps.handleChange}
                />
              </SuiBox>
              <SuiBox mb={2} lineHeight={1.25}>
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Password
                  </SuiTypography>
                </SuiBox>
                <SuiInput
                  type="password"
                  placeholder="Password"
                  name={"password"}
                  value={formProps?.values.password}
                  error={formProps?.errors?.password}
                  success={formProps?.values.password?.length > 0 && !formProps?.errors?.password}
                  onChange={formProps.handleChange}
                />
              </SuiBox>
              <SuiBox display="flex" alignItems="center">
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <SuiTypography
                  variant="button"
                  fontWeight="regular"
                  onClick={handleSetRememberMe}
                  customClass="cursor-pointer user-select-none"
                >
                  &nbsp;&nbsp;Remember me
                </SuiTypography>
              </SuiBox>
              <SuiBox mt={4} mb={1}>
                <SuiButton
                  disabled={isLoading}
                  variant="gradient"
                  buttonColor="info"
                  fullWidth
                  type="submit"
                >
                  เข้าสู่ระบบ
                </SuiButton>
              </SuiBox>
              <SuiBox mt={3} textAlign="center">
                <SuiTypography variant="button" textColor="text" fontWeight="regular">
                  Don&apos;t have an account? Please Contact Admin
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </form>
        )}
      </Formik>
    </CoverLayout>
  );
}

const mapDispatchToProps = {
  authenticateAction,
};

const mapStateToProps = (state) => {
  return state;
};
Cover.propTypes = {
  authenticateAction: PropTypes.func.isRequired,
};
// CONNECT REDUX
export default connect(mapStateToProps, mapDispatchToProps)(Cover);
// export default Cover;
