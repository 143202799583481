import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
const AuthRoute = (props) => {
  const { authentication, isAuth } = props;
  if (isAuth === "guest" && authentication.isAuthenticated && localStorage.getItem("access_token"))
    return <Redirect to="/" />;
  else if (
    // isAuth === "private" &&
    !authentication.isAuthenticated &&
    !localStorage.getItem("access_token")
  )
    return <Redirect to="/sign-in" />;
  else if (
    isAuth === "MEMBER" &&
    authentication?.user?.role_id !== "MEMBER" &&
    authentication.isAuthenticated &&
    localStorage.getItem("access_token")
  )
    return <Redirect to="/" />;
  else if (
    isAuth === "AGENT" &&
    authentication?.user?.role_id === "MEMBER" &&
    localStorage.getItem("access_token")
  )
    return <Redirect to="/" />;
  else if (
    isAuth === "ADMIN" &&
    authentication?.user?.role_id !== "COMPANY" &&
    authentication.isAuthenticated &&
    localStorage.getItem("access_token")
  )
    return <Redirect to="/" />;
  else if (
    isAuth === "SUBADMIN" &&
    authentication?.user?.role_id === "MEMBER" &&
    authentication?.user?.role_id === "AGENT" &&
    authentication.isAuthenticated &&
    localStorage.getItem("access_token")
  )
    return <Redirect to="/" />;

  return <Route {...props} />;
};

const mapStateToProps = ({ authentication }) => ({
  authentication,
});
AuthRoute.propTypes = {
  authentication: PropTypes.object,
  isAuth: PropTypes.string,
};
export default connect(mapStateToProps)(AuthRoute);
