import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import PropTypes from "prop-types";

import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";

import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";

import Style from "../style.js";
import "../index.scss";
import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import Swal from "sweetalert2";

const useStyle = makeStyles(Style);

function RunCard({ formData, onsetTotaldata }) {
  const [inputList, setInputList] = useState([{ gatchaNumber: "", numberUp: "", numberDown: "" }]);

  const options = [
    { value: "", label: "นำออก" },
    { value: "0", label: 0 },
    { value: "1", label: 1 },
    { value: "2", label: 2 },
    { value: "3", label: 3 },
    { value: "4", label: 4 },
    { value: "5", label: 5 },
    { value: "6", label: 6 },
    { value: "7", label: 7 },
    { value: "8", label: 8 },
    { value: "9", label: 9 },
  ];

  const handleInputChange = (e, index) => {
    const { name, value } = e.target ? e.target : e;
    // console.log("value = ",value)
    if (name) {
      formData.setFieldValue(`gatchaRunNumber[${index}].${name}`, value);
    } else {
      if (value.length > 0 && !formData.values.gatchaRunNumber[index].checktext) {
        formData.setFieldValue(`gatchaRunNumber`, [
          ...formData.values.gatchaRunNumber,
          { gatchaNumber: "", numberUp: "", numberDown: "", checktext: false },
        ]);
        formData.setFieldValue(`gatchaRunNumber[${index}].checktext`, true);
      }
      formData.setFieldValue(`gatchaRunNumber[${index}].gatchaNumber`, value);
    }
  };

  const handleBlurChange = (e, index) => {
    const { name, value } = e.target ? e.target : e;

    if (!value) {
      let list = [...formData.values.gatchaRunNumber];
      list.pop();
      formData.setFieldValue(`gatchaRunNumber`, list);
      formData.setFieldValue(`gatchaRunNumber[${index}].checktext`, false);
    }
  };

  const handleRemoveClick = (index) => {
    let list = [...formData.values.gatchaRunNumber];
    list.splice(index, 1);
    formData.setFieldValue(`gatchaRunNumber`, list);
    // setInputList(list);
  };

  const onChangeSelectOption = (item) => {
    //number, up, down, multi)
    setInputList([...inputList, { gatchaNumber: "", numberUp: "", numberDown: "" }]);
    // console.log("item555 = ",item)
  };

  const handleblurTotalchange = () => {
    var num = 0;
    formData.values.gatchaRunNumber.map((e, i) => {
      num =
        num +
        parseInt(e.numberUp || 0) +
        parseInt(e.numberDown || 0) +
        parseInt(e.numberMulti || 0);
    });
    onsetTotaldata(num, "run");
  };

  const copyNumber = async (valuesCopy) => {
    // formData?.values?.gatchaRunNumber.pop();

    const { numberUp, numberDown, numberMulti } = formData?.values?.gatchaRunNumber[0];

    if (numberUp < 1 && numberDown < 1 && numberMulti < 1) {
      Swal.fire("ผิดพลาด!", "กรุณาใส่ราคา", "error");
    } else {
      let copyarr = formData.values.gatchaRunNumber;
      if (valuesCopy === "numberUp") {
        if (numberUp < 1) {
          Swal.fire("ผิดพลาด!", "กรุณาใส่ราคา", "error");
        } else {
          formData?.values?.gatchaRunNumber.length > 1
            ? formData?.values?.gatchaRunNumber.pop()
            : null;
          await copyarr.map((e, i) => {
            copyarr[i].numberUp = numberUp;

            formData.setFieldValue(`gatchaRunNumber`, [
              ...copyarr,
              {
                gatchaNumber: "",
                numberUp: "",
                numberDown: "",
                numberMulti: "",
                checktext: false,
              },
            ]);
            handleblurTotalchange();
          });
        }
      } else if (valuesCopy === "numberDown") {
        if (numberDown < 1) {
          Swal.fire("ผิดพลาด!", "กรุณาใส่ราคา", "error");
        } else {
          formData?.values?.gatchaRunNumber.length > 1
            ? formData?.values?.gatchaRunNumber.pop()
            : null;
          await copyarr.map((e, i) => {
            copyarr[i].numberDown = numberDown;

            formData.setFieldValue(`gatchaRunNumber`, [
              ...copyarr,
              {
                gatchaNumber: "",
                numberUp: "",
                numberDown: "",
                numberMulti: "",
                checktext: false,
              },
            ]);
            handleblurTotalchange();
          });
        }
      } else if (valuesCopy === "numberMulti") {
        if (numberMulti < 1) {
          Swal.fire("ผิดพลาด!", "กรุณาใส่ราคา", "error");
        } else {
          formData?.values?.gatchaRunNumber.length > 1
            ? formData?.values?.gatchaRunNumber.pop()
            : null;
          await copyarr.map((e, i) => {
            copyarr[i].numberMulti = numberMulti;

            formData.setFieldValue(`gatchaRunNumber`, [
              ...copyarr,
              {
                gatchaNumber: "",
                numberUp: "",
                numberDown: "",
                numberMulti: "",
                checktext: false,
              },
            ]);
            handleblurTotalchange();
          });
        }
      }
    }
  };

  useEffect(() => {
    // console.log("inputList = ",inputList)
    // console.log("formData?.values?.gatchaRunNumber = ",formData.values.gatchaRunNumber)
  });

  return (
    <>
      <table className="card-table">
        <thead className="head1">
          <td>หมายเลข</td>
          <td>วิ่งตัวบน</td>
          <td>วิ่งตัวล่าง</td>
        </thead>
        <thead className="head2">
          <td />
          <td>
            <div className="type-button" onClick={() => copyNumber("numberUp")}>
              คัดลอก
            </div>
          </td>
          <td>
            <div className="type-button" onClick={() => copyNumber("numberDown")}>
              คัดลอก
            </div>
          </td>
        </thead>
        <tbody className="body1">
          {formData?.values?.gatchaRunNumber.map((x, i) => {
            return (
              <tr key={i}>
                <td>
                  <div className="table-flex">
                    <SuiSelect
                      placeholder="0"
                      options={options}
                      onChange={(e) => {
                        handleInputChange(e, i);
                      }}
                      // onBlur={(e) => {
                      //     handleBlurChange(e,i)
                      // }}
                      name={"gatchaNumber"}
                      value={{ value: x.gatchaNumber, label: x.gatchaNumber }}
                    />
                  </div>
                </td>
                <td>
                  <div className="table-flex-run">
                    <SuiInput
                      type="text"
                      placeholder="0"
                      name={"numberUp"}
                      onChange={(e) => {
                        handleInputChange(e, i);
                        formData.setFieldValue("numberUp", e.target);
                      }}
                      value={x.numberUp} //formData.values.twoThreeNumberUp
                      onBlur={(e) => handleblurTotalchange(e, i)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <p className="td-text">บาท</p>
                  </div>
                </td>
                <td>
                  <div className="table-flex-run">
                    <SuiInput
                      type="text"
                      placeholder="0"
                      name={"numberDown"}
                      onChange={(e) => {
                        handleInputChange(e, i);
                        formData.setFieldValue("numberDown", e.target);
                      }}
                      value={x.numberDown} //formData.values.twoThreeNumberDown
                      onBlur={(e) => handleblurTotalchange(e, i)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <p className="td-text">บาท</p>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

RunCard.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onsetTotaldata: PropTypes.func,
};

export default RunCard;
