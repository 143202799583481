import React from "react";
import { Box } from "@mui/material";
export default function Lottothai() {
  return (
    <Box width={"100%"}>
      <table style={{ fontSize: "16px" }}>
        <thead>
          <tr>
            <th>เงื่อนไขการเขียนบิล</th>
            <th>ความหมาย</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <b>2 ตัว</b>
          </tr>
          <tr>
            <td>12 100*100</td>
            <td>ซื้อ 12 บน ล่าง 100x100</td>
          </tr>
          <tr>
            <td>21 100*100</td>
            <td>ซื้อ 21 บน บาท ล่าง 100x100</td>
          </tr>
          <tr>
            <td>12 100*0</td>
            <td>ซื้อ 12 บน 100 บาท ไม่ซื้อล่าง</td>
          </tr>
          <tr>
            <td>23 0*100</td>
            <td>ซื้อ 23 ล่าง 100 บาท ไม่ซื้อบน</td>
          </tr>
          <tr>
            <b>3 ตัว</b>
          </tr>
          <tr>
            <td>456 100*100</td>
            <td>ซื้อ 456 บน ล่าง 100x100</td>
          </tr>
          <tr>
            <td>456 100*0</td>
            <td>ซื้อ 456 บน100 บาท ไม่ซื้อล่าง</td>
          </tr>
          <tr>
            <td>456 100*100*100</td>
            <td>ซื้อ 456 100x100x100</td>
          </tr>
          <tr>
            <td>456 0*0*100</td>
            <td>ซื้อ 456 โต๊ด 100 บาท</td>
          </tr>
          <tr>
            <b>วิ่ง</b>
          </tr>
          <tr>
            <td>8 100*100</td>
            <td>วิ่ง 8 บน ล่าง 100x100</td>
          </tr>
          <tr>
            <td>8 100*0</td>
            <td>วิ่งบน 8 100 บาท</td>
          </tr>
          <tr>
            <td>8 0*100</td>
            <td>วิ่งล่าง 8 100 บาท</td>
          </tr>
          <tr>
            <b>หกกลับ/6 ประตู</b>
          </tr>
          <tr>
            <td>123# 100*100</td>
            <td>ซื้อเลขแบบ หกกลับ/6ประตู บน ล่าง 100x100</td>
          </tr>
          <tr>
            <td>123# 100*100*100</td>
            <td>ซื้อเลข 123 หกกลับ/6ประตู บน ล่าง โต๊ด 100x100x100</td>
          </tr>
          <tr>
            <b>19 ประตู</b>
          </tr>
          <tr>
            <td>1% 100*100</td>
            <td>ซื้อ 1 แบบ 19 ประตู บน ล่าง 100x100</td>
          </tr>
          <tr>
            <td>%1 100*100</td>
            <td>ซื้อ 1 แบบ 19 ประตู บน ล่าง 100x100</td>
          </tr>
          <tr>
            <b>รูด</b>
          </tr>
          <tr>
            <td>^3 100*100</td>
            <td>ซื้อ 3 รูดหน้า บน ล่าง 100x100</td>
          </tr>
          <tr>
            <td>4^ 100*100</td>
            <td>ซื้อ 4 รูดหลัง บน ล่าง 100x100</td>
          </tr>
          <tr>
            <td>^23 100*100</td>
            <td>ซื้อ 23 รูดหลักแรก บน ล่าง 100x100</td>
          </tr>
          <tr>
            <td>2^3 100*100</td>
            <td>ซื้อ 23 รูดหลักสอง บน ล่าง 100x100</td>
          </tr>
          <tr>
            <td>23^ 100*100</td>
            <td>ซื้อ 23 รูดหลักสาม บน ล่าง 100x100</td>
          </tr>
          <tr>
            <td>23^ 100*100*100</td>
            <td>ซื้อ 23 รูดหลักสาม บน ล่าง โต๊ด 100x100x100</td>
          </tr>
          <tr>
            <td>23^ 100*0</td>
            <td>ซื้อ 23 รูดหลักสาม บน 100 บาท</td>
          </tr>
          <tr>
            <td>23^ 0*100</td>
            <td>ซื้อ 23 รูดหลักสาม ล่าง 100 บาท</td>
          </tr>
          <tr>
            <td>23^ 0*0*100</td>
            <td>ซื้อ 23 รูดหลักสาม โต๊ด 100 บาท</td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
}
