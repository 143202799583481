import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SuiBox from "components/SuiBox";
import Table from "components/Tables/Table";
import DataTable from "components/Tables/DataTable";
import { Switch, Grid } from "@mui/material";
import { Text16, TextBlack24 } from "components/Text/TextStyle";
import { get, post } from "utils/helper";
import { HOST } from "utils/config";
import SuiSelect from "components/SuiSelect";

import SuiButton from "components/SuiButton";
import { Select, MenuItem, FormControl } from "@mui/material";
import Swal from "sweetalert2";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";

import { LootoList20, LootoList18, LootoList16, LootoList16span } from "components/Text/TextStyle";

function fightMax(max, min) {
  let arrStock = [];
  let numberSelectedStock = 0;
  arrStock.push({ value: numberSelectedStock, label: `${numberSelectedStock}%` });
  let maxNumber = max * 2;
  for (let i = min * 2; i < maxNumber; i++) {
    numberSelectedStock = numberSelectedStock + 0.5;
    arrStock.push({ value: numberSelectedStock, label: `${numberSelectedStock}%` });
  }
  return arrStock;
}
const ManageRateAgent = ({
  dataLoto,
  gatchaId,
  maxrate,
  agentrate,
  title,
  gatchaGroups,
  tabname,
}) => {
  const [dataAgent, setDataAgent] = useState(agentrate);
  const [dataAgentCopy, setDataAgentCopy] = useState(maxrate);
  const [isLoading, setIsLoading] = useState(false);
  const [checkswitch, setCheckswitch] = useState(false);
  const [stockcopy, setStockcopy] = useState(0);

  useEffect(() => {
    setDataAgent(agentrate);
    setDataAgentCopy(maxrate);
  }, [agentrate]);
  const onCopyLotto = async (groupId) => {
    setIsLoading(true);

    try {
      let payload = { type: "AGENT", gatcha_id: gatchaId.toString(), group_id: groupId };
      const { data } = await post(`${HOST}api/UserConfig/CloneUserStockToGroupGatcha`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
      console.log(error);
    }
  };
  const onSubmitLotto = async () => {
    setIsLoading(true);

    try {
      let payload = {
        tab: tabname,
        config_rates: dataAgent.map((e, i) => ({
          role_id: e.role_id,
          user_id: e.user_id,
          gatcha_id: gatchaId.toString(),
          value_rate: e.value_rate.map((elem, index) => ({
            config_rates_id: elem.config_rates_id,
            value: parseInt(elem.value),
          })),
        })),
      };
      const { data } = await post(`${HOST}api/UserConfig/SetUserConfigRate`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
      console.log(error);
    }
  };
  const columns = [
    { name: "role", align: "center", label: "ระดับชั้น" },
    { name: "user_name", align: "center", label: "ยูเซอร์เนม" },
    { name: "name", align: "center", label: "ติดต่อ	" },
    {
      name: "value_rate",
      align: "center",
      label: (
        <SuiBox display="flex" justifyContent="space-between">
          {agentrate.length > 0 &&
            agentrate[0]?.value_rate?.map((e, i) => <SuiBox key={i}>{e.gatcha_type_id}</SuiBox>)}
        </SuiBox>
      ),
    },
  ];

  const onCopyLottoMax = (value, index, i) => {
    let data = dataAgentCopy;
    data[index].valueCopy = value;

    setDataAgentCopy([...data]);
  };
  const onCopyAll = (indexType, value, max) => {
    try {
      let data = dataAgent;
      if (value > max) {
        data.map((e, i) => {
          data[i].value_rate.map((elem, index) => {
            data[i].value_rate[indexType].value = max;
          });
        });
      } else {
        data.map((e, i) => {
          data[i].value_rate.map((elem, index) => {
            data[i].value_rate[indexType].value = value;
          });
        });
      }
      setDataAgent([...data]);
    } catch (error) {
      console.log(error);
    }
  };
  const onLottoMax = (value, index, i, gatchaTypeCode) => {
    let data = dataAgent;
    let checkmax = dataAgentCopy.find((e) => e.gatcha_type_code === gatchaTypeCode);
    if (checkmax.value < value) {
      data[index].value_rate[i].value = checkmax.value;
    } else {
      data[index].value_rate[i].value = value;
    }
    setDataAgent([...data]);
  };

  return (
    <SuiBox p={2} backgroundColor={"#FFF"}>
      <LootoList18>จัดการ {title}</LootoList18>

      <SuiBox mb={4} display="flex" justifyContent={"space-between"}>
        <SuiBox>
          <LootoList16span>ตั้งค่ากลุ่มหวยตามหวย:</LootoList16span>
          {gatchaGroups.map((e, i) => (
            <SuiButton
              disabled={isLoading}
              buttonColor={"info"}
              key={i}
              sx={{ marginRight: 2 }}
              onClick={() => onCopyLotto(e.group_id)}
            >
              {e.group_name}
            </SuiButton>
          ))}
        </SuiBox>
        <SuiBox>
          <SuiButton buttonColor="success" onClick={() => onSubmitLotto()}>
            บันทึกทั้งหมด
          </SuiButton>
        </SuiBox>
      </SuiBox>

      <Table
        maincopy={[
          { name: "role", align: "center", children: <></> },
          { name: "user_name", align: "center", children: <></> },
          { name: "name", align: "center", children: <></> },
          {
            name: "value_rate",
            align: "center",
            children: (
              <SuiBox display="flex" justifyContent="space-between" width="100%">
                {dataAgentCopy.length > 0 &&
                  dataAgentCopy.map((e, i) => (
                    <SuiBox key={i} mr={1}>
                      <SuiButton
                        buttonColor="info"
                        sx={{ mb: 2 }}
                        onClick={() => onCopyAll(i, e.valueCopy, e.value)}
                      >
                        Copy
                      </SuiButton>
                      <SuiInput
                        name={e.gatcha_type_code}
                        value={e.valueCopy || ""}
                        onChange={(e) => {
                          onCopyLottoMax(e.target.value, i);
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <SuiTypography variant="caption" textColor="error">
                        สูงสุด {e.value}
                      </SuiTypography>
                    </SuiBox>
                  ))}
              </SuiBox>
            ),
          },
        ]}
        columns={columns}
        rows={dataAgent?.map((elem, index) => ({
          role: elem.role,
          user_name: elem.user_name,
          name: elem.name,
          value_rate: (
            <SuiBox width="100%" display="flex">
              {elem.value_rate.map((e, i) => (
                <SuiBox key={i} mr={1}>
                  <SuiInput
                    value={e.value}
                    onChange={(event) => {
                      onLottoMax(event.target.value, index, i, e.gatcha_type_code);
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </SuiBox>
              ))}
            </SuiBox>
          ),
        }))}
      />
    </SuiBox>
  );
};

ManageRateAgent.propTypes = {
  dataLoto: PropTypes.object,
  gatchaId: PropTypes.string,
  maxrate: PropTypes.array,
  agentrate: PropTypes.array,
  title: PropTypes.string,
  gatchaGroups: PropTypes.array,
  tabname: PropTypes.string,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageRateAgent);
