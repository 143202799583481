import { logout } from "utils/auth";

// import SocketIOManager from '@utils/SocketIOManager'

export const initialState = {
  user: null,
  isAuthenticated: false,
  isLoadding: true,
  errorMessage: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "Auth/Loading":
      return { ...state, isLoadding: action.isLoading };
    case "Auth/Success":
      return {
        ...state,
        user: action.user,
        isLoadding: false,
        isAuthenticated: true,
        errorMessage: "",
      };
    case "Auth/Failure":
      logout();
      return {
        user: null,
        isAuthenticated: false,
        isLoadding: false,
        errorMessage: action.message,
      };
    case "Auth/UpdateProfile":
      return {
        ...state,
        user: action.user,
      };
    case "Auth/Logout":
      logout();
      // if (process.env.NODE_ENV == 'production') {
      //   const socket = SocketIOManager.getInstance()
      //   socket.disconnect()
      // }
      return initialState;
    default:
      return state;
  }
};

export default reducer;
