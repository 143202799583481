import { useState, useEffect } from "react";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import SuiBadgeDot from "components/SuiBadgeDot";
import Swal from "sweetalert2";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Switch } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";
// Reports page components
import Reviews from "memberpage/pages/users/reports/components/Reviews";
import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import FormField from "components/FormField";

import {
  LootoList20,
  LootoList18,
  LootoList18_2,
  LootoList16,
  LootoList16span,
  LootoList12,
  LootoList14,
} from "components/Text/TextStyle";

// Data
import { Formik, Form, FieldArray, FastField } from "formik";
function DialogEditgroup({ open, onClose, onSuccess, dataUpdate }) {
  const onhandleSubmit = async (event) => {
    const payload = new FormData();
    payload.append("name", event.name);
    payload.append("status", event.status);
    payload.append("id", dataUpdate.id);
    try {
      const { data } = await post(`${HOST}api/MasterLotto/UpdateGroup`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success").then((result) => {
        onSuccess();
        onClose();
      });
    } catch (error) {
      Swal.fire("เกิดข้อผิดพลาด", error.response.data.errorMessage, "error");
      console.log(error);
    }
  };
  function validateAmount(value) {
    let error;

    if (!value) {
      error = "โปรดกรอกชื่อกลุ่มหวย";
    }

    return error;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
      <DialogTitle>แก้ไขกลุ่มหวย</DialogTitle>

      <Formik
        initialValues={{
          name: dataUpdate.name,
          status: true,
        }}
        // validationSchema={}
        onSubmit={onhandleSubmit}
      >
        {(formProps) => (
          <Form autoComplete="off">
            <DialogContent>
              <SuiBox>
                <SuiBox>
                  <SuiTypography variant="caption">ชื่อกลุ่มหวย</SuiTypography>
                  <FormField
                    type="text"
                    placeholder="โปรดกรอกชื่อกลุ่มหวย"
                    name={`name`}
                    onChange={formProps.handleChange}
                    value={formProps.values.name}
                    validate={(e) => validateAmount(e)}
                  />

                  <SuiBox>
                    <SuiTypography variant="caption">สถานะ</SuiTypography>
                  </SuiBox>
                  <SuiBox>
                    <SuiTypography variant="BUTTON">
                      <LootoList16span>{formProps.values.status ? "เปิด" : "ปิด"} </LootoList16span>
                    </SuiTypography>
                    <Switch
                      checked={formProps.values.status}
                      onChange={() => formProps.setFieldValue(`status`, !formProps.values.status)}
                    />
                  </SuiBox>

                  <Divider />
                </SuiBox>
              </SuiBox>
            </DialogContent>
            <DialogActions>
              <SuiButton buttonColor="error" onClick={onClose}>
                ยกเลิก
              </SuiButton>
              <SuiButton type="submit" buttonColor="success">
                แก้ไขกลุ่มหวย
              </SuiButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
DialogEditgroup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  dataUpdate: PropTypes.object,
};
export default DialogEditgroup;
