import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
import { Grid } from "@mui/material";

import managefightPageStyle from "./styles";
import { get, post } from "utils/helper/";
import { HOST } from "utils/config";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TextBlack30, TextCenter } from "components/Text/TextStyle";
import ManageUser from "./components/ManageUser";
import ManageAction from "./components/ManageAction";
const ManageOpen = (props) => {
  const [dataLoto, setDataLoto] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [thead, setThead] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [loading, setLoading] = useState(false);
  const { lotto } = props;

  const fetchUser = async (value) => {
    setLoading(true);

    try {
      const { data } = await get(`${HOST}api/UserConfig/GetUserDownLineMarketStatus`);
      let adddata = data.results.map((e, i) => (e.check = true));

      setDataLoto(data.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const fetchUserAll = async (value) => {
    setLoading(true);

    try {
      const payload = { page: 1, per_page: 9999 };
      const { data } = await post(`${HOST}api/user/GetUserTeamList`, payload);
      let adddata = data.results.map((e, i) => (e.check = true));

      setDataUser(data.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchHeadTable = async (value) => {
    setLoading(true);

    try {
      const { data } = await get(`${HOST}api/UserConfig/GetUserMarket`);
      setThead(data.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const fetchMarket = async (value) => {
    setLoading(true);

    try {
      const { data } = await get(`${HOST}api/UserConfig/GetMarket`);
      const adddata = data.results.map((e, i) =>
        e.gatcha_list.map((el, index) => (el.check = true))
      );
      console.log(adddata);
      setMarkets(data.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const classes = managefightPageStyle();
  useEffect(() => {
    fetchUser();
    fetchHeadTable();
    fetchMarket();
    fetchUserAll();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox p={3} display="flex" justifyContent={"space-between"} backgroundColor={"#FFF"}>
        <TextBlack30>จัดการ เปิด/ปิด ตลาด</TextBlack30>
      </SuiBox>
      {/* {loading} */}
      {dataLoto?.length > 0 && thead?.length > 0 && (
        <ManageUser dataheader={thead} dataLoto={dataLoto} />
      )}

      {dataUser?.length > 0 && thead?.length > 0 && markets?.length > 0 && (
        <ManageAction dataheader={thead} dataLoto={dataUser} markets={markets} />
      )}
      <Footer />
    </DashboardLayout>
  );
};

ManageOpen.propTypes = {
  lotto: PropTypes.object,
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageOpen);
