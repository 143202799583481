import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Divider, Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import DataTable from "components/Tables/DataTable";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogNumber({ open, onClose, dataTypeDetail, report }) {
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={false}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        ยอดตามหมายเลข
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          {dataTypeDetail?.map((e, i) => (
            <Grid item lg={3} key={i}>
              <SuiBox display="flex" justifyContent="center" backgroundColor="warning">
                <SuiTypography>{e.gatcha_type_name}</SuiTypography>
              </SuiBox>
              <SuiBox border="1px solid #f1f1f1" padding={2}>
                <Grid container justifyContent="center" style={{ fontSize: 16 }}>
                  <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                    <SuiBox display="flex" justifyContent="center" fontWeight="bold">
                      เดิมพัน
                    </SuiBox>
                  </Grid>
                  <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                    <SuiBox display="flex" justifyContent="center" fontWeight="bold">
                      {e.total_receive}
                    </SuiBox>
                  </Grid>

                  <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                    <SuiBox display="flex" justifyContent="center" fontWeight="bold">
                      ส่วนลด
                    </SuiBox>
                  </Grid>
                  <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                    <SuiBox display="flex" justifyContent="center" fontWeight="bold" color="error">
                      {e.total_discount}
                    </SuiBox>
                  </Grid>
                  {!report ? (
                    <>
                      <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                        <SuiBox display="flex" justifyContent="center" fontWeight="bold">
                          รายรับ
                        </SuiBox>
                      </Grid>
                      <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                        <SuiBox
                          display="flex"
                          justifyContent="flex-end"
                          fontWeight="bold"
                          color="success"
                        >
                          {e.total_receive_discount}
                        </SuiBox>
                      </Grid>
                      <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                        <SuiBox display="flex" justifyContent="center" fontWeight="bold">
                          จ่ายสูงสุด
                        </SuiBox>
                      </Grid>
                      <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                        <SuiBox
                          display="flex"
                          justifyContent="center"
                          fontWeight="bold"
                          color="error"
                        >
                          {e.total_max_reward}
                        </SuiBox>
                      </Grid>
                      <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                        <SuiBox display="flex" justifyContent="center" fontWeight="bold">
                          จำนวนตั้งอั้น
                        </SuiBox>
                      </Grid>
                      <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                        <SuiBox display="flex" justifyContent="center" fontWeight="bold">
                          {e.total_limit}
                        </SuiBox>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                        <SuiBox display="flex" justifyContent="center" fontWeight="bold">
                          ถูกรางวัล
                        </SuiBox>
                      </Grid>
                      <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                        <SuiBox
                          display="flex"
                          justifyContent="center"
                          fontWeight="bold"
                          color="success"
                        >
                          {e.total_reward}
                        </SuiBox>
                      </Grid>
                      <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                        <SuiBox display="flex" justifyContent="center" fontWeight="bold">
                          สรุป
                        </SuiBox>
                      </Grid>
                      <Grid item xs={6} style={{ border: "1px solid #f1f1f1" }}>
                        <SuiBox
                          display="flex"
                          justifyContent="center"
                          fontWeight="bold"
                          color="success"
                        >
                          {e.total_summary}
                        </SuiBox>
                      </Grid>
                    </>
                  )}
                </Grid>

                <DataTable
                  entriesPerPage={{ defaultValue: 5, entries: [5, 10, 15, 20, 25] }}
                  table={{
                    columns: [
                      { Header: "เลข", accessor: "gatcha_number" },
                      { Header: "เดิมพัน", accessor: "total_receive" },
                    ],
                    rows: e.buy_number_list,
                  }}
                />
              </SuiBox>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <SuiButton
          autoFocus
          variant="gradient"
          buttonColor={"error"}
          size="small"
          circular
          onClick={onClose}
        >
          ปิด
        </SuiButton>
      </DialogActions>
    </BootstrapDialog>
  );
}
DialogNumber.defaultProps = {
  report: false,
};

DialogNumber.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dataTypeDetail: PropTypes.array,
  report: PropTypes.bool,
};
