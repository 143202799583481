import * as React from 'react';
import { createStyles } from '@mui/styles';

const Style = createStyles({
    root: {
      background: '#fff',
      margin: '1rem',
      boxShadow: '0px 0px 5px 2px #dadada',
      textAlign: 'center',
      padding: '2rem 1rem',
      "&:active": {
        background: '#CEE9F6'
      }
    },
    type: {
        margin: '1rem',
        textAlign: 'center',
        fontSize: '1rem',
        padding: '0.5rem 0',
        "&:active": {
          background: '#CEE9F6'
        }
      },
  });

  export default Style