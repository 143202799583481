/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import { useHistory } from "react-router";
import SuiBadgeDot from "components/SuiBadgeDot";
import SuiTypography from "components/SuiTypography";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Skeleton } from "@mui/material";
import { useParams } from "react-router";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";
// Reports page components
import Reviews from "memberpage/pages/users/reports/components/Reviews";
import SuiButton from "components/SuiButton";
// Data

const columns = [
  { name: "number", align: "center", label: "ลำดับ" },
  { name: "images", align: "center", label: "รูปภาพ" },
  { name: "name", align: "center", label: "ชื่อกลุ่มหวย" },
  { name: "status", align: "center", label: "สถานะ" },
  { name: "createdate", align: "center", label: "วันที่สร้าง" },
  { name: "action", align: "center", label: "" },
];
function ListLottoSupposedly() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [datalistlotto, setdataListLotto] = useState([]);

  const { gid } = useParams();
  const [rows, setRows] = useState([]);
  const fetchListLotto = async () => {
    try {
      setLoading(true);

      const { data } = await get(`${HOST}api/MasterLotto/GetLottoList?group_id=${gid}`);
      setdataListLotto(data.results);

      const rownaja = data.results.map((elem) => ({
        number: elem.row_no,
        images: <img src={`${elem.cover}`} width={50} style={{ borderRadius: 8 }} />,
        name: elem.name,
        status: (
          <SuiBox ml={-1.325}>
            <SuiBadgeDot size="small" badgeContent={elem.status ? "เปิด" : "ปิด"} />
          </SuiBox>
        ),
        createdate: elem.updated_date_display,
        action: (
          <SuiBox w={"100%"} display="flex">
            <SuiButton
              buttonColor={"info"}
              onClick={() => history.push(`/award/supposedly/${gid}/${elem.id}`)}
            >
              เลือก
            </SuiButton>
          </SuiBox>
        ),
      }));

      setRows(rownaja);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchListLotto();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox py={3} display="flex" justifyContent="space-between">
          <SuiTypography>รายการหวย</SuiTypography>
        </SuiBox>
        {loading ? (
          <SuiBox>
            <Skeleton variant="rectangular" width={"auto"} height={500} />
          </SuiBox>
        ) : rows.length > 0 ? (
          <Table columns={columns} rows={rows} />
        ) : (
          <SuiBox textAlign="center">ไม่พบข้อมูล</SuiBox>
        )}
      </SuiBox>

      <Footer />
    </DashboardLayout>
  );
}

export default ListLottoSupposedly;
