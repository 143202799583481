import { useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

import "./index.scss";

import { Nav } from "components/Text/TextStyle";

function Navbar() {
  const history = useHistory();
  const param = useParams();

  return (
    <>
      <div className="navbar">
        <Nav>
          <DashboardNavbar isMini noBred />
        </Nav>
        <div className="navbar-contain">
          <div
            className={history.location.pathname === `/` ? "navbar-item-active" : "navbar-item"}
            onClick={() => history.push(`/`)}
          >
            <p>หน้าแรก</p>
          </div>
          <div
            className={
              history.location.pathname === `/select-lotto` ||
              history.location.pathname === `/play-game/${param.id}`
                ? "navbar-item-active"
                : "navbar-item"
            }
            onClick={() => history.push(`/select-lotto`)}
          >
            <p>แทงหวย</p>
          </div>
          <div
            className={
              history.location.pathname === `/list-select` ? "navbar-item-active" : "navbar-item"
            }
            onClick={() => history.push(`/list-select`)}
          >
            <p>รายการแทง</p>
          </div>
          <div
            className={
              history.location.pathname === `/financial` ? "navbar-item-active" : "navbar-item"
            }
            onClick={() => history.push(`/financial`)}
          >
            <p>บัญชีการเงิน</p>
          </div>
          <div
            className={
              history.location.pathname === `/report/reward` ? "navbar-item-active" : "navbar-item"
            }
            onClick={() => history.push(`/report/reward`)}
          >
            <p>ตรวจรางวัล</p>
          </div>
          <div
            className={history.location.pathname === `/rule` ? "navbar-item-active" : "navbar-item"}
            onClick={() => history.push(`/rule`)}
          >
            <p>กฎกติกา</p>
          </div>
          <div
            className={
              history.location.pathname === `/howto` ? "navbar-item-active" : "navbar-item"
            }
            onClick={() => history.push(`/howto`)}
          >
            <p>วิธีเล่นหวย</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
