import "./index.scss";
import { Provider, connect } from "react-redux";
import { useEffect, useState } from "react";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";

import SuiButton from "components/SuiButton";
import { HOST } from "utils/config";
import { get } from "utils/helper";
import { post } from "utils/helper/request";
import DialogDetail from "pages/purchasedetails/DialogDetail";
import DialogNumber from "pages/purchasedetails/DialogNumber";
import DialogUser from "pages/purchasedetails/DialogUser";
// or
import { Collapse } from "@mui/material";
import Paginated from "memberpage/pages/list-select/Paginated";
import { COLUMNS } from "memberpage/pages/reward/column";
import DataTable from "components/Tables/DataTable";
import CircularProgress from "@mui/material/CircularProgress";
import SuiBox from "components/SuiBox";
import Navbar from "memberpage/pages/navbar";
import { useDispatch, useSelector } from "react-redux";
function Reward(rest) {
  const options = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 50, label: 50 },
  ];
  // const { authentication } = rest;
  const authentication = useSelector((state) => state.authentication);
  const [isLoading, setIsloading] = useState(false);
  const [optionValue, setOptionValue] = useState(10);
  const [dataLotto, setDataLotto] = useState([]);
  const [dataCollape, setDataLottoCollape] = useState([]);
  const [open, setOpen] = useState(false);
  const [openNumber, setOpenNumber] = useState(false);
  const [openUser, setOpenUser] = useState(false);

  const [gatchaType, setGatchaType] = useState([]);
  const [gatchaNumber, setGatchaNumber] = useState([]);
  const [gatchaUser, setGatchaUser] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const onChangeOptionValue = (event) => {
    setOptionValue(event);
    fetchLotto(event);
  };
  const fetchGetDetailBuyByGatchaType = async (gatchaid) => {
    try {
      const { data } = await get(
        `${HOST}api/Report/GetReportBuyByGatchaType?gatcha_drawing_configs_id=${gatchaid}`
      );

      setGatchaType(data.results);
      handleClickOpen();
    } catch (error) {
      console.log(error);
    }
  };
  const fetchGetDetailBuyByGatchaTypeNumber = async (gatchaid) => {
    try {
      const { data } = await get(
        `${HOST}api/Report/GetReportBuyByGatchaTypeNumber?gatcha_drawing_configs_id=${gatchaid}`
      );

      setGatchaNumber(data.results);
      setOpenNumber(true);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchGetDetailBuyByTypeUserList = async (gatchaid) => {
    try {
      const { data } = await get(
        `${HOST}api/Report/GetReportBuyByGatchaTypeUserList?gatcha_drawing_configs_id=${gatchaid}`
      );

      setGatchaUser(data.results);
      setOpenUser(true);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchLotto = async (event) => {
    try {
      setIsloading(true);
      //   console.log("localStorage = ",localStorage)
      const url = HOST + `api/GatchaResults/GetGatchaResult?page=1&per_page=350`;
      const { data } = await get(url);
      // const { data } = await get(`${HOST}api/Gatcha/GetBetBill?page=1&per_page=50`);
      // setDataLotto(data.results);
      console.log(authentication?.user?.role, "role");
      const rownaja = data.results.map((elem) => ({
        results_id: elem.results_id,
        row_no: elem.row_no,
        gatcha_name: elem.gatcha_name,
        drawing_date_display: elem.drawing_date_display,
        three_On: elem.three_On,
        two_On: elem.two_On,
        two_Under: elem.two_Under,
        action: authentication?.user?.role !== "Member" && (
          <SuiBox w={"100%"} display="flex">
            <SuiButton
              sx={{ marginRight: 4 }}
              buttonColor={"info"}
              onClick={() => fetchGetDetailBuyByGatchaType(elem.gatcha_drawing_configs_id)}
            >
              ผลแพ้ชนะตามประเภท
            </SuiButton>
            <SuiButton
              sx={{ marginRight: 4 }}
              buttonColor={"primary"}
              onClick={() => fetchGetDetailBuyByGatchaTypeNumber(elem.gatcha_drawing_configs_id)}
            >
              ผลแพ้ชนะตามหมายเลข
            </SuiButton>
            <SuiButton
              sx={{ marginRight: 4 }}
              buttonColor={"warning"}
              onClick={() => fetchGetDetailBuyByTypeUserList(elem.gatcha_drawing_configs_id)}
            >
              ผลแพ้ชนะตามชมาชิก
            </SuiButton>
          </SuiBox>
        ),
      }));
      setDataLotto(rownaja);
      //   console.log("dataLotto = ",data.results);
      setIsloading(false);
    } catch (error) {}
  };

  const fetchDataCollape = async (results_id) => {
    const url = HOST + `api/GatchaResults/GetResultNumberByResultId?results_id=${results_id}`;
    const { data } = await get(url);

    setDataLottoCollape(data.results);
  };

  useEffect(() => {
    if (authentication?.user?.role) {
      fetchLotto();
    }
  }, [authentication?.user?.role]);
  const handleClose = () => {
    setOpen(false);
    setOpenNumber(false);
    setOpenUser(false);
  };
  return (
    <>
      <DashboardLayout>
        {authentication?.user?.role === "Member" && <Navbar />}
        <SuiBox>ผลการออกรางวัล</SuiBox>
        <div className="list-select">
          {/* <Paginated data={dataLotto} columns={COLUMNS} /> */}
          {isLoading && (
            <div className="isLoading">
              <CircularProgress />
            </div>
          )}
          {!isLoading && (
            <DataTable
              entriesPerPage={{ defaultValue: 25, entries: [5, 10, 15, 20, 25] }}
              table={{
                columns: COLUMNS,
                rows: dataLotto,
              }}
              canSearch
              isCollapeCallApi
              collapseColumns={[
                { name: "gatcha_type", align: "center", label: "ประเภท" },
                { name: "gatcha_result_number", align: "center", label: "หมายเลข" },
              ]}
              collapseBgColor="#FFD700"
              datacollapseApi={dataCollape}
              onCollapseApi={fetchDataCollape}
            />
          )}
        </div>
        <DialogDetail open={open} onClose={handleClose} dataTypeDetail={gatchaType} report />
        <DialogNumber
          open={openNumber}
          onClose={handleClose}
          dataTypeDetail={gatchaNumber}
          report={true}
        />
        <DialogUser open={openUser} onClose={handleClose} dataTypeDetail={gatchaUser} report />
      </DashboardLayout>
    </>
  );
}
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(Reward);
