import "./index.scss";

import { useEffect, useState } from "react";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import { HOST } from "utils/config";
import { get } from "utils/helper";
import { post } from "utils/helper/request";
import PropTypes from "prop-types";

import Paginated from "memberpage/pages/play-game/list-select/Paginated";
import { COLUMNS } from "memberpage/pages/play-game/list-select/column";

function ListSelect({ dataBillLotto }) {
  const options = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 50, label: 50 },
  ];

  const [isLoading, setIsloading] = useState(false);

  const onChangeOptionValue = (event) => {
    setOptionValue(event);
  };

  // const fetchLotto = async () => {
  //     try {
  //         setIsloading(true);
  //     //   console.log("localStorage = ",localStorage)
  //       const url = HOST + "api/Gatcha/GetBetBill";
  //       const { data } = await get(url);
  //       setDataLotto(data.results);
  //       // console.log("dataLotto = ",data.results);
  //       setIsloading(false);
  //     } catch (error) {}
  //   };
  //   useEffect(() => {
  //     fetchLotto();
  //   }, []);

  return (
    <>
      <div className="play-game-list-select">
        <Paginated data={dataBillLotto} columns={COLUMNS} />
      </div>
    </>
  );
}

ListSelect.propTypes = {
  dataBillLotto: PropTypes.array,
};

export default ListSelect;
