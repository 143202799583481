import Default from "pages/dashboard/index";

import SignInCover from "pages/login/index";
import Reports from "pages/users/reports/index";
import WinLoseReport from "pages/winlosereport";
import SettingProfile from "pages/settingprofile/index";
import CreateMember from "pages/users/createmember";
import EditMember from "pages/users/editMember";
import CopyMember from "pages/users/copyMember";
import ManageFight from "pages/users/managefight";
import ManageDiscount from "pages/users/managediscount";
import ManageRate from "pages/users/managerate";
import ManageOpen from "pages/users/manageopen";
//Risk
import RiskLimit from "pages/risklimit/index";
import Purchasedetails from "pages/purchasedetails";
// Soft UI Dashboard PRO React icons
import Shop from "layouts/Icons/Shop";
import Office from "layouts/Icons/Office";
import SettingsIcon from "layouts/Icons/Settings";
import Basket from "layouts/Icons/Basket";
import Document from "layouts/Icons/Document";

import SelectLotto from "memberpage/pages/selectLotto";
import PlayGame from "memberpage/pages/play-game";
import BetDetail from "memberpage/pages/bet-detail";

import ListSelect from "memberpage/pages/list-select";
import Financial from "memberpage/pages/financial";
import Reward from "memberpage/pages/reward";
import RuleLotto from "memberpage/pages/rule";
import HowToPlay from "memberpage/pages/howto";
import GetResult from "memberpage/pages/status-lotto";
import ListGroupLotto from "pages/managelotto/listgrouplotto";
import ListInstallment from "pages/managelotto/listinstallment";
import ListLotto from "pages/managelotto/listlotto";
import ListGatchaType from "pages/managelotto/listgatchatype";
import ListAnnouce from "pages/manageannounce";
import Transfer from "pages/transfer";
import TransferList from "pages/transferlist";
import Award from "pages/award";
import Supposedly from "pages/supposedly";
import ListSupposedly from "pages/listsupposedly";
import ListLottoSupposedly from "pages/listsupposedly/listlotto";
import ListInstallmentSupposedly from "pages/listsupposedly/listinstallment";
const routes = [
  {
    type: "collapse",
    name: "แดชบอร์ด",
    key: "",
    icon: <Shop size="12px" />,
    noCollapse: true,
    route: "/",
    exact: true,
    isAuth: "private",
    component: Default,
  },

  {
    type: "collapse",
    name: "รายการแทงหวย",
    key: "award",
    icon: <Office size="12px" />,
    collapse: [
      {
        name: "ตั้งค่ารับแยกตามหวย",
        key: "award",
        route: "/award/setaward/:id",
        isAuth: "SUBADMIN",
        component: Award,
      },
    ],
  },
  {
    type: "collapse",
    name: "รายการแทงหวย",
    key: "risk",
    icon: <Office size="12px" />,
    collapse: [
      {
        name: "ตั้งค่ารับแยกตามหวย",
        key: "limit",
        route: "/risk/limit/:id",
        isAuth: "AGENT",
        component: RiskLimit,
      },
      {
        name: "ยอดแทงรวม",
        key: "betdetail",
        route: "/risk/purchasedetails",
        isAuth: "AGENT",
        component: Purchasedetails,
      },
    ],
  },

  {
    type: "collapse",
    name: "จัดการสมาชิก",
    key: "AGENT",
    exact: true,
    icon: <SettingsIcon size="12px" />,
    collapse: [
      {
        name: "สมาชิก",
        key: "member",
        collapse: [
          {
            exact: true,
            name: "สมาชิก",
            key: "reports",
            route: "/manage/member/all",
            isAuth: "AGENT",
            component: Reports,
          },
          {
            exact: true,
            name: "เพิ่มสมาชิก",
            key: "createmember",
            route: "/manage/member/create",
            isAuth: "AGENT",
            component: CreateMember,
          },
          {
            exact: true,
            name: "เพิ่มสมาชิก",
            key: "createmember",
            route: "/manage/member/edit/:uid",
            isAuth: "AGENT",
            component: EditMember,
          },
          {
            exact: true,
            name: "copymember",
            key: "createmember",
            route: "/manage/member/copy/:uid",
            isAuth: "AGENT",
            component: CopyMember,
          },
        ],
      },
      // {
      //   exact: true,
      //   name: "แบ่งหุ้น",
      //   key: "share",
      //   route: "/manage/share/",
      //   component: NewUser,
      //   icon: <Shop size="12px" />,
      //   noCollapse: true,
      //   route: "/",
      //   isAuth: "private",
      // },
      // {
      //   name: "จัดการเดิมพันสมาชิก",
      //   key: "editbet",
      //   route: "/manage/editbet/",
      //   isAuth: "private",
      //   component: NewUser,
      // },
      // {
      //   name: "อัตราจ่าย/คอมมิชชั่น",
      //   key: "commission",
      //   route: "/manage/commission/",
      //   isAuth: "private",
      //   component: NewUser,
      // },
    ],
  },

  {
    type: "collapse",
    name: "การเงิน",
    key: "balance",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "โอนยอด",
        key: "transfer",
        route: "/balance/transfer/",
        isAuth: "AGENT",
        component: Transfer,
      },
      {
        name: "รายการโอนยอด",
        key: "editbet",
        route: "/balance/transferlist/",
        isAuth: "AGENT",
        component: TransferList,
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  // {
  //   name: "Cover",
  //   key: "cover",
  //   route: "/sign-in",
  //   isAuth: "guest",
  //   component: SignInCover,
  // },

  {
    name: "เลือกหวย",
    key: "play",
    route: "/play-game/:id",
    isAuth: "MEMBER",
    component: PlayGame,
  },
  {
    name: "เลือกหวย",
    key: "select-lotto",
    route: "/select-lotto",
    isAuth: "MEMBER",
    component: SelectLotto,
  },
  {
    name: "ตั้งค่าบัญชีผู้ใช้",
    exact: true,
    noCollapse: true,
    key: "profile",
    route: "/profile/setting",
    isAuth: "AGENT",
    component: SettingProfile,
    icon: <Shop size="12px" />,
  },
  {
    name: "1.4 ยอดพนันตามหมายเลข",
    key: "bet-detail",
    route: "/bet-detail",
    isAuth: "MEMBER",
    component: BetDetail,
  },
  {
    name: "รายการแทง",
    key: "list-select",
    route: "/list-select",
    isAuth: "MEMBER",
    component: ListSelect,
  },
  {
    name: "บัญชีการเงิน",
    key: "financial",
    route: "/financial",
    isAuth: "MEMBER",
    component: Financial,
  },
  {
    name: "ผลรางวัล",
    key: "reward",
    route: "/report/reward",
    isAuth: "private",
    component: Reward,
  },
  {
    name: "กฎกติกา",
    key: "rule",
    route: "/rule",
    isAuth: "MEMBER",
    component: RuleLotto,
  },
  {
    name: "วิธีเล่นหวย",
    key: "howto",
    route: "/howto",
    isAuth: "MEMBER",
    component: HowToPlay,
  },
  {
    name: "ตรวจรางวัล",
    key: "get-result",
    route: "/get-result/:drawing",
    isAuth: "MEMBER",
    component: GetResult,
  },
  {
    name: "จัดการกรุ๊ปหวย",
    key: "lottogrouplist",
    route: "/managelotto/lottogrouplist",
    isAuth: "ADMIN",
    component: ListGroupLotto,
  },
  {
    name: "จัดหวย",
    key: "lottolist",
    route: "/managelotto/lottolist/:id",
    isAuth: "ADMIN",
    exact: true,
    component: ListLotto,
  },
  {
    name: "จัดการประเภท",
    key: "gatchalist",
    route: "/managelotto/lottolist/gatchatype/:id",
    isAuth: "ADMIN",
    exact: true,
    component: ListGatchaType,
  },
  {
    name: "จัดงวดหวย",
    key: "lottolist",
    route: "/managelotto/lottolist/:id/:lid",
    isAuth: "ADMIN",
    exact: true,
    component: ListInstallment,
  },
  {
    name: "จัดการกรุ๊ปหวย",
    key: "lottogrouplist",
    route: "/manageannounce/announcelist",
    isAuth: "ADMIN",
    exact: true,
    component: ListAnnouce,
  },
  {
    name: "รายงานผลแพ้ชนะ",
    key: "winlosereport",
    route: "/report/winlosereport",
    isAuth: "AGENT",
    exact: true,
    component: WinLoseReport,
  },
  {
    name: " ถือสู้/แบ่งหุ้น",
    key: "managefight",
    route: "/manage/share",
    isAuth: "AGENT",
    exact: true,
    component: ManageFight,
  },
  {
    name: "อัตราจ่าย",
    key: "managerate",
    route: "/manage/rate",
    isAuth: "AGENT",
    exact: true,
    component: ManageRate,
  },
  {
    name: "เปิดปิดหวย",
    key: "openmarket",
    route: "/manage/openmarket",
    isAuth: "AGENT",
    exact: true,
    component: ManageOpen,
  },
  {
    name: "รายการคาดะเนหวย",
    key: "supposedly",
    route: "/award/supposedly",
    isAuth: "ADMIN",
    exact: true,
    component: ListSupposedly,
  },
  {
    name: "รายการหวย",
    key: "supposedly",
    route: "/award/supposedly/:gid",
    isAuth: "ADMIN",
    exact: true,
    component: ListLottoSupposedly,
  },
  {
    name: "รายการงวด",
    key: "supposedly",
    route: "/award/supposedly/:gid/:lid",
    isAuth: "ADMIN",
    exact: true,
    component: ListInstallmentSupposedly,
  },
  {
    name: "คาดคะเนหวย",
    key: "supposedly",
    route: "/supposedly",
    isAuth: "ADMIN",
    exact: true,
    component: Supposedly,
  },
];

export default routes;
