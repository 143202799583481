/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import SuiBadgeDot from "components/SuiBadgeDot";
import SuiTypography from "components/SuiTypography";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useParams, useHistory } from "react-router";
import { Switch } from "@mui/material";
import { Divider } from "@mui/material";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";
// Reports page components
import Reviews from "memberpage/pages/users/reports/components/Reviews";
import SuiButton from "components/SuiButton";
import { Skeleton } from "@mui/material";
// Data
import DialogaddInstallment from "./DialogaddInstallment";
import DialogEditInstallment from "./DialogEditInstallment";

import { Formik, Form, FieldArray, FastField } from "formik";
import SuiDatePicker from "components/SuiDatePicker";
import FormField from "components/FormField";
import Swal from "sweetalert2";

import moment from "moment";
import {
  LootoList20,
  LootoList18,
  LootoList18_2,
  LootoList16,
  LootoList16span,
  LootoList12,
  LootoList14,
} from "components/Text/TextStyle";

const paddingTop = 12;

const columns = [
  { name: "number", align: "center", label: "ลำดับ" },
  { name: "round", align: "center", label: "รอบ" },
  { name: "dateOut", align: "center", label: "วันที่ออก" },
  { name: "openclose", align: "center", label: "ตลาดเปิด-ปิด" },
  { name: "status", align: "center", label: "สถานะ" },
  { name: "createdate", align: "center", label: "วันที่สร้าง" },
  { name: "action", align: "center", label: "" },
];
function ListInstallment() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [datalistlotto, setdataListLotto] = useState([]);
  const [dataUpdate, setdataUpdate] = useState({});
  const [pages, setpages] = useState(1);
  const [perpages, setperpages] = useState(10);
  const [showAll, setshowAll] = useState(0);
  const [jobDrawingData, setJobDrawingData] = useState(null);
  const { id, lid } = useParams();
  const [rows, setRows] = useState([]);

  function isValidTime(timeStr) {
    const timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/; // Regex for HH:MM format
    return timePattern.test(timeStr);
  }

  function isTimeAfter(startTimeStr, endTimeStr) {
    const [startHours, startMinutes] = startTimeStr.split(":").map(Number);
    const [endHours, endMinutes] = endTimeStr.split(":").map(Number);

    const startDate = new Date(0, 0, 0, startHours, startMinutes);
    const endDate = new Date(0, 0, 0, endHours, endMinutes);

    return startDate < endDate;
  }

  function validateTimes(event) {
    if (!event.open_maket || !event.close_maket || !event.draw) {
      Swal.fire("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบ", "error");
      return false;
    }

    if (
      !isValidTime(event.open_maket) ||
      !isValidTime(event.close_maket) ||
      !isValidTime(event.draw)
    ) {
      Swal.fire("เกิดข้อผิดพลาด", "กรุณากรอกเวลาในรูปแบบที่ถูกต้อง (HH:MM)", "error");
      return false;
    }

    if (
      !isTimeAfter(event.open_maket, event.close_maket) ||
      !isTimeAfter(event.close_maket, event.draw)
    ) {
      Swal.fire(
        "เกิดข้อผิดพลาด",
        "เวลาปิดตลาดต้องอยู่หลังเวลาเปิดตลาดและเวลาจับสลากต้องอยู่หลังเวลาปิดตลาด",
        "error"
      );
      return false;
    }

    return true;
  }
  const onhandleSubmit = async (event) => {
    if (event.open_maket === null || event.close_maket === null || event.draw === null) {
      Swal.fire("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบ", "error");
    } else if (event.open_maket == "" || event.close_maket == "" || event.draw == "") {
      Swal.fire("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบ", "error");
    }
    if (validateTimes(event) === false) {
      return;
    } else {
      const payload = {
        job_id: jobDrawingData ? jobDrawingData.job_id : null,
        gatcha_id: parseInt(lid),
        cron: "06:00",
        draw: event.draw,
        open_maket: event.open_maket,
        close_maket: event.close_maket,
        overnight: event.overnight,
      };

      console.log(event, "payload");
      try {
        const { data } = await post(`${HOST}api/Gatcha/upsertJobDrawingDetails`, {
          ...payload,
        });
        console.log(data, "data");
        Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      } catch (error) {
        // console.log(error.response.data.errorMessage, "eieeieiieieei");
        Swal.fire("เกิดข้อผิดพลาด", error.response.data.errorMessage, "error");
        console.log(error);
      }
    }
  };

  const fetchJobDrawingDetails = async () => {
    try {
      setLoading(true);
      const { data } = await get(`${HOST}api/Gatcha/GetJobDrawingDetails?gatcha_id=${lid}`);
      if (data.result) {
        setJobDrawingData({
          ...data.result,
          overnight: data.result.overnight === "True",
          status: data.result.status === "1",
        });
      } else {
        setJobDrawingData(null);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchConfigList = async (showAll = 0) => {
    try {
      setLoading(true);

      const { data } = await get(
        `${HOST}api/MasterLotto/GetLottodrawingConfigList?gatcha_id=${lid}&page=${pages}&limit=${perpages}&show_all=${showAll}`
      );
      setdataListLotto(data.results);

      const rownaja = data.results.map((elem) => ({
        number: elem.row_no,
        round: elem.cycle,
        dateOut: elem.drawing_date_display,
        openclose: (
          <SuiBox>
            {elem.open_maket_display} - {elem.close_maket_display}
          </SuiBox>
        ),
        status: (
          <SuiBox ml={-1.325}>
            <SuiBadgeDot size="small" badgeContent={elem.status ? "เปิด" : "ปิด"} />
          </SuiBox>
        ),
        createdate: elem.updated_date_display,
        action: (
          <SuiBox w={"100%"} display="flex">
            <SuiButton
              buttonColor={"warning"}
              onClick={() => {
                setdataUpdate(elem);
                onOpenEditDialog();
              }}
            >
              แก้ไข
            </SuiButton>
            <div style={{ margin: "0px 2px" }} />
            <SuiButton buttonColor={"success"} onClick={() => history.push(`/supposedly`)}>
              คาดคะเนดารออกหวย
            </SuiButton>
          </SuiBox>
        ),
      }));

      setRows(rownaja);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchJobDrawingDetails();
    fetchConfigList();
  }, []);
  const onCloseDialog = () => {
    setOpenDialog(false);
  };
  const onOpenDialog = () => {
    setOpenDialog(true);
  };
  const onCloseEditDialog = () => {
    setOpenEditDialog(false);
  };
  const onOpenEditDialog = () => {
    setOpenEditDialog(true);
  };
  const onSucces = () => {
    fetchConfigList();
  };

  useEffect(() => {
    fetchConfigList(showAll);
  }, [showAll]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox py={3} display="flex" justifyContent="space-between">
          <LootoList20>จัดการงวดหวย</LootoList20>
          <SuiButton onClick={() => onOpenDialog()} buttonColor="info">
            เพิ่มงวดหวย
          </SuiButton>
        </SuiBox>
        {loading ? (
          <SuiBox>
            <Skeleton variant="rectangular" width={"auto"} height={500} />
          </SuiBox>
        ) : rows.length > 0 ? (
          <>
            <div
              style={{
                background: "#fff",
                borderRadius: "10px",
                padding: "40px 20px 10px 20px",
                marginBottom: "12px",
                boxShadow: "0px 0px 10px 0px #0000001a",
              }}
            >
              <Formik
                initialValues={
                  jobDrawingData
                    ? jobDrawingData
                    : {
                        gatcha_id: lid,
                        cron: null,
                        draw: null,
                        open_maket: null,
                        close_maket: null,
                        overnight: false,
                      }
                }
                // validationSchema={}
                onSubmit={onhandleSubmit}
              >
                {(formProps) => (
                  <Form autoComplete="off">
                    <SuiBox>
                      <SuiBox>
                        <Grid container spacing={4}>
                          <Grid
                            item
                            lg={4}
                            style={{
                              paddingTop: paddingTop,
                            }}
                          >
                            <SuiTypography variant="caption">เวลาตลาดเปิด</SuiTypography>
                            <SuiBox>
                              <TextField
                                id="open_maket"
                                type="time"
                                name="open_maket"
                                defaultValue={null}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 300, // 5 min
                                }}
                                onChange={formProps.handleChange}
                                value={formProps.values.open_maket}
                                sx={{ width: "150px" }}
                              />
                            </SuiBox>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            style={{
                              paddingTop: paddingTop,
                            }}
                          >
                            <SuiTypography variant="caption">เวลาตลาดปิด</SuiTypography>
                            <SuiBox>
                              <TextField
                                id="close_maket"
                                type="time"
                                name="close_maket"
                                // defaultValue="07:30"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 300, // 5 min
                                }}
                                onChange={formProps.handleChange}
                                value={formProps.values.close_maket}
                                sx={{ width: "150px" }}
                              />
                            </SuiBox>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            style={{
                              paddingTop: paddingTop,
                            }}
                          >
                            <SuiTypography variant="caption">เวลาออกหวย</SuiTypography>
                            <SuiBox>
                              <TextField
                                id="draw"
                                type="time"
                                name="draw"
                                // defaultValue="07:30"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 300, // 5 min
                                }}
                                onChange={formProps.handleChange}
                                value={formProps.values.draw}
                                sx={{ width: "150px" }}
                              />
                            </SuiBox>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            style={{
                              paddingTop: paddingTop,
                            }}
                          >
                            <SuiTypography variant="caption">เปิดทั้งวัน</SuiTypography>
                            <SuiBox>
                              <SuiTypography variant="BUTTON">
                                {formProps.values.overnight ? "เปิด" : "ปิด"}{" "}
                              </SuiTypography>
                              <Switch
                                checked={formProps.values.overnight}
                                onChange={() =>
                                  formProps.setFieldValue(`overnight`, !formProps.values.overnight)
                                }
                              />
                            </SuiBox>
                          </Grid>
                        </Grid>
                        <Divider />
                      </SuiBox>
                    </SuiBox>
                    <SuiBox py={3} display="flex" justifyContent="end">
                      <SuiButton type="submit" buttonColor="success">
                        บันทึก
                      </SuiButton>
                    </SuiBox>
                  </Form>
                )}
              </Formik>
            </div>

            <Table columns={columns} rows={rows} />
            <SuiBox py={3} display="flex" justifyContent="end">
              <SuiButton
                onClick={() => {
                  setshowAll(!showAll ? 1 : 0);
                }}
                buttonColor="info"
              >
                {!showAll ? "แสดงทั้งหมด" : `แสดง ${perpages} รายการล่าสุด`}
              </SuiButton>
            </SuiBox>
          </>
        ) : (
          <SuiBox textAlign="center">ไม่พบข้อมูล</SuiBox>
        )}
      </SuiBox>
      <DialogaddInstallment
        open={openDialog}
        onClose={onCloseDialog}
        gatcha_id={lid}
        onSucces={onSucces}
      />
      <DialogEditInstallment
        open={openEditDialog}
        onClose={onCloseEditDialog}
        dataUpdate={dataUpdate}
        onSucces={onSucces}
      />

      <Footer />
    </DashboardLayout>
  );
}

export default ListInstallment;
