import "./index.scss";

import { useEffect, useState } from "react";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import Navbar from "memberpage/pages/navbar";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { HOST } from "utils/config";
import { get } from "utils/helper";
import { post } from "utils/helper/request";
import TablePagination from "components/Tables/TablePagination";
import { Box, Pagination } from "@mui/material";

function GetResult() {
  const param = useParams();
  const history = useHistory();
  const [isLoading, setIsloading] = useState(false);
  const [dataLotto, setDataLotto] = useState([]);
  const [dataSummaryLotto, setDataSummaryLotto] = useState([]);
  const [tableValue, setTableValue] = useState(-1);
  const [dataDetailLotto, setDataDetailLotto] = useState();
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);
  const [totalBillPage, setTotalBillPage] = useState();
  const [currentBillPage, setCurrentBillPage] = useState();
  const [limitBillPage, setlimitBillPage] = useState();
  const fetchLotto = async (pages = 1, limit = 10) => {
    try {
      setIsloading(true);
      const { data } = await get(
        // `${HOST}api/gatcha/GetBetBillResult?gatcha_drawing_config_id=${param.drawing}&type=all`
        `${HOST}api/gatcha/GetBetBillResult?gatcha_drawing_config_id=${param.drawing}&page=${pages}&limit=${limit}`
      );

      // if (data?.result?.result_bet_bill_list.length < 1) {
      //   history.push(`/`);
      // }
      setDataLotto(data?.result);
      setTotalPages(data?.total_page);
      // setDataSummaryLotto(data?.result?.summary_result_bet_bill);
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
    }
  };
  const fetchDetailBill = async (bill_id, index, pages = 1, limit = 10) => {
    try {
      const { data } = await get(
        `${HOST}api/gatcha/GetBetBillResultNumber?bill_id=${bill_id}&page=${pages}&limit=${limit}`
      );
      setDataDetailLotto(data.result);
      setTotalBillPage(data?.total_page);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchTotalBill = async (pages = 1, limit = 10) => {
    try {
      const { data } = await get(
        `${HOST}api/gatcha/GetDrawingBillSummaryResult?gatcha_drawing_config_id=${param.drawing}&page=${pages}&limit=${limit}`
      );
      setDataSummaryLotto(data.result);
    } catch (error) {
      console.log(error);
    }
  };
  const showDetail = (value) => {
    if (tableValue == parseInt(value)) {
      setTableValue(-1);
    } else {
      setTableValue(parseInt(value));
    }
  };

  useEffect(() => {
    fetchLotto();
    fetchTotalBill();
  }, []);

  return (
    <DashboardLayout>
      <Navbar />
      <div className="status-lotto">
        <div className="header-button-group"></div>
        <div className="table-contain">
          <table className="card-table">
            <thead className="head1">
              <tr>
                <td
                  style={{ textAlign: "left", backgroundColor: "#17C1E8", color: "#000" }}
                  colSpan={10}
                >
                  รายงานแพ้ชนะ (บัญชี)
                </td>
              </tr>
              <tr>
                <td>#</td>
                <td>เลขบิล/เวลาแทง</td>
                <td>ตลาด/งวด</td>
                <td>อ้างอิง</td>
                <td>รายการ</td>
                <td>ยอดแทง</td>
                <td>ส่วนลด</td>
                <td>ถูกรางวัล</td>
                <td>ได้เสีย</td>
                <td>ดูบิล</td>
              </tr>
            </thead>
            <tbody className="body2">
              {dataLotto?.map((e, i) => (
                <>
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td className="text-left">
                      <p className="text-blue">#{e.bill_number}</p>
                      <p>{e.bill_date_display}</p>
                    </td>
                    <td className="text-left">
                      <p className="text-with-flag">
                        <img src={e.market_cover}></img>
                        {e.market}
                      </p>
                      <p>{e.gatcha_drawing_date_display}</p>
                    </td>
                    <td>{e.reference}</td>
                    <td>{e.number_of_list}</td>
                    <td className="text-right">{e.total_buy_display}</td>
                    <td className="text-right">{e.total_discount_display}</td>
                    <td className="text-right">{e.total_win_display}</td>
                    <td
                      className={
                        e.loss_profit > 0 ? "text-right text-green" : "text-right text-red"
                      }
                    >
                      {e.loss_profit_display}
                    </td>
                    <td
                      className="nav-link"
                      onClick={() => {
                        fetchDetailBill(e.bill_id, i);
                        showDetail(i);
                      }}
                    >
                      ดูบิล
                    </td>
                  </tr>
                  {tableValue === i && (
                    <tr>
                      <td className="detail-bill-contain" colSpan={10}>
                        <table className="card-table">
                          <thead className="head1">
                            <tr>
                              <td>ประเภท @ หมายเลข</td>
                              <td>ยอดเดิมพัน</td>
                              <td>สถานะ</td>
                            </tr>
                          </thead>
                          <tbody className="body2">
                            {dataDetailLotto?.map((s, x) => (
                              <tr key={x}>
                                <td className="type-number">
                                  <p className="text-type">{s.type}</p>{" "}
                                  <p className="text-add">@</p>{" "}
                                  <p className="text-number">{s.number}</p>
                                </td>
                                <td className="text-right">{s.total_buy_display}</td>
                                <td className={s.status === true ? "text-green" : "text-red"}>
                                  {s.status_display}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>{" "}
                        <Box display={"flex"} justifyContent={"center"}>
                          <Pagination
                            count={totalBillPage}
                            color="secondary"
                            onChange={(event, p) => fetchDetailBill(e.bill_id, i, p, limitBillPage)}
                          />
                        </Box>
                      </td>
                    </tr>
                  )}
                </>
              ))}
              <tr className="green-row">
                <td colSpan={5} />
                <td className="text-right">{dataSummaryLotto?.total_buy_display}</td>
                <td className="text-right">{dataSummaryLotto?.total_discount_display}</td>
                <td className="text-right">{dataSummaryLotto?.total_win_display}</td>
                <td
                  className={
                    dataSummaryLotto?.loss_profit > 0
                      ? "text-right text-green"
                      : "text-right text-red"
                  }
                >
                  {dataSummaryLotto?.loss_profit_display}
                </td>
                <td />
              </tr>
            </tbody>
          </table>{" "}
          <Box display={"flex"} justifyContent={"center"}>
            <Pagination
              count={totalPages}
              color="primary"
              onChange={(event, p) => {
                fetchLotto(p, limitPage);
                fetchTotalBill(p, limitPage);
              }}
            />
          </Box>
        </div>
      </div>

      {/* <TablePagination
            columns={columns}
            rows={rows}
            isPagination
            currentPage={pages}
            totalPage={totalPage}
            onPageChange={onPageChange}
          /> */}
    </DashboardLayout>
  );
}

export default GetResult;
