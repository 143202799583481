import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SuiBox from "components/SuiBox";
import Table from "components/Tables/Table";
import DataTable from "components/Tables/DataTable";
import { Switch, Grid } from "@mui/material";
import { Text16, TextBlack24 } from "components/Text/TextStyle";
import { get, post } from "utils/helper";
import { HOST } from "utils/config";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Select, MenuItem, FormControl } from "@mui/material";
import Swal from "sweetalert2";

import { LootoList20, LootoList18, LootoList16, LootoList16span } from "components/Text/TextStyle";

function fightMax(max, min) {
  let arrStock = [];
  let numberSelectedStock = min;
  arrStock.push({ value: numberSelectedStock, label: `${numberSelectedStock}%` });
  let maxNumber = max * 2;
  for (let i = min * 2; i < maxNumber; i++) {
    numberSelectedStock = numberSelectedStock + 0.5;
    arrStock.push({ value: numberSelectedStock, label: `${numberSelectedStock}%` });
  }
  return arrStock.reverse();
}
const ManageAgent = ({ dataLoto, gatchaId }) => {
  const [dataAgent, setDataAgent] = useState(dataLoto?.agent_stock);
  const [isLoading, setIsLoading] = useState(false);
  const [checkswitch, setCheckswitch] = useState(false);
  const [stockcopy, setStockcopy] = useState(0);
  const chageValues = (name, index, value) => {
    let data = dataAgent;
    data[index][name] = value;
    setDataAgent([...data]);
  };
  const chageValuesFight = (index, value) => {
    let data = dataAgent;
    data[index].upline_stock = data[index].all_stock - value;
    data[index].stock = value;
    setDataAgent([...data]);
  };
  useEffect(() => {
    setDataAgent(dataLoto?.agent_stock);
  }, [dataLoto]);
  const onCopyLotto = async (groupId) => {
    setIsLoading(true);

    try {
      let payload = { type: "AGENT", gatcha_id: gatchaId.toString(), group_id: groupId };
      const { data } = await post(`${HOST}api/UserConfig/CloneUserStockToGroupGatcha`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
      console.log(error);
    }
  };
  const onSubmitLotto = async () => {
    setIsLoading(true);

    try {
      let payload = {
        stock: dataAgent.map((e, i) => ({
          role_id: e.role_id,
          user_id: e.user_id,
          gatcha_id: gatchaId.toString(),
          stock: e.newstock || e.stock,
          give_difference: false,
          upline_stock: e.upline_stock,
          all_stock: e.all_stock,
          all_stock_display: e.all_stock_display,
          downline_stock: e.downline_stock,
          downline_stock_display: e.downline_stock_display,
        })),
      };
      const { data } = await post(`${HOST}api/UserConfig/SetUserStock`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
      console.log(error);
    }
  };
  const columns = [
    { name: "role", align: "center", label: "ระดับชั้น" },
    { name: "user_name", align: "center", label: "ยูเซอร์เนม" },
    { name: "name", align: "center", label: "ติดต่อ	" },
    { name: "all_stock_display", align: "center", label: "ทั้งหมดที่ได้มา	" },
    { name: "stock", align: "center", label: "ถือสู้เอง	" },
    { name: "upline_stock", align: "center", label: "ให้เอเย่น", position: "absolute" },
    { name: "downline_stock", align: "center", label: "เอเย่นให้ลูก	" },
    // { name: "give_difference", align: "center", label: "ตั้งเก็บ" },
  ];
  const onCopyKeep = () => {
    let data = dataAgent;
    data.map((e, i) => {
      data[i].give_difference = checkswitch;
    });

    setDataAgent([...data]);
  };

  const onCopyOver = () => {
    let data = dataAgent;

    data.map((e, i) => {
      if (stockcopy > e.all_stock - e.downline_stock) {
        data[i].stock = e.stock - (e.all_stock - e.downline_stock);
        data[i].upline_stock = e.all_stock - e.downline_stock;
      } else {
        data[i].upline_stock = e.all_stock - stockcopy;
        data[i].stock = stockcopy;
      }
    });

    setDataAgent([...data]);
  };
  return (
    <SuiBox p={2} backgroundColor={"#FFF"}>
      <LootoList18>จัดการ เอเย่นต์</LootoList18>
      <SuiBox mb={4} display="flex" justifyContent={"space-between"}>
        {/* <SuiBox>
          <LootoList16span>ตั้งค่ากลุ่มหวยตามหวยไทย:</LootoList16span>
          {dataLoto?.gatcha_groups.map((e, i) => (
            <SuiButton
              disabled={isLoading}
              buttonColor={"info"}
              key={i}
              sx={{ marginRight: 2 }}
              onClick={() => onCopyLotto(e.group_id)}
            >
              {e.group_name}
            </SuiButton>
          ))}
        </SuiBox> */}
        <SuiBox>
          <SuiButton buttonColor="success" onClick={() => onSubmitLotto()}>
            บันทึกทั้งหมด
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <Table
        maincopy={[
          { name: "role", align: "center", children: <></> },
          { name: "user_name", align: "center", children: <></> },
          { name: "name", align: "center", children: <></> },
          { name: "all_stock_display", align: "center", children: <></> },
          { name: "stock", align: "center", children: <></> },
          {
            name: "upline_stock",
            align: "center",
            position: "absolute",
            children: (
              <SuiBox display="flex" justifyContent="center" align="center" position="relative">
                <SuiSelect
                  placeholder="แบ่งหุ้น"
                  options={fightMax(90, 0)}
                  defaultValue={{ value: stockcopy, label: `${stockcopy}%` }}
                  value={{ value: stockcopy, label: `${stockcopy}%` }}
                  onChange={(value) => {
                    setStockcopy(value.value);
                  }}
                />
                <SuiButton
                  buttonColor="info"
                  onClick={() => onCopyOver()}
                  style={{ marginLeft: "18px" }}
                >
                  Copy
                </SuiButton>
              </SuiBox>
            ),
          },
          { name: "downline_stock", align: "center", children: <></> },
          // {
          //   name: "give_difference",
          //   align: "center",
          //   children: (
          //     <>
          //       <Switch
          //         checked={checkswitch}
          //         onChange={() => setCheckswitch(!checkswitch)}
          //         sx={{ marginRight: 2 }}
          //       />
          //       <SuiButton buttonColor="info" onClick={() => onCopyKeep()}>
          //         Copy
          //       </SuiButton>
          //     </>
          //   ),
          // },
        ]}
        columns={columns}
        rows={dataAgent?.map((elem, index) => ({
          role: elem.role,
          user_name: elem.user_name,
          name: elem.name,
          all_stock_display: <>{elem.all_stock_display} %</>,
          stock: <>{elem.upline_stock}</>,
          upline_stock: (
            <SuiBox width="100%">
              <SuiSelect
                placeholder="แบ่งหุ้น"
                options={fightMax(elem.all_stock, elem.downline_stock)}
                defaultValue={{ value: elem.stock, label: `${elem.stock}%` }}
                value={{ value: elem.stock, label: `${elem.stock}%` }}
                onChange={(value) => {
                  chageValuesFight(index, value.value);
                }}
              />
            </SuiBox>
          ),
          downline_stock: elem.downline_stock,
          give_difference: (
            <SuiBox>
              <LootoList16span>{elem.give_difference ? "เก็บ" : "ไม่เก็บ"}</LootoList16span>
              <Switch
                checked={elem.give_difference}
                onChange={() => chageValues("give_difference", index, !elem.give_difference)}
                sx={{ marginRight: 2 }}
              />
            </SuiBox>
          ),
        }))}
      />
    </SuiBox>
  );
};

ManageAgent.propTypes = {
  dataLoto: PropTypes.object,
  gatchaId: PropTypes.string,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAgent);
