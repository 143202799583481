import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { get } from "utils/helper";
import { HOST } from "utils/config";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import SuiBox from "components/SuiBox";
import Twogatcha from "./components/Twogatcha";
import SuiSelect from "components/SuiSelect";
import managefightPageStyle from "./styles";
import { LootoList20_2 } from "components/Text/TextStyle";

export const Supposedly = (props) => {
  const classes = managefightPageStyle();
  const [value, setValue] = React.useState("1");
  const [groupSelect, setgroupSelect] = useState(1);
  const [lottoSelect, setLottoSelect] = useState({ value: 1, label: "หวยไทย" });
  const [installment, setInstallment] = useState({});
  const [lottogroup, setLottogroup] = useState([]);
  const [lottoList, setLottoList] = useState([]);
  const [installmentList, setinstallmentList] = useState([]);
  const setSelectInstallment = ({ value, label }) => {
    setInstallment({ value: value, label: label });
  };
  const setSelectLotto = ({ value, label }) => {
    setLottoSelect({ value: value, label: label });
    fetchLottoInstallment(value);
  };
  const setSelectValue = ({ value, label }) => {
    setgroupSelect(value);
    fetchLotto(value);
  };
  const fetchgroup = async () => {
    try {
      const { data } = await get(`${HOST}api/Gatcha/GetGatchaGroupSelectListItem`);
      setLottogroup(data.results);
      setgroupSelect({ value: data?.results[0]?.value, label: data?.results[0]?.text });
      fetchLotto(data?.results[0]?.value);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchLotto = async (value) => {
    try {
      const { data } = await get(
        `${HOST}api/Gatcha/GetGatchaSelectListItemByGroupId?gatcha_group_id=${value || groupSelect}`
      );
      setLottoList(data.results);

      setLottoSelect({ value: data?.results[0]?.value, label: data?.results[0]?.text });
      fetchLottoInstallment(data?.results[0]?.value);
      // setInstallment({ value: 0, label: "โปรดเลือกงวด" });
    } catch (error) {
      console.log(error);
    }
  };
  const fetchLottoInstallment = async (value) => {
    try {
      const { data } = await get(
        `${HOST}api/Supposedly/GetGatchaDrawingSelectListItem?gatcha_id=${
          value || lottoSelect.value
        }`
      );

      setinstallmentList(data?.results);
      setInstallment({ value: data?.results[0]?.value, label: data?.results[0]?.text });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchgroup();
  }, []);
  const columns = [
    { field: "value", headerName: "Column 1", width: 150 },
    { field: "text", headerName: "Column 2", width: 150 },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox p={3} display="flex" justifyContent={"space-between"} backgroundColor={"#FFF"}>
        <Grid container>
          <Grid xs={6}>
            <LootoList20_2>
              {" "}
              คาดคะเนการออกรางวัล งวดที่ &nbsp;
              <span style={{ color: "red" }}>{installment.value !== 0 && installment.label}</span>
            </LootoList20_2>

            {/* <SuiTypography variant="h4" textColor="secondary" fontSize={matches ? 10 : 20}>
              ถือหุ้น/แบ่งสู้
            </SuiTypography> */}
          </Grid>
          <Grid xs={6}>
            {lottogroup?.length > 0 && (
              <SuiBox display="flex">
                <SuiSelect
                  defaultValue={{ value: lottogroup[0]?.value, label: lottogroup[0]?.text }}
                  options={lottogroup.map((entry) => ({
                    value: entry.value,
                    label: entry.text,
                  }))}
                  onChange={setSelectValue}
                  size="large"
                  className={classes.selectmenu}
                />

                <SuiBox mr={2} />
                {groupSelect !== null && (
                  <SuiSelect
                    defaultValue={{ value: 1, label: "หวยไทย" }}
                    options={lottoList.map((entry) => ({
                      value: entry.value,
                      label: entry.text,
                    }))}
                    value={{ value: lottoSelect.value, label: `${lottoSelect.label}` }}
                    onChange={setSelectLotto}
                    size="large"
                    className={classes.selectmenu}
                  />
                )}
                <SuiBox mr={2} />
                {lottoSelect !== null && (
                  <SuiSelect
                    defaultValue={{ value: 111, label: "01/06/2022 15:45" }}
                    options={installmentList.map((entry) => ({
                      value: entry.value,
                      label: entry.text,
                    }))}
                    value={{ value: installment.value, label: `${installment.label}` }}
                    onChange={setSelectInstallment}
                    size="large"
                    className={classes.selectmenu}
                  />
                )}
              </SuiBox>
            )}
          </Grid>
        </Grid>
      </SuiBox>

      {installment.value && <Twogatcha cfid={installment?.value} />}
      {/* <DataGrid rows={rows} columns={columns} /> */}
      <Footer />
    </DashboardLayout>
  );
};

Supposedly.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Supposedly);
