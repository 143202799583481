/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import SuiBadgeDot from "components/SuiBadgeDot";
import Swal from "sweetalert2";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";
// Reports page components
import Reviews from "memberpage/pages/users/reports/components/Reviews";
import { Typography } from "@mui/material";

// Data

const columns = [
  { name: "time", align: "left", label: "วันเวลา" },
  { name: "role", align: "left", label: "ระดับ" },
  { name: "username", align: "center", label: "ชื่อผู้ใช้" },
  { name: "name", align: "center", label: "ชื่อ/สกุล" },
  { name: "ref", align: "center", label: "อ้างอิง " },
  { name: "note", align: "center", label: "หมายเหตุ " },
  { name: "transfer", align: "center", label: "โอนยอด" },
];
function TransferList() {
  const [loading, setLoading] = useState(false);
  const [dataMember, setdataMember] = useState([]);
  const [dataDialog, setdataDialog] = useState({});
  const [pages, setpages] = useState(1);

  const [perpages, setperpages] = useState(10);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onTranferAlone = (values) => {
    setdataDialog(values);
    handleClickOpen();
  };

  const fetchMember = async () => {
    try {
      setLoading(true);

      const { data } = await get(`${HOST}api/Transfer/GetTransferHistory`);
      setdataMember(data.results);

      const rownaja = data.results.map((elem) => ({
        time: elem.transition_date_display,
        role: elem.user_role,
        username: elem.user_name,
        name: elem.name,
        ref: elem.full_transfer_display,
        note: elem.remark,
        transfer: <Typography textColor={"error"}> {elem.amount_display}</Typography>,
      }));

      setRows(rownaja);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchMember();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SuiBox py={3}>
        {rows.length > 0 ? (
          <Table columns={columns} rows={rows} />
        ) : (
          <SuiBox textAlign="center"> ไม่มีรายการโอน</SuiBox>
        )}
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TransferList;
