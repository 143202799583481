import Style from "./style.js";
import "./index.scss";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";

import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import { Link, useHistory } from "react-router-dom";
import { get, post } from "utils/helper";
import { HOST } from "utils/config";

import ListLotto from "./ListLotto";
import Navbar from "memberpage/pages/navbar";

const useStyle = makeStyles(Style);

function selectLotto({ authentication }) {
  const [idTypeLotto, setIdTypeLotto] = useState(1);
  const [dataLotto, setDataLotto] = useState([]);
  const [dataListLotto, setDataListLotto] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyle();
  const history = useHistory();

  const fetchLotto = async () => {
    try {
      setLoading(true);
      const url = HOST + "api/Gatcha/GetGatchaForSpin";
      const { data } = await get(url);
      setDataLotto(data.results);
      setLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    fetchLotto();
  }, []);

  return (
    <>
      <DashboardLayout>
        <Navbar />
        {authentication?.user?.status !== "ปกติ" && (
          <div className="text-red-contain">
            <p>ขออภัย คุณถูกระงับแทง</p>
          </div>
        )}
        {authentication?.user?.status == "ปกติ" && (
          <SuiBox>
            <Grid container>
              <Grid item xs={12} lg={12}>
                {dataLotto.map((e, i) => (
                  <div className="list-lotto-contain" key={i}>
                    <div className="lotto-header">
                      <p>{e.group_name}</p>
                    </div>
                    <div className="lotto-card">
                      <ListLotto key={i} dataLotto={e} />
                    </div>
                  </div>
                ))}
              </Grid>
            </Grid>
          </SuiBox>
        )}
      </DashboardLayout>
    </>
  );
}

const mapStateToProps = ({ authentication }) => ({
  authentication,
});

selectLotto.propTypes = {
  authentication: PropTypes.object,
};

export default connect(mapStateToProps)(selectLotto);
