import react, { useState, useEffect } from "react";
import { post } from "utils/helper/request";
import { HOST } from "utils/config";
// @mui material components
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import { Grid, Divider } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";

import Swal from "sweetalert2";

import { LootoList20, LootoList18, LootoList16 } from "components/Text/TextStyle";
// Data
function LottoListTable({ dataLotto }) {
  const [lotto, setLotto] = useState([]);
  const [loading, setLoading] = useState(false);

  const saveSigleLimit = async (typeId, amount) => {
    try {
      setLoading(true);
      const payload = new FormData();

      payload.append("lotto_config_type_id", typeId);
      payload.append("total_limit", amount);
      const { data } = await post(`${HOST}api/Lotto/SetLimitLotto`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const saveAllLimit = async (item) => {
    try {
      setLoading(true);
      const payload = [];
      item.map((e) => {
        let my_object = {};
        my_object.lotto_config_type_id = e.config_types_id;
        my_object.total_limit = e.total_limit;
        payload.push(my_object);
      });

      const { data } = await post(`${HOST}api/Lotto/SetLimitLottoArray`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChageAmount = (i, index, event) => {
    // let lottoChange = lotto;
    // lottoChange.lotto_list_item[i].lotto_type_list_item[index].total_limit = parseInt(
    //   event.target.value
    // );
    setLotto((oldState) => {
      oldState[i].lotto_type_list_item[index].total_limit = event.target.value;
      return oldState;
    });
    // setLotto(lottoChange);
  };
  useEffect(() => {
    setLotto(dataLotto?.lotto_list_item);
  }, [dataLotto]);
  return (
    <SuiBox my={3}>
      <Card>
        {lotto?.map((e, i) => (
          <SuiBox key={i} p={3}>
            <SuiBox display={"flex"} justifyContent={"space-between"}>
              <SuiTypography variant={"h5"} fontWeight="bold">
                <LootoList20>{e.lotto_name}</LootoList20>
              </SuiTypography>
              <SuiButton buttonColor="info" onClick={() => saveAllLimit(e.lotto_type_list_item)}>
                <LootoList16>บันทึกทั้งหมด</LootoList16>
              </SuiButton>
            </SuiBox>
            <Grid container>
              <Grid xs={1}></Grid>
              <Grid xs={7}>
                <LootoList18>ประเภท</LootoList18>
              </Grid>
              <Grid xs={4}>
                <LootoList18>กำหนดจำนวน</LootoList18>
              </Grid>
            </Grid>
            <Divider />
            {e.lotto_type_list_item.map((values, index) => {
              return (
                <Grid container key={index} style={{ padding: 8 }} spacing={2}>
                  <Grid xs={1} item></Grid>
                  <Grid xs={7} item>
                    {values.lotto_type_name}
                  </Grid>
                  <Grid xs={2} item>
                    <SuiInput
                      type="number"
                      placeholder="โปรดกรอกจำนวนจำกัด"
                      name={"limit"}
                      onChange={(e) => {
                        lotto[i].lotto_type_list_item[index].total_limit = e.target.value;
                        setLotto([...lotto]);
                      }}
                      value={values.total_limit}
                    />
                  </Grid>
                  <Grid xs={2} item>
                    <SuiButton
                      buttonColor="info"
                      onClick={() => saveSigleLimit(values.config_types_id, values.total_limit)}
                    >
                      บันทึก
                    </SuiButton>
                  </Grid>
                </Grid>
              );
            })}
            <Divider />
          </SuiBox>
        ))}
      </Card>
    </SuiBox>
  );
}
LottoListTable.propTypes = {
  dataLotto: PropTypes.array,
};
export default LottoListTable;
