import { useState, useEffect } from "react";
import { Provider, connect } from "react-redux";
// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { store } from "redux/index";
import { checkAuthAction } from "redux/authentication/action";
import { getLotto } from "redux/lotto/action";
import AuthRoute from "utils/AuthRoute";
import PropTypes from "prop-types";
import Footer from "layouts/Footer";
import Error404 from "memberpage/authentication/error/404";
import SignInCover from "pages/login/index";
// jss components
// import { create } from "jss";

// jss-rtl components
// import rtl from "jss-rtl";

// @mui style components
// import { StylesProvider, jssPreset } from "@mui/styles";

// @mui material components
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "components/Sidenav";
import pageRoutesMember from "pagesRole/pageRoutesMember";
import pageRoutesAgent from "pagesRole/pageRoutesAgent";
import pageRoutesAdmin from "pagesRole/pageRoutesAdmin";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

import routes from "routes";

import { useSoftUIController } from "context";
import pageRoutesSubAdmin from "pagesRole/pageRoutesSubAdmin";

function App(props) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { pathname } = useLocation();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      dispatch({ type: "MINI_SIDENAV", value: false });
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      dispatch({ type: "MINI_SIDENAV", value: true });
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => {
    dispatch({ type: "OPEN_CONFIGURATOR", value: !openConfigurator });
  };

  const initial = async () => {
    try {
      if (localStorage.getItem("access_token")) {
        await props.checkAuthAction(localStorage.getItem("access_token"));
        await props.getLotto();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    initial();
  }, []);
  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <AuthRoute
            exact={route.exact}
            path={route.route}
            component={route.component}
            key={route.key}
            isAuth={route.isAuth}
          />
        );

        // return <Route exact path={route.route} component={route.component} key={route.key} />;
      }

      return null;
    });
  const routeRole = () => {
    // console.log(props?.authentication?.user?.role, "props.authentication.user.role");
    // return pageRoutesAgent;
    // console.log(props?.authentication?.user?.role, "props.authentication.user.role");
    switch (props?.authentication?.user?.role_id) {
      case "COMPANY":
        return pageRoutesAdmin;
        break;
      case "SUBADMIN":
        return pageRoutesSubAdmin;
        break;
      case "MASTER":
        return pageRoutesAgent;
        break;
      case "AGENT":
        return pageRoutesAgent;
        break;
      case "MEMBER":
        return pageRoutesMember;
        break;
      case "Partner":
        return pageRoutesAgent;
        break;
      default:
        return pageRoutesAgent;
    }
  };

  const configsButton = (
    <SuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      backgroundColor="white"
      boxShadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      customClass="cursor-pointer"
      onClick={handleConfiguratorOpen}
    >
      <Icon className=" text-dark" fontSize="default">
        settings
      </Icon>
    </SuiBox>
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              routes={routeRole()}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            {/* <Configurator /> */}
            {/* {configsButton} */}
          </>
        )}
        <Switch>
          <Route exact path={"/sign-in"} key={"cover"} component={SignInCover} />
          {getRoutes(routes)}
          <Route component={Error404} />
          {/* <Redirect from="*" to="/sign-in" /> */}
        </Switch>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const mapDispatchToProps = { checkAuthAction, getLotto };

const mapStateToProps = ({ authentication }) => ({
  authentication,
});
App.propTypes = {
  checkAuthAction: PropTypes.func.isRequired,
  getLotto: PropTypes.func,
  authentication: PropTypes.object,
};
// CONNECT REDUX
export default connect(mapStateToProps, mapDispatchToProps)(App);
