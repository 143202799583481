const combinations = (nums, isNotDuplicate = true, digits = 2) => {
  const results = [];

  const backtrack = (start, path) => {
    if (path.length === digits) {
      results.push(path.join(""));
      return;
    }
    for (let i = start; i < nums.length; i++) {
      backtrack(i + isNotDuplicate, path.concat(nums[i]));
    }
  };

  backtrack(0, []);
  return results.sort((a, b) => a - b);
};

const permutator = (inputArr) => {
  let result = [];

  const permute = (arr, m = []) => {
    if (arr.length === 0) {
      if (m.length > 1) {
        result.push(m.join(""));
      }
    } else {
      for (let i = 0; i < arr.length; i++) {
        let curr = arr.slice();
        let next = curr.splice(i, 1);
        permute(curr.slice(), m.concat(next));
      }
    }
  };

  permute(inputArr);

  return result;
};

export const reverseNumber = (input_nums) => {
  for (const num of input_nums) {
    const newData = permutator(num.toString().split(""));
    newData.push(num);
    input_nums = [...input_nums, ...newData];
  }

  // remove duplicate and sort
  input_nums = removeDuplicate(input_nums);
  return input_nums;
};

export const removeDuplicate = (input_nums) => {
  return [...new Set(input_nums)].sort((a, b) => a - b);
}

export const genarateNumber = (input_nums, mode) => {
  if (input_nums.length === 0) {
    return [];
  }

  switch (mode) {
    case 1:
      return combinations(input_nums, true, 2);
    case 2:
      return combinations(input_nums, false, 2);
    case 3:
      return combinations(input_nums, true, 3);
    case 4:
      return combinations(input_nums, false, 3);
    default:
      return [];
  }
};