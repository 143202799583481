import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SuiBadgeDot from "components/SuiBadgeDot";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import DataTable from "components/Tables/DataTable";
import colors from "assets/theme/base/colors";
const { info, white, primary, success } = colors;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogUser({ open, onClose, dataTypeDetail, report }) {
  const rednderTotal = () => {
    let downline_user_id = 0;
    let downline_name = 0;
    let total_buy = 0;
    let downline_receive_discount = 0;
    let downline_discount = 0;
    let downline_receive = 0;
    let agent_total_receive_discount = 0;
    let agent_total_receive = 0;
    let agent_total_discount = 0;
    let upline_receive = 0;
    let upline_discount = 0;
    let upline_receive_total = 0;
    let agent_total_reward = 0;
    let upline_total_reward = 0;
    let downline_total_reward = 0;
    const datatotal =
      dataTypeDetail &&
      dataTypeDetail.map((elem, i) => {
        // downline_user_id = downline_user_id + elem.downline_user_id;
        // downline_name = downline_name + elem.downline_name;
        total_buy = total_buy + elem.total_buy;
        downline_receive = downline_receive + elem.downline_receive;
        downline_discount = downline_discount + elem.downline_discount;
        downline_receive_discount = downline_receive_discount + elem.downline_receive_discount;
        agent_total_receive = agent_total_receive + elem.agent_total_receive;
        agent_total_discount = agent_total_discount + elem.agent_total_discount;
        agent_total_receive_discount =
          agent_total_receive_discount + elem.agent_total_receive_discount;
        upline_receive = upline_receive + elem.upline_receive;
        upline_discount = upline_discount + elem.upline_discount;
        upline_receive_total = upline_receive_total + elem.upline_receive_total;
        agent_total_reward = agent_total_reward + elem.agent_total_reward;
        upline_total_reward = upline_total_reward + elem.upline_total_reward;
        downline_total_reward = downline_total_reward + elem.downline_total_reward;
      });

    return [
      ...dataTypeDetail,
      {
        downline_name: (
          <SuiBox textAlign="end" width={"100%"}>
            รวม
          </SuiBox>
        ),
        downline_user_id: "",
        downline_name: "รวม",
        total_buy: total_buy,
        downline_receive: downline_receive,
        downline_discount: downline_discount,
        downline_receive_discount: downline_receive_discount,
        downline_total_reward: downline_total_reward,
        agent_total_receive: agent_total_receive,
        agent_total_discount: agent_total_discount,
        agent_total_reward: agent_total_reward,
        agent_total_receive_discount: agent_total_receive_discount,
        upline_receive: upline_receive,
        upline_discount: upline_discount,
        upline_total_reward: upline_total_reward,
        upline_receive_total: upline_receive_total,
      },
    ];
  };

  const dataDetail = {
    columns: [
      { Header: "สมาชิก", accessor: "downline_user_id" },
      { Header: "ติดต่อ", accessor: "downline_name" },
      { Header: "ยอดพนัน", accessor: "total_buy" },
      {
        Header: "ยอดส่งออก",
        accessor: "downline_receive",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "ส่วนลด",
        accessor: "downline_discount",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "รวม",
        accessor: "downline_receive_discount",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "ยอดถือสู้",
        accessor: "agent_total_receive",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "ส่วนลด",
        accessor: "agent_total_discount",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "รวม",
        accessor: "agent_total_receive_discount",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "ยอดถือสู้	",
        accessor: "upline_receive",
        bgcolor: success.main,
        fontcolor: white.main,
      },
      {
        Header: "ส่วนลด",
        accessor: "upline_discount",
        bgcolor: success.main,
        fontcolor: white.main,
      },
      {
        Header: "รวม",
        accessor: "upline_receive_total",
        bgcolor: success.main,
        fontcolor: white.main,
      },
    ],
    // rows: dataTypeDetail.map((elem) => ({
    //   downline_user_id: elem.downline_user_id,
    //   downline_name: elem.downline_name,
    //   total_buy: elem.total_buy,
    //   downline_receive: elem.downline_receive,
    //   downline_discount: elem.downline_discount,
    //   downline_receive_discount: elem.downline_receive_discount,
    //   agent_total_receive: elem.agent_total_receive,
    //   agent_total_discount: elem.agent_total_discount,
    //   agent_total_receive_discount: elem.agent_total_receive_discount,
    //   upline_receive: elem.upline_receive,
    //   upline_discount: elem.upline_discount,
    //   upline_receive_total: elem.upline_receive_total,
    // })),
    rows: rednderTotal(),
  };
  const mainTable = [
    { Header: "" },
    { Header: "" },
    { Header: "" },
    { Header: "", bgcolor: info.main, fontcolor: white.main },
    { Header: "ลูกทีม", bgcolor: info.main, fontcolor: white.main },
    { Header: "", bgcolor: info.main, fontcolor: white.main },
    { Header: "", bgcolor: primary.main, fontcolor: white.main },
    { Header: "ตัวคุณ", bgcolor: primary.main, fontcolor: white.main },
    { Header: "", bgcolor: primary.main, fontcolor: white.main },
    { Header: "", bgcolor: success.main, fontcolor: white.main },
    { Header: "แม่ทีม", bgcolor: success.main, fontcolor: white.main },
    { Header: "", bgcolor: success.main, fontcolor: white.main },
  ];
  const dataDetailReport = {
    columns: [
      { Header: "สมาชิก", accessor: "downline_user_id" },
      { Header: "ติดต่อ", accessor: "downline_name" },
      { Header: "ยอดพนัน", accessor: "total_buy" },
      {
        Header: "ยอดส่งออก",
        accessor: "downline_receive",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "ส่วนลด",
        accessor: "downline_discount",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "ถูกรางวัล",
        accessor: "downline_total_reward",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "รวม",
        accessor: "downline_receive_discount",
        bgcolor: info.main,
        fontcolor: white.main,
      },
      {
        Header: "ยอดถือสู้",
        accessor: "agent_total_receive",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "ส่วนลด",
        accessor: "agent_total_discount",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "ถูกรางวัล",
        accessor: "agent_total_reward",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "รวม",
        accessor: "agent_total_receive_discount",
        bgcolor: primary.main,
        fontcolor: white.main,
      },
      {
        Header: "ยอดถือสู้	",
        accessor: "upline_receive",
        bgcolor: success.main,
        fontcolor: white.main,
      },
      {
        Header: "ส่วนลด",
        accessor: "upline_discount",
        bgcolor: success.main,
        fontcolor: white.main,
      },
      {
        Header: "ถูกรางวัล",
        accessor: "upline_total_reward",
        bgcolor: success.main,
        fontcolor: white.main,
      },
      {
        Header: "รวม",
        accessor: "upline_receive_total",
        bgcolor: success.main,
        fontcolor: white.main,
      },
    ],
    // rows: dataTypeDetail.map((elem) => ({
    //   downline_user_id: elem.downline_user_id,
    //   downline_name: elem.downline_name,
    //   total_buy: elem.total_buy,
    //   downline_receive: elem.downline_receive,
    //   downline_discount: elem.downline_discount,
    //   downline_receive_discount: elem.downline_receive_discount,
    //   agent_total_receive: elem.agent_total_receive,
    //   agent_total_discount: elem.agent_total_discount,
    //   agent_total_receive_discount: elem.agent_total_receive_discount,
    //   upline_receive: elem.upline_receive,
    //   upline_discount: elem.upline_discount,
    //   upline_receive_total: elem.upline_receive_total,
    // })),
    rows: rednderTotal(),
  };
  const mainTableReport = [
    { Header: "" },
    { Header: "" },
    { Header: "" },
    { Header: "", bgcolor: info.main, fontcolor: white.main },
    { Header: "", bgcolor: info.main, fontcolor: white.main },
    { Header: "ลูกทีม", bgcolor: info.main, fontcolor: white.main },
    { Header: "", bgcolor: info.main, fontcolor: white.main },
    { Header: "", bgcolor: primary.main, fontcolor: white.main },
    { Header: "", bgcolor: primary.main, fontcolor: white.main },
    { Header: "ตัวคุณ", bgcolor: primary.main, fontcolor: white.main },
    { Header: "", bgcolor: primary.main, fontcolor: white.main },
    { Header: "", bgcolor: success.main, fontcolor: white.main },
    { Header: "", bgcolor: success.main, fontcolor: white.main },
    { Header: "แม่ทีม", bgcolor: success.main, fontcolor: white.main },
    { Header: "", bgcolor: success.main, fontcolor: white.main },
  ];
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={false}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        ยอดตามสมาชิก
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <DataTable
          table={report ? dataDetailReport : dataDetail}
          mainHeadTable={report ? mainTableReport : mainTable}
        />
      </DialogContent>
      <DialogActions>
        <SuiButton
          autoFocus
          variant="gradient"
          buttonColor={"error"}
          size="small"
          circular
          onClick={onClose}
        >
          ปิด
        </SuiButton>
      </DialogActions>
    </BootstrapDialog>
  );
}

DialogUser.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dataTypeDetail: PropTypes.array,
  report: PropTypes.bool,
};
