import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Table from "components/Tables/Table";
import SuiSelect from "components/SuiSelect";
import { Switch } from "@mui/material";
import { post } from "utils/helper";
import { HOST } from "utils/config";
import Swal from "sweetalert2";
import {
  Text16,
  TextBlack24,
  LootoList20,
  LootoList18,
  LootoList16,
  LootoList16span,
} from "components/Text/TextStyle";
const columns = [
  { name: "role", align: "center", label: "ระดับชั้น" },
  { name: "user_name", align: "center", label: "ยูเซอร์เนม" },
  { name: "name", align: "center", label: "ติดต่อ	" },
  { name: "all_stock_display", align: "center", label: "ทั้งหมดที่ได้มา" },
  { name: "push_up_line", align: "center", label: "ผลักขึ้นสายบน" },
  { name: "fighting_mem", align: "center", label: "ถือสู้เอง	", position: "absolute" },
];
function fightMax(max, min) {
  let arrStock = [];
  let numberSelectedStock = 0;
  arrStock.push({ value: numberSelectedStock, label: `${numberSelectedStock}%` });
  let maxNumber = max * 2;
  for (let i = min * 2; i < maxNumber; i++) {
    numberSelectedStock = numberSelectedStock + 0.5;
    arrStock.push({ value: numberSelectedStock, label: `${numberSelectedStock}%` });
  }
  return arrStock.reverse();
}
const ManageMember = ({ dataLoto, gatchaId }) => {
  const [dataMember, setDataMember] = useState(dataLoto?.member_fighting);
  const [isLoading, setIsLoading] = useState(false);
  const [fightmem, setFightmem] = useState(0);
  const chageValuesFight = (index, value) => {
    let data = dataMember;
    data[index].push_up_line = data[index].all_stock - value;
    data[index].fighting_mem = value;
    setDataMember([...data]);
  };
  useEffect(() => {
    setDataMember(dataLoto?.member_fighting);
  }, [dataLoto]);
  const onCopyLotto = async (groupId) => {
    setIsLoading(true);

    try {
      let payload = { type: "AGENT", gatcha_id: gatchaId.toString(), group_id: groupId };
      const { data } = await post(`${HOST}api/UserConfig/CloneUserStockToGroupGatcha`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
      console.log(error);
    }
  };
  const onSubmitLotto = async () => {
    setIsLoading(true);

    try {
      let payload = {
        stock: dataMember.map((e, i) => ({
          role_id: e.role_id,
          user_id: e.user_id,
          gatcha_id: gatchaId.toString(),
          push_up_line: e.push_up_line,
          fighting_mem: e.fighting_mem,
        })),
      };
      const { data } = await post(`${HOST}api/UserConfig/SetUserStock`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
      console.log(error);
    }
  };
  const onCopyFight = () => {
    let data = dataMember;

    data.map((e, i) => {
      if (fightmem > e.fighting_mem_max - e.fighting_min) {
        data[i].push_up_line = e.all_stock - (e.fighting_max - e.fighting_min);
        data[i].fighting_mem = e.fighting_max - e.fighting_min;
      } else {
        data[i].push_up_line = e.all_stock - fightmem;
        data[i].fighting_mem = fightmem;
      }
    });
    setDataMember([...data]);
  };
  return (
    <SuiBox p={2} backgroundColor={"#FFF"}>
      <LootoList18>จัดการ เมมเบอร์</LootoList18>
      <SuiBox mb={4} display="flex" justifyContent={"space-between"}>
        {/* <SuiBox>
          <LootoList16span>ตั้งค่ากลุ่มหวยตามหวยไทย:</LootoList16span>
          {dataLoto?.gatcha_groups.map((e, i) => (
            <SuiButton
              disabled={isLoading}
              buttonColor={"info"}
              key={i}
              sx={{ marginRight: 2 }}
              onClick={() => onCopyLotto(e.group_id)}
            >
              {e.group_name}
            </SuiButton>
          ))}
        </SuiBox> */}
        <SuiBox>
          <SuiButton buttonColor="success" onClick={() => onSubmitLotto()}>
            บันทึกทั้งหมด
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <Table
        maincopy={[
          { name: "role", align: "center", children: "ระดับชั้น" },
          { name: "user_name", align: "center", children: "ยูเซอร์เนม" },
          { name: "name", align: "center", children: "ติดต่อ	" },
          { name: "all_stock_display", align: "center", children: "ทั้งหมดที่ได้มา" },
          { name: "push_up_line", align: "center", children: "ผลักขึ้นสายบน" },
          {
            name: "fighting_mem",
            align: "center",
            position: "absolute",
            children: (
              <SuiBox
                display="flex"
                justifyContent="space-between"
                align="center"
                position="relative"
              >
                <SuiSelect
                  placeholder="แบ่งหุ้น"
                  options={fightMax(90, 0)}
                  defaultValue={{ value: fightmem, label: `${fightmem}%` }}
                  value={{ value: fightmem, label: `${fightmem}%` }}
                  onChange={(value) => {
                    setFightmem(value.value);
                  }}
                />
                <SuiButton
                  buttonColor="info"
                  onClick={() => onCopyFight()}
                  style={{ marginLeft: "18px" }}
                >
                  Copy
                </SuiButton>
              </SuiBox>
            ),
          },
          { name: "fighting_mem", align: "center", children: <></> },
        ]}
        columns={columns}
        rows={dataMember?.map((elem, index) => ({
          role: elem.role,
          user_name: elem.user_name,
          name: elem.name,
          all_stock_display: <>{elem.all_stock_display} %</>,
          push_up_line: <>{elem.push_up_line}</>,
          fighting_mem: (
            <SuiBox>
              <SuiSelect
                placeholder="แบ่งหุ้น"
                options={fightMax(elem.fighting_mem_max, elem.fighting_mem_min)}
                defaultValue={{ value: elem.fighting_mem, label: `${elem.fighting_mem}%` }}
                value={{ value: elem.fighting_mem, label: `${elem.fighting_mem}%` }}
                onChange={(value) => {
                  chageValuesFight(index, value.value);
                }}
              />
            </SuiBox>
          ),
        }))}
      />
    </SuiBox>
  );
};

ManageMember.propTypes = {
  dataLoto: PropTypes.object,
  gatchaId: PropTypes.string,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageMember);
