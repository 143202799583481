import { createStore, applyMiddleware, combineReducers } from "redux";
import authenticationReducers from "./authentication/reducer";
import lottoReducers from "./lotto/reducer";
import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";

const rootReducer = combineReducers({
  authentication: authenticationReducers,
  lotto: lottoReducers,
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export { store };
