import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { get } from "utils/helper";
import { HOST } from "utils/config";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import MaterialReactTable from "material-react-table";
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import SuiInput from "components/SuiInput";
import Table from "components/Tables/Table";
import SuiButton from "components/SuiButton";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
let moccktype = [
  { name: "เลขล่าง", value: "under" },
  { name: "เลขบน", value: "on" },
];
const dataTest = [
  {
    name: {
      firstName: "John",
      lastName: "Doe",
    },
    address: "261 Erdman Ford",
    city: "East Daphne",
    state: "Kentucky",
  },
  {
    name: {
      firstName: "Jane",
      lastName: "Doe",
    },
    address: "769 Dominic Grove",
    city: "Columbus",
    state: "Ohio",
  },
  {
    name: {
      firstName: "Joe",
      lastName: "Doe",
    },
    address: "566 Brakus Inlet",
    city: "South Linda",
    state: "West Virginia",
  },
  {
    name: {
      firstName: "Kevin",
      lastName: "Vandy",
    },
    address: "722 Emie Stream",
    city: "Lincoln",
    state: "Nebraska",
  },
  {
    name: {
      firstName: "Joshua",
      lastName: "Rolluffs",
    },
    address: "32188 Larkin Turnpike",
    city: "Charleston",
    state: "South Carolina",
  },
  {
    name: {
      firstName: "Joshua2",
      lastName: "Rolluffs2",
    },
    address: "32188 Larkin Turnpike",
    city: "Charleston",
    state: "South Carolina",
  },
];

function Twogatcha(props) {
  // const { cfid } = useParams();
  const [supposedlyinfo, setSupposedlyinfo] = useState([]);
  const [rowinfo, setRowinfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typeLotto, setTypeLotto] = useState("under");
  const [typeLabel, setTypeLabel] = useState("เลขล่าง");
  const [profit, setProfit] = useState("profit_money");
  const [valueProfit, setValueProfit] = useState(0);
  const [columns, setColumns] = useState([
    { name: "summary", align: "center", label: "ผลออกรางวัล", width: "17.5%" },
    { name: "two_number", align: "center", label: "2 ตัวล่าง", width: "13%" },
    { name: "one_number", align: "center", label: "วิ่งล่าง", width: "13%" },
    { name: "three_number", align: "center", label: "3 ตัวบน", width: "13%" },
    { name: "three_nearby_number", align: "center", label: "3 ตัวโต๊ด", width: "13%" },
    { name: "total_correct", align: "center", label: "จำนวนถูก(รวม)" },
    { name: "profit_money", align: "center", label: "กำไรขาดทุน (บาท)" },
    { name: "profit_percen", align: "center", label: "กำไรขาดทุน (%)" },
  ]);

  const [columnstwonumber, setColumnstwonumber] = useState([
    { accessorKey: "number", align: "center", header: "เลขล่าง", size: 40 },
    {
      id: "two_number", //id used to define `group` column
      header: "2ตัวล่าง",
      columns: [
        {
          accessorKey: "two_number_total_correct", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically

          header: "ถูก",
          size: 40,
        },
        {
          accessorKey: "two_number_turnover", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically

          header: "ได้/เสีย",
          size: 40,
          Cell: (row) => (
            <SuiBox
              color={
                row?.cell?.getValue() === 0 ? "" : row?.cell?.getValue() > 0 ? "success" : "error"
              }
            >
              {row?.cell?.getValue().toLocaleString?.("en-US")} ฿
            </SuiBox>
          ),
        },
      ],
    },
    {
      id: "one_number", //id used to define `group` column
      header: "วิ่งล่าง",
      columns: [
        {
          accessorKey: "one_number_total_correct", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically

          header: "ถูก",
          size: 40,
        },
        {
          accessorKey: "one_number_turnover", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically

          header: "ได้/เสีย",
          size: 40,
          Cell: (row) => (
            <SuiBox
              color={
                row?.cell?.getValue() === 0 ? "" : row?.cell?.getValue() > 0 ? "success" : "error"
              }
            >
              {row?.cell?.getValue().toLocaleString?.("en-US")} ฿
            </SuiBox>
          ),
        },
      ],
    },
    {
      id: "three_number", //id used to define `group` column
      header: "3ตัวล่าง",
      columns: [
        {
          accessorKey: "three_number_total_correct", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically

          header: "ถูก",
          size: 40,
        },
        {
          accessorKey: "three_number_turnover", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically

          header: "ได้/เสีย",
          size: 40,
          Cell: (row) => (
            <SuiBox
              color={
                row?.cell?.getValue() === 0 ? "" : row?.cell?.getValue() > 0 ? "success" : "error"
              }
            >
              {row?.cell?.getValue().toLocaleString?.("en-US")} ฿
            </SuiBox>
          ),
        },
      ],
    },
    {
      id: "three_nearby_number", //id used to define `group` column
      header: "3ตัวโต๊ด",
      columns: [
        {
          accessorKey: "three_nearby_number_total_correct", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically

          header: "ถูก",
          size: 50,
        },
        {
          accessorKey: "three_nearby_number_turnover", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically

          header: "ได้/เสีย",
          size: 50,
          Cell: (row) => (
            <SuiBox
              color={
                row?.cell?.getValue() === 0 ? "" : row?.cell?.getValue() > 0 ? "success" : "error"
              }
            >
              {row?.cell?.getValue().toLocaleString?.("en-US")} ฿
            </SuiBox>
          ),
        },
      ],
    },

    { accessorKey: "summary_total_correct", align: "center", header: "ถูก(รวม)", size: 50 },
    {
      accessorKey: "profit_money",
      align: "center",
      size: 100,
      header: "กำไรขาดทุน (บาท)",
      Cell: (row) => (
        <SuiBox
          color={row?.cell?.getValue() === 0 ? "" : row?.cell?.getValue() > 0 ? "success" : "error"}
        >
          {row?.cell?.getValue().toLocaleString?.("en-US")} ฿
        </SuiBox>
      ),
    },
    {
      accessorKey: "profit_percen",
      align: "center",
      size: 300,
      header: "กำไรขาดทุน (%)",
      Cell: (row) => (
        <SuiBox
          color={row?.cell?.getValue() === 0 ? "" : row?.cell?.getValue() > 0 ? "success" : "error"}
        >
          {row?.cell?.getValue().toFixed(2)}%
        </SuiBox>
      ),
    },
  ]);
  const columnMemo = useMemo(() => columnstwonumber, [columnstwonumber]);
  const [search, setSearch] = useState("");
  const fetchsupposedly = async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${HOST}api/Supposedly/GetSupposedlyNumber?gatcha_drawing_configs_id=${props.cfid}&gatcha_type=${typeLotto}&${profit}=${valueProfit}&number=${search}`
      );

      data.result.buy_item_list.label = "ซื้อ";
      data.result.com_item_list.label = "คอม";
      data.result.receive_item_list.label = "รับ";
      let dataArr = [];
      dataArr.push(data.result.buy_item_list);
      dataArr.push(data.result.com_item_list);
      dataArr.push(data.result.receive_item_list);

      const rownaja = dataArr?.map((elem, index) => ({
        summary: (
          <SuiBox display="flex" justifyContent="center">
            <SuiBox mr={3}>{elem.label}</SuiBox>{" "}
            <SuiBox color={elem.summary === 0 ? "" : elem.summary > 0 ? "success" : "error"}>
              {elem.summary?.toFixed(2)}
            </SuiBox>
          </SuiBox>
        ),
        two_number: (
          <SuiBox color={elem?.two_number === 0 ? "" : elem?.two_number > 0 ? "success" : "error"}>
            {elem.two_number?.toFixed(2) || "-"}
          </SuiBox>
        ),
        one_number: (
          <SuiBox color={elem?.one_number === 0 ? "" : elem?.one_number > 0 ? "success" : "error"}>
            {elem.one_number?.toFixed(2) || "-"}
          </SuiBox>
        ),
        three_number: (
          <SuiBox
            color={elem?.three_number === 0 ? "" : elem?.three_number > 0 ? "success" : "error"}
          >
            {elem?.three_number?.toFixed(2) || "-"}
          </SuiBox>
        ),
        three_nearby_number: (
          <SuiBox
            color={
              elem?.three_nearby_number === 0
                ? ""
                : elem?.three_nearby_number > 0
                ? "success"
                : "error"
            }
          >
            {elem?.three_nearby_number?.toFixed(2) || "-"}
          </SuiBox>
        ),
        total_correct: (
          <SuiBox
            color={elem.total_correct === 0 ? "" : elem.total_correct > 0 ? "success" : "error"}
          >
            {elem.total_correct?.toFixed(2)}
          </SuiBox>
        ),
        profit_money: (
          <SuiBox
            color={elem.profit_money === 0 ? "" : elem.profit_money > 0 ? "success" : "error"}
          >
            {elem.profit_money?.toFixed(2)}
          </SuiBox>
        ),
        profit_percen: (
          <SuiBox
            color={elem.profit_percen === 0 ? "" : elem.profit_percen > 0 ? "success" : "error"}
          >
            {elem.profit_percen?.toFixed(2)}%
          </SuiBox>
        ),
        key: index,
      }));
      // const rownumber = data.result.buy_number_item_list.map((e, i) => ({
      //   number: <SuiBox>{e.number} </SuiBox>,
      //   two_number: (
      //     <SuiBox display="flex" justifyContent="space-between">
      //       <SuiBox>{e.two_number_total_correct || 0}</SuiBox>
      //       <SuiBox
      //         color={
      //           e.two_number_turnover === 0 ? "" : e.two_number_turnover > 0 ? "success" : "error"
      //         }
      //       >
      //         {e.two_number_turnover?.toFixed(2) || 0}
      //       </SuiBox>
      //     </SuiBox>
      //   ),
      //   one_number: (
      //     <SuiBox display="flex" justifyContent="space-between">
      //       <SuiBox>{e.one_number_total_correct || 0}</SuiBox>
      //       <SuiBox
      //         color={
      //           e.one_number_turnover === 0 ? "" : e.one_number_turnover > 0 ? "success" : "error"
      //         }
      //       >
      //         {e.one_number_turnover?.toFixed(2) || 0}
      //       </SuiBox>
      //     </SuiBox>
      //   ),
      //   three_number: (
      //     <SuiBox display="flex" justifyContent="space-between">
      //       <SuiBox>{e.three_number_total_correct || 0}</SuiBox>
      //       <SuiBox
      //         color={
      //           e.three_number_turnover === 0
      //             ? ""
      //             : e.three_number_turnover > 0
      //             ? "success"
      //             : "error"
      //         }
      //       >
      //         {e.three_number_turnover?.toFixed(2) || 0}
      //       </SuiBox>
      //     </SuiBox>
      //   ),
      //   three_number_near: (
      //     <SuiBox display="flex" justifyContent="space-between">
      //       <SuiBox>{e.three_nearby_number_total_correct || 0}</SuiBox>
      //       <SuiBox
      //         color={
      //           e.three_nearby_number_turnover === 0
      //             ? ""
      //             : e.three_nearby_number_turnover > 0
      //             ? "success"
      //             : "error"
      //         }
      //       >
      //         {e.three_nearby_number_turnover?.toFixed(2) || 0}
      //       </SuiBox>
      //     </SuiBox>
      //   ),
      //   summary_total_correct: e.summary_total_correct || 0,
      //   profit_money: e.profit_money?.toFixed(2) || 0,

      //   profit_percen: e.profit_percen?.toFixed(2) || 0,
      // }));
      columnstwonumber[0].header = typeLabel;
      columnstwonumber[1].header = typeLabel === "เลขล่าง" ? "2 ตัวล่าง" : "2 ตัวบน";
      columnstwonumber[2].header = typeLabel === "เลขล่าง" ? "วิ่งล่าง" : "วิ่งบน";
      columnstwonumber[3].header = typeLabel === "เลขล่าง" ? "3 ตัวล่าง" : "3 ตัวบน";
      // columnstwonumber[4].header = typeLabel;
      // columnstwonumber[5].header = typeLabel;
      columns[1].label = typeLabel === "เลขล่าง" ? "2 ตัวล่าง" : "2 ตัวบน";
      columns[2].label = typeLabel === "เลขล่าง" ? "วิ่งล่าง" : "วิ่งบน";
      columns[3].label = typeLabel === "เลขล่าง" ? "3 ตัวล่าง" : "3 ตัวบน";
      setColumns([...columns]);
      setColumnstwonumber([...columnstwonumber]);
      setSupposedlyinfo(data?.result?.buy_number_item_list);
      setRowinfo(rownaja);
      setLoading(false);
    } catch (error) {
      setSupposedlyinfo([]);
      setRowinfo([]);
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchsupposedly();
  }, [props.cfid]);
  return (
    <>
      {" "}
      <SuiBox backgroundColor="#FFF" p={4} mb={3} borderRadius={"25px"}>
        <SuiBox>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <SuiBox width="100%" display="flex">
                <SuiTypography mr={2}>แสดง</SuiTypography>
                <SuiBox width="100%">
                  <SuiSelect
                    placeholder="โปรดเลือกประเภทหวย"
                    defaultValue={{ value: moccktype[0].value, label: moccktype[0].name }}
                    options={moccktype.map((e) => ({
                      value: e.value,
                      label: e.name,
                    }))}
                    onChange={(value) => {
                      setTypeLabel(value.label);
                      setTypeLotto(value.value);
                    }}
                  />
                </SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={6}>
              <SuiBox display="flex">
                <SuiTypography mr={2}>แสดง </SuiTypography>
                <SuiBox display="flex" width="100%">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => setProfit(e.target.value)}
                    value={profit}
                  >
                    <FormControlLabel value="profit_money" control={<Radio />} label="กำไร(บาท)" />
                    <FormControlLabel value="profit_percen" control={<Radio />} label="กำไร(%)" />
                  </RadioGroup>
                  <SuiBox>
                    <SuiInput
                      name="valueProfit"
                      value={valueProfit}
                      onChange={(e) => setValueProfit(e.target.value)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </SuiBox>
                  <SuiBox ml={3}>
                    <SuiButton buttonColor={"info"} onClick={() => fetchsupposedly()}>
                      แสดง
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox display="flex" mt={2}>
          <SuiBox mr={2}>ค้นหาตามหมายเลข</SuiBox>
          <SuiBox mr={2}>
            <SuiInput
              name="search"
              value={search}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </SuiBox>

          <SuiButton buttonColor={"info"} onClick={() => fetchsupposedly()}>
            แสดง
          </SuiButton>
        </SuiBox>
      </SuiBox>
      {loading ? (
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={"100%"} height={200} />
          <Skeleton variant="rectangular" width={"100%"} height={500} />
        </Stack>
      ) : (
        <>
          {rowinfo.length > 0 ? (
            <>
              <SuiBox>
                <Table columns={columns} rows={rowinfo} />
              </SuiBox>
              <SuiBox mt={2}>
                <MaterialReactTable enableResizing columns={columnMemo} data={supposedlyinfo} />
                {/* <Table columns={columnstwonumber} rows={supposedlyinfo} /> */}
              </SuiBox>
            </>
          ) : (
            <SuiBox textAlign="center">ไม่พบข้อมูล</SuiBox>
          )}
        </>
      )}
    </>
  );
}

Twogatcha.propTypes = {
  cfid: PropTypes.any,
};

export default Twogatcha;
