import { Route, Switch, useLocation } from "react-router";

import React, { useState, useEffect } from "react";
import AuthRoute from "utils/AuthRoute";
import { useHistory, useParams } from "react-router";
import LottoList from "./lottolist";
import LottoNumber from "./lottoNumber";
import LottoListTable from "./lottolisttable";
import PropTypes from "prop-types";
function RiskLimitRoute({ dataLotto }) {
  return (
    <>
      <Switch>
        <Route
          path={"/risk/limit/:id/category"}
          render={() => <LottoList dataLotto={dataLotto} />}
          key={"limit1"}
          isAuth={"AGENT"}
        />
        <Route
          path={"/risk/limit/:id/number"}
          render={() => <LottoNumber dataLotto={dataLotto} />}
          key={"limit2"}
          isAuth={"AGENT"}
        />
        <Route
          path={"/risk/limit/:id/table"}
          render={() => <LottoListTable dataLotto={dataLotto} />}
          key={"limit3"}
          isAuth={"AGENT"}
        />
        <Route
          path={"/risk/limit/:id/"}
          render={() => <LottoNumber dataLotto={dataLotto} />}
          key={"limit4"}
          isAuth={"AGENT"}
        />
      </Switch>
    </>
  );
}
RiskLimitRoute.propTypes = {
  dataLotto: PropTypes.array,
};
export default RiskLimitRoute;
