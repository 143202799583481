import Style from "./style.js";
import "./index.scss";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";
import SuiButton from "components/SuiButton";
import { HOST } from "utils/config";
import { get } from "utils/helper";
import { post } from "utils/helper/request";

const useStyle = makeStyles(Style);

function AddBill({ formData, onsetTotaldata }) {
  const gatchaFlashNumber = formData.values.gatchaFlashNumber;

  //   console.log("gatchaFlashNumber = ", formData.values.gatchaFlashNumber, "55555");

  const deleteLottoItem = async (index) => {
    var arr = formData?.values?.gatchaFlashNumber;
    await arr.splice(index, 1);
    formData.setFieldValue("gatchaFlashNumber", arr);
    var num = 0;
    console.log(arr, "arr");
    await arr.map((e, i) => {
      for (var x = 0; x < e.gatchaNumber.length; x++) {
        // num = num + parseInt(e.numberUp || 0) + parseInt(e.numberDown || 0);
        num =
          num +
          parseInt(e.numberUp || 0) +
          parseInt(e.numberDown || 0) +
          parseInt(e.numberMulti || 0);
      }
    });

    handledsetTotal(num);
  };
  const handledsetTotal = (num) => {
    onsetTotaldata(num, "fast");
  };

  return (
    <>
      <div>
        {formData.values.gatchaFlashNumber.map((e, i) => (
          <div key={i} className="add-bill">
            <div className="lotto-detail">
              {e.type == "1" && <p>1 วิ่ง</p>}
              {e.type == "2" && <p>2 ตัว</p>}
              {e.type == "3" && <p>3 ตัว</p>}
              {e.type == "4" && <p>3 ตัว</p>}
              {e.type == "1" && (
                <p>
                  {e.numberUp ? "บน" : ""} {e.numberUp && e.numberDown ? "x" : ""}{" "}
                  {e.numberDown ? "ล่าง" : ""}
                  {e.numberMulti ? "โต๊ด" : ""}
                </p>
              )}
              {e.type == "2" && (
                <p>
                  {e.numberUp ? "บน" : ""} {e.numberUp && e.numberDown ? "x" : ""}{" "}
                  {e.numberDown ? "ล่าง" : ""}
                </p>
              )}
              {e.type == "3" && (
                <p>
                  {e.numberUp ? "บน" : ""}
                  {e.numberUp && e.numberMulti ? "x" : ""}
                  {e.numberMulti ? "โต๊ด" : ""}
                </p>
              )}
              {e.type == "4" && <p>บน</p>}
              <p>
                {e.numberUp ? e.numberUp : ""} {e.numberUp && e.numberDown ? "x" : ""}{" "}
                {e.numberDown ? e.numberDown : ""}
                {e.numberUp && e.numberMulti ? "x" : ""} {e.numberMulti ? e.numberMulti : ""}
              </p>
            </div>
            <div className="lotto-number">
              {e.gatchaNumber.map((el, ind) => (
                <span key={ind}>{el} </span>
              ))}
            </div>
            <div className="delete-button-group">
              <div className="button" onClick={() => deleteLottoItem(i)}>
                <p>ลบ</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

AddBill.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onsetTotaldata: PropTypes.func,
};

export default AddBill;
