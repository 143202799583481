import Style from "./style.js";
import "./index.scss";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";
import SuiButton from "components/SuiButton";
import { HOST } from "utils/config";
import { get } from "utils/helper";
import { post } from "utils/helper/request";
import CircularProgress from "@mui/material/CircularProgress";

import ListSelect from "memberpage/pages/play-game/list-select";

const useStyle = makeStyles(Style);

function ShowBill({ lastHistory, dataLimit, dataBillLotto }) {
  const classes = useStyle();
  const { id } = useParams();

  // const fetchLotto = async () => {
  //   try {
  //     setIsloading(true);
  //     const { data } = await get(`${HOST}api/Gatcha/GetGatchaForSpinByGatchaId?gatcha_id=${id}`);
  //     const { gatcha_drawing_configs_id, gatcha_id } = data.result;
  //     setDataLotto(data.result);
  //     const dataLimit = await get(`${HOST}api/Gatcha/GetGatchaLimitForShow?gatcha_id=${gatcha_id}&gatcha_drawing_id=${gatcha_drawing_configs_id}`);
  //     setDataLimit(dataLimit.data.results);
  //     const dataLast = await get(`${HOST}api/Gatcha/GetLastThreeDrawHistory?gatcha_id=${id}`);
  //     setLastHistory(dataLast.data.results);
  //     setIsloading(false);
  //   } catch (error) {}
  // };
  // useEffect(() => {
  //   fetchLotto();
  // }, []);

  return (
    <>
      <div className="card-right">
        {/* {lastHistory?.length < 1 &&<div className="isLoading">
            <CircularProgress />
        </div>} */}
        <table className="card-table">
          <thead className="head1">
            <tr>
              <td>สถิติผลรางวัล</td>
              <td>3 งวดล่าสุด</td>
              <td>3 ตัวบน</td>
              <td>2 ตัวบน</td>
              <td>2 ตัวล่าง</td>
            </tr>
          </thead>
          <tbody className="body2">
            {lastHistory?.map((e, i) => (
              <tr key={i}>
                <td>{e.gatcha_name}</td>
                <td>{e.drawing_date_display}</td>
                <td>{e.three_On}</td>
                <td>{e.two_On}</td>
                <td>{e.two_Under}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* {dataLimit.length < 1 &&<div className="isLoading">
            <CircularProgress />
        </div>} */}
        <table className="card-table">
          <thead className="head1">
            <tr>
              <td>เลขมาแรง</td>
              <td>จ่ายครึ่ง</td>
              <td>เลขปิดรับ</td>
            </tr>
          </thead>
          <tbody className="body2">
            {dataLimit?.map((e, i) => (
              <tr key={i}>
                <td>{e.gatcha_type}</td>
                <td>-</td>
                <td>{e.gatcha_number_limit ? e.gatcha_number_limit : "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* {dataBillLotto.length < 1 &&<div className="isLoading">
            <CircularProgress />
        </div>} */}
        <ListSelect dataBillLotto={dataBillLotto} />
      </div>
    </>
  );
}

ShowBill.propTypes = {
  lastHistory: PropTypes.array,
  dataLimit: PropTypes.array,
  dataBillLotto: PropTypes.array,
};

export default ShowBill;
