import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";
import gridstyle from "assets/style/gridstyle";
import Navbar from "memberpage/pages/navbar";
import CircularProgress from "@mui/material/CircularProgress";

import "./index.scss";

function Financial() {
  const [datalist, setDatalist] = useState([]);
  const [datalistBalance, setDatalistBalance] = useState([]);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = gridstyle();
  const history = useHistory();

  const fetchTranscript = async () => {
    try {
      setIsLoading(true);
      const { data } = await get(`${HOST}api/User/GetBalanceSheet`);
      //   console.log(data, "data");
      setDatalist(data?.result?.balance_sheet_list);
      setDatalistBalance(data?.result);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTranscript();
  }, []);

  return (
    <DashboardLayout>
      <Navbar />
      <div className="financial">
        {isLoading && (
          <div className="isLoading">
            <CircularProgress />
          </div>
        )}
        {!isLoading && (
          <>
            <table className="card-table">
              <thead className="head1">
                <tr>
                  <td>วันที่</td>
                  <td>ตลาด</td>
                  <td>งวด</td>
                  <td>ได้/เสีย</td>
                  <td>เคลียร์ยอด</td>
                  <td>งบดุล</td>
                </tr>
              </thead>
              <tbody className="body2">
                {datalist?.map((e, i) => (
                  <>
                    {datalist.length > 0 && (
                      <tr key={i}>
                        <td style={{ textAlign: "center" }}>{e.date_display}</td>
                        {e.gatcha_drawing_date_display !== "-" && (
                          <td style={{ textAlign: "center" }}>
                            {e.gatcha_drawing_date_display === "-" ? "-" : e.gatcha_name}
                          </td>
                        )}
                        <td
                          className={
                            e.gatcha_drawing_date_display === "-" ? "text-center" : "nav-link"
                          }
                          colSpan={e.gatcha_drawing_date_display === "-" ? 3 : 1}
                          onClick={() =>
                            e.gatcha_drawing_date_display === "-"
                              ? ""
                              : history.push(`/get-result/${e.gatcha_drawing_config_id}`)
                          }
                        >
                          {e.gatcha_drawing_date_display === "-"
                            ? "-- โอนยอด --"
                            : e.gatcha_drawing_date_display}
                        </td>
                        {e.gatcha_drawing_date_display !== "-" && (
                          <td className={e.loss_profit > 1 ? "text-green" : "text-red"}>
                            {e.gatcha_drawing_date_display === "-" ? "-" : e.loss_profit_display}
                          </td>
                        )}
                        <td style={{ textAlign: "center" }}>{e.transfer_display}</td>
                        <td className={e.balance > 1 ? "text-green" : "text-red"}>
                          {e.balance_display}
                        </td>
                      </tr>
                    )}
                    {datalist.length < 1 && (
                      <tr>
                        <td
                          colSpan={6}
                          style={{ textAlign: "center", color: "red", fontSize: "1rem" }}
                        >
                          ไม่มีข้อมูล
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
            <table className="card-table2">
              <thead className="head1">
                <tr>
                  <td>สรุปบัญชีการเงิน</td>
                  <td></td>
                </tr>
              </thead>
              <tbody className="body2">
                <tr>
                  <td>เครดิตจำกัด</td>
                  <td className="text-right">{datalistBalance.balance?.total_credit_display}</td>
                </tr>
                <tr>
                  <td>งบดุลบัญชี</td>
                  <td className="text-right">{datalistBalance.balance?.loss_profit_display}</td>
                </tr>
                <tr>
                  <td>พนันคงค้าง</td>
                  <td className="text-right">{datalistBalance.balance?.credit_on_bet_display}</td>
                </tr>
                <tr>
                  <td>เครดิตพนันที่แทงได้</td>
                  <td className="text-right">{datalistBalance.balance?.credit_balance_display}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </DashboardLayout>
  );
}

Financial.propTypes = {};
export default Financial;
