import { useTable, usePagination } from "react-table";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { TableCell, TableHead } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import "./index.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table16 } from "components/Text/TextStyle";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import { delV4 } from "utils/helper";
import { HOST } from "utils/config";
import Swal from "sweetalert2";
function Paginated({ data, columns }) {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const options = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 50, label: 50 },
  ];

  const { pageIndex, pageSize } = state;
  const [tableValue, setTableValue] = useState(-1);
  const [optionValue, setOptionValue] = useState(10);

  const showDetail = (value) => {
    if (tableValue == parseInt(value)) {
      setTableValue(-1);
    } else {
      setTableValue(parseInt(value));
    }
  };

  const onChangeOptionValue = (event) => {
    setOptionValue(event);
    setPageSize(event);
  };

  const deletebill = async (bill_id) => {
    Swal.fire({
      title: "คุณต้องการลบบิลใช่หรือไม่",
      text: "คุณจะไม่สามารถย้อนกลับได้ !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ใช่, ลบบิลนี้เลย !",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const data = await delV4(`${HOST}api/Gatcha/DeleteBetBill/${bill_id}`);

          Swal.fire("ลบบิลสำเร็จ!", "บิลของคุณได้ถูกลบเรียบร้อย", "success").then((results) => {
            window.location.reload();
          });
        } catch (error) {
          Swal.fire("เกิดข้อผิดพลาด", error.response.data.errorMessage, "error");
          console.log(error);
        }
      }
    });
  };

  return (
    <>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()} key={column}>
                  <Table16>{column.render("Header")}</Table16>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            // console.log("row = ",row.original?.bet_number_list)
            prepareRow(row);
            return (
              <>
                <TableRow
                  {...row.getRowProps()}
                  key={row}
                  onClick={() => {
                    showDetail(row?.id);
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()} key={cell}>
                        <Table16>{cell.render("Cell")}</Table16>
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  {tableValue == row.id && (
                    <TableCell colSpan="12">
                      <div className="detail-bill-contain">
                        <Table>
                          <TableHead className="detail-bill-table-header">
                            <TableCell>
                              <Table16>ประเภท @ หมายเลข</Table16>
                            </TableCell>
                            <TableCell>
                              <Table16>ยอดเดิมพัน</Table16>
                            </TableCell>
                            <TableCell>
                              <Table16>เรทจ่าย</Table16>
                            </TableCell>
                            <TableCell>
                              <Table16>ส่วนลด</Table16>
                            </TableCell>
                          </TableHead>
                          <TableBody>
                            {row.original?.bet_number_list.map((e, i) => (
                              <TableRow key={i}>
                                <TableCell>
                                  <Table16>
                                    {e.type} @ {e.number}
                                  </Table16>
                                </TableCell>
                                <TableCell>
                                  <Table16>{e.total_buy_display}</Table16>
                                </TableCell>
                                <TableCell>
                                  <Table16>{e.reward_rate_display}</Table16>
                                </TableCell>
                                <TableCell>
                                  <Table16>{e.discount_display}</Table16>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <SuiBox mt={2} display="flex" justifyContent="end">
                          <SuiButton
                            buttonColor="error"
                            sx={{ padding: "4px", minWidth: "40px" }}
                            onClick={() => deletebill(row.original?.bill_id)}
                          >
                            <DeleteIcon />
                          </SuiButton>
                        </SuiBox>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
      <div className="show-all-bill">
        <div>
          <p
            className="button"
            onClick={() => {
              history.push(`/list-select`);
            }}
          >
            แสดงรายการทั้งหมด
          </p>
        </div>
      </div>
    </>
  );
}

Paginated.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
};

export default Paginated;
