/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import SuiBadgeDot from "components/SuiBadgeDot";
import Swal from "sweetalert2";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
// Soft UI Dashboard PRO React example components

import Footer from "layouts/Footer";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";
// Reports page components
import Reviews from "memberpage/pages/users/reports/components/Reviews";
import gridstyle from "assets/style/gridstyle";
// Data
const columns = [
  { name: "time", align: "left", label: "วันเวลา" },
  { name: "market", align: "left", label: "ตลาด" },
  { name: "period", align: "center", label: "ประจำงวดที่" },
  { name: "account", align: "center", label: "รายได้/รายจ่าย" },
  { name: "clear", align: "center", label: "เคลียร์ยอด " },
  { name: "balance", align: "center", label: "งบดุล " },
];
function DialogReport({ open, onClose, userId }) {
  const [datalist, setDatalist] = useState([]);
  const [rows, setRows] = useState([]);
  const classes = gridstyle();
  const fetchTranscript = async () => {
    try {
      const { data } = await get(`${HOST}api/User/GetBalanceSheet?user_id=${userId}`);
      console.log(data, "data");
      setDatalist(data?.result);
      const rownaja = data?.result?.balance_sheet_list.map((elem) => ({
        time: elem.date_display,
        market: elem.gatcha_name,
        period:
          elem.gatcha_drawing_date_display === "-" ? "โอยอด" : elem.gatcha_drawing_date_display,
        account: (
          <SuiTypography textColor={elem.loss_profit > 0 ? "success" : "error"} variant="subtitle2">
            {elem.loss_profit_display}
          </SuiTypography>
        ),
        clear: (
          <SuiTypography textColor={elem.transfer > 0 ? "success" : "error"} variant="subtitle2">
            {elem.transfer_display}
          </SuiTypography>
        ),
        balance: (
          <SuiTypography textColor={elem.balance > 0 ? "success" : "error"} variant="subtitle2">
            {elem.balance_display}
          </SuiTypography>
        ),
      }));
      setRows(rownaja);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchTranscript();
  }, [userId]);
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
      <DialogTitle>ประวัติ ได้/เสีย-โอนยอด </DialogTitle>
      <SuiBox p={4}>
        {rows.length > 0 ? <Table columns={columns} rows={rows} /> : "ไม่พบข้อมูล"}
        <SuiBox
          mt={4}
          fontSize={16}
          backgroundColor="white"
          borderRadius={25}
          p={2}
          sx={{ boxShaow: "0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%)" }}
        >
          <Grid container>
            <Grid item lg={8}>
              สรุปบัญชีการเงิน
            </Grid>
            <Grid item lg={4}></Grid>
            <Grid item lg={8} className={classes.borderGrid}>
              เครดิตจำกัด
            </Grid>
            <Grid item lg={4} className={classes.borderGrid}>
              {datalist.balance?.total_credit_display}
            </Grid>
            <Grid item lg={8} className={classes.borderGrid}>
              งบดุลบัญชี
            </Grid>
            <Grid item lg={4} className={classes.borderGrid}>
              <SuiTypography
                textColor={datalist.balance?.loss_profit > 0 ? "success" : "error"}
                variant="subtitle2"
              >
                {datalist.balance?.loss_profit_display}
              </SuiTypography>
            </Grid>
            <Grid item lg={8} className={classes.borderGrid}>
              พนันคงค้าง
            </Grid>
            <Grid item lg={4} className={classes.borderGrid}>
              {datalist.balance?.credit_on_bet_display}
            </Grid>
            <Grid item lg={8} className={classes.borderGrid}>
              เครดิตพนันที่แทงได้
            </Grid>
            <Grid item lg={4} className={classes.borderGrid}>
              {datalist.balance?.credit_balance_display}
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <DialogActions>
        <SuiButton buttonColor="error" onClick={onClose}>
          ปิด
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
}

// Typechecking props for BaseLayout
DialogReport.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  userId: PropTypes.string,
};
export default DialogReport;
