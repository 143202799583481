import colors from "assets/theme/base/colors";
const { info, white, primary, success } = colors;
export const mainTable = [
  { Header: "" },
  { Header: "" },
  { Header: "", bgcolor: info.main, fontcolor: white.main },
  { Header: "ลูกทีม", bgcolor: info.main, fontcolor: white.main },
  { Header: "", bgcolor: info.main, fontcolor: white.main },
  { Header: "", bgcolor: info.main, fontcolor: white.main },
  { Header: "", bgcolor: primary.main, fontcolor: white.main },
  { Header: "ตัวคุณ", bgcolor: primary.main, fontcolor: white.main },
  { Header: "", bgcolor: primary.main, fontcolor: white.main },
  { Header: "", bgcolor: primary.main, fontcolor: white.main },

  { Header: "", bgcolor: success.main, fontcolor: white.main },
  { Header: "แม่ทีม", bgcolor: success.main, fontcolor: white.main },
  { Header: "", bgcolor: success.main, fontcolor: white.main },
  { Header: "", bgcolor: success.main, fontcolor: white.main },
];
export const mainTableUser = [
  { Header: "" },
  { Header: "" },
  { Header: "" },
  { Header: "", bgcolor: info.main, fontcolor: white.main },
  { Header: "ลูกทีม", bgcolor: info.main, fontcolor: white.main },
  { Header: "", bgcolor: info.main, fontcolor: white.main },
  { Header: "", bgcolor: info.main, fontcolor: white.main },
  { Header: "", bgcolor: primary.main, fontcolor: white.main },
  { Header: "ตัวคุณ", bgcolor: primary.main, fontcolor: white.main },
  { Header: "", bgcolor: primary.main, fontcolor: white.main },
  { Header: "", bgcolor: primary.main, fontcolor: white.main },
  { Header: "", bgcolor: success.main, fontcolor: white.main },
  { Header: "แม่ทีม", bgcolor: success.main, fontcolor: white.main },
  { Header: "", bgcolor: success.main, fontcolor: white.main },
  { Header: "", bgcolor: success.main, fontcolor: white.main },
];
