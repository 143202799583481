import { get, post, posts } from "utils/helper/request";
import Swal from "sweetalert2";
import { HOST } from "utils/config";
import { useHistory } from "react-router";
export const authenticateAction = ({ username, password }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "Auth/Loading", isLoading: true });
      const payload = new FormData();

      payload.append("username", username);
      payload.append("password", password);

      const { data } = await post(`${HOST}api/Account/Login`, payload);

      localStorage.setItem("access_token", data.result?.token);
      localStorage.setItem("expires_in", data.result?.token_expires_in);
      localStorage.setItem("refresh_token", data.result?.refresh_token);
      localStorage.setItem("token_expires_date", data.result?.token_expires_date);
      const login = {
        user: data.result,
        isAuthenticated: true,
        type: "Auth/Success",
      };

      return dispatch(login);
    } catch (error) {
      console.log(error.response.data.message);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
        // footer: '<a href="">Why do I have this issue?</a>',
      });
      // dispatch({
      //   type: "Auth/Failure",
      //   message: "Auth/Failure",
      // });
      // throw error;
    }
  };
};
export const checkAuthAction = (token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "Auth/Loading", isLoading: true });

      const url = HOST + "api/User/GetUserProfile";
      const { data } = await get(url);
      const login = {
        user: data.result,
        isAuthenticated: true,
        type: "Auth/Success",
      };
      return dispatch(login);
    } catch (e) {
      dispatch({ type: "Auth/Loading", isLoading: false });
      // window.location.assign("/sign-in");
      return dispatch({
        type: "Auth/Failure",
        message: "",
      });
    }
  };
};
