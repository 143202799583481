/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes, { array } from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import MaterialReactTable from "material-react-table";
// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiPagination from "components/SuiPagination";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";
import TableSigle from "../Table";

import { Table16, Table18 } from "components/Text/TextStyle";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  isCollape,
  isCollapeCallApi,
  collapseBgColor,
  collapseColumns,
  collapseRowsName,
  datacollapseApi,
  onCollapseApi,
  mainHeadTable,
}) {
  const { defaultValue, entries } = entriesPerPage;
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const [open, setOpen] = useState(false);
  const [openindex, setOpenIndex] = useState(null);
  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <SuiPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </SuiPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <TableContainer className="shadow-none">
      {entriesPerPage || canSearch ? (
        <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <SuiBox display="flex" alignItems="center">
              <SuiSelect
                defaultValue={{ value: defaultValue, label: defaultValue }}
                options={entries.map((entry) => ({ value: entry, label: entry }))}
                onChange={setEntriesPerPage}
                size="small"
              />
              <SuiTypography variant="caption" textColor="secondary">
                &nbsp;&nbsp; entries per page
              </SuiTypography>
            </SuiBox>
          )}
          {canSearch && (
            <SuiBox width="12rem" ml="auto">
              <SuiInput
                placeholder="Search..."
                value={search}
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </SuiBox>
          )}
        </SuiBox>
      ) : null}
      <Table {...getTableProps()}>
        <SuiBox component="thead">
          {mainHeadTable?.length > 0 && (
            <TableRow>
              {mainHeadTable.map((e, i) => (
                <DataTableHeadCell
                  sorted={false}
                  bgcolor={e.bgcolor}
                  fontcolor={e.fontcolor}
                  key={i}
                >
                  <Table18>{e.Header}</Table18>
                </DataTableHeadCell>
              ))}
            </TableRow>
          )}
          {headerGroups.map((headerGroup, i) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={i}>
              {isCollape && (
                <DataTableHeadCell
                  width={"10%"}
                  sorted={false}
                  bgcolor={collapseBgColor}
                ></DataTableHeadCell>
              )}
              {isCollapeCallApi && (
                <DataTableHeadCell
                  width={"10%"}
                  sorted={false}
                  bgcolor={collapseBgColor}
                ></DataTableHeadCell>
              )}
              {headerGroup.headers.map((column, i) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                  key={i}
                  bgcolor={column.bgcolor}
                  fontcolor={column.fontcolor}
                >
                  <Table18>{column.render("Header")}</Table18>
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </SuiBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <>
                <TableRow {...row.getRowProps()} key={key}>
                  {isCollapeCallApi && (
                    <DataTableBodyCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          onCollapseApi(row.original.results_id);
                          if (openindex !== key) {
                            setOpenIndex(key);
                          } else {
                            setOpenIndex(null);
                          }
                        }}
                      >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        <Table16>คลิกที่นี่</Table16>
                      </IconButton>
                    </DataTableBodyCell>
                  )}
                  {isCollape && (
                    <DataTableBodyCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          if (openindex !== key) {
                            setOpenIndex(key);
                          } else {
                            setOpenIndex(null);
                          }
                        }}
                      >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        <Table16>คลิกที่นี่</Table16>
                      </IconButton>
                    </DataTableBodyCell>
                  )}
                  {row.cells.map((cell, i) => (
                    <>
                      <DataTableBodyCell
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                        key={i}
                        // bgcolor={cell.bgcolor}
                        // fontcolor={cell.fontcolor}
                      >
                        <Table16>{cell.render("Cell")}</Table16>
                      </DataTableBodyCell>
                    </>
                  ))}
                </TableRow>

                {isCollape && (
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
                      colSpan={6}
                    >
                      <SuiBox sx={{ margin: 1, width: "100%" }}>
                        <Collapse in={key === openindex} timeout="auto" unmountOnExit>
                          {console.log(row?.original?.[collapseRowsName])}
                          <MaterialReactTable
                            enableResizing
                            columns={collapseColumns}
                            data={row?.original?.[collapseRowsName]}
                          />
                          {/* <TableSigle
                            columns={collapseColumns}
                            rows={row?.original?.[collapseRowsName]}
                          /> */}
                        </Collapse>
                      </SuiBox>
                    </TableCell>
                  </TableRow>
                )}
                {isCollapeCallApi && (
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
                      colSpan={6}
                    >
                      <SuiBox sx={{ margin: 1, width: "100%" }}>
                        <Collapse in={key === openindex} timeout="auto" unmountOnExit>
                          <TableSigle columns={collapseColumns} rows={datacollapseApi} />
                        </Collapse>
                      </SuiBox>
                    </TableCell>
                  </TableRow>
                )}
              </>
            );
          })}
        </TableBody>
      </Table>

      <SuiBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <SuiBox mb={{ xs: 3, sm: 0 }}>
            <SuiTypography variant="button" textColor="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </SuiTypography>
          </SuiBox>
        )}
        {pageOptions.length > 1 && (
          <SuiPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <SuiPagination item onClick={() => previousPage()}>
                <Icon className=" bold">chevron_left</Icon>
              </SuiPagination>
            )}
            {renderPagination.length > 6 ? (
              <SuiBox width="5rem" mx={1}>
                <SuiInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </SuiBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <SuiPagination item onClick={() => nextPage()}>
                <Icon className=" font-bold">chevron_right</Icon>
              </SuiPagination>
            )}
          </SuiPagination>
        )}
      </SuiBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  isCollape: false,
  mainHeadTable: [],
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  isCollape: PropTypes.bool,
  isCollapeCallApi: PropTypes.bool,
  collapseColumns: PropTypes.array,
  collapseRowsName: PropTypes.string,
  datacollapseApi: PropTypes.array,
  onCollapseApi: PropTypes.func,
  mainHeadTable: PropTypes.array,
  collapseBgColor: PropTypes.string,
};

export default DataTable;
