import Style from "./style.js";
import "./index.scss";
import { makeStyles } from "@mui/styles";
import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";

import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import { Link, useHistory } from "react-router-dom";
import { post } from "utils/helper";
import { HOST } from "utils/config";

const useStyle = makeStyles(Style);

function ListLotto({ dataLotto }) {
  const classes = useStyle();
  const history = useHistory();
  const [lotto, setLotto] = useState(dataLotto.gatcha_list);
  const [dateMarket, setDateMarket] = useState();
  const [dateOpenTo, setDateOpenTo] = useState();
  let interval = useRef();

  const lottoCountDown = (value, status, desc) => {
    const countDownDate = new Date(value).getTime();
    const now1 = new Date().getTime();
    const distance1 = countDownDate - now1;

    const days1 = Math.floor(distance1 / (1000 * 60 * 60 * 24))
      .toString()
      .padStart(1, "0");
    const hours1 = Math.floor((distance1 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      .toString()
      .padStart(2, "0");
    const minutes1 = Math.floor((distance1 % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, "0");
    const seconds1 = Math.floor((distance1 % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, "0");

    const [timerDays, setTimerDays] = useState(days1 < 0 ? "0" : days1);
    const [timerHours, setTimerHours] = useState(hours1 < 0 ? "00" : hours1);
    const [timerMinutes, setTimerMinutes] = useState(minutes1 < 0 ? "00" : minutes1);
    const [timerSecond, setTimerSecond] = useState(seconds1 < 0 ? "00" : seconds1);

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
        .toString()
        .padStart(1, "0");
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        .toString()
        .padStart(2, "0");
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        .toString()
        .padStart(2, "0");
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)
        .toString()
        .padStart(2, "0");

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSecond(seconds);
      }
    }, 1000);

    if (parseInt(status) !== 1 || status == "ปิดงวดชั่วคราว") {
      return (
        <>
          <p className="text-red">{desc}</p>
        </>
      );
    } else {
      return (
        <>
          <p className="text-red">
            {timerDays < 1 ? `เหลือเวลา` : ""} {timerDays > 0 ? `เหลือเวลา ${timerDays} วัน` : ""}{" "}
            {timerHours} : {timerMinutes} : {timerSecond}
          </p>
        </>
      );
    }
  };

  useEffect(() => {
    // lottoCountDown()
    // console.log('dataLotto = ',dataLotto)
  }, []);

  return (
    <>
      {lotto.map((e, i) => (
        <Grid key={i} item>
          <div
            className={
              e.market_status != "1" || e.market_status == "ปิดงวดชั่วคราว"
                ? "list-lotto-card disable-card"
                : "list-lotto-card"
            }
            onClick={() => {
              e.market_status == "1" ? history.push(`/play-game/${e.gatcha_id}`) : "";
            }}
          >
            <div className="image-box">
              <div className="flag-img">
                <img src={e.gatcha_cover} />
              </div>
              <div className="text-right">
                <p className="text-box-header">{e.gatcha_name}</p>
                <p>{e.gatcha_drawing_date_display ? e.gatcha_drawing_date_display : "-"}</p>
              </div>
            </div>
            <div className="text-box">
              <div className="display-flex">
                <div className="display-flex-left">
                  <p>{e.gatcha_drawing_description ? e.gatcha_drawing_description : "-"}</p>
                </div>
                <div className="display-flex-right">
                  <p>เปิด : {e.gatcha_open_market_display ? e.gatcha_open_market_display : "-"}</p>
                  <p>ปิด : {e.gatcha_close_market_display ? e.gatcha_close_market_display : "-"}</p>
                </div>
              </div>
              <div className="countdown-contain">
                {lottoCountDown(e.gatcha_close_market, e.market_status, e.description)}
              </div>
            </div>
          </div>
        </Grid>
      ))}
    </>
  );
}

ListLotto.propTypes = {
  dataLotto: PropTypes.array,
};

export default ListLotto;
