// import BaseLayout from "layouts/pages/account/components/BaseLayout";

import PropTypes from "prop-types";
// @mui material components
import { useHistory, useParams } from "react-router";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BaseLayout from "./components/BaseLayout";
import React, { useState, useEffect } from "react";
import { post } from "utils/helper";
import { HOST } from "utils/config";
import LottoList from "./lottolist";
import LottoNumber from "./lottoNumber";
import RiskLimitRoute from "./route";
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function RiskLimit() {
  const [dataLotto, setDataLotto] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchLotto = async () => {
    try {
      setLoading(true);
      const { data } = await post(`${HOST}api/Lotto/GetLotto`);
      setDataLotto(data.results);

      setLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    fetchLotto();
  }, []);

  return (
    <BaseLayout tabValues={id} tabHandler={handleSetTabValue} tabData={dataLotto}>
      {/* <Tabs
        value={
          history.location.pathname === `/risk/limit/${id}`
            ? `/risk/limit/${id}/category`
            : history.location.pathname
        }
        aria-label="basic tabs example"
      >
        <Tab
          label="ตามประเภท"
          value={`/risk/limit/${id}/category`}
          onClick={() => history.push(`/risk/limit/${id}/category`)}
        />
        <Tab
          label="ตามหมายเลข"
          value={`/risk/limit/${id}/number`}
          onClick={() => history.push(`/risk/limit/${id}/number`)}
        />
      </Tabs> */}

      <RiskLimitRoute dataLotto={dataLotto.find((value) => value.group_id === parseInt(id))} />
    </BaseLayout>
  );
}

export default RiskLimit;
