/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiAvatar from "components/SuiAvatar";
import { Pagination } from "@mui/material";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { Table16, Table18 } from "components/Text/TextStyle";
function TablePagination({ columns, rows, maincopy, currentPage, totalPage, onPageChange }) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const [page, setPage] = React.useState(currentPage || 1);
  const handleChange = (event, value) => {
    setPage(value);
    onPageChange(value);
  };
  const renderColumns = columns.map(({ name, align, label, children }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SuiBox
        key={name}
        component="th"
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.sm}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {/* {label ? <>{children}</> : <Table18> {label}</Table18>} */}

        <SuiBox>{label}</SuiBox>
      </SuiBox>
    );
  });
  const renderMainColumns = maincopy?.map(({ name, align, position, children }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SuiBox
        key={name}
        component="th"
        // pt={1.5}
        // pb={1.25}
        // pl={align === "left" ? pl : 3}
        // pr={align === "right" ? pr : 3}
        p={2}
        // textAlign={align}
        fontSize={size.sm}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}

        // position={position}
      >
        <SuiBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={32}
          width="100%"
        >
          <SuiBox position={position} width={position ? "auto" : "100%"}>
            {children}
          </SuiBox>
        </SuiBox>
      </SuiBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align, position }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <SuiBox key={row[name][1]} component="td" p={1}>
            <SuiBox display="flex" alignItems="center" py={0.5} px={1} justifyContent={align}>
              <SuiBox mr={2}>
                <SuiAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
              </SuiBox>
              <Table16>{row[name][1]}</Table16>
              {/* <SuiTypography
                variant="button"
                fontWeight="medium"
                customClass="w-max"
                fontSize={16}
              ></SuiTypography> */}
            </SuiBox>
          </SuiBox>
        );
      } else {
        template = (
          <SuiBox key={row[name]} component="td" p={1} textAlign={align} justifyContent={align}>
            <SuiBox
              key={row[name]}
              p={1}
              textAlign={align}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              fontSize="16px"
            >
              {/* <SuiTypography
              variant="button"
              fontWeight="regular"
              textColor="secondary"
              customClass="d-inline-block w-max"
            > */}
              <div style={{ position: position, width: position ? "auto" : "100%" }}>
                {row[name]}
              </div>
              {/* <Table16 style={{ position: position }}> {row[name]}</Table16> */}
            </SuiBox>
            {/* </SuiTypography> */}
          </SuiBox>
        );
      }

      return template;
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  });

  return (
    <TableContainer>
      <MuiTable>
        <SuiBox component="thead">
          <TableRow>{renderColumns}</TableRow>
          {maincopy.length > 0 && <TableRow>{renderMainColumns}</TableRow>}
        </SuiBox>
        <TableBody>{renderRows}</TableBody>
      </MuiTable>
      {totalPage !== 0 && (
        <SuiBox display="flex" justifyContent="center" py={3}>
          <Pagination count={totalPage} page={page} onChange={handleChange} color="primary" />
        </SuiBox>
      )}
    </TableContainer>
  );
}

// Setting default values for the props of Table
TablePagination.defaultProps = {
  columns: [],
  rows: [{}],
  maincopy: [],
  isPagination: false,
};

// Typechecking props for the Table
TablePagination.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  maincopy: PropTypes.arrayOf(PropTypes.object),
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  isPagination: PropTypes.bool,
  onPageChange: PropTypes.func,
};

export default TablePagination;
