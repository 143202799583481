/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import SuiBadgeDot from "components/SuiBadgeDot";

// @mui material components

import { Skeleton } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";

import { post } from "utils/helper";
import { HOST } from "utils/config";
// Reports page components

import SuiButton from "components/SuiButton";
import TablePagination from "components/Tables/TablePagination";

const columns = [
  { name: "name", align: "left", label: "ชื่อผู้ใช้" },
  { name: "role", align: "left", label: "ระดับ" },
  { name: "surname", align: "left", label: "ชื่อ-นามสกุล" },
  { name: "tel", align: "center", label: "เบอร์โทร" },
  { name: "status", align: "center", label: "สถานะ" },
  { name: "credit", align: "center", label: "คงเหลือ/เครดิตทั้งหมด" },
  { name: "register", align: "center", label: "วันที่สร้าง" },
  { name: "action", align: "center", label: "" },
];
function Reports() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [dataMember, setdataMember] = useState([]);
  const [pages, setpages] = useState(1);
  const [perpages, setperpages] = useState(10);
  const [rows, setRows] = useState([]);
  const fetchMember = async () => {
    try {
      setLoading(true);
      const payload = new FormData();
      payload.append("role", "");
      payload.append("upline_id", "");
      payload.append("keyword", "");
      payload.append("page", pages);
      payload.append("per_page", perpages);
      const { data } = await post(`${HOST}api/user/GetUserTeamList`, payload);
      setdataMember(data);
      console.log(Math.ceil(dataMember.total_record / dataMember.per_page));

      const rownaja = data.results.map((elem) => ({
        name: [team2, elem.user_name],
        role: elem.role,
        status: (
          <SuiBox ml={-1.325}>
            <SuiBadgeDot size="small" badgeContent={elem.status} />
          </SuiBox>
        ),
        surname: elem.name,
        tel: elem.phone,
        credit: elem.credit_balance_display,
        register: elem.created_date_display,

        action: (
          <SuiBox w={"100%"} display="flex">
            <SuiButton
              buttonColor={"error"}
              onClick={() => history.push(`/manage/member/edit/${elem.user_id}`)}
            >
              Edit
            </SuiButton>
            <div style={{ margin: "0px 2px" }} />
            <SuiButton
              buttonColor={"info"}
              onClick={() => history.push(`/manage/member/copy/${elem.user_id}`)}
            >
              Copy
            </SuiButton>
          </SuiBox>
        ),
      }));

      setRows(rownaja);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchMember();
  }, []);
  const onPageChange = async (currentPage = 1) => {
    try {
      setLoading(true);
      const payload = new FormData();
      payload.append("role", "");
      payload.append("upline_id", "");
      payload.append("keyword", "");
      payload.append("page", currentPage);
      payload.append("per_page", perpages);
      const { data } = await post(`${HOST}api/user/GetUserTeamList`, payload);
      setdataMember(data);
      setpages(data.page);
      const rownaja = data.results.map((elem) => ({
        name: [team2, elem.user_name],
        role: elem.role,
        status: (
          <SuiBox ml={-1.325}>
            <SuiBadgeDot size="small" badgeContent={elem.status} />
          </SuiBox>
        ),
        surname: elem.name,
        tel: elem.phone,
        credit: elem.credit_balance_display,
        register: elem.created_date_display,

        action: (
          <SuiBox w={"100%"} display="flex">
            <SuiButton
              buttonColor={"error"}
              onClick={() => history.push(`/manage/member/edit/${elem.user_id}`)}
            >
              Edit
            </SuiButton>
            <div style={{ margin: "0px 2px" }} />
            <SuiButton
              buttonColor={"info"}
              onClick={() => history.push(`/manage/member/copy/${elem.user_id}`)}
            >
              Copy
            </SuiButton>
          </SuiBox>
        ),
      }));

      setRows(rownaja);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        {loading ? (
          <SuiBox>
            <Skeleton variant="rectangular" width={"auto"} height={500} />
          </SuiBox>
        ) : (
          <TablePagination
            columns={columns}
            rows={rows}
            isPagination
            totalPage={dataMember.total_page}
            currentPage={pages}
            onPageChange={onPageChange}
          />
        )}
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Reports;
