/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";

export default Yup.object().shape({
  profile: Yup.object().shape({
    credit: Yup.string().required("กรุณากรอกจำนวนเครดิต"),
    username: Yup.string().required("กรุณากรอกชื่อผู้ใช้"),
    password: Yup.string().required("กรุณากรอกรหัสผ่าน").min(6, "รหัสผ่านขั้นต่ำ 6 ตัว"),
    repeatPassword: Yup.string()
      .required("กรุณากรอกยืนยันรหัสผ่าน")
      .min(6, "รหัสผ่านขั้นต่ำ 6 ตัว"),
    repeatPassword: Yup.string()
      .required("กรุณากรอกยืนยันรหัสผ่าน")
      .oneOf([Yup.ref("password"), null], "รหัสผ่านไม่ตรงกัน"),
    name: Yup.string().required("กรุณากรอกชื่อ"),
    role: Yup.string().required("โปรดเลือกระดับ"),
    phone: Yup.string().required("กรุณากรอกเบอร์โทรศัพท์"),
  }),
  reward: Yup.array().of(
    Yup.object().shape({
      gatch_id: Yup.string().required("Gatch ID is required"),
      gatcha_name: Yup.string().required("Gatcha Name is required"),
      cover: Yup.string().url("Cover must be a valid URL").required("Cover is required"),
      config_reward_rate: Yup.array().of(
        Yup.object().shape({
          reward_rate: Yup.number()
            .required("Reward Rate is required")
            .max(Yup.ref("reward_rate_max"), ({ max }) => `อัตราจ่ายสูงสุด ${max}`),
          reward_rate_max: Yup.number().required("Reward Rate Max is required"),
          discount: Yup.number()
            .required("Discount is required")
            .max(Yup.ref("discount_max"), "Discount cannot be greater than Discount Max"),
          discount_max: Yup.number().required("Discount Max is required"),
          max_spin: Yup.number()
            .required("Max Spin is required")
            .max(Yup.ref("max_spin_max"), (value) => `ยอดแทงสูงสุด ${value.max}`),
          max_spin_max: Yup.number().required("Max Spin Max is required"),
        })
      ),
    })
  ),
});
