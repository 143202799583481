import react, { useState, useEffect } from "react";
import { post } from "utils/helper/request";
import { HOST } from "utils/config";
// @mui material components
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import { Grid, Divider, Switch } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
// Soft UI Dashboard PRO React example components
import NumberLitmit from "./components/numberLimit";
import FormLimit from "./components/formLimit";

import { LootoList20, LootoList18, LootoList16 } from "components/Text/TextStyle";

// Data
function LottoNumber({ dataLotto }) {
  const [lotto, setLotto] = useState(dataLotto?.lotto_list_item);
  const [refetch, setRefetch] = useState(false);
  const refetchData = () => {
    setRefetch(true);
  };
  useEffect(() => {
    setLotto(dataLotto?.lotto_list_item);
  }, [dataLotto]);
  return (
    <SuiBox my={3}>
      {lotto?.map((e, i) => (
        <Card key={i} style={{ marginBottom: 24 }}>
          <SuiBox p={3}>
            <SuiBox display={"flex"} justifyContent={"space-between"}>
              <SuiTypography variant={"h5"} fontWeight="bold">
                <LootoList20>{e.lotto_name}</LootoList20>
              </SuiTypography>
              <SuiTypography variant={"h5"} fontWeight="bold" textColor={"error"}>
                <LootoList16>ประจำงวดที่ {e.gatcha_drawing_date_display}</LootoList16>
              </SuiTypography>
            </SuiBox>

            <Grid container pacing={2}>
              <Grid xs={3}>
                <LootoList16>ประเภท</LootoList16>
              </Grid>
              <Grid xs={3}>
                <LootoList16>หมายเลข</LootoList16>
              </Grid>
              <Grid xs={2}>
                <LootoList16>สถานะ</LootoList16>
              </Grid>
              <Grid xs={3}>
                <LootoList16>จำนวนที่จะรับ</LootoList16>
              </Grid>

              <Grid xs={1}>
                <LootoList16>ดำเนินการ</LootoList16>
              </Grid>
            </Grid>
            {/* <Divider /> */}
            {/* <FormLimit lotoType={e} refetch={refetchData} /> */}

            <Divider />
            <NumberLitmit lotoType={e} numberLimit={e?.gatcha_drawing_id} refetch={refetch} />
          </SuiBox>
        </Card>
      ))}
    </SuiBox>
  );
}
LottoNumber.propTypes = {
  dataLotto: PropTypes.array,
};
export default LottoNumber;
