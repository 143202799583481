/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import SuiBadgeDot from "components/SuiBadgeDot";
import Swal from "sweetalert2";
import TablePagination from "components/Tables/TablePagination";
// @mui material components
import { Skeleton } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";
// Reports page components
import DialogReport from "./components/DialogReport";
import DialogAllTranfer from "./components/DialogAllTranfer";
// Data

const columns = [
  { name: "name", align: "left", label: "ชื่อผู้ใช้" },
  { name: "role", align: "left", label: "ระดับ" },
  { name: "credit", align: "center", label: "เครดิตทั้งหมด/คงเหลือ" },
  { name: "used", align: "center", label: "เครดิตที่ใช้ไป" },
  { name: "profit", align: "center", label: "กำไร/ขาดทุน" },
  { name: "status", align: "center", label: "สถานะ" },
  { name: "action", align: "center", label: "" },
];
function Transfer() {
  const [loading, setLoading] = useState(false);
  const [dataMember, setdataMember] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [dataDialog, setdataDialog] = useState({});
  const [userId, setUserId] = useState(null);
  const [pages, setpages] = useState(1);
  const [remark, setRemark] = useState("");
  const [amount, setAmount] = useState(0);
  const [perpages, setperpages] = useState(10);

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTransection, setOpenTransection] = useState(false);
  const [openTransectionAll, setOpenTransectionAll] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setRemark("");
    setOpen(false);
  };
  const handleCloseTransection = () => {
    setRemark("");
    setOpenTransection(false);
  };
  const handlesetTransection = (values) => {
    setUserId(values);
    handleOpenTransection();
  };
  const handleOpenTransection = () => {
    setOpenTransection(true);
  };
  const onTranferAlone = (values) => {
    setAmount(values.loss_profit);
    setdataDialog(values);
    handleClickOpen();
  };
  const onhandleSubmit = async () => {
    if (dataDialog.loss_profit < amount) {
      Swal.fire("จำนวนเงินไม่ถูกต้อง", "กรุณาลองใหม่", "error");
    } else {
      try {
        let trandata = [
          {
            to_user_id: dataDialog.user_id,
            amount: parseFloat(amount),
            remark: remark,
          },
        ];
        const payload = { transfer_data: trandata };
        const { data } = await post(`${HOST}api/Transfer/SetTransfer`, payload);
        Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success").then((result) => {
          setRemark("");
          handleClose();
        });
      } catch (error) {
        Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
        console.log(error);
      }
    }
  };
  const fetchMember = async () => {
    try {
      setLoading(true);

      const { data } = await get(
        `${HOST}api/Transfer/GetDownLineTransfer?page=${pages}&per_page=50`
      );

      setTotalPage(Math.ceil(data?.total_record / 50));
      setdataMember(data.results);

      const rownaja = data.results.map((elem) => ({
        name: [team2, elem.user_name],
        role: elem.role,
        credit: (
          <SuiBox display="flex">
            {elem.total_credit_display}/{elem.credit_balance_display}
          </SuiBox>
        ),
        used: elem.credit_used_display,
        profit: elem.loss_profit_display,
        status: (
          <SuiBox ml={-1.325}>
            <SuiBadgeDot size="small" badgeContent={elem.status} />
          </SuiBox>
        ),
        action: (
          <SuiBox w={"100%"} display="flex">
            <SuiButton buttonColor={"error"} onClick={() => handlesetTransection(elem.user_id)}>
              รายงานยอด
            </SuiButton>
            <div style={{ margin: "0px 2px" }} />
            {elem.loss_profit !== 0 && (
              <SuiButton
                buttonColor={"info"}
                onClick={() => onTranferAlone(elem)}
                disabled={elem.loss_profit === 0}
              >
                โอนยอด
              </SuiButton>
            )}
          </SuiBox>
        ),
      }));

      setRows(rownaja);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpentranferAll = () => {
    setOpenTransectionAll(true);
  };
  const handleClosetranferAll = () => {
    setOpenTransectionAll(false);
  };
  const onSucces = () => {
    fetchMember();
  };
  useEffect(() => {
    fetchMember();
  }, [pages]);
  const onPageChange = async (currentPage = 1) => {
    setpages(currentPage);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiButton onClick={handleOpentranferAll} buttonColor={"info"}>
        โอนยอดทั้งหมด
      </SuiButton>

      <SuiBox py={3}>
        {loading ? (
          <SuiBox>
            <Skeleton variant="rectangular" width={"auto"} height={500} />
          </SuiBox>
        ) : rows.length > 0 ? (
          <TablePagination
            columns={columns}
            rows={rows}
            isPagination
            currentPage={pages}
            totalPage={totalPage}
            onPageChange={onPageChange}
          />
        ) : (
          <SuiBox textAlign="center">ไม่พบผู้ใช้งาน</SuiBox>
        )}
      </SuiBox>
      <Footer />

      <DialogAllTranfer
        open={open}
        onClose={handleClose}
        dataUser={[dataDialog]}
        onSucces={onSucces}
        type={"single"}
      />

      <DialogReport open={openTransection} onClose={handleCloseTransection} userId={userId} />
      <DialogAllTranfer
        open={openTransectionAll}
        onClose={handleClosetranferAll}
        dataUser={dataMember?.filter((e) => e.loss_profit !== 0)}
        onSucces={onSucces}
        type={"all"}
      />
    </DashboardLayout>
  );
}

export default Transfer;
