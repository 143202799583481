/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
//  Yup.array().when("rule_type", {
//    is: (value) => value === "campaign_rule",
//    then: Yup.array()
//      .of(
//        Yup.object().shape({
//          attribute: Yup.string().required(),
//          operator: Yup.string().required(),
//          selected_values: Yup.mixed().required(),
//        })
//      )
//      .min(1),
//    otherwise: Yup.array().nullable(),
//  });
export default Yup.object().shape({
  award: Yup.array().of(
    Yup.object().shape({
      gatcha_result_number: Yup.string().required("กรุณากรอกเลขรางวัล"),
    })
  ),
});
