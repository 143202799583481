import { useTable, usePagination } from "react-table";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { TableCell, TableHead } from "@mui/material";
import "./index.scss";

import { Table16 } from "components/Text/TextStyle";

function Paginated({ data, columns }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const options = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 50, label: 50 },
  ];

  const { pageIndex, pageSize } = state;
  const [tableValue, setTableValue] = useState(-1);
  const [optionValue, setOptionValue] = useState(15);

  const showDetail = (value) => {
    if (tableValue == parseInt(value)) {
      setTableValue(-1);
    } else {
      setTableValue(parseInt(value));
    }
  };

  const onChangeOptionValue = (event) => {
    setOptionValue(event);
    setPageSize(event);
  };

  useEffect(() => {
    //   console.log("betNumber = ",betNumber)
  });

  return (
    <>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()} key={column}>
                  <Table16>{column.render("Header")}</Table16>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            // console.log("row = ",row.original?.bet_number_list)
            prepareRow(row);
            return (
              <>
                <TableRow
                  {...row.getRowProps()}
                  key={row}
                  onClick={() => {
                    showDetail(row?.id);
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()} key={cell}>
                        <Table16>{cell.render("Cell")}</Table16>
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  {tableValue == row.id && (
                    <TableCell colSpan="12">
                      <div className="detail-bill-contain">
                        <Table>
                          <TableHead className="detail-bill-table-header">
                            <TableCell>
                              <Table16>ประเภท @ หมายเลข</Table16>
                            </TableCell>
                            <TableCell>
                              <Table16>ยอดเดิมพัน</Table16>
                            </TableCell>
                            <TableCell>
                              <Table16>เรทจ่าย</Table16>
                            </TableCell>
                            <TableCell>
                              <Table16>ส่วนลด</Table16>
                            </TableCell>
                          </TableHead>
                          <TableBody>
                            {row.original?.bet_number_list.map((e, i) => (
                              <TableRow key={i}>
                                <TableCell>
                                  <Table16>
                                    {e.type} @ {e.number}
                                  </Table16>
                                </TableCell>
                                <TableCell>
                                  <Table16>{e.total_buy}</Table16>
                                </TableCell>
                                <TableCell>
                                  <Table16>{e.reward_rate}</Table16>
                                </TableCell>
                                <TableCell>
                                  <Table16>{e.discount}</Table16>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
      <div>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(pageNumber);
            }}
            style={{ width: "50px" }}
          />
        </span>{" "}
      </div>
    </>
  );
}

Paginated.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
};

export default Paginated;
