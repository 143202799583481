/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import SuiBadgeDot from "components/SuiBadgeDot";
import Swal from "sweetalert2";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Switch } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiDatePicker from "components/SuiDatePicker";

// Soft UI Dashboard PRO React example components
import SuiDropzone from "components/SuiDropzone";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";

// Reports page components
import Reviews from "memberpage/pages/users/reports/components/Reviews";
import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import moment from "moment";
import FormField from "components/FormField";
// Data
import { Formik, Form, FieldArray, FastField } from "formik";

function DialogaddInstallment({ open, onClose, gatcha_id, onSucces }) {
  const onhandleSubmit = async (event) => {
    const payload = new FormData();
    payload.append("cycle", event.cycle);
    payload.append("status", event.status);
    payload.append("gatcha_drawing_date", event.gatcha_drawing_date);
    payload.append("open_maket", event.open_maket);
    payload.append("close_maket", event.close_maket);
    payload.append("gatcha_id", gatcha_id);

    try {
      const { data } = await post(`${HOST}api/MasterLotto/AddLottodrawingConfig`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success").then((result) => {
        onSucces();
        onClose();
      });
    } catch (error) {
      // console.log(error.response.data.errorMessage, "eieeieiieieei");
      Swal.fire("เกิดข้อผิดพลาด", error.response.data.errorMessage, "error");
      console.log(error);
    }
  };
  function validateAmount(value) {
    let error;

    if (!value) {
      error = "โปรดกรอกชื่อกลุ่มหวย";
    }

    return error;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
      <DialogTitle>เพิ่มงวดหวย</DialogTitle>

      <Formik
        initialValues={{
          status: true,
          cycle: "",
          gatcha_drawing_date: moment().format("MM/DD/YYYY HH:mm"),
          open_maket: moment().format("MM/DD/YYYY HH:mm"),
          close_maket: moment().format("MM/DD/YYYY HH:mm"),
        }}
        // validationSchema={}
        onSubmit={onhandleSubmit}
      >
        {(formProps) => (
          <Form autoComplete="off">
            <DialogContent>
              <SuiBox>
                <SuiBox>
                  <Grid container spacing={4}>
                    <Grid item lg={4}>
                      <SuiTypography variant="caption">วันที่ออกหวย</SuiTypography>
                      <SuiDatePicker
                        onChange={(e) =>
                          formProps.setFieldValue(
                            "gatcha_drawing_date",
                            moment(e[0]).format("MM/DD/YYYY HH:mm")
                          )
                        }
                        input={{
                          placeholder: "Select a date",
                          name: "gatcha_drawing_date",
                        }}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <SuiTypography variant="caption">วันที่ตลาดเปิด</SuiTypography>
                      <SuiDatePicker
                        onChange={(e) =>
                          formProps.setFieldValue(
                            "open_maket",
                            moment(e[0]).format("MM/DD/YYYY HH:mm")
                          )
                        }
                        input={{
                          placeholder: "Select a date",
                          name: "open_maket",
                        }}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <SuiTypography variant="caption">วันที่ตลาดปิด</SuiTypography>
                      <SuiDatePicker
                        onChange={(e) =>
                          formProps.setFieldValue(
                            "close_maket",
                            moment(e[0]).format("MM/DD/YYYY HH:mm")
                          )
                        }
                        input={{
                          placeholder: "Select a date",
                          name: "close_maket",
                        }}
                      />
                    </Grid>

                    <Grid item lg={4}>
                      <SuiTypography variant="caption">รอบที่ออก</SuiTypography>
                      <FormField
                        type="number"
                        placeholder="โปรดกรอกรอบ"
                        name={`cycle`}
                        onChange={formProps.handleChange}
                        value={formProps.values.cycle}
                        validate={(e) => validateAmount(e)}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <SuiTypography variant="caption">สถานะ</SuiTypography>
                      <SuiBox>
                        <SuiTypography variant="BUTTON">
                          {formProps.values.status ? "เปิด" : "ปิด"}{" "}
                        </SuiTypography>
                        <Switch
                          checked={formProps.values.status}
                          onChange={() =>
                            formProps.setFieldValue(`status`, !formProps.values.status)
                          }
                        />
                      </SuiBox>
                    </Grid>
                  </Grid>
                  <Divider />
                </SuiBox>
              </SuiBox>
            </DialogContent>
            <DialogActions>
              <SuiButton buttonColor="error" onClick={onClose}>
                ยกเลิก
              </SuiButton>
              <SuiButton type="submit" buttonColor="success">
                เพิ่มงวดหวย
              </SuiButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
DialogaddInstallment.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  gatcha_id: PropTypes.number.isRequired,
  onSucces: PropTypes.func,
};
export default DialogaddInstallment;
