import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
import { Grid } from "@mui/material";
import ManageMember from "./components/ManageMember";
import ManageAgent from "./components/ManageAgent";
import managefightPageStyle from "./styles";
import { get } from "utils/helper/";
import { HOST } from "utils/config";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  TextBlack30,
  TextCenter,
  LootoList20,
  LootoList18,
  LootoList16,
  LootoList16span,
  LootoList20_2,
  LootoList20_3,
  LootoList36,
} from "components/Text/TextStyle";
const ManageFight = (props) => {
  const [groupSelect, setgroupSelect] = useState(null);
  const [groupSelectLotto, setgroupSelectLotto] = useState(null);
  const [dataLoto, setDataLoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const { lotto } = props;
  const matches = useMediaQuery("(min-width:600px)");
  const setSelectValue = ({ value }) => {
    setgroupSelect(value);
  };
  const setSelectLotto = ({ value }) => {
    setgroupSelectLotto(value);
    fetchLotto(value.lotto_id);
  };
  const fetchLotto = async (value) => {
    setLoading(true);

    try {
      const { data } = await get(`${HOST}api/UserConfig/GetUserStockForManage?gatcha_id=${value}`);
      setDataLoto(data.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const classes = managefightPageStyle();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox p={3} display="flex" justifyContent={"space-between"} backgroundColor={"#FFF"}>
        <Grid container>
          <Grid xs={6}>
            <LootoList20_2> ถือหุ้น/แบ่งสู้</LootoList20_2>

            {/* <SuiTypography variant="h4" textColor="secondary" fontSize={matches ? 10 : 20}>
              ถือหุ้น/แบ่งสู้
            </SuiTypography> */}
          </Grid>
          <Grid xs={6}>
            <SuiBox display="flex">
              {lotto?.isFetchLoto && (
                <SuiSelect
                  // defaultValue={{ value: defaultValue, label: defaultValue }}
                  options={lotto?.lotto.map((entry) => ({
                    value: entry.group_id,
                    label: entry.group_name,
                  }))}
                  onChange={setSelectValue}
                  size="large"
                  className={classes.selectmenu}
                />
              )}
              <SuiBox mr={2} />
              {groupSelect !== null && (
                <SuiSelect
                  // defaultValue={{ value: defaultValue, label: defaultValue }}
                  options={lotto?.lotto
                    .find((e) => e.group_id === groupSelect)
                    ?.lotto_list_item.map((entry) => ({
                      value: entry,
                      label: entry.lotto_name,
                    }))}
                  onChange={setSelectLotto}
                  size="large"
                  className={classes.selectmenu}
                />
              )}
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      {dataLoto?.agent_stock ? (
        <SuiBox>
          {dataLoto.access ? (
            <>
              <SuiBox display="flex" justifyContent="center" mt={2} backgroundColor="#FFF">
                {groupSelectLotto?.lotto_name}
              </SuiBox>
              {dataLoto.agent_stock.length > 0 && (
                <ManageAgent dataLoto={dataLoto} gatchaId={groupSelectLotto?.lotto_id} />
              )}

              {dataLoto.member_fighting.length > 0 && (
                <ManageMember dataLoto={dataLoto} gatchaId={groupSelectLotto?.lotto_id} />
              )}
            </>
          ) : (
            <SuiBox display="flex" justifyContent="center" mt={2} color="red">
              คุณไม่ได้รับสิทธิ์ กรุณาติดต่อสายบนเพื่อเปิดหวย
            </SuiBox>
          )}
        </SuiBox>
      ) : (
        <LootoList36>โปรดเลือกประเภทหวย</LootoList36>
      )}
      <Footer />
    </DashboardLayout>
  );
};

ManageFight.propTypes = {
  lotto: PropTypes.object,
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageFight);
