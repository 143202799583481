import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";

import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";

import Style from "../style.js";
import "../index.scss";
import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";

import TwoDigits from "memberpage/pages/play-game/flash-card/TwoDigits";
import ThreeDigits from "memberpage/pages/play-game/flash-card/ThreeDigits";
import SixBack from "memberpage/pages/play-game/flash-card/SixBack";
import NineTeenDoor from "memberpage/pages/play-game/flash-card/NineTeenDoor";
import Run from "memberpage/pages/play-game/flash-card/Run";

const useStyle = makeStyles(Style);

function FlashCard({ formData, onsetTotaldata, gatchaconfig }) {
  const [typeButton, setTypeButton] = useState(1);

  function setButtonType(type) {
    setTypeButton(type);
  }
  useEffect(() => {
    // console.log("เข้าาา", formData);
    if (formData.values.gatchaFlashNumber.length > 0) {
      var num = 0;
      formData.values.gatchaFlashNumber.map((e, i) => {
        for (var x = 0; x < e.gatchaNumber.length; x++) {
          num =
            num +
            parseInt(e.numberUp || 0) +
            parseInt(e.numberDown || 0) +
            parseInt(e.numberMulti || 0);
        }
      });
      onsetTotaldata(num, "fast");
    }
  }, [formData.values.gatchaFlashNumber]);
  return (
    <>
      <div className="flash-card">
        <div className="header-contain">
          <ul className="left-contain">
            <p>เพิ่มตัวเลข</p>
            <li>1. เลือกประเภท</li>
            <li>2. ใส่เลข</li>
            <li>3. ใส่ราคา</li>
            <li>4. กดปุ่มเพิ่ม</li>
          </ul>
          <ul className="right-contain">
            <p>ลบตัวเลข</p>
            <li>1. กดที่ตัวเลข</li>
            <li>2. เมื่อกดปุ่มเพิ่ม ตัวเลขที่กดลบไว้จะไม่นำเข้าบิล</li>
            <li>3. กดที่ตัวเลขอีกครั้งเพื่อยกเลิก</li>
          </ul>
        </div>
        <div className="button-group">
          <div
            className={typeButton == 1 ? "type-button active" : "type-button"}
            onClick={() => setButtonType(1)}
          >
            2ตัว
          </div>
          <div
            className={typeButton == 2 ? "type-button active" : "type-button"}
            onClick={() => setButtonType(2)}
          >
            3ตัว
          </div>
          <div
            className={typeButton == 3 ? "type-button active" : "type-button"}
            onClick={() => setButtonType(3)}
          >
            6 กลับ
          </div>
          <div
            className={typeButton == 4 ? "type-button active" : "type-button"}
            onClick={() => setButtonType(4)}
          >
            19 ประตู
          </div>
          <div
            className={typeButton == 5 ? "type-button active" : "type-button"}
            onClick={() => setButtonType(5)}
          >
            วิ่ง
          </div>
        </div>
        {typeButton == 1 && <TwoDigits formData={formData} onsetTotaldata={onsetTotaldata} />}
        {typeButton == 2 && <ThreeDigits formData={formData} gatchaconfig={gatchaconfig} />}
        {typeButton == 3 && <SixBack formData={formData} />}
        {typeButton == 4 && <NineTeenDoor formData={formData} />}
        {typeButton == 5 && <Run formData={formData} />}
      </div>
    </>
  );
}

FlashCard.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onsetTotaldata: PropTypes.func,
  gatchaconfig: PropTypes.array,
};

export default FlashCard;
