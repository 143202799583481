/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import SuiBadgeDot from "components/SuiBadgeDot";
import Swal from "sweetalert2";
// @mui material components
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
// Soft UI Dashboard PRO React example components
import SuiDropzone from "components/SuiDropzone";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";
import SuiDatePicker from "components/SuiDatePicker";
// Reports page components
import Reviews from "memberpage/pages/users/reports/components/Reviews";
import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import FormField from "components/FormField";
// Data
import { Formik, Form, FieldArray, FastField } from "formik";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: "auto",
  height: 150,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
function DialogEditAnnouce({ open, onClose, dataUpdate, onSuccess }) {
  const [files, setFiles] = useState([]);
  const [dataRole, setDataRole] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));
  const fetchRole = async () => {
    const { data } = await get(`${HOST}api/Announcement/GetRoleListItem`);
    setDataRole(data);
  };
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  useEffect(() => {
    fetchRole();
  }, []);
  const onhandleSubmit = async (event) => {
    const payload = new FormData();
    payload.append("id", event.id);
    payload.append("status", event.status);
    payload.append("title", event.title);
    payload.append("description", event.description);
    payload.append("start_date", event.start_date);
    payload.append("end_date", event.end_date);
    files.length > 0 && payload.append("fileUpload", files[0]);
    let check = 0;
    {
      event.role.map((e, i) => {
        if (e.status) {
          payload.append(`roles[${check}]`, e.role);
          check = check + 1;
        }
      });
    }
    try {
      const { data } = await post(`${HOST}api/Announcement/UpdateAnnouncement`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success").then((result) => {
        setFiles([]);
        onSuccess();
        onClose();
      });
    } catch (error) {
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาลองใหม่", "error");
      console.log(error);
    }
  };
  function validateAmount(value) {
    let error;
    if (!value) {
      error = "โปรดกรอกข้อมูล";
    }
    return error;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"lg"}>
      <DialogTitle>แก้ไขประกาศ</DialogTitle>
      <SuiBox px={4}>
        <Formik
          initialValues={{
            id: dataUpdate.id,
            title: dataUpdate.title,
            description: dataUpdate.description,
            start_date: moment(dataUpdate?.start_date).format("MM/DD/YYYY HH:mm"),
            end_date: moment(dataUpdate?.end_date).format("MM/DD/YYYY HH:mm"),
            status: true,
            role: dataRole.map((e, i) => {
              return { role: e.value, status: dataUpdate?.roles?.some((el) => el === e.value) };
            }),
          }}
          // validationSchema={}
          onSubmit={onhandleSubmit}
        >
          {(formProps) => (
            <Form autoComplete="off">
              {console.log(formProps)}
              <Grid container spacing={4}>
                <Grid item lg={8}>
                  <SuiBox
                    {...getRootProps({ className: "dropzone" })}
                    component="div"
                    sx={{ cursor: "pointer" }}
                  >
                    <SuiBox component="input" name="file" type="file" {...getInputProps()} />
                    {/* <input {...getInputProps()} /> */}
                    {files.length < 1 && <p>select files</p>}
                    <SuiBox display="flex" justifyContent="center" style={thumbsContainer}>
                      {files.length > 0 ? (
                        thumbs
                      ) : (
                        <SuiBox
                          style={thumb}
                          {...getRootProps({ className: "dropzone" })}
                          sx={{ cursor: "pointer" }}
                        >
                          <div style={thumbInner}>
                            <img src={dataUpdate.cover} style={img} />
                          </div>
                        </SuiBox>
                      )}
                    </SuiBox>
                  </SuiBox>

                  <SuiTypography variant="caption">หัวข้อประกาศ</SuiTypography>
                  <FormField
                    type="text"
                    placeholder="โปรดกรอกชื่อหวย"
                    name={`title`}
                    onChange={formProps.handleChange}
                    value={formProps.values.title}
                    validate={(e) => validateAmount(e)}
                  />
                  <SuiTypography variant="caption">รายละเอียด</SuiTypography>
                  <FormField
                    type="text"
                    placeholder="รายละเอียด"
                    name={`description`}
                    onChange={formProps.handleChange}
                    value={formProps.values.description}
                    validate={(e) => validateAmount(e)}
                  />
                </Grid>
                <Grid item lg={4}>
                  <DialogContent>
                    <SuiBox>
                      <SuiBox>
                        <SuiTypography variant="caption">วันที่เปิด</SuiTypography>
                        <SuiDatePicker
                          onChange={(e) =>
                            formProps.setFieldValue(
                              "start_date",
                              moment(e[0]).format("MM/DD/YYYY HH:mm")
                            )
                          }
                          dateDefault={formProps.values.start_date}
                          input={{
                            placeholder: "Select a date",
                            name: "start_date",
                          }}
                        />
                        <SuiTypography variant="caption">วันที่ปิด</SuiTypography>
                        <SuiDatePicker
                          onChange={(e) =>
                            formProps.setFieldValue(
                              "end_date",
                              moment(e[0]).format("MM/DD/YYYY HH:mm")
                            )
                          }
                          dateDefault={formProps.values.end_date}
                          input={{
                            placeholder: "Select a date",
                            name: "end_date",
                          }}
                        />

                        <SuiTypography variant="caption">สถานะ</SuiTypography>
                        <SuiBox>
                          <SuiTypography variant="BUTTON">
                            {formProps.values.status ? "เปิด" : "ปิด"}{" "}
                          </SuiTypography>
                          <Switch
                            checked={formProps.values.status}
                            onChange={() =>
                              formProps.setFieldValue(`status`, !formProps.values.status)
                            }
                          />
                        </SuiBox>
                        <SuiTypography variant="caption">ตำแหน่งที่เห็น</SuiTypography>
                        <Grid container>
                          {dataRole.map((e, i) => (
                            <Grid item lg={6} key={i}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={formProps.values.role[i].status}
                                    onChange={(e) => {
                                      formProps.setFieldValue(
                                        `role[${i}].status`,
                                        !formProps.values.role[i].status
                                      );
                                    }}
                                  />
                                }
                                label={<SuiTypography variant="caption">{e.value}</SuiTypography>}
                              />
                            </Grid>
                          ))}
                        </Grid>
                        <Divider />
                      </SuiBox>
                    </SuiBox>
                  </DialogContent>
                </Grid>
              </Grid>

              <DialogActions>
                <SuiButton buttonColor="error" onClick={onClose}>
                  ยกเลิก
                </SuiButton>
                <SuiButton type="submit" buttonColor="success">
                  แก้ไขประกาศ
                </SuiButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </SuiBox>
    </Dialog>
  );
}
DialogEditAnnouce.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  dataUpdate: PropTypes.object,
  onSuccess: PropTypes.func,
};
export default DialogEditAnnouce;
