/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router";
// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";

function BaseLayout({ stickyNavbar, children, tabValues, tabHandler, tabData }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <DashboardLayout>
      <DashboardNavbar absolute={!stickyNavbar} isMini />
      <SuiBox mt={stickyNavbar ? 3 : 10}>
        <Grid container>
          <Grid item xs={12} sm={8} lg={12}>
            <AppBar position="static">
              {/* <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: "background.paper" }}> */}
              <Tabs
                orientation={tabsOrientation}
                variant="scrollable"
                scrollButtons="auto"
                value={tabValues}
                onChange={tabHandler}
              >
                {tabData.map((e, i) => (
                  <Tab
                    icon={
                      <img
                        src={e.cover}
                        alt=""
                        width={50}
                        style={{ borderRadius: 25, marginRight: 8 }}
                      />
                    }
                    iconPosition="start"
                    label={e.gatcha_name}
                    key={i}
                    value={`${e.row_no}`}
                    onClick={() => history.push(`/award/setaward/${e.row_no}`)}
                  />
                ))}
              </Tabs>

              {/* </Box> */}
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

// Setting default values for the props of BaseLayout
BaseLayout.defaultProps = {
  stickyNavbar: false,
};

// Typechecking props for BaseLayout
BaseLayout.propTypes = {
  stickyNavbar: PropTypes.bool,
  children: PropTypes.node.isRequired,
  tabValues: PropTypes.number.isRequired,
  tabHandler: PropTypes.func.isRequired,
  tabData: PropTypes.array,
};

export default BaseLayout;
