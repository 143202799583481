import { useEffect, useState } from "react";
import { HOST } from "utils/config";
import { get } from "utils/helper";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import "./index.scss";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Navbar from "memberpage/pages/navbar";
import { useDispatch, connect } from "react-redux";
function Default({ ...rest }) {
  const { authentication } = rest;
  const [isLoading, setIsLoading] = useState(false);
  const [dataLotto, setDataLotto] = useState([]);

  const fetchNewsLotto = async () => {
    try {
      setIsLoading(true);
      const { data } = await get(`${HOST}api/Announcement/GetAnnouncementDashboard`);
      setDataLotto(data.results);
      setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchNewsLotto();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {authentication?.user?.role === "Member" && <Navbar />}
      <div className="dashboard">
        {dataLotto?.map((e, i) => (
          <div className="news-card" key={i}>
            <div className="image-contain">
              <img src={e.cover} />
            </div>
            <div className="text-contain">
              <p className="text-title">{e.title}</p>
              <p className="text-desc">{e.description}</p>
              <p className="text-desc">ทีมแอดมิน AG NanaLotto</p>
            </div>
          </div>
        ))}
      </div>
    </DashboardLayout>
  );
}
const mapStateToProps = (state) => {
  return state;
};

// CONNECT REDUX
export default connect(mapStateToProps)(Default);
