/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { HOST } from "utils/config";
import { get, post } from "utils/helper";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Footer from "layouts/Footer";
import LottoProjectCard from "./LottoCard/LottoProjectCard";

// Project page components

import DialogDetail from "./DialogDetail";
import DialogNumber from "./DialogNumber";
import DialogUser from "./DialogUser";

function Purchasedetails() {
  // ComplexProjectCard dropdown menu state
  const [open, setOpen] = useState(false);
  const [openNumber, setOpenNumber] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [gatchaUserList, setGatchaUserList] = useState([]);
  const [lottoList, setLottoList] = useState([]);
  const [gatchaType, setGatchaType] = useState([]);
  const [gatchaNumber, setGatchaNumber] = useState([]);
  const fetchGetDetailBuy = async () => {
    const { data } = await get(`${HOST}api/Buy/GetDetailBuy`);

    setLottoList(data.results);
  };
  useEffect(() => {
    fetchGetDetailBuy();
  }, []);
  // TeamProfileCard dropdown menu handlers

  const fetchGetDetailBuyByGatchaType = async (gatchaid) => {
    // const payload = new FormData();

    // payload.append("gatcha_drawing_configs_id", gatchaid);
    const { data } = await get(
      `${HOST}api/Buy/GetDetailBuyByGatchaType?gatcha_drawing_configs_id=${gatchaid}`
    );

    setGatchaType(data.results);
    handleClickOpen();
  };
  const fetchGetDetailBuyByGatchaTypeNumber = async (gatchaid) => {
    // const payload = new FormData();

    // payload.append("gatcha_drawing_configs_id", gatchaid);
    const { data } = await get(
      `${HOST}api/Buy/GetDetailBuyByGatchaTypeNumber?gatcha_drawing_configs_id=${gatchaid}`
    );

    setGatchaNumber(data.results);
    setOpenNumber(true);
  };
  const GetDetailBuyByGatchaTypeUserList = async (gatchaid) => {
    // const payload = new FormData();

    // payload.append("gatcha_drawing_configs_id", gatchaid);
    const { data } = await get(
      `${HOST}api/Buy/GetDetailBuyByGatchaTypeUserList?gatcha_drawing_configs_id=${gatchaid}`
    );

    setGatchaUserList(data.results);
    setOpenUser(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenNumber(false);
    setOpenUser(false);
  };
  return (
    <DashboardLayout>
      <SuiBox pt={5} pb={2}>
        <SuiBox mt={{ xs: 1, lg: 3 }} mb={1}>
          <Grid container spacing={3}>
            {lottoList.map((e, i) => (
              <Grid item xs={12} md={6} lg={4} key={i}>
                <LottoProjectCard
                  image={e.cover}
                  title={e.name}
                  subtitle={e.gatcha_drawing_date_display}
                  description={e.gatcha_drawing_date_text}
                  lottoin={e.receive_discount_total}
                  lottoout={e.export_buy_total}
                  gatchadrawingconfigsid={e.gatcha_drawing_id}
                  gatchatype={fetchGetDetailBuyByGatchaType}
                  gatchatypenumber={fetchGetDetailBuyByGatchaTypeNumber}
                  gatchatypeuserlist={GetDetailBuyByGatchaTypeUserList}
                  setDialogOpen={handleClickOpen}
                />
              </Grid>
            ))}
          </Grid>
        </SuiBox>
      </SuiBox>
      <DialogDetail open={open} onClose={handleClose} dataTypeDetail={gatchaType} />
      <DialogNumber open={openNumber} onClose={handleClose} dataTypeDetail={gatchaNumber} />
      <DialogUser open={openUser} onClose={handleClose} dataTypeDetail={gatchaUserList} />
      <Footer />
    </DashboardLayout>
  );
}

export default Purchasedetails;
