import React from "react";
// Soft UI Dashboard PRO React example components
import ChangePassword from "./ChangePassword";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
const SettingProfile = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ChangePassword />
      <Footer />
    </DashboardLayout>
  );
};

export default SettingProfile;
