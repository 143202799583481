import { useState, useEffect } from "react";

import SuiBadgeDot from "components/SuiBadgeDot";
import SuiTypography from "components/SuiTypography";
// @mui material components

import { useParams, useHistory } from "react-router";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";
// Reports page components
import Reviews from "memberpage/pages/users/reports/components/Reviews";
import SuiButton from "components/SuiButton";
import { Skeleton } from "@mui/material";

import { LootoList20 } from "components/Text/TextStyle";

const columns = [
  { name: "number", align: "center", label: "ลำดับ" },
  { name: "round", align: "center", label: "รอบ" },
  { name: "dateOut", align: "center", label: "วันที่ออก" },
  { name: "openclose", align: "center", label: "ตลาดเปิด-ปิด" },
  { name: "status", align: "center", label: "สถานะ" },
  { name: "createdate", align: "center", label: "วันที่สร้าง" },
  { name: "action", align: "center", label: "" },
];
function ListInstallmentSupposedly() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [datalistlotto, setdataListLotto] = useState([]);

  const { gid, lid } = useParams();
  const [rows, setRows] = useState([]);
  const fetchConfigList = async () => {
    try {
      setLoading(true);

      const { data } = await get(
        `${HOST}api/MasterLotto/GetLottodrawingConfigList?gatcha_id=${lid}`
      );
      setdataListLotto(data.results);

      const rownaja = data.results.map((elem) => ({
        number: elem.row_no,
        round: elem.cycle,
        dateOut: elem.drawing_date_display,
        openclose: (
          <SuiBox>
            {elem.open_maket_display} - {elem.close_maket_display}
          </SuiBox>
        ),
        status: (
          <SuiBox ml={-1.325}>
            <SuiBadgeDot size="small" badgeContent={elem.status ? "เปิด" : "ปิด"} />
          </SuiBox>
        ),
        createdate: elem.updated_date_display,
        action: (
          <SuiBox w={"100%"} display="flex">
            <div style={{ margin: "0px 2px" }} />
            <SuiButton buttonColor={"success"} onClick={() => history.push(`/supposedly`)}>
              คาดคะเนดารออกหวย
            </SuiButton>
          </SuiBox>
        ),
      }));

      setRows(rownaja);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchConfigList();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox py={3} display="flex" justifyContent="space-between">
          <LootoList20>รายการงวดหวย</LootoList20>
        </SuiBox>
        {loading ? (
          <SuiBox>
            <Skeleton variant="rectangular" width={"auto"} height={500} />
          </SuiBox>
        ) : rows.length > 0 ? (
          <Table columns={columns} rows={rows} />
        ) : (
          <SuiBox textAlign="center">ไม่พบข้อมูล</SuiBox>
        )}
      </SuiBox>

      <Footer />
    </DashboardLayout>
  );
}

export default ListInstallmentSupposedly;
