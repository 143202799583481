/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import SuiBadgeDot from "components/SuiBadgeDot";
import Swal from "sweetalert2";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Switch } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "components/Tables/Table";
import { post, get } from "utils/helper";
import { HOST } from "utils/config";
// Reports page components
import Reviews from "memberpage/pages/users/reports/components/Reviews";
import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import FormField from "components/FormField";
// Data
import { Formik, Form, FieldArray, FastField } from "formik";
function DialogAddGroup({ open, onClose, onSuccess }) {
  const onhandleSubmit = async (event) => {
    const payload = new FormData();
    payload.append("name", event.name);
    payload.append("status", event.status);
    try {
      const { data } = await post(`${HOST}api/MasterLotto/AddGroup`, payload);
      Swal.fire("บันทึก", "คุณได้บันทึกสำเร็จ", "success").then((result) => {
        onClose();
        onSuccess();
      });
    } catch (error) {
      Swal.fire("เกิดข้อผิดพลาด", error.response.data.errorMessage, "error");
      console.log(error);
    }
  };
  function validateAmount(value) {
    let error;

    if (!value) {
      error = "โปรดกรอกชื่อกลุ่มหวย";
    }

    return error;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
      <DialogTitle>เพิ่มกลุ่มหวย</DialogTitle>

      <Formik
        initialValues={{
          name: "",
          status: true,
        }}
        // validationSchema={}
        onSubmit={onhandleSubmit}
      >
        {(formProps) => (
          <Form autoComplete="off">
            <DialogContent>
              <SuiBox>
                <SuiBox>
                  <SuiTypography variant="caption">ชื่อกลุ่มหวย</SuiTypography>
                  <FormField
                    type="text"
                    placeholder="โปรดกรอกชื่อกลุ่มหวย"
                    name={`name`}
                    onChange={formProps.handleChange}
                    value={formProps.values.name}
                    validate={(e) => validateAmount(e)}
                  />

                  <SuiBox>สถานะ</SuiBox>
                  <SuiBox>
                    <SuiTypography variant="BUTTON">
                      {formProps.values.status ? "เปิด" : "ปิด"}{" "}
                    </SuiTypography>
                    <Switch
                      checked={formProps.values.status}
                      onChange={() => formProps.setFieldValue(`status`, !formProps.values.status)}
                    />
                  </SuiBox>

                  <Divider />
                </SuiBox>
              </SuiBox>
            </DialogContent>
            <DialogActions>
              <SuiButton buttonColor="error" onClick={onClose}>
                ยกเลิก
              </SuiButton>
              <SuiButton type="submit" buttonColor="success">
                เพิ่มกลุ่มหวย
              </SuiButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
DialogAddGroup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};
export default DialogAddGroup;
